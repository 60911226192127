import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from "@angular/core";
import { EditTabs } from "@features/workspaces/modals/edit-workspace-modal/edit-workspace-modal.component";
import { WorkspaceModalService } from "@features/workspaces/services/workspace-modal.service";
import { Workspace } from "@model-main/workspaces/workspace";

const DESCRIPTION_HEIGHT_THRESHOLD = 64;

@Component({
    selector: 'workspace-header',
    templateUrl: './workspace-header.component.html',
    styleUrls: ['./workspace-header.component.less'],
})
export class WorkspaceHeaderComponent implements OnChanges, AfterViewInit, OnDestroy {
    avatars: string[];
    canExpand = false;
    isExpanded = false;
    hasGroups = false;
    resizeObserver?: ResizeObserver;

    @Input() workspace: Workspace;
    @ViewChild('description') descriptionEl: ElementRef<HTMLElement>;

    constructor(
        private workspaceModal: WorkspaceModalService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { workspace } = changes;
        if (workspace) {
            // Update avatars
            this.avatars = this.workspace.permissions
                .filter(permission => permission.user)
                .slice(0, 3)
                .map(permission => permission.user as string);
            
            this.hasGroups = this.workspace.permissions.some(permission => permission.group);
        }
    }

    ngAfterViewInit(): void {
        const content = this.descriptionEl.nativeElement.firstElementChild;
        if (content) {
            this.resizeObserver = new ResizeObserver(entries => {
                if (entries.length > 0) {
                    const height = entries[0].contentRect.height;
                    if (height <= DESCRIPTION_HEIGHT_THRESHOLD) {
                        this.canExpand = false;
                        this.setExpanded(false);
                    } else {
                        this.canExpand = true;
                    }
                }
            });
            this.resizeObserver.observe(content);
        }
    }

    ngOnDestroy(): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    openDeleteWorkspaceModal(): void {
        this.workspaceModal.deleteWorkspace(this.workspace);
    }

    openEditWorkspaceModal(): void {
        this.workspaceModal.editWorkspace(this.workspace, 'header');
    }

    openMembersModal(): void {
        this.workspaceModal.editWorkspace(this.workspace, 'header', [EditTabs.PERMISSIONS]);
    }

    setExpanded(expanded: boolean): void {
        if (expanded !== this.isExpanded) {
            this.isExpanded = expanded;
            this.descriptionEl.nativeElement.scrollTop = 0;
        }
    }

}
