import type {CardResult, _CardResult} from './card-result';
import type {Card} from './card';
import type {Filter} from './../../compute/filtering/filter';
import type {HeatmapParams} from './common/heatmap-params';
import type {SplitBySpec} from './../models/split-by-spec';
import type {_MultivariateCard} from './multivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.PCACard
 */
export interface PCACard extends _MultivariateCard {
    colorBy?: SplitBySpec | null;
    heatmapParams: HeatmapParams;
    showComponents: boolean;
    showLoadingPlot: boolean;
    showScatterPlot: boolean;
    showScreePlot: boolean;

    // PolyJson type
    type: 'pca';
}

export namespace PCACard {
    export const type = 'pca';
}

/** @deprecated Use Card.isPCACard() */ export function isPCACard(toTest: Card): toTest is PCACard { return PCACard.type === toTest['type']; }

export namespace PCACard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.PCACard$PCACardResult
     */
    export interface PCACardResult extends _CardResult {
        components: number[][];
        explainedVariance: number[];
        explainedVarianceRatio: number[];
        groups: Filter[];
        partialErrors?: string[] | null;
        projections: number[][][];

        // PolyJson type
        type: 'pca';
    }

    export namespace PCACardResult {
        export const type = 'pca';
    }

    /** @deprecated Use CardResult.isPCACardResult() */ export function isPCACardResult(toTest: CardResult): toTest is PCACardResult { return PCACardResult.type === toTest['type']; }
}