export namespace ITaggingService {
    /**
     * Generated from com.dataiku.dip.server.services.ITaggingService$TaggableType
     */
    export enum TaggableType {
        PROJECT = 'PROJECT',
        FLOW_ZONE = 'FLOW_ZONE',
        DATASET = 'DATASET',
        RECIPE = 'RECIPE',
        SQL_NOTEBOOK = 'SQL_NOTEBOOK',
        JUPYTER_NOTEBOOK = 'JUPYTER_NOTEBOOK',
        ANALYSIS = 'ANALYSIS',
        SAVED_MODEL = 'SAVED_MODEL',
        MODEL_EVALUATION_STORE = 'MODEL_EVALUATION_STORE',
        INSIGHT = 'INSIGHT',
        MANAGED_FOLDER = 'MANAGED_FOLDER',
        LAMBDA_SERVICE = 'LAMBDA_SERVICE',
        SCENARIO = 'SCENARIO',
        DASHBOARD = 'DASHBOARD',
        WEB_APP = 'WEB_APP',
        CODE_STUDIO = 'CODE_STUDIO',
        REPORT = 'REPORT',
        STREAMING_ENDPOINT = 'STREAMING_ENDPOINT',
        ARTICLE = 'ARTICLE',
        STATISTICS_WORKSHEET = 'STATISTICS_WORKSHEET',
        WORKSPACE = 'WORKSPACE',
        MODEL_COMPARISON = 'MODEL_COMPARISON',
        LABELING_TASK = 'LABELING_TASK',
        API_DEPLOYER_INFRA = 'API_DEPLOYER_INFRA',
        API_DEPLOYER_SERVICE = 'API_DEPLOYER_SERVICE',
        API_DEPLOYER_DEPLOYMENT = 'API_DEPLOYER_DEPLOYMENT',
        PROJECT_DEPLOYER_INFRA = 'PROJECT_DEPLOYER_INFRA',
        PROJECT_DEPLOYER_PROJECT = 'PROJECT_DEPLOYER_PROJECT',
        PROJECT_DEPLOYER_DEPLOYMENT = 'PROJECT_DEPLOYER_DEPLOYMENT',
        WORKSPACE_LINK = 'WORKSPACE_LINK',
        CODE_STUDIO_TEMPLATE = 'CODE_STUDIO_TEMPLATE'
    }
}