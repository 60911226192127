// @ts-ignore
import IntervalScale from 'echarts/lib/scale/Interval';
import _, { List } from 'lodash';

export function getChartNumericalAxisMaxTickLength(splitXAxis: number, minVal: number, maxVal: number): number {
    const interval = new IntervalScale();
    interval.setExtent(minVal, maxVal);
    interval.calcNiceExtent({ splitXAxis, fixMin: true, fixMax: true });
    const ticks: ({ value: number })[] = interval.getTicks();
    const tickLengths = ticks.map(it => String(it.value).length)
    return Math.max(...tickLengths);
}

// Stricter zip that only supports array of same size, and that can't generate undefined
// (useful to comply to echarts type definitions)
export function zipSameSize<T>(arr1: List<T>, arr2: List<T>): T[][] {
    if(arr1.length != arr2.length) {
        throw new Error("Not the same size");
    }
    return _.zip(arr1, arr2) as unknown as T[][];
}
