import { Injectable } from '@angular/core';
import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PrivateCatalogAPI {
    constructor(
        private dkuHttp: DkuHttpService,
    ) { }

    flush(): Observable<void> {
        return this.dkuHttp.request('GET', '/catalog/flush');
    }
}
