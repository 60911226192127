import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Workspace } from "src/generated-sources";
import { WorkspaceListType, WorkspaceObjectStarEvent } from "../workspace-object-list-element/workspace-object-list-element.component";

@Component({
    selector: 'workspace-object-list',
    templateUrl: './workspace-object-list.component.html',
    styleUrls: ['./workspace-object-list.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceObjectListComponent {

    @Input() workspaceKey: string;
    @Input() objects: Workspace.WorkspaceObject[];
    @Input() displayType = WorkspaceListType.TILE;
    @Input() canRemove = false;
    @Output() removeObject = new EventEmitter<Workspace.WorkspaceObject>();
    @Output() toggleStar = new EventEmitter<WorkspaceObjectStarEvent>();

    onToggleStar(event: WorkspaceObjectStarEvent): void {
        this.toggleStar.emit(event);
    }

    onRemoveObject(object: Workspace.WorkspaceObject): void {
        this.removeObject.emit(object);
    }

}
