import { Injectable } from '@angular/core';
import { ImageMetaData } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { StaticCanvas } from 'fabric/fabric-impl';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeedImageModalService {
    private canvasSource$ = new Subject<StaticCanvas>();
    private imageIdSource$ = new ReplaySubject<number>(1);
    private imageSource$ = new ReplaySubject<ImageMetaData>(1);

    canvas$ = this.canvasSource$.asObservable();
    imageId$ = this.imageIdSource$.asObservable();
    image$ = this.imageSource$.asObservable();

    constructor() { }

    setCanvas(canvas: StaticCanvas) {
        this.canvasSource$.next(canvas);
    }
    setImageId(id: number) {
        this.imageIdSource$.next(id);
    }

    setImage(im: ImageMetaData) {
        this.imageSource$.next(im)
    }


}
