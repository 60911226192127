import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PainterService } from '@shared/services/painter.service';
import { DeepHubPredictionModelDetails } from 'src/generated-sources';
import { DeephubObjectDetectionInteractiveScoringPainterService } from '../services/deephub-object-detection-interactive-scoring-painter.service';
import { InteractiveScoringService } from "@features/deephub/computer-vision/report/interactive-scoring/interactive-scoring-data.service";
import { DeephubInteractiveScoringDataFetcherService } from '@features/deephub/computer-vision/report/interactive-scoring/deephub-report-interactive-scoring-data-fetcher.service';
import { ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { CarouselService } from '@shared/services/carousel.service';
import { DeephubObjectDetectionInteractiveScoringCellData, ObjectDetectionInteractiveScoringService } from './object-detection-interactive-scoring.service';

@Component({
    selector: 'deephub-object-detection-report-interactive-scoring',
    templateUrl: './deephub-object-detection-report-interactive-scoring.component.html',
    styleUrls: ['./deephub-object-detection-report-interactive-scoring.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        CarouselService,
        ColorMapContextService,
        ObjectDetectionInteractiveScoringService,
        { provide: InteractiveScoringService, useExisting: ObjectDetectionInteractiveScoringService }, 
        DeephubObjectDetectionInteractiveScoringPainterService,
        { provide: PainterService, useExisting: DeephubObjectDetectionInteractiveScoringPainterService },
        DeephubInteractiveScoringDataFetcherService,
        { provide: ImagesDataFetcherService, useExisting: DeephubInteractiveScoringDataFetcherService }
    ]
})
export class DeephubObjectDetectionReportInteractiveScoringComponent implements OnInit {
    @Input() modelData: DeepHubPredictionModelDetails;
    threshold: number;
    DeephubObjectDetectionInteractiveScoringCellData = DeephubObjectDetectionInteractiveScoringCellData;

    constructor() { }

    ngOnInit() {
        this.threshold = (this.modelData.perf as any).optimalThreshold! || this.modelData.userMeta.activeClassifierThreshold;
    }
}
