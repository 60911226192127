<deephub-report-interactive-scoring [modelData]="modelData" [headerTemplate]="headerTemplate" [imageDetailsTemplate]="imageDetailsTemplate"></deephub-report-interactive-scoring>

<ng-template #headerTemplate>
    <label class="threshold-form mbot0 cursor-initial" for="threshold-setter">
        <span>Confidence score</span>
        <span class="threshold-range">
            <input id="threshold-setter" class="threshold-setter mleftright4" type="range" min="0" max="1" step="0.05" [(ngModel)]="threshold" />
        </span>
        <code class="threshold-value">{{ threshold | number: '1.2-2' }}</code>
    </label>
</ng-template>

<ng-template #imageDetailsTemplate let-cellData="cellData">
    <deephub-object-detection-report-image-section class="interactive-scoring-selected-image-section" *ngIf="cellData | instanceOf: DeephubObjectDetectionInteractiveScoringCellData" [cellData]="cellData" [threshold]="threshold"></deephub-object-detection-report-image-section>
</ng-template>