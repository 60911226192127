import { Component, HostListener, OnInit } from "@angular/core";
import { LabelingShortcutService, ShortcutAction } from "@features/labeling/services/labeling-shortcut.service";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { map, switchMap, withLatestFrom } from "rxjs/operators";
import { LabelingReviewImagePathService } from "../services/labeling-review-image-path.service";
import { LabelingReviewAnnotationGroupService } from "../services/labeling-review-annotation-group.service";
import { LabelingReviewUpdateService } from "../services/labeling-review-update-service";

@UntilDestroy()
@Component({
    selector: 'review-image-header',
    templateUrl: './review-image-header.component.html',
    styleUrls: ['./review-image-header.component.less'],

})
export class ReviewImageHeaderComponent implements OnInit {
    private keyUpEvent$ = new Subject<KeyboardEvent>();

    saveTrigger$ = new Subject<void>();
    resetModificationsTrigger$ = new Subject<void>();
    copyPermalinkTrigger$ = new Subject<void>();
    canSave$: Observable<boolean>;
    shouldSave$: Observable<boolean>;
    conflicting$: Observable<boolean>;

    constructor(
        public labelingReviewImagePathService: LabelingReviewImagePathService,
        public labelingReviewUpdateService: LabelingReviewUpdateService,
        public labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        public labelingService: LabelingService,
        private labelingShortcutService: LabelingShortcutService
    ) { }

    ngOnInit(): void {
        this.canSave$ = combineLatest([
            this.labelingReviewUpdateService.updatingReview$,
            this.labelingReviewUpdateService.currentReview$,
        ]).pipe(
            switchMap(([updatingReview, currentReview]) => {
                if (updatingReview) {
                    return of(currentReview != null && currentReview.annotations.length > 0
                        && currentReview.annotations.every((a) => !!a.category));
                } 

                return this.labelingReviewAnnotationGroupService.annotationGroupList$.pipe(
                    map(annotationGroupList => annotationGroupList.length > 0 && 
                        !annotationGroupList.some(annotationGroup => annotationGroup.hasConflictingCategory() || annotationGroup.hasMissingCategory()) && 
                        !annotationGroupList.some(annotationGroup => annotationGroup.annotations.length === 0)
                    )
                );  
            })
        );
        this.conflicting$ = combineLatest([
            this.labelingReviewUpdateService.updatingReview$,
            this.labelingService.getMinNbAnnotators()
        ]).pipe(
            switchMap(([updatingReview, minNbAnnotators]) => {
                if (updatingReview) {
                    return of(false);
                } 

                return this.labelingReviewAnnotationGroupService.annotationGroupList$.pipe(
                    map(annotationGroupList => annotationGroupList.some(annotationGroup => annotationGroup.hasConflict(minNbAnnotators)))
                );  
            })
        );
        
        this.resetModificationsTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.labelingReviewUpdateService.updatingReview$)
        ).subscribe(([_trigger, updatingReview]) => {
            updatingReview ? this.labelingReviewUpdateService.resetReview() : this.labelingReviewAnnotationGroupService.resetAnnotationGroupList() 
        });

        this.saveTrigger$.pipe(
            withLatestFrom(
                this.labelingReviewUpdateService.updatingReview$,
                this.labelingReviewUpdateService.currentReview$,
                this.labelingReviewImagePathService.isLast$,
                this.labelingReviewImagePathService.currentImagePath$,
                this.labelingReviewAnnotationGroupService.annotationGroupList$,
            ),
            switchMap(([_trigger, update, currentReview, isLast, path, annotationGroupList]) => {
                if (isLast) {
                    this.labelingReviewImagePathService.finishedReview$.next(true);
                }

                if (update) {
                    return this.labelingService.resolveRecordFromAnnotation(currentReview, path!);
                } else {
                    return this.labelingService.resolveRecordFromAnnotationGroupList(annotationGroupList, path!);
                }
            }),
            untilDestroyed(this),
        ).subscribe(() => {
            this.labelingReviewImagePathService.nextImage();
        });

        this.copyPermalinkTrigger$.pipe(
            withLatestFrom(this.labelingReviewImagePathService.currentImagePath$),
        ).subscribe(([, imagePath]) => this.labelingService.copyPermalinkToClipboard(imagePath));

        this.keyUpEvent$.pipe(
            withLatestFrom(
                this.labelingReviewImagePathService.isLast$,
                this.labelingReviewImagePathService.isFirst$,
            ),
            untilDestroyed(this),
        ).subscribe(([event, isLast, isFirst]) => {
            if (this.labelingShortcutService.isShortcut(event, ShortcutAction.BACK) && !isFirst) {
                this.previousImage();
            }

            if (this.labelingShortcutService.isShortcut(event, ShortcutAction.NEXT) && !isLast) {
                this.nextImage();
            }
        });
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.keyUpEvent$.next(event);
    }


    nextImage() {
        this.labelingReviewImagePathService.nextImage();
    }

    previousImage() {
        this.labelingReviewImagePathService.previousImage();
    }

    saveReview() {
        this.saveTrigger$.next();
    }

    resetModifications() {
        this.resetModificationsTrigger$.next();
    }

    copyPermalink() {
        this.copyPermalinkTrigger$.next();
    }
 }
