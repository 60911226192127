import type {LabelingAnswer} from './labeling-answer';
import type {LabelingScoreComputer} from './score/labeling-score-computer';

/**
 * Generated from com.dataiku.dip.labeling.LabelingTaskStats
 */
export interface LabelingTaskStats {
    globalRecordsStat: LabelingTaskStats.RecordsStat;
    perAnnotatorRecordsStats: {[key: string]: LabelingTaskStats.AnswersStat};
    perClassCount: {[key: string]: number};
}

export namespace LabelingTaskStats {
    /**
     * Generated from com.dataiku.dip.labeling.LabelingTaskStats$RecordStatus
     */
    export enum RecordStatus {
        TO_LABEL = 'TO_LABEL',
        PARTIALLY_LABELED = 'PARTIALLY_LABELED',
        READY_FOR_REVIEW = 'READY_FOR_REVIEW',
        REVIEWED = 'REVIEWED'
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTaskStats$AnswersStat
     */
    export interface AnswersStat {
        perAnswerStatusCount: {[key in LabelingAnswer.AnswerStatus]: number};
        score: LabelingScoreComputer.AnswerScore;
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTaskStats$RecordsStat
     */
    export interface RecordsStat {
        perRecordStatusCount: {[key in LabelingTaskStats.RecordStatus]: number};
    }
}