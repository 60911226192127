<button mat-button (click)="select.open()" class="btn btn--text btn--primary">
    <ng-content></ng-content>
</button>
<mat-select #select multiple disableOptionCentering
    class="select-button__input"
    (selectionChange)="selectionChange.emit($event)"
    [ngModel]="selection">
    <mat-option class="select-button__search">
        <ngx-mat-select-search
            [(ngModel)]="filter"
            [hideClearSearchButton]="true"
            [noEntriesFoundLabel]="searchNoEntry ?? ''"
            [placeholderLabel]="searchPlaceholder ?? ''">
        </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let option of (options | filter: filter:labelKey); index as i" [value]="option | map:mapValue:this" class="select-button__option">
        <ng-template #defaultTemplate>{{ option | map:mapLabel:this }}</ng-template>
        <ng-container
            [ngTemplateOutlet]="optionTemplateRef || defaultTemplate"
            [ngTemplateOutletContext]="{ $implicit: option, index: i }"
        ></ng-container>
    </mat-option>
</mat-select>
