<form [formGroup]="configForm" id="config-form" class="dkuform-modal-horizontal">
    <div class="control-group" [qa]="'ts-series-variable'">
        <label class="control-label">Series variable</label>
        <single-variable-selector
            [variables]="(seriesVariables$ | async) ?? []"
            formControlName="seriesColumn"
        ></single-variable-selector>
    </div>

    <div class="control-group" [qa]="'ts-time-variable'">
        <label class="control-label">
            Time variable
            <i class="icon-info-sign mleft4" matTooltip="The time variable must hold ISO8601 formatted date times"></i>
        </label>
        <single-variable-selector
            [variables]="(timeVariables$ | async) ?? []"
            formControlName="timeColumn"
        ></single-variable-selector>
    </div>

    <div class="control-group" [qa]="'ts-use-long-format'">
        <label class="control-label">Data has multiple series (long format)</label>
        <input type="checkbox" class="control" formControlName="useLongFormat" />
    </div>

    <div class="control-group identifiers" *ngIf="useLongFormat" [qa]="'ts-series-id'">
        <label class="control-label">Select a single time series</label>

        <editable-list
            *ngIf="uiData$ | async as uiData"
            [itemsFormArray]="seriesIdColumnsForm"
            [hasDivider]="false"
            [focusOnEnter]="false"
            addLabel="Add identifier"
        >
            <ng-template
                let-item="item"
                let-index="index"
                let-goToNext="goToNext"
            >
                <editable-list-template (onInputEnter)="goToNext($event)" [qa]="'ts-series-id-' + index">
                    <div class="identifier-column" [qa]="'ts-series-id-column'">
                        <single-variable-selector
                            [variables]="uiData.selectableColumns[index] || []"
                            [formControl]="item.get('column')"
                        ></single-variable-selector>
                    </div>

                    <div class="identifier-value" [qa]="'ts-series-id-value'">
                        <editable-list-input
                            [inputControl]="item.get('value')"
                            [suggestions]="uiData.suggestedValues[(item.get('column')?.value?.name)] || []"
                            placeholder="Identifier value"
                            autocomplete="off"
                        ></editable-list-input>
                    </div>
                </editable-list-template>
            </ng-template>
        </editable-list>
    </div>

    <ng-container [ngSwitch]="params.type">
        <ng-container *ngSwitchCase="'unit_root_test_adf'" formGroupName="adfOptions">
            <div class="control-group">
                <label class="control-label">Regression mode</label>
                <dku-bs-select-form-control
                    [list]="adfRegressionOptions"
                    formControlName="regressionMode"
                    ngOptions="v.value as v.name for v in list"
                ></dku-bs-select-form-control>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'unit_root_test_za'" formGroupName="zaOptions">
            <div class="control-group">
                <label class="control-label">Regression mode</label>
                <dku-bs-select-form-control
                    [list]="zaRegressionOptions"
                    formControlName="regressionMode"
                    ngOptions="v.value as v.name for v in list"
                ></dku-bs-select-form-control>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'unit_root_test_kpss'" formGroupName="kpssOptions">
            <div class="control-group">
                <label class="control-label">Regression mode</label>
                <dku-bs-select-form-control
                    [list]="kpssRegressionOptions"
                    formControlName="regressionMode"
                    ngOptions="v.value as v.name for v in list"
                ></dku-bs-select-form-control>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'acf_plot'" formGroupName="acfPlotOptions">
            <div class="control-group">
                <label class="control-label">Show series summary</label>
                <input type="checkbox" class="control" formControlName="showSummary" />
            </div>

            <div class="control-group">
                <label class="control-label">Compute partial auto-correlation</label>
                <input type="checkbox" class="control" formControlName="isPartial" />
            </div>

            <div class="control-group" *ngIf="isPartialACF">
                <label class="control-label">Method</label>
                <dku-bs-select-form-control
                    [list]="pacfMethodOptions"
                    formControlName="pacfMethod"
                    ngOptions="v.value as v.name for v in list"
                ></dku-bs-select-form-control>
            </div>

            <div class="control-group" *ngIf="!isPartialACF">
                <label class="control-label">Adjust for the loss of data</label>
                <input type="checkbox" class="control" formControlName="adjusted" />
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="isAutoComputeLagsEnabled">
        <div class="control-group">
            <label class="control-label">Automatically compute the lags</label>
            <input type="checkbox" class="control" formControlName="autoComputeLags" />
        </div>

        <div class="control-group" *ngIf="!autoComputeLags">
            <label class="control-label">Number of lags</label>
            <input type="number" class="control" formControlName="nLags" min="1" />
        </div>
    </ng-container>

    <api-error-alert
        [apiError]="error"
        (resetError)="pushError(null)"
        [closable]="true"
    ></api-error-alert>
</form>
