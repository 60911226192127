import { Workspace } from "src/generated-sources";

export const EMPTY_WORKSPACE: Workspace = {
    color: '',
    workspaceKey: '',
    displayName: '',
    description: '',
    permissions: [],
    currentUserPermissions: {
        admin: true,
        write: true,
        read: true,
    },
    workspaceObjects: [],
    checklists: { checklists: [] },
    tags: [],
    customFields: {},
};

export const WORKSPACE_STOCK_COLORS = [ '#540d6e', '#ee4266', '#ffd23f', '#3bceac', '#43aa8b', '#118ab2', '#073b4c' ];

export enum ROLES {
    ADMIN = 'admin',
    CONTRIBUTOR = 'write',
    MEMBER ='read',
};

export const DEFAULT_ROLE = ROLES.CONTRIBUTOR;

export type Role = { role: string, label: string, description: string };

export const ROLE_LIST: Role[] = [
    { role: ROLES.ADMIN, label: 'Admin', description: 'All contributors privileges, plus the ability to manage workspace and its users.' },
    { role: ROLES.CONTRIBUTOR, label: 'Contributor', description: 'Contributors can add and remove content from workspace.' },
    { role: ROLES.MEMBER, label: 'Member', description: 'Members can interact with workspace content.' },
];

export const DEFAULT_PERMISSION = {
    admin: false,
    write: true,
    read: true,
};
