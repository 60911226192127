<div class="modal modal-root feed-image-modal">
    <modal-header
        [title]="currentImage?.cellData?.itemPath || ''"
    ></modal-header>
    <div class="modal-body flex modal-body--analysis no-padding horizontal-flex">
        <div class="feed-image-modal__canvas">
            <carousel *ngIf="currentImage" [index]="currentImage.cellData.imageId" [itemCount]="currentImage.rawChunk.totalKeptRows" (change)="setImageId($event)">
                <feed-image-modal-canvas class="w100 h100" [cellData]="currentImage.cellData" (imageStatusChanged)="updateImageStatus($event)" (canvasUpdate)="onCanvasUpdate($event)" [options]="imageOptions"></feed-image-modal-canvas>
            </carousel>
        </div>
        <div class="feed-image-modal__meta">
            <ng-container *ngIf="currentImage">
                <ng-container *ngTemplateOutlet="templateRef, context: { image: currentImage }"></ng-container>
            </ng-container>
        </div>
    </div>
</div>
