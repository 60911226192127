import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { WorkspaceModalService } from "@features/workspaces/services/workspace-modal.service";
import { WorkspaceSecurityService } from "@features/workspaces/services/workspace-security.service";
import { Workspace } from "@model-main/workspaces/workspace";

@Component({
    selector: 'workspaces-left-menu',
    templateUrl: './workspaces-left-menu.component.html',
    styleUrls: ['./workspaces-left-menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspacesLeftMenuComponent {
    canCreateWorkspaces = false;

    @Input() workspaces: Array<Workspace>;
    @Input() selectedWorkspaceKey: string;
    @Output() workspaceSelected = new EventEmitter<string>();

    constructor (
        private workspaceSecurityService: WorkspaceSecurityService,
        private workspaceModal: WorkspaceModalService,
    ) {
        this.canCreateWorkspaces = this.workspaceSecurityService.canCreateWorkspaces();
    }

    trackWorkspace(index: number, workspace: Workspace): string {
        return workspace.workspaceKey;
    }

    selectWorkspace(workspaceKey: string): void {
        this.workspaceSelected.emit(workspaceKey);
    }

    onAddWorkspace(): void {
        this.workspaceModal.createWorkspace();
    }
}
