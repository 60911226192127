import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, ViewContainerRef } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { LabelingCanvasPainterService } from "@features/labeling/services/labeling-image-painter.service";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ModalShape, ModalsService } from "@shared/modals/modals.service";
import { ImagesDataFetcherService } from "@shared/services/images-data-fetcher.service";
import { PainterService } from "@shared/services/painter.service";
import { SampleDataFetcherService } from "@shared/services/sample-data-fetcher.service";
import { resolveSmartName } from "@utils/loc";
import { combineLatest, forkJoin, Observable, of } from "rxjs";
import { map, share, switchMap } from "rxjs/operators";
import { LabelingTask, UsabilityComputer } from "src/generated-sources";
import { LabelingSettingsDataFetcherService } from "../labeling-settings-data-fetcher.service";
import { LabelingSettingsImageFeedModalComponent } from "./labeling-settings-image-feed-modal/labeling-settings-image-feed-modal.component";

@UntilDestroy()
@Component({
    selector: 'labeling-settings-data',
    templateUrl: './labeling-settings-data.component.html',
    styleUrls: [
        './labeling-settings-data.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers:[
        LabelingSettingsDataFetcherService,
        { provide: ImagesDataFetcherService, useExisting: LabelingSettingsDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: LabelingSettingsDataFetcherService },
        { provide: PainterService, useClass: LabelingCanvasPainterService },
    ]
})
export class LabelingSettingsDataComponent implements OnInit, ErrorContext {
    @Output() partialTaskChange = new EventEmitter<Partial<LabelingTask>>();

    managedFolder$: Observable<UsabilityComputer.UsableComputable>;
    metadataColumns$: Observable<string[]>;
    error: APIError;

    form = this.fb.group({
        idColumn: this.fb.control(null, [Validators.required]),
    });

    constructor(private modalsService: ModalsService,
                private viewContainerRef: ViewContainerRef,
                private labelingService: LabelingService,
                private DataikuAPI: DataikuAPIService,
                private labelingSettingsDataFetcherService: LabelingSettingsDataFetcherService,
                private fb: FormBuilder,
                @Inject('$state') private $state: any) {
    }

    ngOnInit() {
        this.labelingService.labelingTaskInfo$.pipe(
            untilDestroyed(this),
            map(info => info.idColumn),
        ).subscribe((idColumn) => {
            this.form.patchValue({idColumn}, {emitEvent: false});
        });

        this.form.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe((formValue) => {
            this.partialTaskChange.emit(formValue);
            this.labelingSettingsDataFetcherService.setIdColumn(formValue.idColumn)
        })

        this.managedFolder$ = combineLatest([
            this.labelingService.getManagedFolderLoc(),
            this.labelingService.listComputables()
        ]).pipe(
            map(([mfLoc, computables]) => computables.filter((computable) => computable.id === `${mfLoc.projectKey}.${mfLoc.id}`)[0])
        )

        this.metadataColumns$ = this.labelingService.labelingTaskInfo$.pipe(
            switchMap((taskInfo) => {
                const datasetLoc = resolveSmartName(taskInfo.projectKey, taskInfo.inputMetadata)
                return this.DataikuAPI.datasets.getFullInfo(taskInfo.projectKey, datasetLoc.projectKey, datasetLoc.id).pipe(catchAPIError(this))
            }),
            map((datasetInfo) => datasetInfo.dataset.schema.columns.map(c => c.name)),
            share(),
        );
    }

    pushError(error: APIError): void {
        this.labelingService.pushError(error);
    }

    goToManagedFolder(managedFolder: UsabilityComputer.UsableComputable) {
        this.$state.go('projects.project.managedfolders.managedfolder.view', {
            projectKey: managedFolder.projectKey,
            odbId: managedFolder.name,
        });
    }

    openModal({imageId}: { imageId?: number }) {
        const columns = this.labelingSettingsDataFetcherService.tableData!.allColumnNames.map((name, index) => ({
            name,
            index
        })).filter(col => col.name !== this.form.get("idColumn")?.value);
        this.modalsService
            .open(LabelingSettingsImageFeedModalComponent, {
                imageId,
                columns,
            }, ModalShape.FULL, this.viewContainerRef)
            .then(() => {}, () => {});
    }
}