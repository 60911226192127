import { Injectable } from "@angular/core";

export enum ShortcutAction {
    DELETE="DELETE",
    BACK="BACK",
    NEXT="NEXT",
    SKIP="SKIP",
    FIRST="FIRST",
    LAST="LAST",
    MULTI_SELECTION="MULTI_SELECTION"
};
@Injectable()
export class LabelingShortcutService {
    
    isShortcut(event: KeyboardEvent, action: ShortcutAction): boolean {
        const shortcuts = {
            MULTI_SELECTION: [event.key === "Shift"],
            DELETE: [event.key === "Delete", event.key === "Backspace"],
            BACK: [event.code === 'ArrowLeft'],
            NEXT: [event.code === 'ArrowRight', event.code === 'Space'],
            SKIP: [event.code === 'Tab'],
            FIRST: [event.code === 'ArrowUp'],
            LAST: [event.code === 'ArrowDown'],
        }
        return shortcuts[action].some((x) => x);
    }
}