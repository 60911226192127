<ng-container *ngIf="availableMetricKeys && availableMetricKeys.length">
    <form class="dkuform-horizontal h100 chart-runs-metrics-form" [formGroup]="form">
        <!-- refresh -->
        <button class="refresh-button link-std mleft8 btn btn--text btn--secondary"
                (click)="refresh()"
                [wt1Click]="'experiment-tracking-refresh-runs'">
            <i class="icon-refresh"></i>
        </button>
        <div class="dib" *ngIf="togglableDisplayMode">
            <button class="btn btn--secondary btn--icon"
                [class.btn--active]="displayMode == DisplayModeEnum.LAST_VALUES"
                (click)="setDisplayMode(DisplayModeEnum.LAST_VALUES)"
                [wt1Click]="'experiment-tracking-runs-charts-display-last-values'" matTooltip="Last values">
                <i class="icon-bar-chart"></i>
            </button>
            <button class="btn btn--secondary btn--icon" [class.btn--active]="displayMode == DisplayModeEnum.STEPS"
                (click)="setDisplayMode(DisplayModeEnum.STEPS)"
                [wt1Click]="'experiment-tracking-runs-charts-display-steps'" matTooltip="Series">
                <i class="icon-dku-ml-hp-scaling-uniform"></i>
            </button>
        </div>
        <div class="dib control" *ngIf="displayMode == DisplayModeEnum.STEPS">
            <label>Display:</label>
            <div>
                <ul class="chart-options-selector btn-group">
                    <button class="btn btn--secondary" (click)="toggleStepsDisplayMode(false)"
                        [ngClass]="{'btn--active' : !displayStepsAsTSDiff}"
                        [wt1Click]="'experiment-tracking-runs-charts-toggle-steps-display-mode'"
                        matTooltip="Display metrics by steps">
                        STEP
                    </button>
                    <button class="btn btn--secondary" (click)="toggleStepsDisplayMode(true)"
                        [ngClass]="{'btn--active' : displayStepsAsTSDiff}"
                        [wt1Click]="'experiment-tracking-runs-charts-toggle-steps-display-mode'"
                        matTooltip="Display metrics as relative time">
                        RELATIVE TIME
                    </button>
                </ul>
            </div>
        </div>
        <div class="dib control">
            <label>Metrics:</label>
            <div>
                <dku-bs-select-form-control formControlName="selectedMetrics" ngOptions="metric for metric in list"
                    [list]="availableMetricKeys" [dataLiveSearch]="true" [dkuMultiple]="true" [layout]="'list'">
                </dku-bs-select-form-control>
            </div>
        </div>
        <div class="dib control" *ngIf="displayMode == DisplayModeEnum.LAST_VALUES">
            <label>X Axis:</label>
            <div>
                <dku-bs-select-form-control formControlName="selectedParameter" ngOptions="param for param in list"
                    [list]="availableParameterKeys ?? []" [dataLiveSearch]="true" [layout]="'list'">
                </dku-bs-select-form-control>
            </div>
        </div>
        <div class="dib control autorefresh-control pull-right">
            <label class="dku-toggle">
                <input type="checkbox" formControlName="autoRefresh">
                <span></span>
            </label>
            Autorefresh mode
        </div>

        <div class="model-comparator">
            <div class="model-comparator-graph-container"
                [ngClass]="{'model-comparator-graph-container-rows' : !pinnedCharts.size}">
                <ng-container *ngFor="let chartOptions of chartsOptions">
                    <div class="model-comparator-graph pinned" *ngIf="isChartPinned(chartOptions.metric)">
                        <span class="title mx-textellipsis" isEllipsisActive
                            [matTooltip]="chartOptions.metric">{{chartOptions.metric}}</span>
                        <span matTooltip="Un-pin this metric" (click)="unpinChart(chartOptions)"
                            [wt1Click]="'experiment-tracking-runs-chart-unpin'" class="resize">
                            <i class="icon-fixed-width icon-dku-unpin-chart"></i>
                        </span>
                        <lazy-echart [options]="chartOptions" (chartInit)="onChartInit($event, chartOptions)">
                        </lazy-echart>
                    </div>
                </ng-container>
                <ng-container *ngFor="let chartOptions of chartsOptions">
                    <div class="noflex model-comparator-graph" *ngIf="!isChartPinned(chartOptions.metric)">
                        <span class="title mx-textellipsis" isEllipsisActive
                            [matTooltip]="chartOptions.metric">{{chartOptions.metric}}</span>
                        <span matTooltip="Pin this metric" (click)="pinChart(chartOptions)"
                            [wt1Click]="'experiment-tracking-runs-chart-pin'" class="resize">
                            <i class="icon-fixed-width icon-dku-pin-chart"></i>
                        </span>
                        <lazy-echart [options]="chartOptions" (chartInit)="onChartInit($event, chartOptions)">
                        </lazy-echart>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</ng-container>
<div *ngIf="runs?.length && (!availableMetricKeys || !availableMetricKeys.length)"
    class="flex empty-list-cta placeholder">
    <h1>
        Selected runs do not have at least one metric defined
    </h1>
    <p>
        Add metrics using the MLflow API
    </p>
    <p>
        <a target="_blank" href="https://doc.dataiku.com/dss/latest/mlops/experiment-tracking/index.html">Read the
            documentation</a> to learn more.
    </p>
    <div>
        <button class="btn btn--secondary btn--large"
                (click)="refresh()"
                [wt1Click]="'experiment-tracking-refresh-runs'">
            <i class="icon-refresh"></i>&nbsp;Refresh
        </button>
    </div>
</div>
<div *ngIf="!runs?.length" class="flex empty-list-cta placeholder">
    <h1>
        No runs selected for display
    </h1>
</div>
