export namespace FilterRequest {
    /**
     * Generated from com.dataiku.dip.shaker.filter.FilterRequest$FilterType
     */
    export enum FilterType {
        ALPHANUM = 'ALPHANUM',
        ALPHANUM_FACET = 'ALPHANUM_FACET',
        NUMERICAL_FACET = 'NUMERICAL_FACET',
        DATE_FACET = 'DATE_FACET',
        VALIDITY = 'VALIDITY',
        GLOBAL_SEARCH = 'GLOBAL_SEARCH'
    }
}