import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeephubImageClassificationReportDataFetcherService } from '../services/deephub-image-classification-report-data-fetcher.service';

@Component({
  selector: 'deephub-image-classification-report-image-legend',
  templateUrl: './deephub-image-classification-report-image-legend.component.html',
  styleUrls: [
      '../../../computer-vision/report/image-legend/deephub-computer-vision-report-image-legend.component.less',
      '../../../shared-styles/deephub-image.less'
    ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubImageClassificationReportImageLegendComponent {
    constructor(
        public dataFetcher: DeephubImageClassificationReportDataFetcherService,
        public colorMapService: ColorMapContextService
    ) {}
}
