<ng-container *ngIf="apiError">
    <div *ngIf="apiError.errorType" class="alert alert-error api-error-alert qa_generic_api-error">
        <div *ngIf="closable" class="actions pull-right"><a (click)="reset()" style="text-decoration: none" class="qa_generic_api-reset-error"><i><span style="font-size:1.3em; vertical-align: top">&times;</span></i></a></div>
        <div *ngIf="errorFoldable" class="actions pull-right">
            <a (click)="open = !open">
                <i class="{{open ? 'icon-chevron-up' : 'icon-chevron-down'}}"></i>
            </a>
        </div>

        <div class="api-error-alert__contents" *ngIf="!open && apiError.code">
            <h4 class="api-error-alert__title" *ngIf="apiError | hasProperty: 'title'"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;{{apiError.title}}</h4>
        </div>

        <div class="api-error-alert__contents" *ngIf="!open && !apiError.code">
            <h4>Error occurred</h4>
        </div>

        <div class="api-error-alert__contents" *ngIf="open">
            <div *ngIf="apiError.code; else noncoded">
                <h4 class="api-error-alert__title" *ngIf="apiError | hasProperty: 'title'"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;{{apiError.title}}</h4>
                <h5 class="api-error-alert__subtitle summary-of-error">
                    <span *ngIf="apiError | hasProperty: 'detailedMessageHTML'" [innerHtml]="apiError.detailedMessageHTML"></span>
                    <span *ngIf="!(apiError | hasProperty: 'detailedMessageHTML')">{{detailedMessageOrMessage}}</span>
                </h5>

                <div style="display: flex; flex-direction: row; align-items: center">
                    <div class="noflex" style="flex-basis: 75%; padding-right: 25px">
                        <error-fixability *ngIf="apiError | hasProperty: 'fixability'" [serializedError]="apiError"></error-fixability>
                    </div>
                    <div class="noflex" style="flex-basis: 25%;">
                        <p style="font-weight: 500; text-align: center; margin: 0">
                            <a target="_blank" rel="noopener noreferrer" href="{{$root.versionDocRoot}}troubleshooting/errors/{{apiError.code}}.html">
                                More information on this error in {{$root.wl.productShortName || 'DSS'}} documentation
                            </a>
                        </p>
                    </div>
                </div>

                <p style="margin-bottom: 0"><span class="tech-details">Additional technical details:
                    <span *ngIf="apiError.httpCode > 0">HTTP code: {{apiError.httpCode}},</span>
                    <span>Code: {{apiError.code}}, </span>
                    <span>type: {{apiError.errorType}}</span>
                    </span>
                </p>
            </div>
            <ng-template #noncoded>
                <div *ngIf="apiError.httpCode === 502">
                    <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Could not connect to {{$root.wl.productShortName || 'DSS'}} server</h4>
                    <h5>Please ask your administrator to check if the {{$root.wl.productShortName || 'DSS'}} server backend is running.</h5>
                    <p class="api-error-alert__description">{{detailedMessageOrMessage}}<br />
                    <span class="tech-details">HTTP code: {{apiError.httpCode}}, type: {{apiError.errorType}}</span></p>
                </div>

                <div [ngSwitch]="apiError.errorType" *ngIf="apiError.httpCode !== 502">
                    <div *ngSwitchCase="'XHRNetworkError'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Network error</h4>
                        <h5 class="api-error-alert__subtitle">
                            An attempt to communicate with {{$root.wl.productShortName || 'DSS'}} failed. Please check your network connectivity.
                        </h5>
                        <p>{{detailedMessageOrMessage}}</p>
                    </div>
                    <div *ngSwitchCase="'HTTPError413'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Request too large</h4>
                        <h5 class="api-error-alert__subtitle">
                            The network request was rejected because it was too large. This is probably caused by a proxy between your browser and {{$root.wl.productShortName || 'DSS'}} that rejects too large uploads. Please contact your network administrator.
                        </h5>
                        <span class="tech-details">HTTP code: {{apiError.httpCode}} - Response body: {{detailedMessageOrMessage}}</span>
                    </div>

                    <div *ngSwitchCase="'com.dataiku.dip.exceptions.DSSIllegalArgumentException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;An internal error occurred</h4>
                        <h5 *ngIf="$root.appConfig.admin">
                            Please report this issue to {{$root.wl.productLongName}} Support
                        </h5>
                        <h5 *ngIf="!$root.appConfig.admin">
                            Please ask your {{$root.wl.productShortName}} administrator to report this issue to {{$root.wl.productLongName}} Support
                        </h5>
                        <p style="margin-bottom: 0">Technical details follow:</p>
                        <ul style="margin-bottom: 0">
                            <li>{{detailedMessageOrMessage}}</li>
                            <li>HTTP code: {{apiError.httpCode}}, type: {{apiError.errorType}}</li>
                        </ul>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.exceptions.DSSInternalErrorException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;An internal error occurred</h4>
                        <h5 *ngIf="$root.appConfig.admin">
                            Please report this issue to {{$root.wl.productLongName}} Support
                        </h5>
                        <h5 *ngIf="!$root.appConfig.admin">
                            Please ask your {{$root.wl.productShortName}} administrator to report this issue to {{$root.wl.productLongName}} Support
                        </h5>
                        <p style="margin-bottom: 0">Technical details follow:</p>
                        <ul style="margin-bottom: 0">
                            <li>{{detailedMessageOrMessage}}</li>
                            <li>HTTP code: {{apiError.httpCode}}, type: {{apiError.errorType}}</li>
                        </ul>
                    </div>

                    <div *ngSwitchCase="'java.lang.IllegalArgumentException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Invalid argument</h4>
                        <h5 class="api-error-alert__subtitle">An invalid argument has been encountered : {{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.exceptions.UnauthorizedException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Not authorized</h4>
                        <h5 class="api-error-alert__subtitle">{{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.exceptions.DataStoreIOException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>{{apiError.message}}</h4>
                        <h5 class="api-error-alert__subtitle" *ngIf="apiError | hasProperty:'detailedMessage'">{{apiError.detailedMessage}}</h5>
                        <span class="tech-details">HTTP code: {{apiError.httpCode}}, type: {{apiError.errorType}}</span>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.exceptions.IllegalConfigurationException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Configuration issue</h4>
                        <h5 class="api-error-alert__subtitle" *ngIf="apiError | hasProperty:'detailedMessage'">{{apiError.detailedMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.server.services.licensing.LicenseEnforcementService$LicenseLimitException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Limit reached !</h4>
                        <h5 class="api-error-alert__subtitle">{{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.server.services.licensing.LicenseEnforcementService$LicenseFeatureException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Unavailable feature</h4>
                        <h5 class="api-error-alert__subtitle">{{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.pivot.UnsupportedOperation'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Unsupported operation</h4>
                        <h5 class="api-error-alert__subtitle">{{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'ApplicativeException'">
                        <h4 *ngIf="apiError | hasProperty: 'title'"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;{{apiError.title}}</h4>
                        <h5 *ngIf="detailedMessageOrMessage" style="margin-bottom: 0; word-break: break-word;">{{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'NotReady'">
                        <h4 *ngIf="apiError | hasProperty:'title'"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;{{apiError.title}}</h4>
                        <h5 *ngIf="detailedMessageOrMessage" style="margin-bottom: 0; word-break: break-word;"><span *ngIf="!(apiError | hasProperty:'title')"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;</span>{{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'ExpectedException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;An error occurred</h4>
                        <h5 class="api-error-alert__subtitle">{{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'java.lang.SecurityException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Access denied</h4>
                        <h5 class="api-error-alert__subtitle">{{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'SecurityException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Access denied</h4>
                        <h5 class="api-error-alert__subtitle">{{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'java.util.zip.ZipException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Zip error</h4>
                        <h5 class="api-error-alert__subtitle">There is a problem with the zip file : {{detailedMessageOrMessage}}</h5>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.shaker.SampleBuilder$SampleBuildException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Could not build data sample</h4>
                        <p class="api-error-alert__description">{{detailedMessageOrMessage}}<br />
                        <span class="tech-details">Type: {{apiError.errorType}}</span></p>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.io.CustomPythonKernelException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Python plugin code failed</h4>
                        <p class="api-error-alert__description">{{apiError.message}}<br />
                        <smart-log-tail logTail="apiError.logTail"></smart-log-tail>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.exceptions.ExternalProcessFailedException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;External process failed</h4>
                        <p class="api-error-alert__description">{{apiError.message}}<br />
                            <smart-log-tail logTail="apiError.logTail"></smart-log-tail>
                        </p>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.io.SocketBlockLinkIOException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>
                            &nbsp;Communication to external code failed</h4>
                        <p class="api-error-alert__description">{{apiError.message}}<br />
                            <smart-log-tail logTail="apiError.logTail"></smart-log-tail>
                        </p>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.io.SocketBlockLinkKernelException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;External code failed</h4>
                        <p class="api-error-alert__description">{{apiError.message}}<br />
                            <smart-log-tail logTail="apiError.logTail"></smart-log-tail>
                        </p>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.server.controllers.NotFoundException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Not found</h4>
                        <p class="api-error-alert__description">{{apiError.message}}<br />
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.scheduler.runnables.DSSRunnableKernelException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Macro code failed</h4>
                        <p class="api-error-alert__description">{{apiError.message}}<br /></p>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.exceptions.SourceDatasetNotReadyException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;{{detailedMessageOrMessage}}</h4>
                    </div>
                    <div *ngSwitchCase="'com.dataiku.dip.datasets.fs.HTTPSourceException'">
                        <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Source responded with HTTP error</h4>
                        <p class="api-error-alert__description">{{apiError.message}}<br /></p>
                    </div>

                    <div *ngSwitchDefault>
                        <div *ngIf="canBeUnexpected">
                            <h4 class="api-error-alert__title"><i class="icon-dku-error api-error-alert__icon"></i>&nbsp;Oops: an unexpected error occurred</h4>
                            <h5 class="api-error-alert__subtitle">
                                <span *ngIf="apiError | hasProperty:'detailedMessageHTML'" [innerHtml]="apiError.detailedMessageHTML"></span>
                                <span *ngIf="!(apiError | hasProperty:'detailedMessageHTML')">{{detailedMessageOrMessage}}</span>
                            </h5>

                            <p class="api-error-alert__description">
                                Please see our <a ng-click="$root.showHelpModal();">options for getting help</a>
                            </p>
                            <span class="tech-details">HTTP code: {{apiError.httpCode}}, type: {{apiError.errorType}}</span>
                        </div>

                        <!-- Default formatting -->
                        <div *ngIf="!canBeUnexpected">
                            <h4>{{detailedMessageOrMessage}}</h4>
                            <span class="tech-details">
                                <span *ngIf="apiError.httpCode > 0">HTTP code: {{apiError.httpCode}},</span>
                                Type: {{apiError.errorType}}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>
