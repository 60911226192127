<a class="workspace-object-list-element" [href]="link?.href" [target]="link?.target">

    <div class="workspace-object-list__border" [ngClass]="(type | typeToColor:true) || 'universe-background'"></div>

    <div class="workspace-object-list-element__title">
        {{ object.displayName || object.reference?.id }}
    </div>
    <star-interest *ngIf="object.reference"
        class="workspace-object-list-element__star"
        [status]="object.starred"
        (toggle)="onToggleStar($event)"
        (click)="$event.preventDefault()"
    ></star-interest>

    <div class="workspace-object-list-element__icon" [ngSwitch]="type">
        <workspace-object-link-favicon *ngSwitchCase="displayType === WorkspaceListType.LIST && 'LINK'" [object]="object"></workspace-object-link-favicon>
        <i *ngSwitchCase="'DATASET'" class="universe-color dataset" [ngClass]="(object.subType || type) | datasetTypeToIcon"></i>
        <i *ngSwitchDefault [ngClass]="[type | typeToIcon, type | typeToColor]"></i>
    </div>

    <workspace-object-thumbnail class="workspace-object-list-element__thumbnail" [object]="object">
    </workspace-object-thumbnail>

    <div class="workspace-object-list-element__type">
        {{ type | lowercase | niceConst }}
    </div>

    <button *ngIf="canRemove" class="workspace-object-list-element__remove btn btn--text btn--danger btn--icon" (click)="onRemove()">
        <i class="icon-trash"></i>
    </button>
</a>
