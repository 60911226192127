import type {ACFPlotCard} from './acfplot-card';
import type {BoxPlotFragment} from './fragments/box-plot-fragment';
import type {Card, _Card} from './card';
import type {CardResult, _CardResult} from './card-result';
import type {DurbinWatsonCard} from './durbin-watson-card';
import type {MannKendallTestCard} from './mann-kendall-test-card';
import type {TimeInfoFragment} from './fragments/time-info-fragment';
import type {UnitRootTestADFCard} from './unit-root-test-adfcard';
import type {UnitRootTestKPSSCard} from './unit-root-test-kpsscard';
import type {UnitRootTestZACard} from './unit-root-test-zacard';
import type {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard
 */
export interface _TimeSeriesCard extends _Card {
    seriesColumn: Variable;
    seriesIdentifiers: TimeSeriesCard.TimeSeriesIdentifier[];
    timeColumn: Variable;
}

export type TimeSeriesCard = ACFPlotCard | DurbinWatsonCard | MannKendallTestCard | UnitRootTestADFCard | UnitRootTestKPSSCard | UnitRootTestZACard;

export namespace TimeSeriesCard {
    export const type = ['acf_plot', 'durbin_watson', 'mann_kendall_test', 'unit_root_test_adf', 'unit_root_test_kpss', 'unit_root_test_za'] as const;
}

/** @deprecated Use Card.isTimeSeriesCard() */ export function isTimeSeriesCard(toTest: Card): toTest is TimeSeriesCard { return (TimeSeriesCard.type as readonly unknown[]).includes(toTest['type']); }

export namespace TimeSeriesCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesCardResult
     */
    export interface _TimeSeriesCardResult extends _CardResult {
        boxPlot: BoxPlotFragment;
        timeInfo: TimeInfoFragment;
    }

    export type TimeSeriesCardResult = ACFPlotCard.ACFPlotCardResult | DurbinWatsonCard.DurbinWatsonCardResult | MannKendallTestCard.MannKendallTestCardResult | UnitRootTestADFCard.UnitRootTestADFCardResult | UnitRootTestKPSSCard.UnitRootTestKPSSCardResult | UnitRootTestZACard.UnitRootTestZACardResult;

    export namespace TimeSeriesCardResult {
        export const type = ['acf_plot', 'durbin_watson', 'mann_kendall_test', 'unit_root_test_adf', 'unit_root_test_kpss', 'unit_root_test_za'] as const;
    }

    /** @deprecated Use CardResult.isTimeSeriesCardResult() */ export function isTimeSeriesCardResult(toTest: CardResult): toTest is TimeSeriesCardResult { return (TimeSeriesCardResult.type as readonly unknown[]).includes(toTest['type']); }

    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesIdentifier
     */
    export interface TimeSeriesIdentifier {
        column: Variable;
        value: string;
    }
}