import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorMapContextService {
    mapping: Map<string, string>;

    setMapping(labels: string[], colors: string[]) {
        this.mapping = new Map();
        labels.forEach((category: string, index: number) => {
            this.mapping.set(category, colors[index % colors.length]);
        });
    }
}
