import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep
 */
export interface TimeStep extends Computation._UnivariateComputation {
    maxSteps: number;

    // PolyJson type
    type: 'time_step';
}

export namespace TimeStep {
    export const type = 'time_step';
}

/** @deprecated Use Computation.isTimeStep() */ export function isTimeStep(toTest: Computation): toTest is TimeStep { return TimeStep.type === toTest['type']; }

export namespace TimeStep.TimeStepResult {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult$Step
     */
    export interface _Step {}

    export type Step = TimeStep.TimeStepResult.BusinessDaysStep | TimeStep.TimeStepResult.IsoDurationStep;

    export namespace Step {
        export const type = ['business_days_step', 'iso_duration_step'] as const;
        export function isBusinessDaysStep(toTest: TimeStep.TimeStepResult.Step): toTest is TimeStep.TimeStepResult.BusinessDaysStep { return toTest['type'] === 'business_days_step'; }
        export function isIsoDurationStep(toTest: TimeStep.TimeStepResult.Step): toTest is TimeStep.TimeStepResult.IsoDurationStep { return toTest['type'] === 'iso_duration_step'; }
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult$BusinessDaysStep
     */
    export interface BusinessDaysStep extends TimeStep.TimeStepResult._Step {
        weekMask: string;

        // PolyJson type
        type: 'business_days_step';
    }

    export namespace BusinessDaysStep {
        export const type = 'business_days_step';
    }

    /** @deprecated Use TimeStep.TimeStepResult.Step.isBusinessDaysStep() */ export function isBusinessDaysStep(toTest: TimeStep.TimeStepResult.Step): toTest is BusinessDaysStep { return BusinessDaysStep.type === toTest['type']; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult$IsoDurationStep
     */
    export interface IsoDurationStep extends TimeStep.TimeStepResult._Step {
        duration: string;

        // PolyJson type
        type: 'iso_duration_step';
    }

    export namespace IsoDurationStep {
        export const type = 'iso_duration_step';
    }

    /** @deprecated Use TimeStep.TimeStepResult.Step.isIsoDurationStep() */ export function isIsoDurationStep(toTest: TimeStep.TimeStepResult.Step): toTest is IsoDurationStep { return IsoDurationStep.type === toTest['type']; }
}

export namespace TimeStep {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult
     */
    export interface TimeStepResult extends _AvailableResult {
        nSteps: number;
        steps: TimeStep.TimeStepResult.Step[];

        // PolyJson type
        type: 'time_step';
    }

    export namespace TimeStepResult {
        export const type = 'time_step';
    }

    /** @deprecated Use ComputationResult.isTimeStepResult() */ export function isTimeStepResult(toTest: ComputationResult): toTest is TimeStepResult { return TimeStepResult.type === toTest['type']; }
}