import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KeyValuesListModule } from '@app/widgets/lists/key-values-list/key-values-list.module';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { CoreModule } from '@core/core.module';
import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { BboxThumbComponent } from '@features/labeling/labeling-task-annotate/category-selector/bbox-thumb/bbox-thumb.component';
import { LabelingTaskAnnotateComponent } from '@features/labeling/labeling-task-annotate/labeling-task-annotate.component';
import { LabelingTaskReviewComponent } from '@features/labeling/labeling-task-review/labeling-task-review.component';
import { LabelingTaskSettingsComponent } from '@features/labeling/labeling-task-settings/labeling-task-settings.component';
import { LabelingComponent } from '@features/labeling/labeling.component';
import { ObjectDetectionImageCanvasComponent } from '@features/labeling/object-detection-image-canvas/object-detection-image-canvas.component';
import { ComponentsModule } from '@shared/components/components.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { DkuFrontendCoreModule } from 'dku-frontend-core';
import { LabelingContainerComponent } from './labeling-container/labeling-container.component';
import { LabelingErrorComponent } from './labeling-error/labeling-error.component';
import { CategorySelectorComponent } from './labeling-task-annotate/category-selector/category-selector.component';
import { LabelingTaskOverviewComponent } from './labeling-task-overview/labeling-task-overview.component';
import { ReviewImageHeaderComponent } from './labeling-task-review/header/review-image-header.component';
import { ReviewImageFeedComponent } from './labeling-task-review/image-feed/review-image-feed.component';
import { AnnotationGroupListComponent } from './labeling-task-review/lists/annotation-group-list/annotation-group-list.component';
import { AnnotationListComponent } from './labeling-task-review/lists/annotation-list/annotation-list.component';
import { ObjectDetectionRegionSelectorComponent } from './labeling-task-review/object-detection-region-selector/object-detection-region-selector.component';
import { ReviewImageComponent } from './labeling-task-review/review-image/review-image.component';
import { LabelingPermissionsComponent } from './labeling-task-settings/labeling-permissions/labeling-permissions.component';
import { LabelingSettingsDataComponent } from './labeling-task-settings/labeling-settings-data/labeling-settings-data.component';
import { LabelingSettingsImageFeedModalComponent } from './labeling-task-settings/labeling-settings-data/labeling-settings-image-feed-modal/labeling-settings-image-feed-modal.component';
import { AnnotateAnnotationListComponent } from './labeling-task-annotate/annotate-annotation-list/annotate-annotation-list.component';
import { ReviewImageFeedHeaderComponent } from './labeling-task-review/image-feed-header/review-image-feed-header.component';
import { LabelingUnusableWarningComponent } from './labeling-unusable-warning/labeling-unusable-warning.component';

@NgModule({
    declarations: [
        LabelingComponent,
        LabelingTaskAnnotateComponent,
        LabelingTaskReviewComponent,
        LabelingTaskSettingsComponent,
        ObjectDetectionImageCanvasComponent,
        BboxThumbComponent,
        LabelingTaskReviewComponent,
        ReviewImageComponent,
        ReviewImageHeaderComponent,
        ReviewImageFeedComponent,
        ReviewImageFeedHeaderComponent,
        AnnotationListComponent,
        AnnotationGroupListComponent,
        ObjectDetectionRegionSelectorComponent,
        LabelingSettingsDataComponent,
        LabelingTaskSettingsComponent,
        LabelingSettingsImageFeedModalComponent,
        LabelingPermissionsComponent,
        LabelingErrorComponent,
        LabelingTaskOverviewComponent,
        LabelingContainerComponent,
        CategorySelectorComponent,
        AnnotateAnnotationListComponent,
        LabelingUnusableWarningComponent
    ], imports: [
        CoreModule,
        CommonModule,
        ComponentsModule,
        FormsModule,
        DataikuAPIModule,
        ReactiveFormsModule,
        KeyValuesListModule,
        PipesModule,
        MatSelectModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        WidgetsModule,
        DkuFrontendCoreModule
    ],
})
export class LabelingModule {}
