<div class="review-image-feed-header__wrapper">
    <div *ngIf="nbLabeledImages$" class="review-image-feed-header__image-number">{{nbLabeledImages$ | async}} labeled images</div>
    <div class="m8" [matTooltip]="'Validate all consensual images'" [matTooltipPosition]="'above'">
        <button class="btn btn--outline btn--primary review-image-feed-header__button" (click)="resolveConsensualRecords()" [disabled]="(resolveClass$ | async) != defaultAutoResolveClass || (resolvingConsensualRecords$ | async) || (nbLabeledImages$ | async)! == 0">
            <i class="dku-loader icon-spin dku-loader--inlined review-image-feed__loader"  *ngIf="resolvingConsensualRecords$ | async"></i>
            <i [class]="resolveClass$ | async" class="review-image-feed-header__validate-icon" *ngIf="!(resolvingConsensualRecords$ | async)"></i>
            <div *ngIf="(resolveClass$ | async) == defaultAutoResolveClass">Auto validate</div>
            <div *ngIf="(resolveClass$ | async) != defaultAutoResolveClass">Validated</div>
        </button>
    </div>
</div>
