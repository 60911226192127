import type {AnumGrouping} from './anum-grouping';
import type {BinnedGrouping} from './binned-grouping';
import type {CrossGrouping} from './cross-grouping';
import type {MergeGrouping} from './merge-grouping';
import type {SubsampledGrouping} from './subsampled-grouping';
import type {SubsetGrouping} from './subset-grouping';
import type {TopNTimeGrouping} from './top-ntime-grouping';
import type {UnionGrouping} from './union-grouping';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.Grouping
 */
export interface _Grouping {}

export type Grouping = AnumGrouping | BinnedGrouping | CrossGrouping | MergeGrouping | SubsampledGrouping | SubsetGrouping | TopNTimeGrouping | UnionGrouping;

export namespace Grouping {
    export const type = ['anum', 'binned', 'cross', 'merge', 'subsampled', 'subset', 'topn_time', 'union'] as const;
    export function isAnumGrouping(toTest: Grouping): toTest is AnumGrouping { return toTest['type'] === 'anum'; }
    export function isBinnedGrouping(toTest: Grouping): toTest is BinnedGrouping { return toTest['type'] === 'binned'; }
    export function isCrossGrouping(toTest: Grouping): toTest is CrossGrouping { return toTest['type'] === 'cross'; }
    export function isMergeGrouping(toTest: Grouping): toTest is MergeGrouping { return toTest['type'] === 'merge'; }
    export function isSubsampledGrouping(toTest: Grouping): toTest is SubsampledGrouping { return toTest['type'] === 'subsampled'; }
    export function isSubsetGrouping(toTest: Grouping): toTest is SubsetGrouping { return toTest['type'] === 'subset'; }
    export function isTopNTimeGrouping(toTest: Grouping): toTest is TopNTimeGrouping { return toTest['type'] === 'topn_time'; }
    export function isUnionGrouping(toTest: Grouping): toTest is UnionGrouping { return toTest['type'] === 'union'; }
}