<div class="h100 paned-page-section vertical-flex pad0" *ngIf="dataFetcher.filter$ | async as filter">
    <div class="image-feed__header">
        <h2 class="paned-page-section__subheading">
            {{ dataFetcher.totalItems! }} {{ 'Image' | plurify: dataFetcher.totalItems!}}
        </h2>
        <div *ngIf="filter">
            Sorted by probability &nbsp;
            <i (click)='setSorting(filter)' [ngClass]="{'icon-sort-by-attributes': filter.ascending, 'icon-sort-by-attributes-alt': !filter.ascending}" class=" image-feed__sorting-order"></i>
        </div>
    </div>
    <div class="padleftright16 mbot8">
        <deephub-image-classification-report-image-legend></deephub-image-classification-report-image-legend>
    </div>
    <image-feed class="h100" [imageHeight]="200" [itemsPerRow]="2" (imageClick)="openModal($event)"></image-feed>
</div>
