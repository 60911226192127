<table class="deephub-detection-table w100" *ngIf="displayedPredictions?.length; else noPredictions">
    <thead>
        <tr class="deephub-detection-table__row">
            <th width="75%">Detection</th>
            <th width="25%">Score</th>
        </tr>
    </thead>
    <tbody>
        <tr class="deephub-detection-table__row deephub-detection-table__row--body" [class.deephub-detection-table__row--disabled]="!prediction.aboveThreshold" *ngFor="let prediction of displayedPredictions" (mouseenter)="togglePrediction.emit({
            index: prediction.originalIndex,
            active: true
        })" (mouseleave)="togglePrediction.emit({
            index: prediction.originalIndex,
            active: false
        })">
            <td>
                <div class="horizontal-flex aic">
                    <div class="deephub-image-legend noflex">
                        <span class="deephub-image-legend__item" [style.background-color]="getCategoryColor(prediction.category)"></span>
                    </div>
                    <span class="mleft4 mx-textellipsis" [title]="prediction.category" >
                        {{ prediction.category }}
                    </span>
                </div>
            </td>
            <td>
                {{ prediction.confidence | number }}
            </td>
        </tr>
    </tbody>
</table>
<ng-template #noPredictions>
    <p class="deephub-detection-empty">
        Model did not detect any objects for this image
    </p>
</ng-template>