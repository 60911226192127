import { Component, OnInit, Input, OnChanges, forwardRef, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CardWizardVariable } from '@features/eda/card-models';
import { Variable } from 'src/generated-sources';
import _ from 'lodash';

interface SelectableItem {
    displayName: string;
    type?: string;
    variable?: CardWizardVariable;
    disabled: boolean;
}

function toSelectableItem(variable: CardWizardVariable): SelectableItem {
    const selectableItem: SelectableItem = {
        displayName: variable.name,
        type: variable.type,
        disabled: variable.disabled || false,
        variable,
    }
    return selectableItem;
}

@Component({
    selector: 'single-variable-selector',
    templateUrl: './single-variable-selector.component.html',
    styleUrls: ['./single-variable-selector.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SingleVariableSelectorComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleVariableSelectorComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
    @Input() noVariableLabel?: string;
    @Input() noneSelectedText = 'Select variable...';
    @Input() variables: CardWizardVariable[];

    selectParams: any;
    selectableItems: SelectableItem[];
    filterQuery: string;
    selectedVariableName?: string;
    variableTypes: string[];
    Variable = Variable;

    propagateChange = (_: any) => { };

    constructor(private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.selectParams = {
            noneSelectedText: this.noneSelectedText
        };
    }

    get selectedItem(): SelectableItem | undefined {
        return this.selectableItems.find(selectableItem => {
            if (this.selectedVariableName) {
                return selectableItem.variable && selectableItem.variable.name === this.selectedVariableName;
            } else {
                return !selectableItem.variable;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        let newSelectableItems: SelectableItem[] = this.variables.map(toSelectableItem);

        this.variableTypes = this.variables.map(variable => variable.type === Variable.Type.CONTINUOUS ? 'numerical' : 'categorical');

        if (this.noVariableLabel) {
            newSelectableItems = [{
                displayName: this.noVariableLabel,
                disabled: false
            }, ...newSelectableItems];

            this.variableTypes = ['', ...this.variableTypes];
        }

        if(changes.variables && !_.isEqual(changes.variables.currentValue, changes.variables.previousValue)) {
            // Work around a dku-bs-select bug which clears itself when the list changes
            // => do not not update the list if new one is identical (most of the cases)
            this.selectableItems = newSelectableItems;
        }
    }

    selectItem(selectableItem?: SelectableItem) {
        this.selectedVariableName = selectableItem?.variable?.name;
        this.propagateChange(selectableItem && selectableItem.variable);
    }

    writeValue(value?: CardWizardVariable) {
        this.selectedVariableName = value ? value.name : undefined;
        this.changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: (_: any) => {}) {
        this.propagateChange = fn;
    }

    registerOnTouched() { }

    ngOnDestroy() {

    }
}
