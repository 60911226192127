import { Injectable } from "@angular/core";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CellData } from "@shared/services/images-data-fetcher.service";
import { SampleDataFetcherService } from "@shared/services/sample-data-fetcher.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SerializedMemTableV2, SerializedTableChunk } from "src/generated-sources";
import { LabelingService } from "../services/labeling.service";
import { getImageURL } from "../utils";

@UntilDestroy()
@Injectable()
export class LabelingSettingsDataFetcherService extends SampleDataFetcherService implements ErrorContext {
    labelingTaskId: string;
    idColumn: string;
    itemPathColIndex: number;

    constructor(private labelingService: LabelingService, private DataikuAPI: DataikuAPIService) {
        super();
        this.labelingService.labelingTaskInfo$.pipe(untilDestroyed(this)).subscribe((infos) => {
            if (this.projectKey !== infos.getManagedFolderLoc().projectKey ||
                this.managedFolderLoc.id !== infos.getManagedFolderLoc().id ||
                this.labelingTaskId !== infos.labelingTaskId ||
                this.idColumn !== infos.idColumn) {
                this.projectKey = infos.projectKey;
                this.managedFolderLoc = infos.getManagedFolderLoc();
                this.labelingTaskId = infos.labelingTaskId;
                this.idColumn = infos.idColumn;
                this.onDataChanged();
            }
        });
    }

    onRefreshSample(sample: SerializedMemTableV2): void {
        this.tableData = sample;
        this.totalItems = sample.totalKeptRows;
        this.itemPathColIndex = sample.allColumnNames.indexOf(this.idColumn);
    }

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.DataikuAPI.labelingTasks.refreshImagesDataSample(this.projectKey, this.labelingTaskId, this.CHUNK_SIZE)
        .pipe(
            catchAPIError(this, true),
            map((sample) => {
                this.onRefreshSample(sample);
                return sample;
            })
        );
    }

    getSampleChunk(offset: number, nbRows: number): Observable<SerializedTableChunk> {
        return this.DataikuAPI.labelingTasks.getImagesDataChunk(this.projectKey, this.labelingTaskId, nbRows, offset).pipe(catchAPIError(this, true));
    }

    rowToCellData(rowContent: string[], i: number, j: number): CellData {
        return {
            itemPath: rowContent[this.itemPathColIndex],
            imageId: i
        }
    }

    setIdColumn(idColumn: string) {
        if (idColumn !== this.idColumn) {
            this.idColumn = idColumn;
            this.onDataChanged();
        }
    }

    pushError(error: APIError): void {
        this.labelingService.pushError(error);
    }

    getImagePath(itemPath: string): string {
        return getImageURL(this.projectKey, this.labelingTaskId, this.managedFolderLoc, itemPath);
    }

}