<form [formGroup]="form">
    <ng-container formArrayName="facets">
        <div *ngFor="let facetForm of getFacetForms(); let i = index" class="facet">
            <div *ngIf="facetKey(facetForm) as key" formGroupName="{{i}}">
                <h4 (click)="facetsCollapse[key] = !facetsCollapse[key]" [attr.data-qa-smid]="facetDataQaSmid[key] + '-chevron'">
                    <i [ngClass]="{'icon-chevron-up':!facetsCollapse[key], 'icon-chevron-down':facetsCollapse[key]}"></i>
                    {{ formatFacetName(key) }}
                </h4>
                <div class="value-list" [ngClass]="{'feature-store_facet_collapsed':facetsCollapse[key]}">
                    <label class="search" [ngClass]="{'expanded': showInput[key]}" *ngIf="numberOfValues(facetForm) > 5">
                        <i class="icon {{ showInput[key] ? 'icon-remove' : 'icon-search' }}" (click)="resetFacetSearch(key)"></i>
                        <input type="text" formControlName="facetSearch"
                            class="facet-search"
                            (keydown)="onFacetSearchKeyDown(key, $event);"/>
                    </label>
                    <label class="all" (click)="selectAllFacetValues(facetForm)" [ngClass]="{checked: noFacetSelected(facetForm)}">
                        <input type="checkbox" [checked]="noFacetSelected(facetForm)" [disabled]="noFacetSelected(facetForm)" [attr.data-qa-smid]="facetDataQaSmid[key] + '-all-checkbox'"/> All
                    </label>
                    <ng-container *ngIf="filterFacetsValues(facetForm) as items">
                        <div  formArrayName="values">
                            <ng-container *ngFor="let item of items; let j=index; let size = count">
                                <label *ngIf="facetsShowAll[key] || j < 3 || size === 4 || facetSelected(item) || facetForm.controls.facetSearch.value !== ''" class="feature-store__facet-values">
                                    <div class="feature-store__facet-value" formGroupName="{{j}}" [attr.data-qa-smid]="facetDataQaSmid[key] + '-facet-value-' + item.controls.value.value">
                                        <input type="checkbox" formControlName="selected"/>
                                        <ui-global-tag class="text feature-store__tag" [tag]=item.controls.value.value [objectType]="'TAGGABLE_OBJECT'"></ui-global-tag>
                                        <span class="count">{{item.controls.count.value}}</span>
                                    </div>
                                </label>
                            </ng-container>
                        </div>
                        <label *ngIf="items && items.length === 0">No matching value</label>
                        <span class="more" 
                            *ngIf="hasMoreItems(key, items)"
                            (click)="facetsShowAll[key] = true">
                            + {{numberOfValues(facetForm) - 3}} more…
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</form>