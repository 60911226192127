import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { noEmptyKeysValidator } from '@utils/no-empty-keys-validator';
import { LabelingTask } from 'src/generated-sources';

@UntilDestroy()
@Component({
    selector: 'labeling-task-settings',
    templateUrl: './labeling-task-settings.component.html',
    styleUrls: [
        './labeling-task-settings.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingTaskSettingsComponent implements OnChanges {
    @Input() labelingTask: LabelingTask;
    @Output() partialTaskChange = new EventEmitter<Partial<LabelingTask>>();
    @Output() partialTaskValidityChange = new EventEmitter<boolean>();

    settingsForm: FormGroup;

    constructor(private fb: FormBuilder) {
        this.settingsForm = this.fb.group({
            classes: this.fb.control(null, [noEmptyKeysValidator()]),
            instructions: this.fb.control(null),
            minNbAnnotatorsPerRecord: this.fb.control(null)
        });
        this.settingsForm.valueChanges
            .pipe(
                untilDestroyed(this)
            )
            .subscribe(formValue => {
                this.partialTaskChange.emit(formValue);
            });

        this.settingsForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.partialTaskValidityChange.emit(this.settingsForm.valid));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.labelingTask && this.labelingTask) {
            this.settingsForm.patchValue(this.labelingTask, { emitEvent: false });
        }
    }
}
