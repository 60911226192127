import { Injectable } from "@angular/core";
import { fabric } from "fabric";
import { Subject } from "rxjs";
import { LabelingColorService } from "../services/labeling-color.service";
import { UIBoundingBox } from "../models/annotation";
import { LabelingAnnotationService } from "../services/labeling-annotation.service";

// Using IDkuRectOptions and DkuRect to extend fabricjs's IRectOptions and Rect with extra properties that will be stored in the canvas object (category, selected)
// Those classes should not be exported to other component, BoundingBox should be used.
// /!\ keep sync with `getRectanglesOnCanvas` method
interface IDkuRectOptions extends fabric.IRectOptions {
    selected: boolean;
    category: string;
    pinned: boolean;
    annotator: string;
    state: string;
}

export class DkuRect extends fabric.Rect {
    selected: boolean;
    category: string;
    pinned: boolean;
    annotator: string;
    state: string;
    constructor(rectOptions: IDkuRectOptions) {
        super(rectOptions);
        this.selected = rectOptions.selected;
        this.category = rectOptions.category;
        this.pinned = rectOptions.pinned;
        this.annotator = rectOptions.annotator;
        this.state = rectOptions.state;
    }
 }

@Injectable()
export abstract class LabelingDrawingService {
    readonly STROKE_WIDTH = 6;
    // If you change the font size adjust the box title height accordingly
    readonly FONT_SIZE = 18;
    readonly BOX_TITLE_HEIGHT = 15.3;
    readonly GROUP_SETTINGS = {
        hasControls: true,
        cornerSize: 6,
        transparentCorners: false,
        // perPixelTargetFind makes the box selectable only by the borders
        perPixelTargetFind: true
    };
    private deleteAllTrigger$ = new Subject<void>();
    deleteAll$ = this.deleteAllTrigger$.asObservable()

    constructor(
        private labelingColorService: LabelingColorService,
        private labelingAnnotationService: LabelingAnnotationService
    ) {}

    abstract createGroup(rect: fabric.Rect, category?: string, pinned?: boolean): fabric.Group;

    applyResizeToGroup(obj: fabric.Group): fabric.Group {
        let group: fabric.Group;
        const rect = obj. getObjects()[0] as DkuRect;
        const text = obj. getObjects()[1] as fabric.Text;
        rect.set({
            left: obj.left,
            top: obj.top,
            width: Math.round(obj.width! * obj.scaleX!) - this.STROKE_WIDTH,
            height: Math.round(obj.height! * obj.scaleY!) - this.STROKE_WIDTH,
            scaleX: 1,
            scaleY: 1,
        })
        if (text) {
            text.set({
                left: rect.left! + this.STROKE_WIDTH,
                top: rect.top! + rect.height! - this.BOX_TITLE_HEIGHT - this.STROKE_WIDTH,
                fontSize: this.FONT_SIZE,
            });
            group = new fabric.Group([rect, text], this.GROUP_SETTINGS);
        } else {
            group = new fabric.Group([rect], this.GROUP_SETTINGS);
        }
        group.setControlsVisibility({ mtr: false });
        return group;
    }

    createGroupFromBoundingBox(bbox: UIBoundingBox) {
        return this.createGroup(this.createRectFromBoundingBox(bbox), bbox.category!, bbox.pinned);
    }

    private createRectFromBoundingBox(bbox: UIBoundingBox): DkuRect {
        return this.createRect(bbox);
    }

    createNewRect(origX: number, origY: number, pointerX: number, pointerY: number): DkuRect {
        return this.createRect(new UIBoundingBox({
            top: origY,
            left: origX, 
            width: pointerX - origX,
            height: pointerY - origY,
            category: this.labelingAnnotationService.getSelectedCategory(),
            selected: true,
            pinned: false}));
    }

    private createRect(bbox: UIBoundingBox): DkuRect {
        const color = this.labelingColorService.getColorForBBox({category: bbox.category, annotator: bbox.annotator, state: bbox.state})
        const rect = new DkuRect({
            left: bbox.left,
            top: bbox.top,
            originX: 'left',
            originY: 'top',
            width: bbox.width,
            height: bbox.height,
            strokeWidth: this.STROKE_WIDTH,
            fill: 'transparent',
            cornerSize: 12,
            transparentCorners: false,
            stroke: this.labelingColorService.colorAsString(color, 0.8),
            selected: bbox.selected,
            annotator: bbox.annotator,
            pinned: bbox.pinned,
            perPixelTargetFind: true,
            state: bbox.state,
            category: bbox.category
        } as IDkuRectOptions);
        rect.setControlsVisibility({ mtr: false });
        return rect;
    }

    deleteAll() {
        this.deleteAllTrigger$.next()
    }

}