import { Component, ChangeDetectionStrategy, Input, ElementRef, ViewChild, ChangeDetectorRef, OnDestroy, SimpleChanges, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { CellData, ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { PainterService } from "@shared/services/painter.service";
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BasePainterOptions, ImagePositionInformation, PainterImage } from '@shared/models/painter';
import { WindowService } from 'dku-frontend-core';

@UntilDestroy()
@Component({
  selector: 'image-feed-image',
  templateUrl: './image-feed-image.component.html',
  styleUrls: ['./image-feed-image.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageFeedImageComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('imageEl') imageEl: ElementRef;
    @Input() cellData: CellData;
    @Input() imageId: number; // in case cell has no data yet
    @Input() imageHeight: number;
    @Input() showBackground: boolean = false;
    @Input() imageSelected?: boolean;
    @Input() options: BasePainterOptions;
    @Input() set painterImage(image: PainterImage) {
        this.activePainterImage = image;
        this.cd.detectChanges();
    }
    @Output() painterImageChange = new EventEmitter<PainterImage>();

    public hasLoaded = false;
    public hasError = false;
    public margin = 16;
    public imgUrl: string;
    public showArtifacts = true;
    private image: HTMLImageElement;
    public activePainterImage: PainterImage = new PainterImage();
    // sometimes onImageError gets triggered before we try to load the image, so make sure we are actually
    // trying to load it
    private loading = false;

    constructor(
        private dataFetcher: ImagesDataFetcherService,
        private painter: PainterService,
        private cd: ChangeDetectorRef,
        private windowService: WindowService
    ) {
    }

    ngOnInit() {

        this.windowService.resize$.pipe(
            untilDestroyed(this)
        ).subscribe(_ => {
            if (this.showArtifacts) {
                this.showArtifacts = false;
                this.cd.detectChanges();
            }
        });

        this.windowService.resize$.pipe(
            debounceTime(500),
            untilDestroyed(this)
        ).subscribe(_ => {
            this.showArtifacts = true;
            this.drawImageArtifacts();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.cellData) {
            // redraw if image is different
            if (changes.cellData?.currentValue?.itemPath !== changes.cellData?.previousValue?.itemPath) {
                this.hasLoaded = false;
                this.hasError = false;
                this.loading = true;
                this.imgUrl = this.dataFetcher.getImagePath(this.cellData.itemPath);
            } else { // if we're just resizing, don't reload image
                if (changes.painterImage?.currentValue === changes.painterImage?.previousValue) {
                    this.drawImageArtifacts();
                }
            }

            this.cd.markForCheck();
        }
    }

    onImageLoad() {
        this.image = this.imageEl.nativeElement;
        this.hasLoaded = true;
        this.hasError = !this.image?.width;
        this.loading = false;

        if (!this.hasError) {
            this.drawImageArtifacts();
        }
    }

    onImageError(event: any) {
        if (this.loading) {
            this.hasLoaded = true;
            this.hasError = true;
            this.loading = false;
        }
    }

    drawImageArtifacts() {
        if (!this.image) {
            return;
        }
        const width = this.image.width;
        const originalWidth = this.image.naturalWidth;

        const imgPosition: ImagePositionInformation = {
            scale: width / originalWidth,
            left: this.image.offsetLeft,
            top: this.image.offsetTop
        };

        this.activePainterImage.removeArtifacts();
        this.painter.paintForFeed(this.cellData, this.activePainterImage, imgPosition);
        this.showArtifacts = true;
        this.painterImageChange.emit(this.activePainterImage);
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        if (this.image) {
            /*
                Stop trying to load the image if no longer in view by replacing src attribute

                this.image.src = '' is also possible but causes problems in Android browsers
                (which aren't really supported anyway)

                See https://stackoverflow.com/a/5278475/11907039 &
                https://stackoverflow.com/a/14115340/11907039
            */
            this.image.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
        }
    }
}
