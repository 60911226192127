<div *ngIf="this.hits && !canShowSearchResults()" class="h100">
    <div *ngIf="noFiltersInSearch()" class="vertical-flex feature-store__empty-store">
        <div class="feature-store__empty-store-icon">
            <i class="icon-dku-feature-store"></i>
        </div>
        <div>
            <span><em>No feature group visible.</em></span>
        </div>
        <div>
            <span>Either promote datasets to feature groups, or request access to a project containing feature groups.</span>
        </div>
    </div>
    <div *ngIf="!noFiltersInSearch()" class="alert alert-info">
        <h4>No match</h4>
        <p *ngIf="hasFacets()">
            Remove your filters to see more
            <a class="btn btn--secondary" (click)="resetSearch()">Remove all filters</a>
        </p>
    </div>
</div>


<div class="vertical-flex h100" *ngIf="canShowSearchResults()">
    <div style="height: 50px; padding: 0 10px; " class="noflex dku-border-bottom">
        <div style=" align-items: center; display: flex; height: 100%;">
            <div style="width: 100%;">
                <div class="pull-right">
                    <div class="counters" data-block="counter">
                        <div class="dib" style="font-size: 18px; margin-right: 1em; vertical-align: middle" [innerHtml]=itemCount()></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ul class="list-items noflex"
        *ngFor="let item of hits"
        >
        <div style="height: 70px;">
            <li (click)="selectItem(item)"
                class="list-item"
                [ngClass]="{
                    'selected': item._id === selectedItem?._id
                }"
                line-height="70"
                style="border-bottom: 1px #ccc solid; list-style: none; position: relative; height: 100%; align-items: center; display: flex;">
                <div class="hit feature-group" dataset="item" style="display: flex; padding:0 0 0 0px; width: 100%; align-items: center;" [attr.data-qa-smid]="'feature-group-' + item._source.projectKey + '-' + item._source.id">
                    <div class="hitContent" style="flex: 1; overflow: hidden; margin-left: 15px;">
                        <div class="hit-content-main vertical-flex feature-store_feature-group-preview">
                            <h4 class="flex hit-content-main__title" [innerHtml]="formatItemName(item)"></h4>
                            <div class="description-preview ellipsed" *ngIf="itemHasDescription(item)">
                                <span [innerHtml]=formatItemDescription(item)></span>
                            </div>
                            <div *ngIf="itemHasTags(item)" class="matching_part feature-store__item-attribute">
                                <ul class="tags feature-store__tags-listing inline">
                                    <li *ngFor="let tag of itemTags(item)">
                                        <span class="tag feature-store__tag" [ngStyle]="{'background-color': tagBackgroundColor(item._source.projectKey, tag)}" [ngClass]="{'feature-store__highlighted-tag': tagIsHighlighted(item, tag)}">
                                            <ui-global-tag [tag]="tag" [objectType]="item._type.toUpperCase()" [attr.data-qa-smid]="tagIsHighlighted(item, tag)?'highlighted':''"></ui-global-tag>
                                        </span>
                                    </li>
                                    <li *ngIf="numberOfTagsNotDisplayed(item) > 0">
                                        <span class="feature-store__more-tags">
                                            {{numberOfTagsNotDisplayed(item)}}&nbsp;more...
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div [attr.data-qa-smid]="'feature-group-project_name'">
                        <p class="feature-store__project-name">
                            <span>
                                <span [innerHtml]="formatProjectName(item)"></span>
                            </span>
                        </p>
                    </div>
                </div>
            </li>
        </div>
    </ul>
</div>