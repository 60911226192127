<labeling-error></labeling-error> 
<ng-container *ngIf="{unusableTaskWarning: unusableTaskWarning$ | async} as ctx">
    <labeling-unusable-warning *ngIf="ctx.unusableTaskWarning" [warning]="ctx.unusableTaskWarning" [privileges]="privilegesOnTask"></labeling-unusable-warning>
    <ng-container *ngIf="!ctx.unusableTaskWarning">
        <div class="labeling-overview">

            <div class="labeling-overview-insight" *ngIf="globalPerRecordStatusCount$ | async as globalProgress">
                <div class="global-progress__header">
                    <div class="dku-grand-title">Global Progress</div>
                    <a *ngIf="privilegesOnTask?.canReview && globalProgress['READY_FOR_REVIEW'] > 0" (click)="goToReviewTab()"
                        class="btn btn--contained btn--primary">Review images</a>
                </div>
                <div class="global-progress__legend">
                    <span><span class="labeling-color-dot labeling-color-dot--reviewed"></span> {{ globalProgress['REVIEWED']}} images reviewed
                    </span>
                    <span><span class="labeling-color-dot labeling-color-dot--to-review"></span>{{ globalProgress['READY_FOR_REVIEW'] }} to be
                        reviewed</span>
                    <span><span class="labeling-color-dot labeling-color-dot--partially-labeled"></span>{{ globalProgress['PARTIALLY_LABELED'] }}
                        partially labeled</span>
                    <span><span class="labeling-color-dot labeling-color-dot--to-label"></span>{{ globalProgress['TO_LABEL']}} to label</span>
                </div>

                <div class="progress-bar-container" *ngIf="totalNumRecords$ | async as totalNumRecords">
                    <div class="global-progress-bar progress-bar--reviewed"
                        [ngStyle]="{ 'width': 100 * globalProgress['REVIEWED'] / totalNumRecords + '%' }"></div>
                    <div class="global-progress-bar progress-bar--to-review"
                        [ngStyle]="{ 'width': 100 * globalProgress['READY_FOR_REVIEW'] / totalNumRecords + '%' }"></div>
                    <div class="global-progress-bar progress-bar--partially-labeled"
                        [ngStyle]="{ 'width': 100 * globalProgress['PARTIALLY_LABELED'] / totalNumRecords + '%' }"></div>
                    <div class="global-progress-bar progress-bar--to-label"
                        [ngStyle]="{ 'width': 100 * globalProgress['TO_LABEL'] / totalNumRecords + '%' }"></div>
                </div>
            </div>

            <div class="labeling-overview-insight" *ngIf="perAnnotatorStatusCount$ | async as perAnnotatorStatusCount">
                <div class="dku-grand-title">Labeler performance</div>
                <table class="table labeling-overview-insight__table">
                    <thead>
                        <tr>
                            <th width="25%">Labeler</th>
                            <th width="75%" class="table__status-col-header">
                                <span><span class="labeling-color-dot labeling-color-dot--reviewed"></span>Reviewed</span>
                                <span class="mleft8"><span class="labeling-color-dot labeling-color-dot--partially-labeled"></span>Labeled</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="allUsersByLogin$ | async as allUsersByLogin">
                        <tr *ngIf="objectKeysFun(perAnnotatorStatusCount).length === 0">
                            <td colspan="2" class="labeling-overview-insight__empty-state-table">
                                No annotations have been made yet
                            </td>
                        </tr>
                        <ng-container *ngIf="numRecordsLabeledByBiggestAnnotator$ | async as maxNumLabeledRecords">
                            <tr
                                *ngFor="let annotatorStat of perAnnotatorStatusCount | keyvalue: sortByBiggerLabeler; let index = index;">
                                <ng-container *ngIf="annotatorStat.value as perStatusCount">
                                    <td class="labeling-overview-insight__name-col"
                                        *ngIf="allUsersByLogin[annotatorStat.key] as annotator"
                                        title="{{annotator.displayName}}">
                                        <user-picture class="mright4" [user]="annotator.login" [size]="24"></user-picture>
                                        {{annotator.displayName}}
                                    </td>

                                    <td class="labeling-overview-insight__progress-bar-row">
                                        <div class="progress-bar-container">
                                            <div class="labeler-progress-bar progress-bar--reviewed"
                                                [matTooltip]="perStatusCount.REVIEWED +' ('+ ((perStatusCount.REVIEWED / perStatusCount.TOTAL)| percent: '1.0-1') +')' "
                                                matTooltipPosition="above" matTooltipClass="progress-chart-tooltip"
                                                [ngStyle]="{ 'width': 100 * perStatusCount.REVIEWED / maxNumLabeledRecords + '%' }">
                                            </div>
                                            <div class="labeler-progress-bar progress-bar--partially-labeled"
                                                [matTooltip]="perStatusCount.LABELED +' ('+ ((perStatusCount.LABELED / perStatusCount.TOTAL)| percent: '1.0-1') +')'"
                                                matTooltipPosition="above"
                                                [ngStyle]="{ 'width': 100 * perStatusCount.LABELED / maxNumLabeledRecords + '%' }">
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="labeling-overview-insight" *ngIf="globalPerClassPercentage$ | async as perClassPercentage">
                <div class="dku-grand-title">Label popularity</div>
                <table class="table table-hover labeling-overview-insight__table ">
                    <thead>
                        <tr>
                            <th width="50%">Label</th>
                            <th width="50%" class="labeling-overview-insight__figure-col">Usage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="objectKeysFun(perClassPercentage).length === 0">
                            <td colspan="2" class="labeling-overview-insight__empty-state-table">
                                No classes have been defined yet
                            </td>
                        </tr>
                        <tr *ngFor="let classPercentage of perClassPercentage | keyvalue: sortByDescValue">
                            <td class="labeling-overview-insight__name-col">
                                <span class="labeling-color-dot"
                                    [ngStyle]="{'background-color': getColorForClass(classPercentage.key)}"></span>
                                <span [ngClass]="{ 'disabled': !classPercentage.value[1] }" title="{{ classPercentage.key }}">{{ classPercentage.key }}</span>
                            </td>
                            <td class="labeling-overview-insight__figure-col" [ngClass]="{ 'disabled': !classPercentage.value[1] }">{{ classPercentage.value[0] | percent: '1.0-1'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
</ng-container>
