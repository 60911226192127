import { KeyValue } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { withLatestFrom, takeUntil, tap, map } from 'rxjs/operators';
import { AccessibleObjectsService } from '../../../../generated-sources';
import { observeFormControl } from '../../../utils/form-control-observer';

export interface AccessibleObjectOption extends Partial<Omit<AccessibleObjectsService.AccessibleObject, 'type'>> {
    id: AccessibleObjectsService.AccessibleObject['id'];
    label: AccessibleObjectsService.AccessibleObject['label'];
    type?: AccessibleObjectsService.AccessibleObject['type'] | 'APP';
    usable?: boolean;
    usableReason?: string;
}

@Component({
    selector: 'dss-accessible-objects-selector',
    templateUrl: './accessible-objects-selector.component.html',
    styleUrls: ['./accessible-objects-selector.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AccessibleObjectsSelectorComponent,
        multi: true
    }]
})
export class AccessibleObjectsSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() id: string;
    @Input() type: string;
    @Input() set objects(objects: AccessibleObjectOption[] | null) {
        if (!objects) return;

        this.objects$.next(objects);
    }
    @Input() multi: boolean;

    touched = false;

    readonly objectsControl = this.fb.control(null);
    readonly searchControl = this.fb.control('');
    readonly objects$ = new ReplaySubject<AccessibleObjectOption[]>(1);
    readonly filteredAndGroupedObjects$ = combineLatest([
        this.objects$,
        observeFormControl<string>(this.searchControl),
    ])
        .pipe(
            map(([objects, filter]) => this.filterObjects(objects, filter)),
            map(filteredObjects => this.groupObjects(filteredObjects))
        );
    readonly selectTriggerContent$ = (this.objectsControl.valueChanges as Observable<string[]>).pipe(withLatestFrom(this.objects$), map(([ids, objects]) => objects.filter(({ id }) => ids.includes(id)).map(({ label }) => label).join(', ')));

    private readonly destroy$ = new Subject<void>();

    private onChange?: (objects: AccessibleObjectOption[] | AccessibleObjectOption) => void;
    private onTouched?: () => void;

    constructor(private readonly fb: FormBuilder) { }

    ngOnInit(): void {
        this.handleObjectsSelection();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    writeValue(objects: string[]): void {
        this.objectsControl.setValue(objects, { emitEvent: false });
    }

    registerOnChange(onChange: (objects: AccessibleObjectOption[] | AccessibleObjectOption) => void): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    trackOptGroup(_: number, { key }: KeyValue<string, AccessibleObjectOption[]>): string {
        return key;
    }

    trackOption(_: number, { id }: AccessibleObjectOption): string {
        return id;
    }

    private markAsTouched(): void {
        if (!this.touched) {
            this.onTouched?.();
            this.touched = true;
        }
    }

    private filterObjects(objects: AccessibleObjectOption[], filter: string): AccessibleObjectOption[] {
        const lowerCaseFilter = filter.trim().toLowerCase();

        return objects.filter(object => {
            const labelFilter = !!object.label?.toLowerCase().includes(lowerCaseFilter);
            const typeFilter = !!object.type?.toLowerCase().includes(lowerCaseFilter);
            const subtypeFilter = !!object.subtype?.toLowerCase().includes(lowerCaseFilter);

            return labelFilter || typeFilter || subtypeFilter;
        });
    }

    private handleObjectsSelection(): void {
        this.objectsControl.valueChanges.pipe(
            takeUntil(this.destroy$),
            tap((objects: AccessibleObjectOption | AccessibleObjectOption[]) => {
                if (Array.isArray(objects)) {
                    this.onChange?.(objects);
                } else {
                    this.onChange?.(objects);
                }
                this.markAsTouched();
            }
            )).subscribe();
    }

    private groupObjects(objects: AccessibleObjectOption[]): Map<string, AccessibleObjectOption[]> {
        const objectsByFirstLetter: Map<string, AccessibleObjectOption[]> = new Map();

        for (const object of objects) {
            const firstLetter = object.label.charAt(0).toUpperCase();
            objectsByFirstLetter.set(firstLetter, (objectsByFirstLetter.get(firstLetter) || []).concat(object));
        }

        return objectsByFirstLetter;
    }
}
