import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'autocompletable-textarea',
    template: `
        <angularjs-autocompletable-textarea [model]="model" [modelChange]="modelChange" [textarea]="textarea" [options]="options"></angularjs-autocompletable-textarea>`,
})
// This is a thin wrapper to allow angular-style "two-way binding"
// by passing the outpout (modelChange) as an input to <angularjs-autocompletable-textarea>
export class AutocompletableTextareaComponent {
    @Input() model: string | null | undefined;
    @Input() textarea?: object;
    @Input() options?: string;

    // Allow to have 2 way data-binding in templates using the `[(model)]` syntax
    @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}
}