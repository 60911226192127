import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WindowService } from 'dku-frontend-core';
import { concat } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

/*
    This directive adds dynamic height capabilities to CdkVirtualScrollViewport.

    Normally with a cdk-virtual-scroll-viewport, the viewport height must be set
    in advance. This directive updates the height of the viewport as items are
    added and removed. The viewport becomes scrollable once exceeding maxHeight.
    By default, maxHeight is set to the viewport parent's height, but also can
    be passed as an input parameter (dynamicViewportMaxHeight).
*/
@UntilDestroy()
@Directive({
    selector: '[dynamicViewport]'
})
export class DynamicVirtualScrollViewportDirective implements OnChanges {
    @Input('dynamicViewport') dataLength: number;
    // if no dynamic max height specified, directive takes height of parent element
    @Input() set dynamicViewportMaxHeight(height: number | null) {
        this.maxHeight = height;
    }

    private DEFAULT_MARGIN = 16;
    private maxHeight: number | null;

    constructor(
        private viewport: CdkVirtualScrollViewport,
        private scrollStrategy: CdkFixedSizeVirtualScroll,
        private el: ElementRef,
        private renderer: Renderer2,
        private windowService: WindowService
    ) {
        concat(
            this.windowService.resize$.pipe(take(1)),
            this.windowService.resize$.pipe(debounceTime(500)),
        ).pipe(
            untilDestroyed(this),
        ).subscribe(() => {
            if (!this.dynamicViewportMaxHeight) {
                this.setMaxHeight();
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        const itemSize = this.scrollStrategy.itemSize;
        const prevHeight = this.getTrueHeight(changes.dataLength.previousValue * itemSize);
        const height = this.getTrueHeight(this.dataLength * itemSize);

        if (prevHeight !== height) {
            this.renderer.setStyle(this.el.nativeElement, 'height', `${height}px`);
            this.viewport.checkViewportSize();
        }
    }

    private getTrueHeight(height: number) {
        return Math.min(height, this.maxHeight || 0);
    }

    private setMaxHeight() {
        this.renderer.setStyle(this.el.nativeElement, 'height', 0);

        const parentHeight = (this.el.nativeElement as HTMLElement).parentElement?.clientHeight;

        if (parentHeight && parentHeight > this.DEFAULT_MARGIN) {
            this.maxHeight = parentHeight - this.DEFAULT_MARGIN;
            const height = this.getTrueHeight(this.dataLength * this.scrollStrategy.itemSize);
            
            this.renderer.setStyle(this.el.nativeElement, 'height', `${height}px`);
            this.viewport.checkViewportSize();
        }
    }

}
