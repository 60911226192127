import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Workspace } from 'src/generated-sources';

@Injectable({ providedIn: 'root' })
export class PrivateWorkspacesAPI {

    constructor(private dkuHttp: DkuHttpService) { }

    list(accessLevel = "READ"): Observable<Workspace[]> {
        return this.dkuHttp.request('GET', '/workspaces/list', { accessLevel });
    }

    get(workspaceKey: string): Observable<any> {
        return this.dkuHttp.request('GET', '/workspaces/get', { workspaceKey });
    }

    create(workspace: Workspace): Observable<any> {
        return this.dkuHttp.request('POST', '/workspaces/create', { workspace });
    }

    update(workspace: Workspace): Observable<any> {
        return this.dkuHttp.request('PUT', '/workspaces/update', { workspace });
    }

    save(workspace: Workspace): Observable<any> {
        return this.dkuHttp.request('POST', '/workspaces/save', { workspace });
    }

    delete(workspaceKey: string): Observable<any> {
        return this.dkuHttp.request('POST', '/workspaces/delete', { workspaceKey });
    }

    getObject(workspaceKey: string, objectKey: string): Observable<any> {
        return this.dkuHttp.request('GET', '/workspaces/get-object', { workspaceKey, objectKey });
    }

    addObjects(workspaceKey: string, workspaceObjects: Workspace.WorkspaceObject[]): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/add-objects', { workspaceKey, workspaceObjects });
    }

    removeObjects(workspaceKey: string, objectsToRemove: Workspace.WorkspaceObject[]): Observable<Workspace> {
        return this.dkuHttp.request('POST', '/workspaces/remove-objects', { workspaceKey, objectsToRemove });
    }

    timeline(workspaceKey: string): Observable<any> {
        return this.dkuHttp.request('GET', '/workspaces/timeline', { workspaceKey });
    }
}
