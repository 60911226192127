<div *ngIf="formattedPredictions">
    <div class="mbot32 db">
        <div class="deephub-image-classification-feed-modal__results-header">Prediction</div>
        <div class="deephub-image-classification-feed-modal__results-horizontal-bars" >
            <div
                *ngFor="let p of formattedPredictions"
                [style.background-color]="p.color"
                [style.width.%]="p.proba * 100"
                [style.height.px]="16"
                [matTooltip]="p.title" matTooltipPosition="below">
            </div>
        </div>
    
        <div class="deephub-image-classification-feed-modal__results-horizontal-bars deephub-image-classification-feed-modal__results-horizontal-axis">
            <div>0</div>
            <div>20</div>
            <div>40</div>
            <div>60</div>
            <div>80</div>
            <div>100%</div>
        </div>
    </div>
    
    <div class="deephub-image-classification-feed-modal__row"
        *ngFor="let p of formattedPredictions">
    
        <div class="mbot4 deephub-image-classification-feed-modal__category"
            [style.background-color]="p.category === classifData.prediction? p.color : ''">
            <i  *ngIf="p === formattedPredictions[0]"
                [ngClass]="{'icon-ok-sign': target && target === p.category,
                            'icon-remove': target && target !== p.category}"
                class="mright4 deephub-image-classification-feed-modal__category--elt"
                [style.color]="p.color | colorContrast"></i>

            <span *ngIf="p !== formattedPredictions[0]"
                  class="mright4 deephub-image-classification-feed-modal__category--elt deephub-image-classification-feed-modal__row-bullet"
                  [style.color]="p.color"></span>
    
            <span *ngIf="!p.others" class="deephub-image-classification-feed-modal__category--elt" [style.color]="(p === formattedPredictions[0])? (p.color | colorContrast) : '#000000' ">{{p.category}} </span>
            <span *ngIf="p.others" class="deephub-image-classification-feed-modal__category--elt" [matTooltip]="p.title" matTooltipPosition="below"><em>Others</em></span>
        </div>
    
        <div class="mbot4">{{(p.proba *100).toFixed(1)}}%</div>
    </div>
</div>