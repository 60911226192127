export namespace AppsService {
    /**
     * Generated from com.dataiku.dip.projects.apps.AppsService$AppOrigin
     */
    export enum AppOrigin {
        PROJECT = 'PROJECT',
        PLUGIN = 'PLUGIN'
    }

    /**
     * Generated from com.dataiku.dip.projects.apps.AppsService$AppListItem
     */
    export interface AppListItem {
        appId: string;
        appVersion: string;
        defaultImgColor: string;
        description: string;
        imgColor: string;
        imgPattern: number;
        instanceCount: number;
        instanceOwners: any /* TODO: Missing translation of com.dataiku.dip.security.model.PublicUser */[];
        isAppImg: boolean;
        label: string;
        lastInstantiation: number;
        objectImgHash: number;
        onlyLimitedVisibility: boolean;
        origin: AppsService.AppOrigin;
        originProjectKey: string;
        shortDesc: string;
        showInitials: boolean;
        tags: string[];
        useAsRecipe: boolean;
    }
}