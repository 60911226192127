import { BoundingBox, ClassificationAnnotation } from 'src/generated-sources';

export class UIAnnotation {
    category?: string;
    selected?: boolean;
    annotator?: string;
    isSelectable?: boolean;

    constructor(category?: string, selected?: boolean, annotator?: string, isSelectable?: boolean) {
        this.category = category;
        this.selected = selected;
        this.annotator = annotator;
        this.isSelectable = isSelectable;
    }

    equals(annotation: UIAnnotation) {
        return this.category === annotation.category;
    }
}

export class UIClassificationAnnotation extends UIAnnotation implements ClassificationAnnotation {
    category: string;

    constructor(category?: string, selected?: boolean, annotator?: string, isSelectable?: boolean) {
        super(category, selected, annotator, false);
    }
}

export class UIBoundingBox extends UIAnnotation implements BoundingBox {
    bbox: BoundingBox.BoxCoordinates;
    category: string;
    pinned: boolean;
    state?: string;

    constructor({ width, height, top, left, category, annotator, pinned, selected, state}: { width: number, height: number, top: number, left: number, category?: string, annotator?: string, pinned: boolean, selected?: boolean, state?: string }) {
        super(category, selected, annotator, true);
        this.bbox = {
            x0: left,
            y0: top,
            width: width,
            height: height
        };

        this.pinned = pinned;
        this.selected = selected || false;
        this.state = state;
    }

    get left() {
        return this.bbox.x0;
    }

    get top() {
        return this.bbox.y0;
    }

    get width() {
        return this.bbox.width;
    }

    get height() {
        return this.bbox.height;
    }

    static allEqual(bboxes1: UIBoundingBox[], bboxes2: UIBoundingBox[], ignoreSelection?: boolean) {
        if (bboxes1.length !== bboxes2.length) {
            return false;
        }
        for (let i = 0; i < bboxes1.length; i++) {
            if (!bboxes1[i].equals(bboxes2[i], ignoreSelection)) {
                return false;
            }
        }
        return true;
    }

    equals(boundingBox: UIBoundingBox, ignoreSelection?: boolean): boolean {
        return this.width == boundingBox.width &&
            this.height == boundingBox.height &&
            this.top == boundingBox.top &&
            this.left == boundingBox.left &&
            this.category == boundingBox.category &&
            this.annotator == boundingBox.annotator &&
            this.state == boundingBox.state &&
            (ignoreSelection ? true : this.selected == boundingBox.selected)
    }

    empty() {
        return this.width == 0 || this.height == 0;
    }

    iou(bbox: BoundingBox.BoxCoordinates) {
        const xLeft = Math.max(this.left, bbox.x0);
        const yTop = Math.max(this.top, bbox.y0);
        const xRight = Math.min(this.left + this.width, bbox.x0 + bbox.width);
        const yBottom = Math.min(this.top + this.height, bbox.y0 + bbox.height);

        if (xRight <= xLeft || yBottom <= yTop) {
            return 0.0;
        }

        const intersection_area = (xRight - xLeft) * (yBottom - yTop);

        const bb1Area = (this.width) * (this.height);
        const bb2Area = (bbox.width) * (bbox.height);

        return intersection_area / (bb1Area + bb2Area - intersection_area);
    }
}
