import { Component, Input } from '@angular/core';
import { PainterService } from "@shared/services/painter.service";
import { SampleDataFetcherService } from '@shared/services/sample-data-fetcher.service';
import { ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeephubObjectDetectionDesignPainterService } from '../services/deephub-object-detection-design-painter.service';
import { DeephubObjectDetectionDesignDataFetcherService } from '../services/deephub-object-detection-design-data-fetcher.service';
import { PredictionMLTask } from 'src/generated-sources';
import { AbstractDeephubDesignDataFetcherService } from '@features/deephub/computer-vision/design/services/abstract-deephub-design-data-fetcher.service';

@Component({
    selector: 'deephub-object-detection-design-image-feed',
    templateUrl: 'deephub-object-detection-design-image-feed.component.html',
    providers: [
        ColorMapContextService,
        DeephubObjectDetectionDesignPainterService,
        DeephubObjectDetectionDesignDataFetcherService,
        { provide: ImagesDataFetcherService, useExisting: DeephubObjectDetectionDesignDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: DeephubObjectDetectionDesignDataFetcherService },
        { provide: PainterService, useExisting: DeephubObjectDetectionDesignPainterService },
        { provide: AbstractDeephubDesignDataFetcherService, useExisting: DeephubObjectDetectionDesignDataFetcherService },
    ]
})
export class DeephubObjectDetectionDesignImageFeedComponent {
    @Input() mltask: PredictionMLTask.DeepHubPredictionMLTask;
    @Input() selectedCategories?: string[];
    // Explicitly pass pathColumn and managedFolderSmartId to trigger change from Angular
    @Input() pathColumn: string;
    @Input() managedFolderSmartId: string;
};
