import { Injectable } from "@angular/core";
import { BasePainterOptions, ImagePositionInformation, PainterImage } from "@shared/models/painter";
import { CellData } from "@shared/services/images-data-fetcher.service";
import { PainterService } from "@shared/services/painter.service";
import { Canvas } from "fabric/fabric-impl";

@Injectable()
export class LabelingCanvasPainterService extends PainterService {
    paint(cellData: CellData, base: Canvas, imagePosition: ImagePositionInformation, options?: BasePainterOptions): void {
        // TODO @labeling add bbox to image in feed (or dont?)
    }

}
