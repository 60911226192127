<div class="vertical-flex h100 padbot0">
    <div class="paned-page-section simple-columns aic">
        <h1 class="paned-page-section__heading">What if?</h1>
        <ng-container *ngIf="hasLoadedImages$ | async">
            <ng-container
                *ngIf="headerTemplate"
                [ngTemplateOutlet]="headerTemplate"
            ></ng-container>
            <button class="btn btn--text btn--primary" (click)="inputFileUpload.click()">
                Add more images
            </button>
            <input #inputFileUpload type="file" (change)="uploadImages(inputFileUpload.files!)" multiple hidden>
        </ng-container>
    </div>
    <div class="interactive-scoring-container">
        <deephub-report-interactive-scoring-drop-zone
          [draggedOver]="draggedOver$ | async"
          [loading]="loading$ | async"
          [hideDefault]="hasLoadedImages$ | async"
          (uploadByClick)="uploadImages($event)"
        >
        </deephub-report-interactive-scoring-drop-zone>
        <div *ngIf="hasLoadedImages$ | async" class="interactive-scoring-container__content">
            <ng-container *ngIf="selectedCellData$ | async as selectedCellData">
                <image-feed class="h100 interactive-scoring-image-feed" (imageClick)="selectImage($event)" [selectedPath]="selectedCellData.itemPath" [selectedIndex]="carouselService.selectedIndex$ | async" [imageHeight]="200" [itemsPerRow]="1"></image-feed>
                <ng-container
                    [ngTemplateOutlet]="imageDetailsTemplate"
                    [ngTemplateOutletContext]="{ cellData: selectedCellData }"
                ></ng-container>
            </ng-container>
        </div>
    </div>
</div>
