import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchOptions } from '../feature-store-models';

@UntilDestroy()
@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent implements OnInit, OnChanges {

  @ViewChild('search') searchElement: ElementRef;
  @Input() searchQuery: string;
  @Output() newQueryEvent: EventEmitter<SearchOptions> = new EventEmitter();

  sortOptions = [
    {
      label: 'Relevance',
      value: 'score'
    },
    {
      label: 'Creation',
      value: 'createdOn'
    },
    {
      label: 'Last modification',
      value: 'lastModifiedOn'
    }
  ];

  form: FormGroup

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      query: this.fb.control(""),
      sortBy: this.fb.control("score"),
      reverse: this.fb.control(true)
    });
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        untilDestroyed(this),
      )
      .subscribe((searchOptions) => {
        this.newQueryEvent.emit(searchOptions);
      });
  }

  ngOnChanges(_: SimpleChanges): void {
    this.form.patchValue({
      query: this.searchQuery
    });
  }

  hasSearch(): boolean {
    return this.form.controls['query'].value !== "";
  }
  
  resetSearch(): void {
    this.form.controls['query'].setValue("");
  }

  selectInput(): void {
    this.searchElement.nativeElement.focus();
  }

  disableSortButton(): boolean {
    return this.form.controls['sortBy'].value === 'score';
  }

  reverseOrder(): boolean {
    return this.form.controls['reverse'].value as boolean;
  }
}
