import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserModule } from "@angular/platform-browser";
import { WidgetsModule } from "@app/widgets/widgets.module";
import { MigrationModule } from "@migration/migration.module";
import { ComponentsModule } from "@shared/components/components.module";
import { ModalsModule } from "@shared/modals/modals.module";
import { PipesModule } from "@shared/pipes/pipes.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { WorkspacesLeftMenuComponent } from "./layout/workspaces-left-menu/workspaces-left-menu.component";
import { EditWorkspaceModalComponent } from "./modals/edit-workspace-modal/edit-workspace-modal.component";
import { SelectButtonComponent } from "./workspace/select-button/select-button.component";
import { WorkspaceHeaderComponent } from "./workspace/workspace-header/workspace-header.component";
import { WorkspacePermissionsEditionComponent } from "./workspace/workspace-permissions-edition/workspace-permissions-edition.component";
import { WorkspacesComponent } from "./workspaces.component";
import { WorkspaceAddObjectsComponent } from './workspace/workspace-add-objects/workspace-add-objects.component';
import { WorkspaceGeneralEditionComponent } from "./workspace/workspace-general-edition/workspace-general-edition.component";
import { WorkspaceObjectLinkFaviconComponent } from './workspace/workspace-object-link-favicon/workspace-object-link-favicon.component';
import { DataikuAPIModule } from "@core/dataiku-api/dataiku-api.module";
import { WorkspaceObjectThumbnailComponent } from "./workspace/workspace-object-thumbnail/workspace-object-thumbnail.component";
import { WorkspaceObjectListComponent } from "./workspace/workspace-object-list/workspace-object-list.component";
import { WorkspaceObjectListElementComponent } from "./workspace/workspace-object-list-element/workspace-object-list-element.component";
import { WorkspaceIconComponent } from "./workspace/workspace-icon/workspace-icon.component";
import { DkuFrontendCoreModule } from "dku-frontend-core";

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        MigrationModule,
        WidgetsModule,
        MatMenuModule,
        MatDialogModule,
        MatSelectModule,
        MatTabsModule,
        ComponentsModule,
        NgxMatSelectSearchModule,
        PipesModule,
        ModalsModule,
        DataikuAPIModule,
        DkuFrontendCoreModule
    ],
    declarations: [
        WorkspacesComponent,
        SelectButtonComponent,
        WorkspaceIconComponent,
        WorkspacesLeftMenuComponent,
        WorkspaceHeaderComponent,
        WorkspacePermissionsEditionComponent,
        EditWorkspaceModalComponent,
        WorkspaceGeneralEditionComponent,
        WorkspaceObjectLinkFaviconComponent,
        WorkspaceAddObjectsComponent,
        WorkspaceObjectThumbnailComponent,
        WorkspaceObjectListComponent,
        WorkspaceObjectListElementComponent,
    ],
})
export class WorkspacesModule { }
