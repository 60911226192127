import { Component, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { FeedImageModalParams } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';
import { DeephubImagesDataService } from 'src/generated-sources';
import { DeephubObjectDetectionReportImageFeedModalComponent } from '@features/deephub/object-detection/report/image-feed-modal/deephub-object-detection-report-image-feed-modal.component';
import { DeephubObjectDetectionReportDataFetcherService, DeephubObjectDetectionResultsDataColumns } from '../services/deephub-object-detection-report-data-fetcher.service';

@UntilDestroy()
@Component({
  selector: 'deephub-object-detection-report-image-feed',
  templateUrl: './deephub-object-detection-report-image-feed.component.html',
  styleUrls: ['../../../computer-vision/report/image-feed/deephub-compute-version-report-image-feed.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubObjectDetectionReportImageFeedComponent {
    constructor(
        public dataFetcher: DeephubObjectDetectionReportDataFetcherService,
        public viewContainerRef: ViewContainerRef,
        public modalsService: ModalsService
    ) {}

    openModal({ imageId }: FeedImageModalParams) {
        const dataColumns = this.dataFetcher.dataColumns as DeephubObjectDetectionResultsDataColumns
        const IGNORED_COLUMNS = [
            dataColumns.target.name,
            dataColumns.prediction.name,
            dataColumns.pairing.name,
            dataColumns.enrichedFiltered.name,
            dataColumns.enrichedValid.name
        ];
        const IGNORED_PREFIX = "__dku__deephub_";
        const columns = this.dataFetcher.tableData!.allColumnNames.map((name, index) => ({
            name,
            index
        })).filter(column => !IGNORED_COLUMNS.includes(column.name) && !column.name.startsWith(IGNORED_PREFIX));
        this.modalsService
            .open(DeephubObjectDetectionReportImageFeedModalComponent, {
                imageId,
                columns,
                imageOptions: {
                    showResultIcon: true,
                    selectedStrokeWidth: 3
                }
             }, ModalShape.FULL, this.viewContainerRef)
            .then(() => {}, () => {});
    }

    public get SortBy() {
        return DeephubImagesDataService.SortBy;
    }

    private setSorting(filter: Partial<DeephubImagesDataService.ObjectDetectionPredictedFilter>, sorting: DeephubImagesDataService.Sorting) {
        const newFilter = {...filter, sorting};
        this.dataFetcher.setFilteredPair(newFilter);
    }

    setSortingByConfidence(filter: Partial<DeephubImagesDataService.ObjectDetectionPredictedFilter>) {
        this.setSorting(filter, {
            sortBy: DeephubImagesDataService.SortBy.CONFIDENCE,
            ascending: filter!.sorting!.ascending
        });
    }

    setSortingByIOU(filter: Partial<DeephubImagesDataService.ObjectDetectionPredictedFilter>) {
        this.setSorting(filter, {
            sortBy: DeephubImagesDataService.SortBy.IOU,
            ascending: filter!.sorting!.ascending
        });
    }

    setSortingAscending(filter: Partial<DeephubImagesDataService.ObjectDetectionPredictedFilter>) {
        this.setSorting(filter, {
            sortBy: filter!.sorting!.sortBy,
            ascending: true
        });
    }

    setSortingDescending(filter: Partial<DeephubImagesDataService.ObjectDetectionPredictedFilter>) {
        this.setSorting(filter, {
            sortBy: filter!.sorting!.sortBy,
            ascending: false
        });
    }

}
