import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { map, Observable, shareReplay, withLatestFrom } from 'rxjs';
import { LabelingTaskStats } from 'src/generated-sources';
import { UnusableTaskWarning } from '../labeling-unusable-warning/labeling-unusable-warning.component';
import { LabelingTaskPrivileges } from '../labeling.component';
import { ColorMeaning, LabelingColorService } from '../services/labeling-color.service';
import { LabelingService } from '../services/labeling.service';

type StatusCount = {[key in "LABELED" | "REVIEWED" | "TOTAL"]: number}
type PerAnnotatorStatusCount = { [key: string]: StatusCount};
@Component({
    selector: 'labeling-task-overview',
    templateUrl: './labeling-task-overview.component.html',
    styleUrls: ['./labeling-task-overview.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingTaskOverviewComponent implements OnInit {
    @Input() privilegesOnTask: LabelingTaskPrivileges;

    stats$: Observable<LabelingTaskStats>;

    totalNumRecords$: Observable<number>;

    globalPerRecordStatusCount$: Observable<{[key in LabelingTaskStats.RecordStatus]: number}>;
    globalPerClassPercentage$: Observable<{[key: string]: [number, boolean]}>;
    perAnnotatorStatusCount$: Observable<PerAnnotatorStatusCount>;
    numRecordsLabeledByBiggestAnnotator$: Observable<number>;

    unusableTaskWarning$: Observable<UnusableTaskWarning | null>;

    allUsersByLogin$: Observable<{ [key: string]: any}>;

    objectKeysFun = Object.keys;

    constructor(private DataikuAPI: DataikuAPIService,
        private labelingService: LabelingService,
        private labelingColorService: LabelingColorService,
        @Inject('$state') private $state: any) { }

    ngOnInit(): void {

        this.labelingColorService.setCurrentColorMeaning(ColorMeaning.CLASS);

        const stats$ = this.labelingService.getStats().pipe(shareReplay(1));

        this.allUsersByLogin$ = this.DataikuAPI.security.listUsers().pipe(
            shareReplay(1),
            map((allUsers) => {
                return  allUsers.reduce(
                    (obj: { [key: string]: any}, item: any) => Object.assign(obj, { [item.login]: item }), {});
            })
        );

        this.globalPerRecordStatusCount$ = stats$.pipe(
            map((stats) => stats.globalRecordsStat.perRecordStatusCount)
        );

        this.globalPerClassPercentage$ = stats$.pipe(
            withLatestFrom(this.labelingService.classes$),
            map(([stats, classes]) => {
                const perClassCount = stats.perClassCount;
                const totalCount = Object.values(perClassCount).reduce((partialSum, count) => partialSum + count, 0);
                const perClassPercentage: { [key: string]: [number, boolean] } = {};
                for  (const klass of classes) {
                    perClassPercentage[klass] = [0, true];
                }
                for (const [klass, count] of Object.entries(perClassCount)) {
                    perClassPercentage[klass] = [totalCount > 0 ? count / totalCount : 0, classes.includes(klass)];
                }
                return perClassPercentage;
            })
        )

        this.totalNumRecords$ = stats$.pipe(
            map((stats) => Object.values(stats.globalRecordsStat.perRecordStatusCount).reduce((partialSum, count) => partialSum + count, 0))
        );

        this.perAnnotatorStatusCount$ = stats$.pipe(
            map((stats) => {
                const formattedStats: PerAnnotatorStatusCount = {};
                for (const annotator in stats.perAnnotatorRecordsStats) {
                    const labeled = stats.perAnnotatorRecordsStats[annotator].perAnswerStatusCount.PENDING_REVIEW;
                    const reviewed = stats.perAnnotatorRecordsStats[annotator].perAnswerStatusCount.REVIEWED;
                    formattedStats[annotator] = {
                        LABELED: labeled,
                        REVIEWED: reviewed,
                        TOTAL: labeled + reviewed,
                    };
                }
                return formattedStats;
            })
        );

        this.numRecordsLabeledByBiggestAnnotator$ = this.perAnnotatorStatusCount$.pipe(
            map((perAnnotatorProgress) => {
                let numRecordsLabeledByBiggestAnnotator = 0;
                for (const annotator in perAnnotatorProgress) {
                    if (perAnnotatorProgress[annotator].TOTAL > numRecordsLabeledByBiggestAnnotator) {
                        numRecordsLabeledByBiggestAnnotator = perAnnotatorProgress[annotator].TOTAL;
                    }
                }
                return numRecordsLabeledByBiggestAnnotator;
            })
        );

        this.unusableTaskWarning$ = this.labelingService.labelingTaskUnusableReasons$.pipe(
            map((warnings) => warnings[0])
        );
    }

    sortByDescValue = (a: KeyValue<string, [number, boolean]>, b: KeyValue<string, [number, boolean]>): number => {
        return b.value[0] - a.value[0];
    }

    sortByBiggerLabeler = (a: KeyValue<string, StatusCount>, b: KeyValue<string, StatusCount>): number => {
        return b.value.TOTAL - a.value.TOTAL
    }

    getColorForClass(klass: string): string {
        return this.labelingColorService.categoryToColorString(klass);
    }

    goToReviewTab() {
        this.$state.go('projects.project.labelingtasks.labelingtask.review');
    }
}
