import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelingCanvasPainterService } from '@features/labeling/services/labeling-image-painter.service';
import { Column } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { PainterService } from '@shared/services/painter.service';

@Component({
    selector: 'dss-labeling-settings-image-feed-modal',
    templateUrl: './labeling-settings-image-feed-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LabelingCanvasPainterService,
        { provide: PainterService, useExisting: LabelingCanvasPainterService },
    ]
})
export class LabelingSettingsImageFeedModalComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: {
        imageId: number,
        columns: Column[]
    }) { }

}
