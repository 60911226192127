<div class="card-layout">
    <div class="card-layout__section card-layout__section--vertical">
        <time-series-summary
            [seriesVariableName]="params.seriesColumn.name"
            [timeVariableName]="params.timeColumn.name"
            [boxPlot]="results.boxPlot"
            [timeInfo]="results.timeInfo"
        ></time-series-summary>

        <div class="card-layout__section card-layout__section--horizontal">
            <div class="card-layout__section">
                <div class="card-layout__title">Hypothesis</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Regression mode</th>
                                <td class="stats-table__cell">{{ regressionLabel }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Tested hypothesis</th>
                                <td class="stats-table__cell">
                                    <u>{{ params.seriesColumn.name }}</u>
                                    <span *ngIf="isADF">
                                        has a unit root
                                    </span>
                                    <span *ngIf="isZA">
                                        has a unit root with one structural break
                                    </span>
                                    <span *ngIf="isKPSS">
                                        is {{ stationarityLabel }}
                                    </span>
                                </td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Significance level</th>
                                <td class="stats-table__cell">{{ 1 - params.confidenceLevel | smarterNumber }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-layout__section">
                <div class="card-layout__title">Results</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Used lag</th>
                                <td class="stats-table__cell">{{ usedLagLabel }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Statistic</th>
                                <td class="stats-table__cell">{{ results.statistic | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">
                                    <ng-container *ngIf="isADF else pvalue">
                                        MacKinnon’s approximate 𝘱-value
                                    </ng-container>
                                    <ng-template #pvalue>
                                        𝘱-value
                                    </ng-template>
                                </th>
                                <td class="stats-table__cell">
                                    <colored-pvalue
                                        [pvalue]="results.pValue"
                                        [significanceLevel]="1 - params.confidenceLevel"
                                    ></colored-pvalue>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="test-conclusion">
    <ng-container *ngIf="isRejected else inconclusive">
        <p class="test-conclusion__wording test-conclusion__wording--rejected">
            <span *ngIf="isADF">
                At the given significance level, the test rejects the presence of a unit root
                in <u>{{ params.seriesColumn.name }}</u>. It indicates that
                the series may be {{ stationarityLabel }}.
            </span>
            <span *ngIf="isZA">
                At the given significance level, <u>{{ params.seriesColumn.name }}</u>
                is {{ stationarityLabel }} with one structural break.
            </span>
            <span *ngIf="isKPSS">
                At the given significance level, <u>{{ params.seriesColumn.name }}</u>
                is not {{ stationarityLabel }}. It indicates that the series may have a unit root.
            </span>
        </p>
    </ng-container>

    <ng-template #inconclusive>
        <p class="test-conclusion__wording">
            <span *ngIf="isADF">
                At the given significance level, the test is inconclusive about whether
                <u>{{ params.seriesColumn.name }}</u> has a unit root.
            </span>
            <span *ngIf="isZA">
                At the given significance level, the test is inconclusive about whether
                <u>{{ params.seriesColumn.name }}</u> has a unit root with one structural break.
            </span>
            <span *ngIf="isKPSS">
                At the given significance level, the test is inconclusive about whether
                <u>{{ params.seriesColumn.name }}</u> is {{ stationarityLabel }}.
            </span>
        </p>
    </ng-template>
</div>
