import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { EscapeHtmlPipe } from '@shared/pipes/escaping/escape-html.pipe';
import { fairAny } from 'dku-frontend-core';
import { FacetMap } from '../feature-store-models';
import { Hit } from '../query-result-models';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultsComponent {

  @Input() tagsMap: any;
  @Input() hits: Hit[];
  @Input() selectedItem: Hit | null;
  @Input() facetMap: FacetMap;
  @Input() searchQuery: string;
  @Output() resetFiltersEvent: EventEmitter<void>;
  @Output() itemSelectedEvent: EventEmitter<Hit>;

  escapeHtml: EscapeHtmlPipe;

  constructor(
    @Inject("TaggingService") private taggingService: fairAny,
  ) {
    this.resetFiltersEvent = new EventEmitter();
    this.itemSelectedEvent = new EventEmitter();

    this.escapeHtml = new EscapeHtmlPipe();

  }

  canShowSearchResults(): boolean {
    return this.hits && this.hits.length > 0;
  }

  noFiltersInSearch(): boolean {
    return !this.searchQuery && !this.hasFacets();
  }

  hasFacets(): boolean {
    return this.facetMap && Object.keys(this.facetMap).length > 0;
  }

  itemCount(): string {
    const count = this.hits ? this.hits.length : 0;
    return "<strong>" + count + "</strong> Feature Group" + (count<2?"":"s");
  }

  formatItemName(item: Hit): string {
    if (item.highlight) {
      if (item.highlight.name?.length > 0) {
          return item.highlight.name[0];
      }
      if (item.highlight['name.raw']?.length > 0) {
          return item.highlight['name.raw'][0];
      }
    }
    // Comes from _source, encode HTML entities in order to display attributes like <stuff
    return this.escapeHtml.transform(item._source.name);
  }

  formatProjectName(item: Hit): string {
    if (item.highlight?.projectName?.length > 0) {
      return item.highlight.projectName[0];
    }
    if (item.highlight?.projectKey?.length > 0) {
      return item._source.projectName + " (" + item.highlight.projectKey[0] + ")";
    }
    return item._source.projectName;  
  }

  itemHasDescription(item: Hit): boolean {
    return item._source.shortDesc !== undefined && item._source.shortDesc !=="";
  }

  formatItemDescription(item: Hit): string {
    if (item.highlight?.shortDesc?.length > 0) {
      return item.highlight.shortDesc[0];
    }
    return item._source.shortDesc;
  }

  itemHasTags(item: Hit): boolean {
    return item._source.tag?.length !== 0;
  }

  itemTags(item: Hit): string[] {
    if(item.highlight?.tag?.length > 0) {
      const tags: string[] = item.highlight.tag.map(this.removeEmphasis);
      item._source.tag.forEach(tag => {
        if(tags.length < 5 && !tags.includes(tag)) {
          tags.push(tag);
        }
      });
      return tags;
    } else {
      return item._source.tag.slice(0, 5);
    }
  }

  tagIsHighlighted(item: Hit, tag: string): boolean {
    if(item.highlight?.tag?.length > 0) {
      return item.highlight.tag.map(this.removeEmphasis).includes(tag);
    } else {
      return false;
    }
  }

  numberOfTagsNotDisplayed(item: Hit): number {
    const displayedTags = (item.highlight?.tag?.length > 5)?item.highlight.tag.length:5;
    return item._source.tag.length - displayedTags;
  }

  removeEmphasis(emphazised: string) {
    return emphazised.replace(/<em>/g, "").replace(/<\/em>/g, "");
  }

  tagBackgroundColor(projectKey: string, tag: string): string {
    return this.tagsMap[projectKey]?.tags[tag]?.color || this.taggingService.getTagColor(tag);
  }

  resetSearch(): void {
    this.resetFiltersEvent.emit();
  }

  selectItem(item: Hit): void {
    this.itemSelectedEvent.emit(item);
  }
}
