import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { APIError, ErrorContext } from '@core/dataiku-api/api-error';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { clone } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, shareReplay, Subject } from 'rxjs';
import { exhaustMap, first, map, repeat, startWith, switchMap, switchMapTo, take, withLatestFrom } from 'rxjs/operators';
import { LabelingAnswer, LabelingTask } from 'src/generated-sources';
import { UnusableTaskWarning } from '../labeling-unusable-warning/labeling-unusable-warning.component';
import { LabelingTaskPrivileges } from '../labeling.component';
import { ObjectDetectionUILabel, UILabel } from '../models/label';
import { LabelingDrawingService } from '../object-detection-image-canvas/labeling-drawing.service';
import { AnnotationFactory } from '../services/annotation.factory';
import { LabelingAnnotationService } from '../services/labeling-annotation.service';
import { ColorMeaning, LabelingColorService } from '../services/labeling-color.service';
import { LabelingShortcutService, ShortcutAction } from '../services/labeling-shortcut.service';
import { LabelingService } from '../services/labeling.service';
import { LabelingAnnotateDrawingService } from './services/labeling-annotate-drawing.service';

@UntilDestroy()
@Component({
    selector: 'labeling-task-annotate',
    templateUrl: './labeling-task-annotate.component.html',
    styleUrls: [
        './labeling-task-annotate.component.less',
        '../shared-styles/right-panel.less',
        '../shared-styles/annotation.less'
    ],
    providers: [
        LabelingAnnotationService,
        { provide: LabelingDrawingService, useClass: LabelingAnnotateDrawingService }
    ]
})
export class LabelingTaskAnnotateComponent implements OnInit, ErrorContext {
    @Input() privilegesOnTask: LabelingTaskPrivileges;
    error?: APIError;
    hasNoNextRecord$ = new BehaviorSubject<boolean>(false);
    noRecords$: Observable<boolean>;
    hasBeenSkipped$: Observable<boolean>;

    currentLabel$ = new Subject<UILabel>();
    selectedCategory$ = new Subject<string>();

    fetchedAnswer$ = new Subject<LabelingAnswer | null>();

    indexInHistory$ = new BehaviorSubject<number>(0);

    skipIndex$ = new Subject<void>();
    unskipIndex$ = new Subject<number>();
    skippedIndices$ = new BehaviorSubject<number[]>([]);

    recordHistory$ = new BehaviorSubject([] as string[]);
    addToHistory$ = new Subject<string>();

    currentIdentifier$ = new ReplaySubject<string| undefined>(1);
    imageURL$: Observable<string | undefined>;

    nextTrigger$ = new Subject<void>();
    saveAndNextTrigger$ = new Subject<void>();
    previousTrigger$ = new Subject<void>();
    lastTrigger$ = new Subject<void>();
    copyPermalinkTrigger$ = new Subject<void>();

    isDirty$: Observable<boolean>;
    isLast$: Observable<boolean>;
    canSave$: Observable<boolean>;
    keyUpEvent$ = new Subject<KeyboardEvent>();

    unusableTaskWarning$: Observable<UnusableTaskWarning | null>;

    readonly LabelingTaskType = LabelingTask.LabelingTaskType;
    ObjectDetectionUILabel = ObjectDetectionUILabel;

    constructor(
        public labelingService: LabelingService,
        public annotationFactory: AnnotationFactory,
        private labelingShortcutService: LabelingShortcutService,
        private labelingColorService: LabelingColorService,
        @Inject('$state') public $state: any
    ) {
    }

    public get ColorMeaning() {
        return ColorMeaning;
    }

    ngOnInit() {
        this.labelingColorService.setCurrentColorMeaning(ColorMeaning.CLASS);

        this.selectedCategory$.pipe(
            withLatestFrom(this.currentLabel$),
            untilDestroyed(this)
        ).subscribe(([_, label]) => {
            if (!label.selectable) {
                this.saveAndNext();
            }
        });

        this.currentIdentifier$.pipe(
            switchMap((identifier) => identifier ? this.labelingService.getAnswerFromAnnotator(identifier) : of(null)),
            untilDestroyed(this),
        ).subscribe((answer) => {
            this.currentLabel$.next(this.annotationFactory.fromAnswer(answer));
            this.fetchedAnswer$.next(answer);
        });

        this.addToHistory$.pipe(
            withLatestFrom(this.recordHistory$),
            untilDestroyed(this),
        ).subscribe(([newValue, history]) => {
            if (history.indexOf(newValue) === -1) {
                this.recordHistory$.next([...history, newValue]);
            }
        });

        this.skipIndex$.pipe(
            withLatestFrom(this.skippedIndices$, this.indexInHistory$),
            untilDestroyed(this),
        ).subscribe(([_, skippedIndices, currentIndex]) => {
            this.skippedIndices$.next([...skippedIndices, currentIndex]);
            this.next();
        });

        this.unskipIndex$.pipe(
            withLatestFrom(this.skippedIndices$),
            untilDestroyed(this),
        ).subscribe(([indexToUnSkip, skippedIndices]) => {
            const index = skippedIndices.indexOf(indexToUnSkip)
            if (index !== -1) {
                skippedIndices.splice(index, 1);
                this.skippedIndices$.next([...skippedIndices]);
            }
        });

        this.nextTrigger$.pipe(
            withLatestFrom(this.indexInHistory$),
            untilDestroyed(this)
        ).subscribe(([_, index]) => {
            this.indexInHistory$.next(index + 1);
        });

        this.previousTrigger$.pipe(
            switchMapTo(this.indexInHistory$.pipe(first())),
            untilDestroyed(this)
        ).subscribe((index) => {
            if (index > 0) {
                this.indexInHistory$.next(index - 1)
            }
        });

        this.lastTrigger$.pipe(
            switchMapTo(this.recordHistory$),
            untilDestroyed(this)
        ).subscribe((history) => {
            this.indexInHistory$.next(history.length - 1)
        });

        this.indexInHistory$.pipe(
            withLatestFrom(
                this.recordHistory$,
                this.labelingService.identifier$
            ),
            switchMap(([index, history, identifier]) => {
                if (history[index]) {
                    return of(history[index]);
                } else {
                    if (index === 0 && identifier) {
                        return of(identifier);
                    }
                    
                    return this.labelingService.getNextRecordToAnnotate(history[index-1]);
                }
            }),
            untilDestroyed(this),
        ).subscribe(path => {
            this.currentIdentifier$.next(path);
            if (path) {
                this.addToHistory$.next(path);
                this.hasNoNextRecord$.next(false);
            } else {
                this.hasNoNextRecord$.next(true);
            }
        });

        this.noRecords$ = combineLatest([
            this.hasNoNextRecord$,
            this.recordHistory$,
        ]).pipe(
            map(([noRecordsLeft, history]) => {
                return noRecordsLeft && history.length === 0;
            })
        )

        this.isDirty$ = combineLatest([
            this.currentLabel$,
            this.fetchedAnswer$,
        ]).pipe(
            map(([annotation, savedAnswer]) => !annotation.equals(this.annotationFactory.fromAnswer(savedAnswer))),
            startWith(false),
        );

        this.isLast$ = combineLatest([
            this.indexInHistory$,
            this.recordHistory$]).pipe(
                map(([a, b]) => {
                    return (b.length - 1) === a;
                }),
                startWith(true)
            );

        this.canSave$ = this.currentLabel$.pipe(
            map((annotation) => annotation.canBeSaved()),
            shareReplay(1)
        );

        const savingInfo$ = combineLatest([
            this.canSave$,
            this.isDirty$
        ]).pipe(map(([canSave, isDirty]) => {
            return {
                canSave,
                isDirty,
            }})
        );

        this.saveAndNextTrigger$.pipe(
            withLatestFrom(
                this.currentLabel$,
                this.currentIdentifier$,
                this.fetchedAnswer$,
            ),
            exhaustMap(([_, currentLabel, path, savedAnswer]) => {
                let answer: Partial<LabelingAnswer>;
                if (savedAnswer) {
                    answer = clone(savedAnswer);
                    answer.label = currentLabel.toPreparedLabel();
                    return this.labelingService.saveAnswer(answer);
                } else {
                    return this.labelingService.saveNewAnswer(currentLabel.toPreparedLabel(), path!);
                }
            }),
            take(1),
            repeat(),
            withLatestFrom(this.indexInHistory$),
            untilDestroyed(this),
        ).subscribe(([_, index]) => {
            this.unskipIndex$.next(index);
            this.nextTrigger$.next();
        });

        this.hasBeenSkipped$ = this.indexInHistory$.pipe(
            withLatestFrom(this.skippedIndices$),
            map(([index, skippedIndices]) => skippedIndices.includes(index))
        );

        this.copyPermalinkTrigger$.pipe(
            withLatestFrom(this.currentIdentifier$),
            untilDestroyed(this)
        ).subscribe(([, identifier]) => this.labelingService.copyPermalinkToClipboard(identifier!));

        this.keyUpEvent$.pipe(
            withLatestFrom(
                this.isLast$,
                this.indexInHistory$,
                this.currentLabel$,
                savingInfo$,
                this.hasNoNextRecord$,
            ),
            untilDestroyed(this),
        ).subscribe(([event, isLast, index, label, savingInfo, noRecordsLeft]) => {
            const annotations = label.annotations;

            if (this.labelingShortcutService.isShortcut(event, ShortcutAction.BACK) && index !== 0) {
                this.previous();
            }

            if (this.labelingShortcutService.isShortcut(event, ShortcutAction.NEXT) && !noRecordsLeft) {
                if (savingInfo.isDirty && savingInfo.canSave) {
                    this.saveAndNext();
                } else if (!savingInfo.isDirty && !isLast) {
                    this.next();
                }
            }

            if (this.labelingShortcutService.isShortcut(event, ShortcutAction.SKIP) && (!annotations || annotations.length === 0) && !noRecordsLeft) {
                this.skip();
            }

            if (this.labelingShortcutService.isShortcut(event, ShortcutAction.FIRST) && index !== 0) {
                this.first();
            }

            if (this.labelingShortcutService.isShortcut(event, ShortcutAction.LAST) && !isLast) {
                this.last();
            }
        });

        this.imageURL$ = this.currentIdentifier$.pipe(
            switchMap((path) => {
                return path ? this.labelingService.getImageUrl(path) : of(undefined)
            }));


        this.unusableTaskWarning$ = combineLatest([
            this.labelingService.labelingTaskInfo$,
            this.labelingService.labelingTaskUnusableReasons$,
            this.noRecords$,
            this.hasNoNextRecord$,
        ]).pipe(
            map(([task, genericWarnings, noRecords, noRecordsLeft])=> {
                if (genericWarnings.length > 0) {
                    return genericWarnings[0];
                }
                if (noRecords) {
                    return new UnusableTaskWarning(`No ${this.itemName(task.type)}s to annotate`, "settings");;
                }
                if (noRecordsLeft) {
                    return new UnusableTaskWarning(`No ${this.itemName(task.type)}s left to annotate`, "overview");
                }

                return null;
            })
        );
    }

    private itemName(type: LabelingTask.LabelingTaskType): string {
        switch(type) {
            case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
            case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
                return 'image';
            default:
                return 'item';
        }
    }

    skip() {
        this.skipIndex$.next();
    }

    saveAndNext() {
        this.saveAndNextTrigger$.next();
    }

    next() {
        this.nextTrigger$.next();
    }

    first() {
        this.indexInHistory$.next(0);
    }

    previous() {
        this.previousTrigger$.next();
    }

    last() {
        this.lastTrigger$.next();
    }

    copyPermalink() {
        this.copyPermalinkTrigger$.next();
    }

    pushError(error: APIError): void {
        this.error = error;
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.keyUpEvent$.next(event);
    }

}
