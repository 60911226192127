import { Component, Inject, Input, OnInit } from '@angular/core';
import { LabelingDrawingService } from '@features/labeling/object-detection-image-canvas/labeling-drawing.service';
import { LabelingReviewDrawingService } from '@features/labeling/labeling-task-review/services/labeling-review-drawing.service';
import { LabelingService } from '@features/labeling/services/labeling.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { createFromRegion } from "@features/labeling/models/annotation-group";
import { LabelingReviewImagePathService } from '../services/labeling-review-image-path.service';
import { LabelingReviewAnnotationGroupService } from '../services/labeling-review-annotation-group.service';
import { LabelingReviewUpdateService } from '../services/labeling-review-update-service';
import { LabelingTask } from "src/generated-sources";
import { combineLatest } from 'rxjs';
import { ObjectDetectionUILabel } from '@features/labeling/models/label';
import { LabelingTaskPrivileges } from '@features/labeling/labeling.component';

@UntilDestroy()
@Component({
    selector: 'review-image',
    templateUrl: './review-image.component.html',
    styleUrls: [
        './review-image.component.less',
        '../../shared-styles/annotation.less'
    ],
    providers: [
        { provide: LabelingDrawingService, useClass: LabelingReviewDrawingService }
    ]
})
export class ReviewImageComponent implements OnInit {
    @Input() privilegesOnTask: LabelingTaskPrivileges;
    currentImagePath$: Observable<string | null>;
    imageURL$: Observable<string>;
    error$ = this.labelingService.getError();
    ObjectDetectionUILabel = ObjectDetectionUILabel;
    readonly LabelingTaskType = LabelingTask.LabelingTaskType;

    constructor(public labelingReviewImagePathService: LabelingReviewImagePathService,
        public labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        public labelingReviewUpdateService: LabelingReviewUpdateService,
        public labelingService: LabelingService,
        @Inject('$state') public $state: any
    ) { }

    ngOnInit(): void {
        this.imageURL$ = this.labelingReviewImagePathService.currentImagePath$.pipe(switchMap(path => this.labelingService.getImageUrl(path!)));

        this.labelingReviewImagePathService.first();

        combineLatest([
            this.labelingReviewImagePathService.currentImagePath$,
            this.labelingService.labelingTaskInfo$
        ]).pipe(
            untilDestroyed(this),
            filter(([path, _]) => path != null),
            switchMap(([path, task]) => {
                return this.labelingService.listRegions(path!).pipe(
                    map(regions => regions.map(r => createFromRegion(r, task.type)))
                );
            })
        ).subscribe((annotationGroupList) => {
            this.labelingReviewAnnotationGroupService.annotationGroupList$.next(annotationGroupList);
            this.labelingReviewAnnotationGroupService.fetchedAnnotationGroupList$.next(cloneDeep(annotationGroupList));
        })
    }
}
