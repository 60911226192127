import { Injectable } from "@angular/core";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { isTaskSetProperly } from "@features/labeling/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from "rxjs";
import { filter, map, shareReplay, startWith, switchMap, withLatestFrom } from "rxjs/operators";

@UntilDestroy()
@Injectable()
export class LabelingReviewImagePathService {
    private currentImageSource$ = new ReplaySubject<{ id: number, imagePath: string }>(1);

    private previousImageTrigger$ = new Subject<void>();
    private previousTrigger$ = new Subject<void>();
    private nextImageTrigger$ = new Subject<void>();
    private refreshTrigger$ = new Subject<void>();
    private fetchTrigger$ = new ReplaySubject<void>(1);

    isLast$: Observable<boolean>;
    isFirst$: Observable<boolean>;
    isEmpty$: Observable<boolean>;
    itemPaths$: Observable<string[]>;

    currentImagePath$: Observable<string>;
    currentIndex$: Observable<number>;
    finishedReview$ = new BehaviorSubject<boolean>(false);

    constructor(private labelingService: LabelingService) {
        this.itemPaths$ = this.fetchTrigger$.pipe(
            withLatestFrom(this.labelingService.labelingTaskInfo$,
                this.labelingService.identifier$),
            switchMap(([, task, identifier]) => {
                if (!isTaskSetProperly(task)) {
                    return of([]);
                }
                if (identifier) {
                    return of([identifier]);
                } else {
                    return this.labelingService.fetchRecordsToReview().pipe(
                        map((records) => records.map(record => record.id))
                    );
                }
            }),
            shareReplay(1)
        );

        this.nextImageTrigger$.pipe(
            withLatestFrom(
                this.itemPaths$,
                this.currentImageSource$),
            map(([_, imagePaths, currentImagePath]) => {
                if (currentImagePath) {
                    return [imagePaths, imagePaths.indexOf(currentImagePath.imagePath) + 1] as const;
                } else {
                    return [imagePaths, 1] as const;
                }
            }),
            filter(([imagePaths, newIndex]) => {
                return newIndex !== -1 && newIndex < imagePaths.length;
            }),
            untilDestroyed(this)
        ).subscribe(([imagePaths, newIndex]) => {
            this.selectImage(imagePaths[newIndex], newIndex);
        });

        this.refreshTrigger$.pipe(
            withLatestFrom(this.currentImageSource$),
            untilDestroyed(this)
        ).subscribe(([_, { imagePath, id }]) => {
            this.selectImage(imagePath, id);
        });

        this.previousTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.finishedReview$)
        ).subscribe(([_trigger, finishedReview]) => {
            if (finishedReview) {
                this.refreshTrigger$.next();
                this.finishedReview$.next(false);
            } else {
                this.previousImageTrigger$.next();
            }
        })

        // TODO remove duplication with nextImageSource$
        this.previousImageTrigger$.pipe(
            withLatestFrom(
                this.itemPaths$,
                this.currentImageSource$),
            map(([_, imagePaths, currentImagePath]) => {
                if (currentImagePath) {
                    return [imagePaths, imagePaths.indexOf(currentImagePath.imagePath) - 1] as const;
                } else {
                    return [imagePaths, 1] as const;
                }
            }),
            filter(([imagePaths, newIndex]) => {
                return newIndex !== -1 && newIndex < imagePaths.length;
            }),
            untilDestroyed(this)
        ).subscribe(([imagePaths, newIndex]) => {
            this.currentImageSource$.next({ imagePath: imagePaths[newIndex], id: newIndex });
        });

        this.isLast$ = combineLatest([this.currentImageSource$, this.itemPaths$]).pipe(
            map(([{ id }, paths]) => paths.length > 0 && (id === paths.length-1))
        );

        this.isFirst$ = this.currentImageSource$.pipe(
            map(({ id }) => id === 0),
            startWith(true)
        );

        this.isEmpty$ = this.itemPaths$.pipe(map(paths => paths.length === 0));

        this.itemPaths$.pipe(
            untilDestroyed(this)
        ).subscribe(itemPaths => {
            this.selectImage(itemPaths[0], 0);
        });

        this.currentImagePath$ = this.currentImageSource$.pipe(
            map(image => image.imagePath),
            filter(path => path != null)
        );

        this.currentIndex$ =  this.currentImageSource$.pipe(
            map(image => image.id)
        );
    }

    selectImage(imagePath: string, id: number) {
        this.currentImageSource$.next({ imagePath, id });
        this.finishedReview$.next(false);
    }

    first() {
        this.fetchTrigger$.next();
    }

    nextImage() {
        this.nextImageTrigger$.next();
    }

    previousImage() {
        this.previousTrigger$.next();
    }
}
