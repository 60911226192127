import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeepHubColumnFormat, DeephubImagesDataService } from 'src/generated-sources';
import { DeephubCellData, DeephubColumn } from '@features/deephub/services/abstract-deephub-data-fetcher.service';
import { DeephubObjectDetectionReportService, ObjectDetectionReport } from './deephub-object-detection-report.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeephubObjectDetectionReportPainterService } from './deephub-object-detection-report-painter.service';
import { AbstractDeephubReportDataFetcherService, DeephubResultsDataColumns } from '@features/deephub/computer-vision/report/services/abstract-deephub-report-data-fetcher.service';

export interface DeephubObjectDetectionReportCellData extends DeephubCellData {
    target: DeepHubColumnFormat.ObjectDetectionTargetItem[];
    prediction: DeepHubColumnFormat.ObjectDetectionPredictedItem[],
    pairing: DeepHubColumnFormat.PairingItem[],
    enrichedValid: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[],
    enrichedFiltered: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[],
}

export interface DeephubObjectDetectionResultsDataColumns extends DeephubResultsDataColumns {
    pairing: DeephubColumn,
    enrichedValid: DeephubColumn,
    enrichedFiltered: DeephubColumn,
}

@UntilDestroy()
@Injectable()
export class DeephubObjectDetectionReportDataFetcherService extends AbstractDeephubReportDataFetcherService<ObjectDetectionReport, DeephubImagesDataService.ObjectDetectionPredictedFilter> {
    canvasPainter: DeephubObjectDetectionReportPainterService;

    constructor(
        DataikuAPI: DataikuAPIService,
        objectDetectionService: DeephubObjectDetectionReportService,
        canvasPainter: DeephubObjectDetectionReportPainterService,
        currentRoute: CurrentRouteService,
        colorMapService: ColorMapContextService,
        @Inject('COLOR_PALETTES') COLOR_PALETTES: { [palette: string]: string[]; }
    ) {
        super(DataikuAPI, objectDetectionService, currentRoute, colorMapService, COLOR_PALETTES);
        this.canvasPainter = canvasPainter;

        this.filter$.pipe(
            untilDestroyed(this)
        ).subscribe(filter => {
            this.canvasPainter.setClasses(filter?.groundTruth?.value, filter?.detection?.value);
        });
    }

    getDefaultFilter(): Partial<DeephubImagesDataService.ObjectDetectionPredictedFilter> {
        return { sorting: {sortBy: DeephubImagesDataService.SortBy.IOU, ascending: false}};
    }

    combineReportFilter(report: ObjectDetectionReport, filter: DeephubImagesDataService.ObjectDetectionPredictedFilter): DeephubImagesDataService.ObjectDetectionPredictedFilter {
        filter.minIOU = report.iou;
        filter.minConfidence = report.confidenceThreshold!;
        filter.type = "DEEP_HUB_IMAGE_OBJECT_DETECTION";
        return filter;
    }

    createDataColumns(orderedColumns: string[]): DeephubObjectDetectionResultsDataColumns {
        const columns: DeephubObjectDetectionResultsDataColumns = {
            target: {
                name: this.coreParams.target_variable
            },
            itemPath: {
                name: this.coreParams.pathColumn
            },
            prediction: {
                name: 'prediction',
            },
            pairing: {
                name: 'pairing',
            },
            enrichedValid: {
                name: 'enrichedValid',
            },
            enrichedFiltered: {
                name: 'enrichedFiltered'
            }
        };

        this.addColumnIndices(columns, orderedColumns);

        return columns;
    }

    rowToCellData(rowContent: string[], i: number, j: number): DeephubObjectDetectionReportCellData {
        const columns = this.dataColumns as DeephubObjectDetectionResultsDataColumns;
        return {
            itemPath: rowContent[columns.itemPath.index!],
            target: JSON.parse(rowContent[columns.target.index!]),
            prediction: JSON.parse(rowContent[columns.prediction.index!]),
            pairing: JSON.parse(rowContent[columns.pairing.index!]),
            enrichedValid: JSON.parse(rowContent[columns.enrichedValid.index!]),
            enrichedFiltered: JSON.parse(rowContent[columns.enrichedFiltered.index!]),
            imageId: i
        };
    }
}
