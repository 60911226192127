import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeephubImageClassificationInteractiveScoringCellData } from '../interactive-scoring/image-classification-interactive-scoring-data.service';

@Component({
    selector: 'deephub-image-classification-report-image-section',
    templateUrl: './deephub-image-classification-report-image-section.component.html',
    styleUrls: [
        './deephub-image-classification-report-image-section.component.less',
        '../../../shared-styles/deephub-image.less',
        '../../../shared-styles/deephub-interactive-scoring.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubImageClassificationReportImageSectionComponent implements OnInit {
    @Input() cellData: DeephubImageClassificationInteractiveScoringCellData;

    constructor( 
        private colorMapContextService: ColorMapContextService,
    ) { }

    ngOnInit(): void {
    }

    getPredictionColor(score: any) {
        return this.colorMapContextService.mapping.get(score.prediction);
    }

    getFormattedProba(score: any) {
        return (score["proba_" + score.prediction] * 100).toFixed(1);
    }
}
