import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UIAnnotation, UIBoundingBox } from '@features/labeling/models/annotation';
import { UILabel } from '@features/labeling/models/label';
import { LabelingColorService } from '@features/labeling/services/labeling-color.service';
import { clone } from 'lodash';

@Component({
    selector: 'annotate-annotation-list',
    templateUrl: './annotate-annotation-list.component.html',
    styleUrls: [
        './annotate-annotation-list.component.less',
        '../../shared-styles/right-panel.less',
        '../../shared-styles/annotation.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotateAnnotationListComponent {
    @Input() skipped: boolean;
    @Input() label: UILabel | null;
    @Output() labelChange = new EventEmitter<UILabel>();
    UIBoundingBox = UIBoundingBox;

    constructor(
        private labelingColorService: LabelingColorService,
    ) { }

    color(category: string) {
        return this.labelingColorService.getColor(category);
    }

    selectAnnotation(event: MouseEvent, annotation: UIAnnotation) {
        if(!this.label) {
            return;
        }
        const selecting = !annotation.selected;
        const newLabel = clone(this.label);

        if (selecting && !event.shiftKey) {
            newLabel.annotations.forEach(a => {
                a.selected = false;
            });
        }

        annotation.selected = selecting;
        this.labelChange.emit(newLabel);
    }

    deleteAnnotation(annotationToDelete: UIAnnotation) {
        if(!this.label) {
            return;
        }
        const newLabel = clone(this.label);

        newLabel.annotations = newLabel.annotations.filter(annotation => !annotation.equals(annotationToDelete));
        this.labelChange.emit(newLabel);
    }
}
