import { ChangeDetectionStrategy, Component } from "@angular/core";
import { WorkspaceModalService } from "./services/workspace-modal.service";
import { WorkspacesService } from "./workspaces.service";

/**
 * Main entry point for workspaces
 * For now only used as a context service provider
 */
@Component({
    selector: 'workspaces',
    template: '<ng-content></ng-content>',
    providers: [
        WorkspacesService,
        WorkspaceModalService,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspacesComponent {

}
