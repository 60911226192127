import { Injectable } from '@angular/core';
import { ImagePositionInformation, PainterImage, PainterImageArtifact } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PainterService } from '@shared/services/painter.service';
import { BoxHelper } from '@features/deephub/object-detection/utils/box-helper';
import { DeepHubColumnFormat } from 'src/generated-sources';
import { DeephubObjectDetectionDesignCellData } from './deephub-object-detection-design-data-fetcher.service';
import { fabric } from "fabric";

@Injectable()
export class DeephubObjectDetectionDesignPainterService extends PainterService {
    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }

    paintForModal(cellData: DeephubObjectDetectionDesignCellData, canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation): void {
        cellData.target?.forEach((elem: DeepHubColumnFormat.ObjectDetectionTargetItem) => {
            const box = BoxHelper.createBox(fabric.Rect, elem, imagePosition, {
                stroke: this.colorMapService.mapping.get(elem.category),
                strokeWidth: 2,
                fill: 'rgba(0, 0, 0, 0)'
            });
            if (box !== null) {
                canvas.add(box);
            }
        });
    }

    paintForFeed(cellData: DeephubObjectDetectionDesignCellData, image: PainterImage, imagePosition: ImagePositionInformation): void {
        const target = cellData.target as DeepHubColumnFormat.ObjectDetectionTargetItem[];
        target?.forEach((elem: DeepHubColumnFormat.ObjectDetectionTargetItem) => {
            const box = BoxHelper.createBox(PainterImageArtifact, elem, imagePosition, {
                borderColor: this.colorMapService.mapping.get(elem.category),
                borderWidth: '2px',
                borderStyle: 'solid'
            }, 'px');
            if (box !== null) {
                image.addArtifact(box);
            }
        });
    }
}
