<div class="card-layout">
    <div class="card-layout__section card-layout__section--vertical">
        <time-series-summary
            [seriesVariableName]="params.seriesColumn.name"
            [timeVariableName]="params.timeColumn.name"
            [boxPlot]="results.boxPlot"
            [timeInfo]="results.timeInfo"
        ></time-series-summary>

        <div class="card-layout__section card-layout__section--horizontal">
            <div class="card-layout__section">
                <div class="card-layout__title">Hypothesis</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Tested hypothesis</th>
                                <td class="stats-table__cell">
                                    <u>{{ params.seriesColumn.name }}</u>
                                    has no monotonic trend
                                </td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Significance level</th>
                                <td class="stats-table__cell">{{ 1 - params.confidenceLevel | smarterNumber }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-layout__section">
                <div class="card-layout__title">Results</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Normalized statistic</th>
                                <td class="stats-table__cell">{{ results.statistic | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">𝘱-value</th>
                                <td class="stats-table__cell">
                                    <colored-pvalue
                                        [pvalue]="results.pValue"
                                        [significanceLevel]="1 - params.confidenceLevel"
                                    ></colored-pvalue>
                                </td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Kendall Tau</th>
                                <td class="stats-table__cell">{{ results.tau | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Slope estimate</th>
                                <td class="stats-table__cell">
                                    <span *ngIf="results.slope != null">{{ results.slope | smarterNumber }}</span>
                                    <span *ngIf="results.slope == null"
                                        class="not-computed"
                                        [matTooltip]="'Slope is not computed for series larger than 10,000 elements'"
                                        [matTooltipPosition]="'left'"
                                    >
                                        Not computed
                                    </span>
                                </td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Intercept estimate</th>
                                <td class="stats-table__cell">
                                    <span *ngIf="results.intercept != null">{{ results.intercept | smarterNumber }}</span>
                                    <span *ngIf="results.intercept == null"
                                        class="not-computed"
                                        [matTooltip]="'Intercept is not computed for series larger than 10,000 elements'"
                                        [matTooltipPosition]="'left'"
                                    >
                                        Not computed
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="test-conclusion">
    <ng-container *ngIf="isRejected else inconclusive">
        <p class="test-conclusion__wording test-conclusion__wording--rejected">
            At the given significance level,
            <u>{{ params.seriesColumn.name }}</u> has <b>{{ trendLabel }}</b>.
        </p>
    </ng-container>

    <ng-template #inconclusive>
        <p class="test-conclusion__wording">
            At the given significance level, the test is inconclusive about whether
            <u>{{ params.seriesColumn.name }}</u> has no monotonic trend.
        </p>
    </ng-template>
</div>
