import { Injectable } from '@angular/core';
import { DeephubInteractiveScoringCellData, InteractiveScoringService } from '@features/deephub/computer-vision/report/interactive-scoring/interactive-scoring-data.service';
import { DeepHubColumnFormat } from 'src/generated-sources';

export class DeephubObjectDetectionInteractiveScoringCellData extends DeephubInteractiveScoringCellData {
    prediction: DeepHubColumnFormat.ObjectDetectionPredictedItem[];

    public setScore(modelResponse: any) {
        this.score = modelResponse;
        this.prediction = this.score.prediction;
    }
}

@Injectable({
  providedIn: 'root'
})
export class ObjectDetectionInteractiveScoringService extends InteractiveScoringService {
    createCellData(image: string, file: string) {
        return new DeephubObjectDetectionInteractiveScoringCellData(image, file);
    }
}
