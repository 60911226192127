import { Directive, ElementRef, Injector, Input } from "@angular/core";
import { UpgradeComponent } from "@angular/upgrade/static";

@Directive({
    selector: 'markdown',
})
export class MarkdownDirective extends UpgradeComponent {
    @Input() from: string;
    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1Markdown', elementRef, injector);
    }
}