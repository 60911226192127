import { Injectable } from '@angular/core';
import { rgb, RGBColor } from 'd3-color';
import { scaleOrdinal } from 'd3-scale';

// Constants copied from d3
export const category20 = scaleOrdinal([
    '#1f77b4', '#aec7e8',
    '#ff7f0e', '#ffbb78',
    '#2ca02c', '#98df8a',
    '#d62728', '#ff9896',
    '#9467bd', '#c5b0d5',
    '#8c564b', '#c49c94',
    '#e377c2', '#f7b6d2',
    '#7f7f7f', '#c7c7c7',
    '#bcbd22', '#dbdb8d',
    '#17becf', '#9edae5'
].map(value => rgb(value)));


export const category20b = scaleOrdinal([
    '#393b79', '#5254a3', '#6b6ecf', '#9c9ede',
    '#637939', '#8ca252', '#b5cf6b', '#cedb9c',
    '#8c6d31', '#bd9e39', '#e7ba52', '#e7cb94',
    '#843c39', '#ad494a', '#d6616b', '#e7969c',
    '#7b4173', '#a55194', '#ce6dbd', '#de9ed6'
].map(value => rgb(value)));

/**
 * A global registry of colors assigned to certain variables.
 *
 * Colors are cycled from 'categoryColorPalette', even indexes first, odd colors next, for higher contrast.
 *
 * Components using an independent palette should use getColorFromIndex, providing their own offsets starting from 0.
 * Components using a palette shared with other components should use getColorForVariable,
 *  providing the name of the color to share as parameter
 */
@Injectable({
    providedIn: 'root'
})
export class ColorsService {
    private categoryColorPalette: RGBColor[];
    private categoryColorMap: Map<string, string> = new Map<string, string>();
    private nextCategoryColorIndex = 0;

    constructor() {
        this.categoryColorPalette = category20.range().concat(category20b.range());
    }

    private getNextNamedColor(): string {
        const ret = this.categoryColorPalette[this.nextCategoryColorIndex];
        this.nextCategoryColorIndex += 2;
        if (this.nextCategoryColorIndex >= this.categoryColorPalette.length) {
            // toggling even/odd color indexes
            this.nextCategoryColorIndex = (this.nextCategoryColorIndex - this.categoryColorPalette.length + 1) % 2;
        }
        return ret.toString();
    }

    public getColorForVariable(name: string): string {
        let ret = this.categoryColorMap.get(name);
        if (!ret) {
            ret = this.getNextNamedColor();
            this.categoryColorMap.set(name, ret);
        }
        return ret;
    }

    public getColorFromIndex(index: number): string {
        const offset = Math.floor(index / this.categoryColorPalette.length) % 2;
        return this.categoryColorPalette[(index * 2 + offset) % this.categoryColorPalette.length].toString();
    }
}
