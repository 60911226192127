import { ImagePositionInformation, PainterImageArtifact } from "@shared/models/painter";
import { ColorContrast } from "@shared/pipes/text-pipes/color-contrast.pipe";

export class ImageClassificationLabelHelper {

    static createLabel(imagePosition: ImagePositionInformation, color?: string) {
        return new PainterImageArtifact({
            backgroundColor: color,
            color: ColorContrast.getColorContrast(color),
            left: "4px",
            top: "calc(100% - 24px)",
            padding: "0px 4px",
            borderRadius: "15px",
        });
    }
}
