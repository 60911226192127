import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges } from "@angular/core";

@Component({
    selector: 'user-picture',
    template: `<span [class]="'avatar' + size" [style.backgroundImage]="url"></span>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPictureComponent implements OnChanges {
    url: string;

    @Input() user: string;
    @Input() size: number;

    constructor(
        @Inject('UserImageUrl') private UserImageUrl: (userLogin: string, size: number) => string,
    ) {}

    ngOnChanges() {
        this.url = `url(${this.UserImageUrl(this.user, this.size)})`;
    }
}
