import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gentleTruncateBeginning'
})

export class GentleTruncateBeginningPipe implements PipeTransform {
    transform(str: string | null, len: number): any {
        /**
         * Truncate a string to make sure it takes at most
         * n characters.
         *
         * If str is not a string, returns str unchanged.
         */
         if ((typeof str != "string") || (str.length <= len)) {
            return str;
        }
        var cutOn = /[ ,\.,;\-\\\"\n\?\!\|]/g
        var truncated = str.substring(len, str.length);
        var lastSeparatorIdx = regexLastIndexOf(cutOn, truncated);
        // we don't want to cut more too much.
        if (lastSeparatorIdx > len / 2) {
            truncated = str.substring(lastSeparatorIdx, str.length);
        }
        return "..." + truncated;
    }
}

export function regexLastIndexOf(ptn: RegExp, str: string) {
    /* Same as lastIndexOf, but for regexp.
     * Returns -1 if not found.
     */
    let res = -1;
    let m = null;
    do {
        m = ptn.exec(str);
        if (m) {
            res = m.index;
        }
    } while (m);
    return res;
}
