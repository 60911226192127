import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { WT1Service } from './wt1.service';

@Directive({
    selector: '[wt1Click]'
})
export class Wt1ClickDirective {
    @Input() wt1Click: string;

    constructor(private wt1Service: WT1Service, private el: ElementRef) { }

    @HostListener('click') onClick(): void {
        if (!this.el.nativeElement.classList?.contains("disabled")) {
            this.wt1Service.event('clicked-item', { 'item-id': this.wt1Click });
        }
    }
}
