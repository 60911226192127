import { AbstractControl } from '@angular/forms';

/*
    Allows for a FormControl element (control) to be disabled/enabled based on the specified value
    of another FormControl (conditionControl).

    The enableValue callback is called if defined when the control is enabled. Angular remove the
    value of the control when it is disabled.
*/
export function toggleFormControl(control: AbstractControl, conditionControl: AbstractControl, conditionValue: any, enableValue?: Function) {
    conditionControl.valueChanges
        .subscribe((controlValue) => {
            // if conditionValue is a function, call it with the controlValue as the parameter
            const condition = typeof conditionValue === 'function' ? conditionValue(controlValue) : controlValue === conditionValue;

            if (condition) {
                control.enable();
                if (enableValue) {
                    enableValue(controlValue);
                }
            } else {
                control.disable();
            }
        });

    return control;
}
