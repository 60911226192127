import { Injectable } from '@angular/core';
import { AbstractDeephubDesignDataFetcherService, DeephubDesignCellData } from '@features/deephub/computer-vision/design/services/abstract-deephub-design-data-fetcher.service';
import { UntilDestroy } from '@ngneat/until-destroy';
export class DeephubImageClassificationDesignCellData implements DeephubDesignCellData {
    constructor(
        public itemPath: string,
        public target: string,
        public imageId: number
    ) {}

    listCategories(): string[] {
        return [this.target];
    }
};

@UntilDestroy()
@Injectable()
export class DeephubImageClassificationDesignDataFetcherService extends AbstractDeephubDesignDataFetcherService {
    rowToCellData(rowContent: string[], i: number, j: number): DeephubImageClassificationDesignCellData {
        return new DeephubImageClassificationDesignCellData(
            rowContent[this.dataColumns.itemPath.index!],
            rowContent[this.dataColumns.target.index!],
            i
        );
    }
}
