import type {AnumGrouping} from './anum-grouping';
import type {BinnedGrouping} from './binned-grouping';
import type {CrossGrouping} from './cross-grouping';
import type {MergeGrouping} from './merge-grouping';
import type {SubsampledGrouping} from './subsampled-grouping';
import type {SubsetGrouping} from './subset-grouping';
import type {TopNTimeGrouping} from './top-ntime-grouping';
import type {UnionGrouping} from './union-grouping';

/**
 * Generated from com.dataiku.dip.eda.compute.grouping.GroupingResult
 */
export interface _GroupingResult {}

export type GroupingResult = AnumGrouping.AnumGroupingResult | BinnedGrouping.BinnedGroupingResult | CrossGrouping.CrossGroupingResult | MergeGrouping.MergeGroupingResult | SubsampledGrouping.SubsampledGroupingResult | SubsetGrouping.SubsetGroupingResult | TopNTimeGrouping.TopNTimeGroupingResult | UnionGrouping.UnionGroupingResult;

export namespace GroupingResult {
    export const type = ['anum', 'binned', 'cross', 'merge', 'subsampled', 'subset', 'topn_time', 'union'] as const;
    export function isAnumGroupingResult(toTest: GroupingResult): toTest is AnumGrouping.AnumGroupingResult { return toTest['type'] === 'anum'; }
    export function isBinnedGroupingResult(toTest: GroupingResult): toTest is BinnedGrouping.BinnedGroupingResult { return toTest['type'] === 'binned'; }
    export function isCrossGroupingResult(toTest: GroupingResult): toTest is CrossGrouping.CrossGroupingResult { return toTest['type'] === 'cross'; }
    export function isMergeGroupingResult(toTest: GroupingResult): toTest is MergeGrouping.MergeGroupingResult { return toTest['type'] === 'merge'; }
    export function isSubsampledGroupingResult(toTest: GroupingResult): toTest is SubsampledGrouping.SubsampledGroupingResult { return toTest['type'] === 'subsampled'; }
    export function isSubsetGroupingResult(toTest: GroupingResult): toTest is SubsetGrouping.SubsetGroupingResult { return toTest['type'] === 'subset'; }
    export function isTopNTimeGroupingResult(toTest: GroupingResult): toTest is TopNTimeGrouping.TopNTimeGroupingResult { return toTest['type'] === 'topn_time'; }
    export function isUnionGroupingResult(toTest: GroupingResult): toTest is UnionGrouping.UnionGroupingResult { return toTest['type'] === 'union'; }
}