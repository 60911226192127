import { ElementRef, Injector, Directive, Input, Output, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'star-interest'
})
export class StarInterestComponent extends UpgradeComponent {
    @Input() status: boolean;
    @Input() tooltipPosition?: string;
    @Output() toggle = new EventEmitter<boolean>();

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ng1StarInterest', elementRef, injector);
    }
}
