export const DKU_EXT_TAGS_KEY_PREFIX:string = "dku-ext.";
export const DKU_EXT_EXPERIMENT_NAME:string = "dku-ext.experimentName";
export const DKU_EXT_EXPERIMENT_RUN_COUNT:string = "dku-ext.experimentRunCount";
export const DKU_EXT_LAST_RUN_START:string = "dku-ext.experimentLastRunStart";
export const DKU_EXT_DSS_USER:string = "dku-ext.dssUser";
export const DKU_EXT_DSS_GIT_COMMIT:string = "dku-ext.git.commit";
export const DKU_EXT_DSS_GIT_MESSAGE:string = "dku-ext.git.message";
export const DKU_EXT_DSS_GIT_BRANCH:string = "dku-ext.git.branch";
export const DKU_EXT_CLASS_NAMES:string = "dku-ext.targetClasses";
export const DKU_EXT_PREDICTION_TYPE:string = "dku-ext.predictionType";
export const DKU_EXT_CODE_ENV:string = "dku-ext.codeEnv";
export const DKU_EXT_TARGET:string = "dku-ext.target";
