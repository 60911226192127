import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PredictionMLTask } from 'src/generated-sources';

@Component({
    selector: 'experiment-tracking-run-inference',
    templateUrl: './experiment-tracking-run-inference.component.html',
    styleUrls: ['./experiment-tracking-run-inference.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingRunInferenceComponent {

	@Input() predictionType?: string;
    @Input() codeEnv?: string;
    @Input() modelClasses?: string[];
    @Input() target?: string;

    PredictionType: typeof PredictionMLTask.PredictionType = PredictionMLTask.PredictionType;

    constructor() { }
}
