<div class="interactive-scoring-image-container">
    <image-feed-image class="interactive-scoring-image" [cellData]="cellData"></image-feed-image>
</div>
<div class="interactive-scoring-image-results">
    <deephub-report-interactive-scoring-image-header class="mbot16" [title]="cellData.fileName"></deephub-report-interactive-scoring-image-header>
    <div class="interactive-scoring-image-results__values">        
        <h2 class="settings-section-title mtop0 mbot0">
            The model says 
            <div [style.background-color]="getPredictionColor(cellData.score)" 
                 [style.color]="getPredictionColor(cellData.score) | colorContrast" 
                 class="dib deephub-image-classification-feed-modal__category">{{cellData.score.prediction}}
            </div>
            with {{getFormattedProba(cellData.score)}}% probability
        </h2>
        <deephub-image-classification-prediction-widget [classifData]="cellData">
        </deephub-image-classification-prediction-widget>
    </div>
</div>
