<ng-container *ngIf="labelingService.labelingTaskInfo$ | async as task">
    <div *ngIf="(labelingReviewAnnotationGroupService.annotationGroupList$ | async) && (labelingReviewAnnotationGroupService.annotationGroupList$ | async)!.length" class="annotations-wrapper h100">
        <ng-container *ngFor="let annotationGroup of labelingReviewAnnotationGroupService.annotationGroupList$ | async; index as index">
            <div (click)="selectAnnotationGroup(index)">
                <div class="labeling-annotation" [ngClass]="{ 'selected': annotationGroup.selected }">
                    <i class="text-warning icon-dku-label-conflict mright8" *ngIf="annotationGroup.hasConflict(nbAnnotators)"></i>
                    <i class="text-success icon-ok-sign mright8" *ngIf="!annotationGroup.hasConflict(nbAnnotators) && !annotationGroup.hasMissingCategory()"></i>
                    <i class="text-weak icon-exclamation-sign mright8" *ngIf="annotationGroup.hasMissingCategory()"></i>
                    <span class="name">
                        <ng-container [ngSwitch]="task.type">
                            <ng-container *ngSwitchCase="LabelingTaskType.OBJECT_DETECTION">
                                Region {{index + 1}}
                            </ng-container>
                            <ng-container *ngSwitchCase="LabelingTaskType.IMAGE_CLASSIFICATION">
                                Classification
                            </ng-container>
                        </ng-container>
                    </span>
                    <div class="category">
                        <div *ngIf="!annotationGroup.hasConflict(nbAnnotators) && !annotationGroup.hasMissingCategory()" class="mleft8">{{annotationGroup.getConsensusCategory()}}</div>
                        <div *ngIf="annotationGroup.hasConflict(nbAnnotators)" class="category--conflict text-warning"> 
                            <div>Conflict</div>
                        </div>
                        <div *ngIf="annotationGroup.hasMissingCategory()" class="category--conflict text-weak"> 
                            <div>Missing category</div>
                        </div>
                    </div>
                    <i class="mleft16 icon-trash" (click)="deleteAnnotationGroup(task.type, index); $event.stopPropagation();" [matTooltip]="getDeleteTooltip(task.type)" [matTooltipPosition]="'above'"></i>
                    <i [ngClass]="{'icon-chevron-down': annotationGroup.selected, 'icon-chevron-right': !annotationGroup.selected}" class="carret"></i>
                </div>
                <div *ngIf="annotationGroup.selected">
                    <div *ngFor="let reason of annotationGroup.conflictReasons(nbAnnotators)" class="conflict-reason padleft24">
                        <i class="conflict-icon icon-warning-sign text-warning" ></i>
                        <div class="mleft8 text-warning"> {{reason}} </div>
                    </div>
                </div>
                <annotation-list *ngIf="annotationGroup.selected && annotationGroup.annotations" (click)="$event.stopPropagation()" [conflicting]="annotationGroup.hasConflict(nbAnnotators)" [updating]="false"></annotation-list>
            </div>
        </ng-container>
        <p *ngIf="task.type === LabelingTaskType.OBJECT_DETECTION" class="empty-description mtop8">
            <ng-container *ngIf="!(hasSelectedAnnotationGroup$ | async)">
                Click and drag to add new objects
            </ng-container>
            <ng-container *ngIf="(hasSelectedAnnotationGroup$ | async)">
                Click and drag to override annotations
            </ng-container>
        </p>
    </div>
    <div *ngIf="
        (labelingReviewAnnotationGroupService.annotationGroupList$ | async) && 
        !(labelingReviewAnnotationGroupService.annotationGroupList$ | async)!.length && task.type === LabelingTaskType.OBJECT_DETECTION" 
        class="empty-list-cta empty-list-cta--small">
        <h1>No labels yet</h1>
        <p>Draw a box around the target and choose a category</p>
    </div>
</ng-container>    
