import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CapitalizePipe } from '@shared/pipes/capitalize.pipe';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { fairAny } from 'dku-frontend-core';
import _ from 'lodash';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FacetMap } from '../feature-store-models';
import { Aggregation } from '../query-result-models';

@UntilDestroy()
@Component({
  selector: 'facet-list',
  templateUrl: './facet-list.component.html',
  styleUrls: ['./facet-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacetListComponent implements OnChanges {

  @Input() aggregationResults: Record<string, Aggregation>;
  @Input() users: Record<string, string>;
  @Input() projectNames: Record<string, string>;
  @Output() newFacetSelection: EventEmitter<FacetMap> = new EventEmitter();

  form: FormGroup;

  facetsCollapse: Record<string, boolean>;
  showInput: Record<string, boolean>;
  facetsShowAll: Record<string, boolean>;

  capitalizePipe: CapitalizePipe;

  facetFieldsDisplayNames: {[key: string]: string} = {
    "projectKey.raw": "Projects",
    "user": "Contributors",
    "tag.raw": "Tags",
    "partitioned": "Partitioned",
    "isQuicklyShareable": "Quickly shareable"
  };
  
  facetDataQaSmid: {[key: string]: string} = {
    "projectKey.raw": "projects",
    "user": "contributors",
    "tag.raw": "tags",
    "partitioned": "partitioned",
    "isQuicklyShareable": "quickly-shareable"
  };

  constructor(
    @Inject('$rootScope') public $rootScope: fairAny,
    private fb: FormBuilder,
  ) {
    this.facetsCollapse = {};
    this.showInput = {};
    this.facetsShowAll = {};

    this.capitalizePipe = new CapitalizePipe();

    this.form = this.fb.group({
      facets: new FormArrayRepeat(() => {
        return this.fb.group({
          key: "",
          facetSearch: "",
          values: new FormArrayRepeat(() => {
            return this.fb.group({
              key: "",
              value: "",
              count: 0,
              selected: false
            })
          })
        })
      })
    });
    this.form.valueChanges
      .pipe(
        map((formValue) => this.getSelectedFacets(formValue)),
        debounceTime(200),
        distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
        untilDestroyed(this),
      )
      .subscribe((facetMap) => {
        this.newFacetSelection.emit(facetMap);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if("aggregationResults" in changes) {
      const facets: any[] = [];
      const facetKeys = ["tag.raw", "projectKey.raw", "user", "partitioned"];
      if(this.$rootScope.appConfig.quickSharingElementsEnabled) {
        facetKeys.push("isQuicklyShareable");
      }
      facetKeys.forEach(key => {
        if(key in this.aggregationResults) {
          const buckets = this.aggregationResults[key].agg.buckets;
          if(buckets.length > 0) {
            const facetValuesControls: any[] = [];
            const existingFacetValues: FormGroup[] = this.getFacetValues(key);
            const bucketKeys: (string | number)[] = [];
            buckets.forEach(bucket => {
              const selected = this.getSelectedState(existingFacetValues, bucket.key);
              facetValuesControls.push({
                key: bucket.key,
                value: this.formatFacetValue(bucket.key, key),
                count: bucket.doc_count,
                selected: selected
              });
              bucketKeys.push(bucket.key)
            });
            existingFacetValues.forEach(facet => {
              const facetKey: string = facet.get("key")?.value;
              if(!bucketKeys.includes(facetKey) && this.getSelectedState(existingFacetValues, facetKey)) {
                facetValuesControls.push({
                  key: facetKey,
                  value: this.formatFacetValue(facetKey, key),
                  count: 0,
                  selected: true
                });
              }
            })
            facets.push({
              key: key,
              facetSearch: this.getExistingFacetSearch(key),
              values: facetValuesControls
            });
          }
        }
      });
      this.form.setValue({facets: facets});
    }
  }

  getFacetForms(): FormGroup[] {
    return (this.form.get("facets") as FormArray)?.controls as FormGroup[];
  }

  numberOfValues(facetForm: FormGroup): number {
    return (facetForm.get("values") as FormArray)?.length || 0;
  }

  getFacetForm(facetKey: string): FormGroup {
    return this.getFacetForms().find(facetControl => {
      return facetControl.controls.key.value === facetKey
    }) as FormGroup;
  }

  getFacetValues(facetKey: string): FormGroup[] {
    const facetForm = this.getFacetForm(facetKey);
    if(!facetForm) return [];
    else {
      return (facetForm.controls.values as FormArray).controls as FormGroup[] || [];
    }
  }

  getSelectedState(facetValues: FormGroup[], value: string | number): boolean {
    return facetValues.find(facetValue => {
      return facetValue.controls.key.value === value;
    })?.controls.selected.value as boolean || false;
  }

  getExistingFacetSearch(facetKey: string): string {
    const facetForm = this.getFacetForm(facetKey);
    if(!facetForm) return "";
    else {
      return (facetForm.controls.facetSearch as FormControl).value as string || "";
    }
  }

  getSelectedFacets(facets: {[key: string]: any[]}): FacetMap {
    const facetMap: FacetMap = {};
    facets["facets"].forEach(facet => {
      const key = facet.key as string;
      const facetValues = facet.values as any[];
      const selectedValues = facetValues.filter(facetValue => {return facetValue.selected as boolean})
        .map(facetValue => facetValue.key as string);
      if(selectedValues.length > 0) facetMap[key] = selectedValues;
    });
    return facetMap;
  }

  formatFacetValue(facetValue: string | number | boolean, facet: string): string {
    const value = String(facetValue);
    switch (facet) {
      case "projectKey.raw":
          return this.projectNames[value] || value;
      case "user":
          return this.users[value] || value;
      case "partitioned":
          return value === "0" ? "No" : "Yes";
      case "isQuicklyShareable":
          return value === "0" ? "No" : "Yes";
    }
    return value;
  }

  facetKey(facetForm: FormGroup): string {
    return this.facetKeyForm(facetForm).value as string;
  }

  facetKeyForm(facetForm: FormGroup): FormControl {
    return facetForm.get("key") as FormControl;
  }

  facetValuesForm(facetForm: FormGroup): FormArray {
    return facetForm.get("values") as FormArray;
  }

  facetValues(facetForm: FormGroup): FormGroup[] {
    return this.facetValuesForm(facetForm).controls as FormGroup[];
  }

  formatFacetName(field: string): string {
    return this.facetFieldsDisplayNames[field] ||
      this.capitalizePipe.transform(field);
  }

  resetFacetSearch(key: string): void {
    const facetForm = this.getFacetForm(key);
    this.showInput[key] = !this.showInput[key];
    (facetForm.controls.facetSearch as FormControl).setValue("", {emitEvent: false});
  }

  onFacetSearchKeyDown(key: string, e: any): void {
    if (e.keyCode === 27) { // ESC key
      const facetForm = this.getFacetForm(key);
      e.target.blur();
      this.showInput[key] = false;
      (facetForm.controls.facetSearch as FormControl).setValue("", {emitEvent: false});
    }
  }

  selectAllFacetValues(facetForm: FormGroup): void {
    this.facetValues(facetForm).forEach(facetValueControl => {
      (facetValueControl.get("selected") as FormControl).setValue(false);
    });
  }

  noFacetSelected(facetForm: FormGroup): boolean {
    return !this.facetValues(facetForm).find(facetValueControl => {
      return (facetValueControl.get("selected") as FormControl).value as boolean;
    });
  }

  filterFacetsValues(facetForm: FormGroup): FormGroup[] {
    const search = (facetForm.controls.facetSearch.value || "").toLowerCase();
    if (!search || !search.length) return this.facetValues(facetForm);
    return this.facetValues(facetForm).filter(control => {
      return String(control.get("value")?.value.toLowerCase()).includes(search);
    })
  }

  hasMoreItems(key: string, facetValues: FormGroup[]): boolean {
    const facetForm = this.getFacetForm(key);
    return facetValues.length > 4 && !this.facetsShowAll[key] && facetForm.controls.facetSearch.value === "";
  }

  facetSelected(facet: FormGroup): boolean {
    return (facet.controls.selected.value as boolean) || false;
  }
}
