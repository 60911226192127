import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { ColorMapContextService } from '@shared/services/color-map-context.service';

@Component({
    selector: 'deephub-image-details',
    templateUrl: './deephub-image-details.component.html',
    styleUrls: [
        './deephub-image-details.component.less',
        '../../shared-styles/deephub-image.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubImageDetailsComponent implements OnChanges {
    @Input() categories: string[]

    categoryColorMap: { [key: string]: string } = {};

    constructor(
        public colorMapService: ColorMapContextService
    ) { }

    ngOnChanges(): void {
        this.setColorMap();
    }

    setColorMap() {
        this.categoryColorMap = this.categories.reduce((allCategories, category) => ({
            ...allCategories,
            [category]: this.categoryColorMap[category] || this.colorMapService.mapping.get(category)
        }), {});
    }
}
