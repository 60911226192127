import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datasetTypeToIcon'
})
export class DatasetTypeToIconPipe implements PipeTransform {
  constructor(@Inject('TypeMappingService') private readonly typeMappingService: any) { }

  transform(type: string): string {
    return this.typeMappingService.mapDatasetTypeToIcon(type);
  }
}
