<div class="deephub-results-image-legend" *ngIf="{ filter: dataFetcher.filter$ | async } as data">
    <div class="deephub-results-image-legend__item">
        <div class="deephub-results-image-legend__header">Ground Truth</div>
        <div class="horizontal-flex aic">
            <span [ngClass]="{ 'deephub-results-image-legend__text': !!data?.filter?.groundTruth}" 
                  [style.background-color]="colorMapService.mapping?.get(data?.filter?.groundTruth || '')"
                  [style.color]="colorMapService.mapping?.get(data?.filter?.groundTruth || '') | colorContrast">
                {{ data?.filter?.groundTruth ? data?.filter?.groundTruth : "Any class"}}
            </span>
        </div>
    </div>
    <div class="deephub-results-image-legend__item">
        <div class="deephub-results-image-legend__header">Predicted</div>
        <div class="horizontal-flex aic">
            <span [ngClass]="{ 'deephub-results-image-legend__text': !!data?.filter?.detection}" 
                  [style.background-color]="colorMapService.mapping?.get(data?.filter?.detection || '')"
                  [style.color]="colorMapService.mapping?.get(data?.filter?.detection || '') | colorContrast">
                {{ data?.filter?.detection ? data?.filter?.detection : "Any class"}}
            </span>
        </div>
    </div>
</div>
