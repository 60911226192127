import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiViewDirective } from '@migration/ui-view/ui-view.component';
import { MarkdownDirective } from './markdown/markdown.directive';
import { AngularJSAutocompletableTextareaDirective } from './markdown/angularjs-autocompletable-textarea.directive';
import { AutocompletableTextareaComponent } from './markdown/autocompletable-textarea.component';
import { AngularJSManagedFolderContentsView } from './managed-folders/angularjs-managed-folder-contents-view.directive';
import { ManagedFolderContentsViewComponent } from './managed-folders/managed-folder-contents-view.component';
import { StarInterestComponent } from './star/star-interest.component';
import { RelatedByTypeComponent } from './catalog/related-by-type.component';
import { UiGlobalTag } from './tags/ui-global-tag.directive';
import { DatasetStatusComponent } from './datasets/dataset-status.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        UiViewDirective,
        MarkdownDirective,
        AngularJSAutocompletableTextareaDirective,
        AngularJSManagedFolderContentsView,
        AutocompletableTextareaComponent,
        ManagedFolderContentsViewComponent,
        StarInterestComponent,
        RelatedByTypeComponent,
        UiGlobalTag,
        DatasetStatusComponent
    ],
    exports: [
        UiViewDirective,
        MarkdownDirective,
        AutocompletableTextareaComponent,
        ManagedFolderContentsViewComponent,
        StarInterestComponent,
        RelatedByTypeComponent,
        UiGlobalTag,
        DatasetStatusComponent
    ],
})
export class MigrationModule { }
