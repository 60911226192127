import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { LabelingShortcutService, ShortcutAction } from "@features/labeling/services/labeling-shortcut.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { fabric } from "fabric";
import { Group } from "fabric/fabric-impl";
import { clone } from "lodash";
import { UIBoundingBox } from "../models/annotation";
import { ObjectDetectionUILabel } from '../models/label';
import { clipCoordinates, clipCoordinatesAndScale } from "./image-canvas-utils";
import { DkuRect, LabelingDrawingService } from "./labeling-drawing.service";

fabric.Group.prototype.hasControls = false;

enum CanvasStatus {
    RESCALING,
    MOVING,
    DRAWING,
    IDLE,
    INITIAL_RENDER
}

@UntilDestroy()
@Component({
    selector: 'object-detection-image-canvas',
    templateUrl: './object-detection-image-canvas.component.html',
    styleUrls: ['./object-detection-image-canvas.component.less']
})
export class ObjectDetectionImageCanvasComponent implements OnChanges, AfterViewInit {
    @Input() label: ObjectDetectionUILabel;    
    @Output() labelChange = new EventEmitter<ObjectDetectionUILabel>();

    @Input() selectionModeAvailable?: boolean = false;
    @Input() imagePath: string;
    @ViewChild("canvasWrapper", { static: false }) canvasWrapper: ElementRef;

    canvas: fabric.Canvas;

    INITIAL_CANVAS_WIDTH = 500;
    INITIAL_CANVAS_HEIGHT = 375;
    backgroundImage: fabric.Image;
    rectBeingDrawn: fabric.Rect | null;
    origX: number;
    origY: number;

    canvasStatus: CanvasStatus = CanvasStatus.IDLE;
    imageLoaded = false;

    static instanceNumber = 0;
    canvasId: string;

    constructor(
        private labelingShortCutService: LabelingShortcutService,
        private labelingDrawingService: LabelingDrawingService) {
        ObjectDetectionImageCanvasComponent.instanceNumber += 1;
        this.canvasId = "canvas" + ObjectDetectionImageCanvasComponent.instanceNumber;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.resizeCanvasAndImage();
        if (this.label?.annotations) {
            // To make sure the bboxes are not outdated if the image or canvas size changed, we redraw them
            this.drawData(this.label.annotations);
        }
    }

    ngAfterViewInit(): void {
        this.canvas = new fabric.Canvas(this.canvasId, { uniformScaling: false, targetFindTolerance: 10 });
        this.canvas.selection = false;
        this.canvas.defaultCursor = 'crosshair';

        this.labelingDrawingService.deleteAll$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.canvas) {
                this.canvas.discardActiveObject();
                this.canvas.remove(...this.canvas.getObjects());
            }
        })
        this.canvas.on('mouse:down', (event: fabric.IEvent) => {
            if (this.canvas.selection) {
                return;
            }
            this.canvasStatus = event.target === null ? CanvasStatus.DRAWING : this.canvasStatus;

            if (this.canvasStatus == CanvasStatus.DRAWING) {
                const pointer = this.canvas.getPointer(event.e);
                this.origX = pointer.x;
                this.origY = pointer.y;
            }
        });

        this.canvas.on('mouse:up', () => {
            if (this.canvasStatus == CanvasStatus.DRAWING && this.rectBeingDrawn) {
                const group = this.labelingDrawingService.createGroup(this.rectBeingDrawn);
                this.canvas.remove(this.rectBeingDrawn);
                this.canvas.setActiveObject(group);
                this.canvas.add(group);
                this.rectBeingDrawn = null;
            }

            if (this.canvasStatus == CanvasStatus.RESCALING) {
                /* when rescaling an object with fabricjs the width and height don't change, only the scaleX and scaleY are changing.
                    We need to manually modify those.*/
                const obj = this.canvas.getActiveObject() as fabric.Group;
                const group = this.labelingDrawingService.applyResizeToGroup(obj);
                this.canvas.remove(obj);
                this.canvas.add(group);
                this.canvas.setActiveObject(group);
            }

            this.canvasStatus = CanvasStatus.IDLE;
            this.canvas.requestRenderAll();
        });

        this.canvas.on('mouse:move', (event: fabric.IEvent) => {
            if (this.canvasStatus == CanvasStatus.DRAWING) {
                const pointer = this.canvas.getPointer(event.e);

                // clip the pointer coordinates so that it fits in the canvas
                const pointerX = Math.max(Math.min(this.canvas.width! - this.labelingDrawingService.STROKE_WIDTH, pointer.x), 0);
                const pointerY = Math.max(Math.min(this.canvas.height! - this.labelingDrawingService.STROKE_WIDTH, pointer.y), 0);

                if (!this.rectBeingDrawn) {
                    this.rectBeingDrawn = this.labelingDrawingService.createNewRect(
                        this.origX, this.origY, pointerX, pointerY);
                    this.canvas.add(this.rectBeingDrawn);
                }

                // When drawing towards left or top we need to update the top left corner
                const left = this.origX > pointerX ? pointerX : this.rectBeingDrawn.left;
                const top = this.origY > pointerY ? pointerY : this.rectBeingDrawn.top;

                const width = Math.abs(this.origX - pointerX);
                const height = Math.abs(this.origY - pointerY);

                this.rectBeingDrawn.set({
                    left: left,
                    top: top,
                    width: width,
                    height: height,
                    selectable: true
                }).setCoords();

                this.canvas.requestRenderAll();
            }
        });

        this.canvas.on('object:scaling', (event: fabric.IEvent) => {
            this.canvasStatus = CanvasStatus.RESCALING;
            clipCoordinatesAndScale(event.target as DkuRect, this.canvas.width!, this.canvas.height!);

        });

        this.canvas.on('object:moving', (event: fabric.IEvent) => {
            this.canvasStatus = CanvasStatus.MOVING;
            clipCoordinates(event.target as DkuRect, this.canvas.width!, this.canvas.height!);
        });

        this.canvas.on('after:render', () => {
            if (this.backgroundImage && this.canvasStatus == CanvasStatus.IDLE && this.label) {
                this.label.annotations = this.getBboxesOnCanvas();
                this.labelChange.emit(clone(this.label));
            }
        });

        // Propagate fabric selection/deselection to DkuRect objects
        this.canvas.on('selection:updated', (event: any) => {
            if (event.selected?.length) {
                this.propagateSelectionToRect(event.selected.filter((o: any) => o.type == "group"), true);
            }
                
            if (event.deselected?.length) {
                this.propagateSelectionToRect(event.deselected.filter((o: any) => o.type == "group"), false);
            }
        });

        this.canvas.on('selection:created', (event: any) => {
            if (event.selected) {
                this.propagateSelectionToRect(event.selected.filter((o: any) => o.type == "group"), true);
            }
        });

        this.canvas.on('selection:cleared', (event: any) => {
            if (event.deselected) {
                this.propagateSelectionToRect(event.deselected.filter((o: any) => o.type == "group"), false);
            }
        });

        this.canvasStatus = CanvasStatus.INITIAL_RENDER;
        this.setupCanvas();

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.imagePath && changes.imagePath.currentValue && this.canvasStatus != CanvasStatus.INITIAL_RENDER) {
            this.canvas?.clear();
            this.imageLoaded = false;
            this.setupCanvas();
        }

        if (this.canvas && changes.label && changes.label.currentValue && this.canvasStatus != CanvasStatus.DRAWING && this.canvasStatus != CanvasStatus.INITIAL_RENDER) {
            const objectsOnCanvas = new ObjectDetectionUILabel(this.getBboxesOnCanvas());
            const needsUpdate = !objectsOnCanvas.equals(this.label);
            if (needsUpdate) {
                this.drawData(this.label.annotations);
            }
        }
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDownEvent(event: KeyboardEvent) {
        if (this.labelingShortCutService.isShortcut(event, ShortcutAction.DELETE)) {
            const selection = this.canvas.getActiveObject();
            if (selection == null) {
                return;
            }
            if (selection.type === "activeSelection") { // group of objects
                (selection as Group).forEachObject(o => {
                    this.canvas.remove(o);
                });
            } else {
                this.canvas.remove(selection);
            }
            this.canvas.discardActiveObject();
        }

        if (this.selectionModeAvailable && this.labelingShortCutService.isShortcut(event, ShortcutAction.MULTI_SELECTION)) {
            this.canvas.selection = true;
            this.canvas.defaultCursor = 'default';
        }
    }

    @HostListener('window:keyup', ['$event'])
    handleKeyUpEvent(event: KeyboardEvent) {
        if (this.selectionModeAvailable && this.labelingShortCutService.isShortcut(event, ShortcutAction.MULTI_SELECTION)) {
            this.canvas.selection = false;
            this.canvas.defaultCursor = 'crosshair';
        }
    }

    private setupCanvas() {
        fabric.Image.fromURL(this.imagePath, (img: fabric.Image) => {
            this.backgroundImage = img;

            this.canvas.setWidth(this.INITIAL_CANVAS_WIDTH);
            this.canvas.setHeight(this.INITIAL_CANVAS_HEIGHT);
            this.resizeCanvasAndImage();

            if (this.canvasStatus !== CanvasStatus.INITIAL_RENDER) {
                this.imageLoaded = true;
            }

            this.canvas.setBackgroundImage(this.backgroundImage, () => {
                if (this.label?.annotations) {
                    this.drawData(this.label.annotations);
                }
                this.canvas.renderAll();
                this.canvasStatus = CanvasStatus.IDLE;
            });
        })
    }

    private resizeCanvasAndImage(): void {
        if (!this.backgroundImage?.width) {
            return;
        }

        const containerWidth = this.canvasWrapper.nativeElement.clientWidth - 40;
        const containerHeight = this.canvasWrapper.nativeElement.clientHeight - 40;
        // Fit image to canvas
        const imgScaleX = containerWidth / this.backgroundImage.width!;
        const imgScaleY = containerHeight / this.backgroundImage.height!;
        // don't want to enlarge image more than 3 times its origin resolution to avoid to blury image

        const imgScale = Math.min(imgScaleX, imgScaleY, 3);
        this.backgroundImage.scale(imgScale);

        // Shrink canvas to image
        this.canvas.setWidth(this.backgroundImage.getScaledWidth());
        this.canvas.setHeight(this.backgroundImage.getScaledHeight());
    }


    private drawData(objects: UIBoundingBox[]) {
        this.canvas.remove(...this.canvas.getObjects());
        this.canvas.discardActiveObject();
        if (objects) {
            const selection: fabric.Object[] = [];
            objects.forEach(s => {
                const addedRect = this.addRectFromObject(s);
                if (s.selected) {
                    selection.push(addedRect);
                }
            });
            if (selection.length > 1) {
                this.selectMultipleObjects(selection);
            } else if (selection.length === 1) {
                this.canvas.setActiveObject(selection[0]);
            }                
        }

        this.canvas.requestRenderAll();
    }

    private addRectFromObject(b: UIBoundingBox): fabric.Group {
        const resizedObject = this.resizeObjectForCanvas(b);
        const group = this.labelingDrawingService.createGroupFromBoundingBox(resizedObject);
        this.canvas.add(group);
        return group;
    }

    private getBboxesOnCanvas(): UIBoundingBox[] {
        // get objects (DkuRect) in the canvas and translate them into BoundingBoxes
        if (!this.canvas || !this.backgroundImage) {
            return [];
        }
        return this.getRectanglesOnCanvas().map((rect: DkuRect) => {
            return new UIBoundingBox({
                left: Math.round(rect.left! / this.backgroundImage.scaleX!),
                top: Math.round(rect.top! / this.backgroundImage.scaleY!),
                width: Math.round(rect.width! / this.backgroundImage.scaleX!),
                height: Math.round(rect.height! / this.backgroundImage.scaleY!),
                category: rect.category,
                annotator: rect.annotator,
                pinned: rect.pinned,
                selected: rect.selected,
                state: rect.state
            });
        });
    }

    private resizeObjectForCanvas(b: UIBoundingBox): UIBoundingBox {
        const copy = clone(b);
        copy.bbox = {
            x0: Math.round(b.left! * this.backgroundImage.scaleX!),
            y0: Math.round(b.top! * this.backgroundImage.scaleY!),
            width: Math.round(b.width! * this.backgroundImage.scaleX!),
            height: Math.round(b.height! * this.backgroundImage.scaleY!)
        };

        return copy;
    }

    private getRectanglesOnCanvas(): DkuRect[] {
        // get the objects in the canvas with the extended properties
        return this.canvas.toObject(["category", "selected", "annotator", "pinned", "state"]).objects.map((group: any) => {
            if (group.type == 'group') {
                let rect = clone(group.objects[0]);
                // set rectangle origin to group origin
                rect.top = group.top
                rect.left = group.left
                return rect;
            }
        });
    }

    private propagateSelectionToRect(groups: fabric.Group[], selection: boolean): void {
        groups.forEach((object: fabric.Group) => {
            (object. getObjects()[0] as DkuRect).selected = selection;
        });
    }

    private selectMultipleObjects(objects: fabric.Object[]) {
        const groupSelection = new fabric.ActiveSelection(objects, {canvas: this.canvas});
        this.canvas.setActiveObject(groupSelection);
    }
}
