/**
 * Generated from com.dataiku.dip.labeling.VerifiedLabel
 */
export interface _VerifiedLabel {}

export type VerifiedLabel = VerifiedLabel.ClassificationVerifiedLabel | VerifiedLabel.ObjectDetectionVerifiedLabel;

export namespace VerifiedLabel {
    export const type = ['CLASSIFICATION', 'OBJECT_DETECTION'] as const;
    export function isClassificationVerifiedLabel(toTest: VerifiedLabel): toTest is VerifiedLabel.ClassificationVerifiedLabel { return toTest['type'] === 'CLASSIFICATION'; }
    export function isObjectDetectionVerifiedLabel(toTest: VerifiedLabel): toTest is VerifiedLabel.ObjectDetectionVerifiedLabel { return toTest['type'] === 'OBJECT_DETECTION'; }
}

export namespace VerifiedLabel.ObjectDetectionVerifiedLabel {
    /**
     * Generated from com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel$BoundingBoxPair
     */
    export interface BoundingBoxPair {
        annotationIdx: number;
        iou: number;
        verifiedCategory: string;
    }
}

export namespace VerifiedLabel {
    /**
     * Generated from com.dataiku.dip.labeling.VerifiedLabel$ClassificationVerifiedLabel
     */
    export interface ClassificationVerifiedLabel extends _VerifiedLabel {
        verifiedCategories: string[];

        // PolyJson type
        type: 'CLASSIFICATION';
    }

    export namespace ClassificationVerifiedLabel {
        export const type = 'CLASSIFICATION';
    }



    /**
     * Generated from com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel
     */
    export interface ObjectDetectionVerifiedLabel extends _VerifiedLabel {
        boundingBoxPairs: VerifiedLabel.ObjectDetectionVerifiedLabel.BoundingBoxPair[];

        // PolyJson type
        type: 'OBJECT_DETECTION';
    }

    export namespace ObjectDetectionVerifiedLabel {
        export const type = 'OBJECT_DETECTION';
    }


}