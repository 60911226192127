<div>
    <experiment-tracking-header [intermediateLinks]="intermediateLinks"></experiment-tracking-header>
    <div class="dss-page">
        <div class="runs-table-box h100">
            <api-error-alert *ngIf="error" [apiError]="error" [closable]="false"></api-error-alert>
            <div *ngIf="experimentIdsOnError.length"
                 class="alert alert-warning">
                <i class="icon-dku-warning"></i>The following experiments can not be found:
                <ul><li *ngFor="let experimentId of experimentIdsOnError">{{ experimentId }}</li></ul>
            </div>
            <ng-container *ngIf="runs$ | async as runs">
                <div class="h100 vertical-flex runs-table oys" *ngIf="runs?.length">
                    <chart-runs-metrics [runs]="selectedToDisplayRuns" (hoverOnRun)="setHighlightedRunId($event)"
                        (clickOnRun)="scrollToRunId($event)" class="chart-runs-metrics" (requestRefresh)="refresh()">
                    </chart-runs-metrics>
                    <div class="runs-table-actions">
                        <form class="dkuform-horizontal" [formGroup]="form">
                            <div class="noflex dropdown mass-action-btn mleft8">
                                <input type="checkbox" (change)="masterToggle()"
                                    [checked]="hasSelectedRuns() && isAllSelected()"
                                    [indeterminate]="hasSelectedRuns() && !isAllSelected()"
                                    [wt1Click]="'experiment-tracking-toggle-all-runs'">
                                <a data-toggle="dropdown" class="dropdown-toggle unselectable"
                                    [ngClass]="{disabled: !hasSelectedRuns(), collapsed: !runs.length}">
                                    <span>ACTIONS</span>
                                    <b class="caret"></b>
                                </a>
                                <ul class="dropdown-menu">
                                    <li [ngClass]="{disabled: !hasSelectedDeletedRuns(runs)}">
                                        <a (click)="restoreSelected(runs)"><i class="icon-undo"></i>Restore</a>
                                    </li>
                                    <li [ngClass]="{disabled: !hasSelectedActiveRuns(runs)}">
                                        <a (click)="deleteSelected(runs)"><i class="icon-archive"></i>Delete</a>
                                    </li>
                                    <li>
                                        <a (click)="showSelected(runs)"><i class="icon-eye-open"></i>Show</a>
                                    </li>
                                    <li>
                                        <a (click)="hideSelected(runs)"><i class="icon-eye-close"></i>Hide</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- search -->
                            <div class="std-list-search-box list-control-widget mleft16">
                                <span class="add-on">
                                    <i class="icon-dku-search"></i>
                                </span>
                                <input type="search" placeholder="Search..." style="width: 200px"
                                    formControlName="querySearch">
                            </div>
                            <div class="dib column-selector">
                                <label>Columns</label>
                                <div>
                                    <dku-bs-select-form-control formControlName="selectedColumns"
                                        ngOptions="column.columnDef as column.header group by column.groupColumnHeader for column in list"
                                        [list]="availableColumns" [dataActionsBox]="true" [dataLiveSearch]="true"
                                        [dkuMultiple]="true" [layout]="'list'">
                                    </dku-bs-select-form-control>
                                </div>
                            </div>
                            <div class="dib view-type-group">
                                <input id="viewAllRuns" type="checkbox" formControlName="viewAll">
                                <label for="viewAllRuns">Show deleted</label>
                            </div>
                        </form>
                    </div>
                    <div class="runs-table-container">
                        <div class="runs-table-wrapper" *ngIf="querySort$ | async as querySort">
                            <table mat-table class="dku-mat-table table-hover" [dataSource]="dataSource"
                                [trackBy]="trackExperimentBy" matSort (matSortChange)="onSortChange($event)"
                                [matSortActive]="querySort?.active ?? ''"
                                [matSortDirection]="querySort?.direction ?? ''" [@.disabled]="true">
                                <!-- Highlight Column -->
                                <ng-container matColumnDef="highlight" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="highlight-cell">
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="highlight-cell"
                                        [ngClass]="{'highlighted-run': run.info.runId == highlightedRunId}">
                                    </td>
                                </ng-container>
                                <!-- Select Column -->
                                <ng-container matColumnDef="select" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="select-cell">
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="select-cell">
                                        <input class="select-run-input" type="checkbox"
                                            (click)="$event.stopPropagation()"
                                            [wt1Click]="'experiment-tracking-toggle-run'"
                                            (change)="toggleRunSelection(run)" [checked]="isRunSelected(run)">
                                    </td>
                                </ng-container>
                                <!-- Display Column -->
                                <ng-container matColumnDef="display-chart" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="display-chart-cell">
                                        <span matTooltip="Show/hide all runs" (click)="masterToDisplayToggle()"
                                            [wt1Click]="'experiment-tracking-toggle-all-runs'">
                                            <i
                                                [ngClass]="{
                                                'icon-eye-open': hasToDisplaySelectedRuns() || isAllToDisplaySelected(),
                                                'icon-eye-close': !hasToDisplaySelectedRuns(),
                                                'text-disabled': !hasToDisplaySelectedRuns() || !isAllToDisplaySelected() }"></i>
                                        </span>
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="display-chart-cell">
                                        <span class="show-hide-run" matTooltip="Show/hide run"
                                            (click)="toggleRunToDisplaySelection(run)"
                                            [wt1Click]="'experiment-tracking-toggle-run'">
                                            <i
                                                class="{{ isRunToDisplaySelected(run) ? 'icon-eye-open' : 'icon-eye-close text-disabled' }}">
                                            </i>
                                        </span>
                                    </td>
                                </ng-container>
                                <!-- Deleted Column -->
                                <ng-container matColumnDef="deleted" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="deleted-cell"></th>
                                    <td mat-cell *matCellDef="let run" class="deleted-cell"
                                        matTooltip="Deleted runs can be permanently removed with a Project macro">
                                        <i class="icon icon-archive" *ngIf="run.deletedTooltip"></i>
                                    </td>
                                </ng-container>
                                <!-- Color Column -->
                                <ng-container matColumnDef="runColor" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="run-color-square-cell">
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="run-color-square-cell">
                                        <span class="run-color-square"
                                            [ngStyle]="{'background-color': getRunColor(run.info.runId)}"></span>
                                    </td>
                                </ng-container>
                                <!-- Run information Columns -->
                                <ng-container matColumnDef="ri-experiment" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis">
                                        Experiment
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.experimentName?run.experimentName:run.info.experimentId"
                                        isEllipsisActive>
                                        <span>{{ run.experimentName?run.experimentName:run.info.experimentId }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-run" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis">Run</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.runNameId" isEllipsisActive>
                                        <a [wt1Click]="'experiment-tracking-go-to-run-details'" [href]="run.href">{{ run.runNameId }}</a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-startTime" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis">Start
                                        Time
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.info.startTime | YYYYMMDDHHmmssDateTime" isEllipsisActive>
                                        <span>{{ run.info.startTime | YYYYMMDDHHmmssDateTime }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-totalTime" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis">Total
                                        Time
                                    </th>
                                    <td mat-cell class="mx-textellipsis" *matCellDef="let run"
                                        [matTooltip]="run.info.endTime == 0 ? 'RUNNING' : 'End time: ' + (run.info.endTime | YYYYMMDDHHmmssDateTime)">
                                        <span>{{ run.info.endTime == 0 ? '-' : run.info.startTime |
                                            friendlyTimeDeltaHHMMSS:run.info.endTime }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-status" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis">Status
                                    </th>
                                    <td mat-cell *matCellDef="let run" [matTooltip]="run.info.status">
                                        <experiment-tracking-status [runStatus]="run.info.status">
                                        </experiment-tracking-status>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-dssUser" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis">User</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.dssUser || ''">
                                        <ng-container *ngIf="run.dssUser">
                                            <a [href]="'/profile/' + run.dssUser + '/'">
                                                <user-picture [user]="run.dssUser" [size]="24"></user-picture>
                                            </a>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-dssGitCommit" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis">Git commit id</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis runs-table__git-commit"
                                        [matTooltip]="run.dssGitCommit" isEllipsisActive>
                                        <a [href]="run.dssGitCommitHref" >{{ run.dssGitCommit }}</a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-dssGitMessage" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis">Git commit message</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.dssGitMessage" isEllipsisActive>
                                        {{ run.dssGitMessage }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ri-dssGitBranch" [sticky]="runInformationFrozen">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis">Git branch</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="run.dssGitBranch" isEllipsisActive>
                                        {{ run.dssGitBranch }}
                                    </td>
                                </ng-container>
                                <!-- Metrics Columns -->
                                <ng-container *ngFor="let column of columnsByGroupMap.get(RunGroupColumnsEnum.METRICS)"
                                    [matColumnDef]="column.columnDef">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.METRICS">
                                        {{ column.header }}
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.METRICS"
                                        [matTooltip]="run.metrics.get(column.valueKey)?.value || '-'" isEllipsisActive>
                                        <span>{{ run.metrics.get(column.valueKey)?.lastValue.toFixed(4) || "-" }}</span>
                                    </td>
                                </ng-container>
                                <!-- Parameters Columns -->
                                <ng-container *ngFor="let column of columnsByGroupMap.get(RunGroupColumnsEnum.PARAMS)"
                                    [matColumnDef]="column.columnDef">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.PARAMS">
                                        {{ column.header }}
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.PARAMS"
                                        [matTooltip]="run.params.get(column.header) || '-'" isEllipsisActive>
                                        <span>{{ run.params.get(column.header) || "-" }}</span>
                                    </td>
                                </ng-container>
                                <!-- Tags Columns -->
                                <ng-container *ngFor="let column of columnsByGroupMap.get(RunGroupColumnsEnum.TAGS)"
                                    [matColumnDef]="column.columnDef">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.TAGS">
                                        {{ column.header }}
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.TAGS"
                                        [matTooltip]="run.tags.get(column.header) || '-'" isEllipsisActive>
                                        <span>{{ run.tags.get(column.header) || "-" }}</span>
                                    </td>
                                </ng-container>
                                <!-- System Tags Columns -->
                                <ng-container
                                    *ngFor="let column of columnsByGroupMap.get(RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS)"
                                    [matColumnDef]="column.columnDef">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS">
                                        {{ column.header }}
                                    </th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS"
                                        [matTooltip]="run.systemTags.get(column.header) || '-'" isEllipsisActive>
                                        <span>{{ run.systemTags.get(column.header) || "-" }}</span>
                                    </td>
                                </ng-container>
                                <!-- Artifacts Columns -->
                                <ng-container matColumnDef="ar-models">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header
                                        class="mx-textellipsis" [ngClass]="'cell-' + RunGroupColumnsEnum.ARTIFACTS">
                                        Models</th>
                                    <td mat-cell *matCellDef="let run" class="mx-textellipsis"
                                        [matTooltip]="this.run.data?.models?.length || 0"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.ARTIFACTS" isEllipsisActive>
                                        <span>{{ this.run.data?.models?.length || 0 }}</span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ar-artifact">
                                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="mx-textellipsis"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.ARTIFACTS">
                                        Artifact
                                    </th>
                                    <td mat-cell *matCellDef="let run"
                                        [ngClass]="'cell-' + RunGroupColumnsEnum.ARTIFACTS">
                                        <div class="managed-folder-container">
                                            <div class="mx-textellipsis">
                                                <a *ngIf="run.artifactInfo.managedFolderName" matTooltip="{{ run.artifactInfo.managedFolderName + '/' +
                                                    run.artifactInfo.subfolder }}"
                                                    [wt1Click]="'experiment-tracking-go-to-run-artifacts'"
                                                    [href]="run.artifactHref">
                                                    <span>{{ run.artifactInfo.managedFolderName + '/' +
                                                        run.artifactInfo.subfolder }}</span>
                                                </a>
                                                <span *ngIf="!run.artifactInfo.managedFolderName"
                                                    [matTooltip]="run.info.artifactUri">
                                                    {{ run.info.artifactUri }}
                                                </span>
                                            </div>
                                            <button class="btn btn--text btn--primary copy-button"
                                                (click)="copyToClipboard(run.info.artifactUri)"
                                                matTooltip="Copy artifact URI">
                                                <i class="icon-copy"></i>
                                            </button>
                                        </div>
                                    </td>
                                </ng-container>

                                <!-- Header group -->
                                <ng-container matColumnDef="group-highlight" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="highlight-cell" [attr.colspan]="1">
                                    </th>
                                </ng-container>
                                <ng-container matColumnDef="group-select" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="select-cell" [attr.colspan]="1">
                                    </th>
                                </ng-container>
                                <ng-container matColumnDef="group-display-chart" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="display-chart-cell" [attr.colspan]="1">
                                    </th>
                                </ng-container>
                                <ng-container matColumnDef="group-deleted" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="deleted-cell" [attr.colspan]="1">
                                    </th>
                                </ng-container>
                                <ng-container matColumnDef="group-run-color" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef class="run-color-square-cell"
                                        [attr.colspan]="1">
                                    </th>
                                </ng-container>
                                <!-- Header group run information -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.RUN_INFORMATION"
                                    class="mx-textellipsis" [sticky]="runInformationFrozen">
                                    <th mat-header-cell *matHeaderCellDef
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.RUN_INFORMATION)?.length"
                                        class="run-information-group-header">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.RUN_INFORMATION) }}
                                        <span matTooltip="Freeze/Unfreeze run information columns"
                                            (click)="toggleRunInformationFreezeMode()"
                                            [wt1Click]="'experiment-tracking-toggle-run-info-freeze'">
                                            <i
                                                [ngClass]="runInformationFrozen ? 'icon-dku-unpin-chart' : 'icon-dku-pin-chart'"></i>
                                        </span>
                                    </th>
                                </ng-container>
                                <!-- Header group metrics -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.METRICS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.METRICS)?.length">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.METRICS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group parameters -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.PARAMS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.PARAMS)?.length">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.PARAMS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group tags -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.TAGS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.TAGS)?.length">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.TAGS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group DSS system tags -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.DSS_SYSTEM_TAGS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.DSS_SYSTEM_TAGS)?.length">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.DSS_SYSTEM_TAGS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group MLflow system tags -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS)?.length">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.MLFLOW_SYSTEM_TAGS) }}
                                    </th>
                                </ng-container>
                                <!-- Header group artifacts -->
                                <ng-container [matColumnDef]="RunGroupColumnsEnum.ARTIFACTS">
                                    <th mat-header-cell *matHeaderCellDef class="first-group-cell mx-textellipsis"
                                        [attr.colspan]="displayedColumnsMap.get(RunGroupColumnsEnum.ARTIFACTS)?.length">
                                        {{ getGroupColumnsHeader(RunGroupColumnsEnum.ARTIFACTS) }}
                                    </th>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedGroupColumns; sticky: true"></tr>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let run; columns: displayedColumns;"
                                    [ngClass]="{'highlighted-row': run.info.runId == highlightedRunId, 'greyed-row': run.deletedTooltip}">
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div *ngIf="!runs?.length && !experimentIdsOnError.length" class="h100 vertical-flex oys empty-list-cta">
                    <form class="dkuform-horizontal" [formGroup]="form">
                        <h1>
                            No <span *ngIf="!form.controls['viewAll'].value">Active </span>Run in this Experiment
                        </h1>
                        <p>
                            Create runs by using the MLflow API.
                        </p>
                        <p>
                            <a target="_blank"
                                href="https://doc.dataiku.com/dss/latest/mlops/experiment-tracking/index.html">Read the
                                documentation</a> to learn more.
                        </p>
                        <p>
                            <input id="viewAllRuns" type="checkbox" formControlName="viewAll"
                                style="vertical-align: baseline;">&nbsp;Show Deleted Runs
                        </p>
                        <div>
                            <button class="btn btn--secondary btn--large"
                                    (click)="refresh()"
                                    [wt1Click]="'experiment-tracking-refresh-runs'">
                                <i class="icon-refresh"></i>&nbsp;Refresh
                            </button>
                        </div>
                    </form>
                </div>
            </ng-container>
        </div>
    </div>
</div>
