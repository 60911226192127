import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeToIcon'
})
export class TypeToIconPipe implements PipeTransform {
  constructor(@Inject('TypeMappingService') private readonly typeMappingService: any) { }

  transform(type: string): string {
    return this.typeMappingService.mapTypeToIcon(type);
  }
}
