import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'd3-format';

@Pipe({
  name: 'smartNumber'
})
export class SmartNumberPipe implements PipeTransform {

  transform(d: number|undefined): string {
    let expFormatter = format(".2e");
    let siFormatter = format(".2s");
    let digitFormatters : ((n: number)=>string)[] = [];
    for (var i = 0; i < 6; i++) {
        digitFormatters.push(format("." + i + "f"));
    }
    if (typeof d != "number") {
        return "NA";
    }
    var abs = Math.abs(d);
    if (abs >= 1e12) {
        return expFormatter(d);
    } else if (abs >= 100000) {
        return siFormatter(d);
    } else if (abs >= 100) {
        return digitFormatters[0](d);
    } else if (abs >= 1) {
        if (abs % 1 === 0) return digitFormatters[0](d);
        return digitFormatters[2](d);
    } else if (abs === 0) {
        return digitFormatters[0](d);
    } else if (abs < 0.00001) {
        return d.toPrecision(3);
    } else {
        var x = Math.min(5, 2 - (Math.log10(abs) | 0));
        return digitFormatters[x](d);
    }
  }

}
