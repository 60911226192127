import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import _ from 'lodash';
import { MultivariateCard, Variable, CorrelationMatrixCard, isCorrelationMatrixCard, ParallelCoordinatesPlotCard, isParallelCoordinatesPlotCard, isPCACard, PCACard } from 'src/generated-sources';
import { Observable, combineLatest } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { map, startWith, tap } from 'rxjs/operators';
import { CardWizardVariable } from '@features/eda/card-models';
import { CardWizardService } from '../../../card-wizard/card-wizard.service';
import { MAX_HEADER_COLUMNS, CANNOT_ADD_REASON, identicalVariableNames, unselectVariables, enableVariables } from '@features/eda/card-utils';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WT1Service } from '@core/dataiku-wt1/wt1.service';
import { toggleFormControl } from '@utils/toggle-form-control';
import { minCheckedValidator } from '@utils/min-checked-validator';

@UntilDestroy()
@Component({
    selector: 'multivariate-card-config',
    templateUrl: './multivariate-card-config.component.html',
    styleUrls: [
        '../../../../shared-styles/card-wizard.less',
        './multivariate-card-config.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultivariateCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: MultivariateCard;
    @Input() variables: CardWizardVariable[];
    @Output() paramsChange = new EventEmitter<MultivariateCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);
    @Output() validityTooltipChange = new EventEmitter<string>(true);
    CorrelationMetric = CorrelationMatrixCard.CorrelationMetric;
    isCorrelationMatrixCard = isCorrelationMatrixCard;
    isParallelCoordinatesPlotCard = isParallelCoordinatesPlotCard;
    isPCACard = isPCACard;

    allVariables$: Observable<CardWizardVariable[]>;
    columns$: Observable<Variable[]>;
    currentVariables$: Observable<CardWizardVariable[]>;
    selectedAvailableVariables: CardWizardVariable[] = [];
    selectedSelectedVariables: CardWizardVariable[] = [];
    count = 0;
    tooManySelectedVariablesWarning = '';

    configForm = this.fb.group({
        // Common settings
        columns: this.fb.control([], [
            Validators.required,
            Validators.minLength(2)
        ]),

        // Type of the configurated card (not editable in the UI)
        type: this.fb.control('', Validators.required),

        // Correlation matrix
        correlationMatrixOptions: this.fb.group({
            metric: this.fb.control(CorrelationMatrixCard.CorrelationMetric.SPEARMAN, [Validators.required])
        }),

        // Parallel coordinates plot
        pcpOptions: this.fb.group({
            maxNumberOfPoints: this.fb.control(null, [
                Validators.required,
                Validators.min(1)
            ]),
        }),

        // PCA card
        pcaOptions: this.fb.group({
            showScatterPlot: this.fb.control(null),
            showLoadingPlot: this.fb.control(null),
            showScreePlot: this.fb.control(null),
            showComponents: this.fb.control(null)
        },
        {
            validator: minCheckedValidator([
                'showScatterPlot',
                'showLoadingPlot',
                'showScreePlot',
                'showComponents'
            ])
        })
    });

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService,
        private wt1Service: WT1Service
    ) {
        toggleFormControl(this.configForm.controls.correlationMatrixOptions, this.configForm.controls.type, 'correlation_matrix');
        toggleFormControl(this.configForm.controls.pcpOptions, this.configForm.controls.type, 'parallel_coordinates_plot');
        toggleFormControl(this.configForm.controls.pcaOptions, this.configForm.controls.type, 'pca');

        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => {
                let changedParams: MultivariateCard = {
                    ...this.params,
                    columns: formValue.columns,
                };

                if (isCorrelationMatrixCard(this.params)) {
                    changedParams = { ...changedParams, ...formValue.correlationMatrixOptions };
                } else if (isParallelCoordinatesPlotCard(this.params)) {
                    changedParams = { ...changedParams, ...formValue.pcpOptions };
                } else if (isPCACard(this.params)) {
                    changedParams = { ...changedParams, ...formValue.pcaOptions };
                }

                this.paramsChange.emit(changedParams);
            });
    }

    hasOptionalParameters(params: MultivariateCard) {
        return isCorrelationMatrixCard(params)
            || isParallelCoordinatesPlotCard(params)
            || isPCACard(params);
    }

    ngOnInit() {
        const columns = this.configForm.controls.columns.value as Variable[]; // need starting value for combineLatest
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
        this.columns$ = this.configForm.controls.columns.valueChanges;

        this.currentVariables$ = combineLatest([this.allVariables$, this.columns$.pipe(startWith(columns))])
            .pipe(
                map(([all, column]) => {
                    // remove x variables from current variable list
                    return all.filter(variable => column.findIndex(columnVariable => columnVariable.name === variable.name) < 0).map(({selected, ...attrs}) => attrs);
                }),
                tap(() => {
                    this.selectedSelectedVariables = [];
                    this.selectedSelectedVariables = [];
                })
            );

        this.currentVariables$
            .pipe(untilDestroyed(this))
            .subscribe(variables => {
                this.count = variables.filter(v => !v.disabled).length;
            });

        combineLatest([this.configForm.statusChanges.pipe(startWith('')), this.columns$.pipe(startWith(columns))])
            .pipe(untilDestroyed(this))
            .subscribe(([sc, curColumns]) => {
                const overLimit = curColumns.length > MAX_HEADER_COLUMNS;
                this.validityChange.emit(this.configForm.valid && !overLimit);
                if (overLimit) {
                    this.tooManySelectedVariablesWarning = `${curColumns.length} variables selected. Max is ${MAX_HEADER_COLUMNS}.`;
                } else {
                    this.tooManySelectedVariablesWarning = '';
                }
                this.validityTooltipChange.emit(this.tooManySelectedVariablesWarning);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.configForm.patchValue({
                type: this.params.type,
                columns: this.params.columns,
            })

            if (isCorrelationMatrixCard(this.params)) {
                this.configForm.controls.correlationMatrixOptions.patchValue({
                    metric: this.params.metric
                });
            } else if (isParallelCoordinatesPlotCard(this.params)) {
                this.configForm.controls.pcpOptions.patchValue({
                    maxNumberOfPoints: this.params.maxNumberOfPoints
                });
            } else if(isPCACard(this.params)) {
                this.configForm.controls.pcaOptions.patchValue({
                    showScatterPlot: this.params.showScatterPlot,
                    showLoadingPlot: this.params.showLoadingPlot,
                    showScreePlot: this.params.showScreePlot,
                    showComponents: this.params.showComponents
                });
            }
        }
    }

    add() {
        let columns = this.configForm.controls.columns.value.concat(this.selectedAvailableVariables);
        columns = unselectVariables(columns);
        columns = enableVariables(columns);

        this.configForm.patchValue({ columns });
        this.selectedAvailableVariables = [];
    }

    del() {
        const value = this.configForm.controls.columns.value;

        this.configForm.patchValue({
            columns: Array.isArray(value) ? _.differenceWith(value, this.selectedSelectedVariables, identicalVariableNames) : []
        });

        this.selectedSelectedVariables = [];
    }

    get canAdd() {
        return this.selectedAvailableVariables.length > 0;
    }

    get disabledReason() {
        if (this.configForm.controls.columns.value.length + this.selectedAvailableVariables.length > MAX_HEADER_COLUMNS) {
            return CANNOT_ADD_REASON.MAX_VARIABLES_EXCEEDED;
        } else if (this.selectedAvailableVariables.length <= 0) {
            return CANNOT_ADD_REASON.NO_VARIABLE_SELECTED;
        }

        return '';
    }

    ngOnDestroy() {

    }

    onDropAdd(dropped: CdkDragDrop<CardWizardVariable[]>): void {
        const droppedItems: CardWizardVariable[] = dropped.item.data;
        droppedItems.forEach(v => v.selected = false);
        const currentValue = this.configForm.controls.columns.value;
        let newValue: CardWizardVariable[];
        if (currentValue) {
            let spliceIndex = dropped.currentIndex;
            newValue = _.cloneDeep(currentValue);
            if (dropped.container === dropped.previousContainer) {
                const indexes = droppedItems.map(d => newValue.findIndex(e => e.name === d.name ));
                indexes.splice(0, 1);
                const offset = _.sumBy(indexes, curIndex => curIndex <= spliceIndex ? 1 : 0);
                spliceIndex -= offset;
                newValue = _.differenceWith(newValue, droppedItems, identicalVariableNames);
            }
            newValue.splice(spliceIndex, 0, ...droppedItems);
        } else {
            newValue = droppedItems;
        }
        this.configForm.patchValue({
            columns: unselectVariables(newValue)
        });
        this.selectedAvailableVariables = [];
        this.selectedSelectedVariables = [];
        this.wt1Service.event('statistics-drag-drop-variables', { droppedCount: dropped.item.data.length });
    }

    onDropVariables(dropped: CdkDragDrop<CardWizardVariable[]>): void {
        const droppedItems: CardWizardVariable[] = dropped.item.data;
        droppedItems.forEach(v => v.selected = false);

        const currentValue = this.configForm.controls.columns.value;
        const newValue = currentValue ? _.differenceWith(currentValue, droppedItems, identicalVariableNames) : currentValue;

        this.configForm.patchValue({
            columns: unselectVariables(newValue)
        });
        this.selectedAvailableVariables = [];
        this.selectedSelectedVariables = [];
        this.wt1Service.event('statistics-drag-drop-variables', { droppedCount: dropped.item.data.length });
    }
}
