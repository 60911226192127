import type {CategoricalHyperparameterDimension} from './categorical-hyperparameter-dimension';
import type {FloatHyperparameterDimension} from './float-hyperparameter-dimension';
import type {IntegerHyperparameterDimension} from './integer-hyperparameter-dimension';
import type {MetricParams} from './metric-params';

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams
 */
export interface PredictionModelingParams {
    autoarima_timeseries: PredictionModelingParams.AutoArimaSpace;
    causal_learning: PredictionModelingParams.CausalLearningMethod;
    custom_mllib: PredictionModelingParams.MLLibCustomGridParams[];
    custom_python: PredictionModelingParams.CustomPythonParams[];
    decision_tree_classification: PredictionModelingParams.DecisionTreeHyperparametersSpace;
    decision_tree_regression: PredictionModelingParams.DecisionTreeHyperparametersSpace;
    deep_learning_sparkling: PredictionModelingParams.H2ODeepLearningGridParams;
    extra_trees: PredictionModelingParams.RandomForestHyperparametersSpace;
    gbm_sparkling: PredictionModelingParams.H2OGBMGridParams;
    gbt_classification: PredictionModelingParams.GBTClassificationHyperparametersSpace;
    gbt_regression: PredictionModelingParams.GBTRegressionHyperparametersSpace;
    glm_sparkling: PredictionModelingParams.H2OGLMGridParams;
    gluonts_deepar_timeseries: PredictionModelingParams.GluonTSDeepARSpace;
    gluonts_gpu_params: PredictionModelingParams.GpuCodeParams;
    gluonts_mqcnn_timeseries: PredictionModelingParams.GluonTSMQCNNSpace;
    gluonts_npts_timeseries: PredictionModelingParams.GluonTSNPTSForecasterSpace;
    gluonts_simple_feed_forward_timeseries: PredictionModelingParams.GluonTSSimpleFeedForwardSpace;
    gluonts_transformer_timeseries: PredictionModelingParams.GluonTSTransformerSpace;
    gridSearchParams: PredictionModelingParams.GridSearchParams;
    grid_search: boolean;
    keras: PredictionModelingParams.KerasCodeParams;
    knn: PredictionModelingParams.KNNHyperparametersSpace;
    lars_params: PredictionModelingParams.LarsHyperparametersSpace;
    lasso_regression: PredictionModelingParams.LassoHyperparametersSpace;
    leastsquare_regression: PredictionModelingParams.LeastSquareHyperparametersSpace;
    lightgbm_classification: PredictionModelingParams.LightGBMHyperParametersSpace;
    lightgbm_regression: PredictionModelingParams.LightGBMHyperParametersSpace;
    logistic_regression: PredictionModelingParams.LogisticRegressionHyperparametersSpace;
    max_ensemble_nodes_serialized: number;
    metrics: MetricParams;
    mllib_dt: PredictionModelingParams.MLLibDecisionTreeGridParams;
    mllib_gbt: PredictionModelingParams.MLLibTreesEnsembleGridParams;
    mllib_linreg: PredictionModelingParams.MLLibLinearRegressionGridParams;
    mllib_logit: PredictionModelingParams.MLLibLogisticRegressionGridParams;
    mllib_naive_bayes: PredictionModelingParams.MLLibNaiveBayesGridParams;
    mllib_rf: PredictionModelingParams.MLLibTreesEnsembleGridParams;
    nb_sparkling: PredictionModelingParams.H2ONaiveBayesGridParams;
    neural_network: PredictionModelingParams.NeuralNetworkHyperparametersSpace;
    plugin_python: {[key: string]: PredictionModelingParams.CustomPythonPluginParams};
    random_forest_classification: PredictionModelingParams.RandomForestHyperparametersSpace;
    random_forest_regression: PredictionModelingParams.RandomForestHyperparametersSpace;
    rf_sparkling: PredictionModelingParams.H2ORandomForestGridParams;
    ridge_regression: PredictionModelingParams.RidgeRegressionHyperparametersSpace;
    seasonal_loess_timeseries: PredictionModelingParams.SeasonalLoessSpace;
    seasonal_naive_timeseries: PredictionModelingParams.SeasonalNaiveSpace;
    sgd_classifier: PredictionModelingParams.SGDClassificationHyperparametersSpace;
    sgd_regression: PredictionModelingParams.SGDRegressionHyperparametersSpace;
    skipExpensiveReports: boolean;
    svc_classifier: PredictionModelingParams.SVMHyperparametersSpace;
    svm_regression: PredictionModelingParams.SVMHyperparametersSpace;
    trivial_identity_timeseries: PredictionModelingParams.TrivialIdentityTimeseriesSpace;
    vertica_linear_regression: PredictionModelingParams.VerticaLinearRegParams;
    vertica_logistic_regression: PredictionModelingParams.VerticaLogisticRegParams;
    xgboost: PredictionModelingParams.XGBoostHyperparametersSpace;
}

export namespace PredictionModelingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SGDRegressionHyperparametersSpace
     */
    export interface SGDRegressionHyperparametersSpace extends PredictionModelingParams.SGDHyperparametersSpace {
        epsilon: FloatHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SeasonalNaiveSpace
     */
    export interface SeasonalNaiveSpace extends PredictionModelingParams.HyperparametersSpace {
        season_length: IntegerHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibDecisionTreeGridParams
     */
    export interface MLLibDecisionTreeGridParams {
        cache_node_ids: boolean;
        checkpoint_interval: number;
        enabled: boolean;
        max_bins: number;
        max_depth: IntegerHyperparameterDimension;
        max_memory_mb: number;
        min_info_gain: number;
        min_instance_per_node: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$CausalLearningMethod
     */
    export enum CausalLearningMethod {
        S_LEARNER = 'S-learner',
        T_LEARNER = 'T-learner',
        X_LEARNER = 'X-learner'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$TrivialIdentityTimeseriesSpace
     */
    export interface TrivialIdentityTimeseriesSpace extends PredictionModelingParams.HyperparametersSpace {}

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibRfImpurity
     */
    export enum MLLibRfImpurity {
        gini = 'gini',
        entropy = 'entropy',
        variance = 'variance'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$HyperparametersSpace
     */
    export interface HyperparametersSpace {
        enabled: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$RidgeSelectAlphaMode
     */
    export enum RidgeSelectAlphaMode {
        MANUAL = 'MANUAL',
        AUTO = 'AUTO'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibCustomGridParams
     */
    export interface MLLibCustomGridParams {
        enabled: boolean;
        initializationCode: string;
        name: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2OGBMGridParams
     */
    export interface H2OGBMGridParams {
        balance_classes: boolean;
        enabled: boolean;
        family: string;
        learn_rate: number;
        max_after_balance_size: number;
        max_depth: number;
        min_rows: number;
        nbins: number;
        nbins_cats: number;
        ntrees: number;
        tweedie_power: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GpuCodeParams
     */
    export interface GpuCodeParams {
        gpuList: number[];
        useGPU: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SVMHyperparametersSpace
     */
    export interface SVMHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        C: FloatHyperparameterDimension;
        coef0: number;
        custom_gamma: FloatHyperparameterDimension;
        gamma: CategoricalHyperparameterDimension;
        kernel: CategoricalHyperparameterDimension;
        max_iter: number;
        tol: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$RandomForestHyperparametersSpace
     */
    export interface RandomForestHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        max_feature_prop: FloatHyperparameterDimension;
        max_features: IntegerHyperparameterDimension;
        max_tree_depth: IntegerHyperparameterDimension;
        min_samples_leaf: IntegerHyperparameterDimension;
        n_estimators: IntegerHyperparameterDimension;
        n_jobs: number;
        selection_mode: PredictionModelingParams.TreeSelectionMode;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2ORandomForestGridParams
     */
    export interface H2ORandomForestGridParams {
        enabled: boolean;
        max_depth: number;
        min_rows: number;
        mtries: number;
        nbins: number;
        nbins_cats: number;
        ntrees: number;
        sample_rate: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$CustomPythonPluginParams
     */
    export interface CustomPythonPluginParams extends PredictionModelingParams.HyperparametersSpace {
        acceptsSparseMatrix: boolean;
        elementId: string;
        name: string;
        params: {[key: string]: any};
        pluginId: string;
        pluginVersion: string;
        supportsSampleWeights: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSNPTSForecasterSpace
     */
    export interface GluonTSNPTSForecasterSpace extends PredictionModelingParams.HyperparametersSpace {
        context_length: IntegerHyperparameterDimension;
        exp_kernel_weights: FloatHyperparameterDimension;
        feature_scale: FloatHyperparameterDimension;
        full_context: boolean;
        kernel_type: CategoricalHyperparameterDimension;
        seed: number;
        use_default_time_features: boolean;
        use_seasonal_model: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$VerticaLogisticRegParams
     */
    export interface VerticaLogisticRegParams {
        enabled: boolean;
        epsilon: number;
        maxIterations: number;
        optimizer: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$NeuralNetworkHyperparametersSpace
     */
    export interface NeuralNetworkHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        activation: string;
        alpha: number;
        auto_batch: boolean;
        batch_size: number;
        beta_1: number;
        beta_2: number;
        early_stopping: boolean;
        epsilon: number;
        layer_sizes: IntegerHyperparameterDimension;
        learning_rate: string;
        learning_rate_init: number;
        max_iter: number;
        momentum: number;
        nesterovs_momentum: boolean;
        power_t: number;
        seed: number;
        shuffle: boolean;
        solver: string;
        tol: number;
        validation_fraction: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LightGBMHyperParametersSpace
     */
    export interface LightGBMHyperParametersSpace extends PredictionModelingParams.HyperparametersSpace {
        boosting_type: CategoricalHyperparameterDimension;
        colsample_bytree: FloatHyperparameterDimension;
        early_stopping: boolean;
        early_stopping_rounds: number;
        learning_rate: FloatHyperparameterDimension;
        max_depth: number;
        min_child_samples: IntegerHyperparameterDimension;
        min_child_weight: FloatHyperparameterDimension;
        min_split_gain: FloatHyperparameterDimension;
        n_estimators: IntegerHyperparameterDimension;
        n_jobs: number;
        num_leaves: IntegerHyperparameterDimension;
        random_state: number;
        reg_alpha: FloatHyperparameterDimension;
        reg_lambda: FloatHyperparameterDimension;
        subsample: number;
        subsample_freq: number;
        use_bagging: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibRfFeatureSubsetStrategy
     */
    export enum MLLibRfFeatureSubsetStrategy {
        auto = 'auto',
        all = 'all',
        onethird = 'onethird',
        sqrt = 'sqrt',
        log2 = 'log2'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SGDClassificationHyperparametersSpace
     */
    export interface SGDClassificationHyperparametersSpace extends PredictionModelingParams.SGDHyperparametersSpace {}

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GBTRegressionHyperparametersSpace
     */
    export interface GBTRegressionHyperparametersSpace extends PredictionModelingParams.GradientBoostedTreeHyperparametersSpace {}

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSDeepARSpace
     */
    export interface GluonTSDeepARSpace extends PredictionModelingParams.HyperparametersSpace {
        alpha: FloatHyperparameterDimension;
        auto_num_batches_per_epoch: boolean;
        batch_size: number;
        beta: FloatHyperparameterDimension;
        cell_type: CategoricalHyperparameterDimension;
        context_length: IntegerHyperparameterDimension;
        distr_output: CategoricalHyperparameterDimension;
        dropout_rate: FloatHyperparameterDimension;
        dropoutcell_type: CategoricalHyperparameterDimension;
        epochs: number;
        full_context: boolean;
        minimum_scale: number;
        num_batches_per_epoch: number;
        num_cells: IntegerHyperparameterDimension;
        num_layers: IntegerHyperparameterDimension;
        num_parallel_samples: number;
        scaling: boolean;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LogisticRegressionHyperparametersSpace
     */
    export interface LogisticRegressionHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        C: FloatHyperparameterDimension;
        multi_class: PredictionModelingParams.LogisticRegressionClassifierMultiClass;
        n_jobs: number;
        penalty: CategoricalHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibNaiveBayesGridParams
     */
    export interface MLLibNaiveBayesGridParams {
        enabled: boolean;
        lambda: FloatHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2ODeepLearningGridParams
     */
    export interface H2ODeepLearningGridParams {
        activation: string;
        dropout: boolean;
        enabled: boolean;
        epochs: number;
        epsilon: number;
        hidden: number[];
        hidden_dropout_ratios: number[];
        input_dropout_ratio: number;
        l1: number;
        l2: number;
        max_w2: number;
        momentum_ramp: number;
        momentum_stable: number;
        momentum_start: number;
        rate: number;
        rate_annealing: number;
        rate_decay: number;
        rho: number;
        use_adaptative: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibLogisticRegressionGridParams
     */
    export interface MLLibLogisticRegressionGridParams {
        enabled: boolean;
        enet_param: FloatHyperparameterDimension;
        max_iter: number;
        reg_param: FloatHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$TreeSelectionMode
     */
    export enum TreeSelectionMode {
        AUTO = 'auto',
        SQRT = 'sqrt',
        LOG2 = 'log2',
        NUMBER = 'number',
        PROP = 'prop'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SGDHyperparametersSpace
     */
    export interface SGDHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        alpha: FloatHyperparameterDimension;
        l1_ratio: number;
        loss: CategoricalHyperparameterDimension;
        max_iter: number;
        n_jobs: number;
        penalty: CategoricalHyperparameterDimension;
        tol: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibTreesEnsembleGridParams
     */
    export interface MLLibTreesEnsembleGridParams {
        cache_node_ids: boolean;
        checkpoint_interval: number;
        enabled: boolean;
        impurity: PredictionModelingParams.MLLibRfImpurity;
        max_bins: number;
        max_depth: IntegerHyperparameterDimension;
        max_memory_mb: number;
        min_info_gain: number;
        min_instance_per_node: number;
        num_trees: IntegerHyperparameterDimension;
        seed: number;
        step_size: FloatHyperparameterDimension;
        subsampling_rate: number;
        subset_strategy: PredictionModelingParams.MLLibRfFeatureSubsetStrategy;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$RidgeRegressionHyperparametersSpace
     */
    export interface RidgeRegressionHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        alpha: FloatHyperparameterDimension;
        alphaMode: PredictionModelingParams.RidgeSelectAlphaMode;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$VerticaLinearRegParams
     */
    export interface VerticaLinearRegParams {
        enabled: boolean;
        epsilon: number;
        maxIterations: number;
        optimizer: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GBTClassificationHyperparametersSpace
     */
    export interface GBTClassificationHyperparametersSpace extends PredictionModelingParams.GradientBoostedTreeHyperparametersSpace {}

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$DecisionTreeHyperparametersSpace
     */
    export interface DecisionTreeHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        criterion: CategoricalHyperparameterDimension;
        max_depth: IntegerHyperparameterDimension;
        min_samples_leaf: IntegerHyperparameterDimension;
        splitter: CategoricalHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$KNNAlgorithm
     */
    export enum KNNAlgorithm {
        AUTO = 'auto',
        KD_TREE = 'kd_tree',
        BALL_TREE = 'ball_tree',
        BRUTE = 'brute'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LassoSelectAlphaMode
     */
    export enum LassoSelectAlphaMode {
        MANUAL = 'MANUAL',
        AUTO_CV = 'AUTO_CV',
        AUTO_IC = 'AUTO_IC'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$AutoArimaSpace
     */
    export interface AutoArimaSpace extends PredictionModelingParams.HyperparametersSpace {
        D?: number | null;
        d?: number | null;
        information_criterion: CategoricalHyperparameterDimension;
        m: IntegerHyperparameterDimension;
        max_D: number;
        max_P: number;
        max_Q: number;
        max_d: number;
        max_order: number;
        max_p: number;
        max_q: number;
        method: CategoricalHyperparameterDimension;
        seasonal_test: CategoricalHyperparameterDimension;
        start_P: number;
        start_Q: number;
        start_p: number;
        start_q: number;
        stationary: boolean;
        test: CategoricalHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LarsHyperparametersSpace
     */
    export interface LarsHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        K: number;
        max_features: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GradientBoostedTreeHyperparametersSpace
     */
    export interface GradientBoostedTreeHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        learning_rate: FloatHyperparameterDimension;
        loss: CategoricalHyperparameterDimension;
        max_depth: IntegerHyperparameterDimension;
        max_feature_prop: FloatHyperparameterDimension;
        max_features: IntegerHyperparameterDimension;
        min_samples_leaf: IntegerHyperparameterDimension;
        n_estimators: IntegerHyperparameterDimension;
        selection_mode: PredictionModelingParams.TreeSelectionMode;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LassoHyperparametersSpace
     */
    export interface LassoHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        alpha: FloatHyperparameterDimension;
        alphaMode: PredictionModelingParams.LassoSelectAlphaMode;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GridSearchParams
     */
    export interface GridSearchParams {
        bayesianOptimizer: PredictionModelingParams.GridSearchParams.BayesianOptimizer;
        code: string;
        cvSeed: number;
        distributed: boolean;
        mode: PredictionModelingParams.GridSearchCrossValidationMode;
        nContainers: number;
        nFolds: number;
        nIter: number;
        nIterRandom: number;
        nJobs: number;
        randomized: boolean;
        seed: number;
        shuffleIterations: number;
        splitRatio: number;
        strategy: PredictionModelingParams.GridSearchParams.Strategy;
        stratified: boolean;
        timeout: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$KerasCodeParams
     */
    export interface KerasCodeParams extends PredictionModelingParams.GpuCodeParams {
        advancedFitMode: boolean;
        batchSize: number;
        buildCode: string;
        cachePreprocessedData: boolean;
        enabled: boolean;
        epochs: number;
        fitCode: string;
        gpuAllowGrowth: boolean;
        kerasInputs: string[];
        perGPUMemoryFraction: number;
        shuffleData: boolean;
        stepsPerEpoch: number;
        trainOnAllData: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibLinearRegressionGridParams
     */
    export interface MLLibLinearRegressionGridParams {
        enabled: boolean;
        enet_param: FloatHyperparameterDimension;
        max_iter: number;
        reg_param: FloatHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SeasonalLoessSpace
     */
    export interface SeasonalLoessSpace extends PredictionModelingParams.HyperparametersSpace {
        auto_low_pass: boolean;
        auto_trend: boolean;
        low_pass: IntegerHyperparameterDimension;
        low_pass_deg: CategoricalHyperparameterDimension;
        low_pass_jump: IntegerHyperparameterDimension;
        period: IntegerHyperparameterDimension;
        seasonal: IntegerHyperparameterDimension;
        seasonal_deg: CategoricalHyperparameterDimension;
        seasonal_jump: IntegerHyperparameterDimension;
        trend: IntegerHyperparameterDimension;
        trend_deg: CategoricalHyperparameterDimension;
        trend_jump: IntegerHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSTransformerSpace
     */
    export interface GluonTSTransformerSpace extends PredictionModelingParams.HyperparametersSpace {
        auto_num_batches_per_epoch: boolean;
        batch_size: number;
        context_length: IntegerHyperparameterDimension;
        distr_output: CategoricalHyperparameterDimension;
        dropout_rate: FloatHyperparameterDimension;
        epochs: number;
        full_context: boolean;
        inner_ff_dim_scale: IntegerHyperparameterDimension;
        model_dim: IntegerHyperparameterDimension;
        num_batches_per_epoch: number;
        num_heads: IntegerHyperparameterDimension;
        num_parallel_samples: number;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LeastSquareHyperparametersSpace
     */
    export interface LeastSquareHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        n_jobs: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$CustomPythonParams
     */
    export interface CustomPythonParams {
        code: string;
        enabled: boolean;
        name: string;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2ONaiveBayesGridParams
     */
    export interface H2ONaiveBayesGridParams {
        enabled: boolean;
        laplace: number;
        min_std_dev: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LogisticRegressionClassifierMultiClass
     */
    export enum LogisticRegressionClassifierMultiClass {
        MULTINOMIAL = 'multinomial',
        OVR = 'ovr'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSSimpleFeedForwardSpace
     */
    export interface GluonTSSimpleFeedForwardSpace extends PredictionModelingParams.HyperparametersSpace {
        auto_num_batches_per_epoch: boolean;
        batch_normalization: CategoricalHyperparameterDimension;
        batch_size: number;
        context_length: IntegerHyperparameterDimension;
        distr_output: CategoricalHyperparameterDimension;
        epochs: number;
        full_context: boolean;
        mean_scaling: CategoricalHyperparameterDimension;
        num_batches_per_epoch: number;
        num_hidden_dimensions: number[];
        num_parallel_samples: number;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GridSearchCrossValidationMode
     */
    export enum GridSearchCrossValidationMode {
        SHUFFLE = 'SHUFFLE',
        KFOLD = 'KFOLD',
        TIME_SERIES_KFOLD = 'TIME_SERIES_KFOLD',
        TIME_SERIES_SINGLE_SPLIT = 'TIME_SERIES_SINGLE_SPLIT',
        CUSTOM = 'CUSTOM'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$XGBoostHyperparametersSpace
     */
    export interface XGBoostHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        alpha: FloatHyperparameterDimension;
        base_score: number;
        booster: CategoricalHyperparameterDimension;
        colsample_bylevel: FloatHyperparameterDimension;
        colsample_bytree: FloatHyperparameterDimension;
        cpu_tree_method: string;
        early_stopping_rounds: number;
        enable_cuda: boolean;
        enable_early_stopping: boolean;
        gamma: FloatHyperparameterDimension;
        gpu_tree_method: string;
        impute_missing: boolean;
        lambda: FloatHyperparameterDimension;
        learning_rate: FloatHyperparameterDimension;
        max_delta_step: FloatHyperparameterDimension;
        max_depth: IntegerHyperparameterDimension;
        min_child_weight: FloatHyperparameterDimension;
        missing: number;
        n_estimators: number;
        nthread: number;
        objective: CategoricalHyperparameterDimension;
        scale_pos_weight: number;
        seed: number;
        subsample: FloatHyperparameterDimension;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2OGLMGridParams
     */
    export interface H2OGLMGridParams {
        alpha: number;
        beta_epsilon: number;
        enabled: boolean;
        family: string;
        lambda: number;
        max_iter: number;
        tweedie_variance_power: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSMQCNNSpace
     */
    export interface GluonTSMQCNNSpace extends PredictionModelingParams.HyperparametersSpace {
        auto_num_batches_per_epoch: boolean;
        batch_size: number;
        channels_seq: number[];
        context_length: IntegerHyperparameterDimension;
        decoder_mlp_dim_seq: number[];
        dilation_seq: number[];
        epochs: number;
        full_context: boolean;
        kernel_size_seq: number[];
        num_batches_per_epoch: number;
        scaling: boolean;
        seed: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$KNNHyperparametersSpace
     */
    export interface KNNHyperparametersSpace extends PredictionModelingParams.HyperparametersSpace {
        algorithm: PredictionModelingParams.KNNAlgorithm;
        distance_weighting: boolean;
        k: IntegerHyperparameterDimension;
        leaf_size: number;
        p: number;
    }
}

export namespace PredictionModelingParams.GridSearchParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GridSearchParams$Strategy
     */
    export enum Strategy {
        GRID = 'GRID',
        RANDOM = 'RANDOM',
        BAYESIAN = 'BAYESIAN'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GridSearchParams$BayesianOptimizer
     */
    export enum BayesianOptimizer {
        SCIKIT_OPTIMIZE = 'SCIKIT_OPTIMIZE'
    }
}