import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DeephubAbstractReportConfusionMatrixComponent, MatrixData } from '@features/deephub/computer-vision/report/confusion-matrix/deephub-computer-vision-report-confusion-matrix.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DeephubImageClassificationReportDataFetcherService } from '../services/deephub-image-classification-report-data-fetcher.service';
import { DeephubImageClassificationReportService, ImageClassificationReport } from '../services/deephub-image-classification-report.service';

@UntilDestroy()
@Component({
  selector: 'deephub-image-classification-report-confusion-matrix',
  templateUrl: '../../../computer-vision/report/confusion-matrix/deephub-computer-vision-report-confusion-matrix.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubImageClassificationReportConfusionMatrixComponent extends DeephubAbstractReportConfusionMatrixComponent<ImageClassificationReport, DeephubImageClassificationReportService> implements OnInit {
    constructor(
        imageClassificationService: DeephubImageClassificationReportService,
        private dataFetcher: DeephubImageClassificationReportDataFetcherService,
        formBuilder: FormBuilder
    ) {
        super(imageClassificationService, formBuilder);
    }

    matrixClick(clickedCell: { xIndex: number, yIndex: number} | null): void {
        this.dataFetcher.setFilteredPair({
            groundTruth: clickedCell ? this.reportService.getFilterCategory(clickedCell.yIndex, this.categories) : null,
            detection: clickedCell ? this.reportService.getFilterCategory(clickedCell.xIndex, this.categories) : null,
            ascending: false
        });
    }

    createMatrix(report: ImageClassificationReport): MatrixData {
        this.categories = report.categories;
        const matrixSize = this.categories.length;
        const categoryMap = new Map<string, number>();
        for (let i = 0; i < matrixSize; i++) {
            categoryMap.set(this.categories[i], i);
        }
        const newMatrix: number[][] = Array(matrixSize).fill(0).map(() => Array(matrixSize).fill(0));
        for (const className in report.perf.confusion_matrix.perActual) {
            const classId = categoryMap.get(className);
            if (classId === undefined) { // class not found, ignore it
                continue;
            }

            const predictedClasses = report.perf.confusion_matrix.perActual[className].perPredicted;
            for (const [predictedClass, count] of Object.entries(predictedClasses)) {
                const predictedId = categoryMap.get(predictedClass);
                if (predictedId === undefined) { // class not found, ignore it
                    continue;
                }

                newMatrix[predictedId][classId] = count;
            }
        }

        return {
            data: newMatrix,
            xCategories: this.categories,
            yCategories: this.categories,
            params: {
                filterVariablesWithoutValues: false,
                showAbsValues: true,
                showColors: true,
                showValues: false,
                threshold: 0
            }
        };
    }
}
