import { Component, OnInit } from "@angular/core";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, finalize, Subject, switchMap } from "rxjs";
import { LabelingReviewImagePathService } from "../services/labeling-review-image-path.service";

@UntilDestroy()
@Component({
    selector: 'review-image-feed-header',
    templateUrl: './review-image-feed-header.component.html',
    styleUrls: ['./review-image-feed-header.component.less'],

})
export class ReviewImageFeedHeaderComponent implements OnInit {
    resolvingConsensualRecords$ = new BehaviorSubject<boolean>(false);
    resolutionTrigger$ = new Subject<void>();
    defaultAutoResolveClass = 'icon-dku-validate-label';
    resolveClass$ = new BehaviorSubject<string>(this.defaultAutoResolveClass);
    nbLabeledImages$ = new BehaviorSubject<number>(0);
    constructor(
        public labelingReviewImagePathService: LabelingReviewImagePathService,
        public labelingService: LabelingService,
    ) { }
    ngOnInit(): void {
        this.labelingReviewImagePathService.itemPaths$.pipe(
            untilDestroyed(this)
        ).subscribe((paths) => {
            this.nbLabeledImages$.next(paths != null ? paths.length : 0);
        });

        this.resolutionTrigger$.pipe(
            switchMap(() => this.labelingService.resolveConsensualRecords()),
            untilDestroyed(this),
            finalize(() => this.resolvingConsensualRecords$.next(false))
        ).subscribe({
            next: () => {
                this.resolvingConsensualRecords$.next(false);
                this.resolveClass$.next('icon-ok');
                setTimeout(() => this.resolveClass$.next(this.defaultAutoResolveClass), 3000);
                this.labelingReviewImagePathService.first();
            },

        });
    }

    resolveConsensualRecords() {
        this.resolvingConsensualRecords$.next(true);
        this.resolutionTrigger$.next();
    }
}
