import type {ComputationResult} from './../computation-result';
import type {Computation} from './../computation';
import type {GroupingResult} from './../../grouping/grouping-result';
import type {Grouping} from './../../grouping/grouping';
import type {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.multivariate.PCA
 */
export interface PCA extends Computation._MultivariateComputation {
    projectionDim: number;
    projectionGrouping: Grouping;

    // PolyJson type
    type: 'pca';
}

export namespace PCA {
    export const type = 'pca';
}

/** @deprecated Use Computation.isPCA() */ export function isPCA(toTest: Computation): toTest is PCA { return PCA.type === toTest['type']; }

export namespace PCA {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.multivariate.PCA$PCAResult
     */
    export interface PCAResult extends _AvailableResult {
        eigenvalues: number[];
        eigenvectors: number[][];
        groups: GroupingResult;
        projections: number[][][];

        // PolyJson type
        type: 'pca';
    }

    export namespace PCAResult {
        export const type = 'pca';
    }

    /** @deprecated Use ComputationResult.isPCAResult() */ export function isPCAResult(toTest: ComputationResult): toTest is PCAResult { return PCAResult.type === toTest['type']; }
}