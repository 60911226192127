import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { LabelingColorService } from "../../../services/labeling-color.service";

@Component({
    selector: 'bbox-thumb',
    templateUrl: './bbox-thumb.component.html',
    styleUrls: ['./bbox-thumb.component.less']
})
export class BboxThumbComponent implements OnInit,  OnChanges {

    // Keep sync with .bbox-thumb-container css class
    readonly CONTAINER_WIDTH = 30;
    readonly CONTAINER_HEIGHT= 30;

    @Input() bboxWidth: number;
    @Input() bboxHeight: number;

    @Input() color: number[];
    
    scale: number | undefined;

    constructor(private labelingColorService: LabelingColorService) {
    }

    ngOnInit(): void {
        this.computeScale();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.bboxHeight && changes.bboxHeight.currentValue) ||
                    (changes.bboxWidth && changes.bboxWidth.currentValue)){
            this.computeScale();
        }
    }

    colorStr (original: number[], opacity=1): string {
        return this.labelingColorService.colorAsString(original, opacity);
    }

    private computeScale() {
        this.scale = Math.min(this.CONTAINER_WIDTH / this.bboxWidth, this.CONTAINER_HEIGHT / this.bboxHeight);
    }
}
