import { AfterViewInit, Component, ContentChild, ContentChildren, ElementRef, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChildren, ChangeDetectorRef, forwardRef, OnDestroy, Directive, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormArray, FormBuilder } from '@angular/forms';
import { Observable, merge, ReplaySubject } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import _ from 'lodash';
import { EditableListInputComponent } from '../editable-list-input/editable-list-input.component';
import { FormArrayRepeat } from '@utils/form-array-repeat';
import { PromptService } from '@shared/modals/prompt.service';
import { parse, unparse } from 'papaparse';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * Drag icon of an editable list. Can be used alone.
 */
@Component({
    selector: 'editable-list-drag',
    template: '<i class="editable-list__drag-icon icon-reorder"></i>',
    styleUrls: ['./editable-list.component.less']
})
export class EditableListDragComponent { }

/**
 * Arrow icon of an editable list. Can be used alone.
 */
@Component({
    selector: 'editable-list-arrow',
    template: '<i class="editable-list__arrow-icon icon-long-arrow-right"></i>',
    styleUrls: ['./editable-list.component.less']
})
export class EditableListArrowComponent { }

/**
 * Delete icon of an editable list. Can be used alone.
 */
@Component({
    selector: 'editable-list-delete',
    template: `<button type="button" class="btn btn--text btn--danger btn-icon editable-list__delete" (click)="onDelete.emit($event)" tabindex="-1" data-qa-editable-list-delete>
        <i class="icon-trash editable-list__delete"></i>
    </button>`,
    styleUrls: ['./editable-list.component.less']
})
export class EditableListDeleteComponent {
    @Output() onDelete: EventEmitter<any> = new EventEmitter();
}

/**
 * Template meant to be used in an editable list between the sort icon (if sortable) and the trash icon.
 * It adds styles for edition mode.
 */
@UntilDestroy()
@Component({
    selector: 'editable-list-template',
    template: '<ng-content></ng-content>',
    styleUrls: ['./editable-list.component.less'],
    host: {
        '[class.editable-list__template]': 'true',
        '[class.editable-list__template--editing]': 'editing'
    }
})
export class EditableListTemplateComponent implements AfterViewInit, OnDestroy {
    editing: boolean = false;
    @Output() onInputEnter: EventEmitter<any> = new EventEmitter();
    @ContentChildren(EditableListInputComponent) inputs: QueryList<EditableListInputComponent>;

    ngAfterViewInit() {
        const inputs$: Observable<QueryList<EditableListInputComponent>> = this.inputs.changes.pipe(startWith(this.inputs));

        inputs$.pipe(
            switchMap(inputs => merge(...inputs.map(input => input.onFocus))),
            untilDestroyed(this)
        ).subscribe(() => this.setEditionMode(true));

        inputs$.pipe(
            switchMap(inputs => merge(...inputs.map(input => input.onBlur))),
            untilDestroyed(this)
        ).subscribe(() => this.setEditionMode(false));

        inputs$.pipe(
            switchMap(inputs => merge(...inputs.map(input => input.onEnter))),
            untilDestroyed(this)
        ).subscribe((event: any) => this.handleEnter(event));
    }

    handleEnter(event: Event) {
        this.onInputEnter.emit(event);
    }

    setEditionMode(editing: boolean) {
        this.editing = editing;
    }

    ngOnDestroy(): void { }
}

/**
 * Most generic code to be inherited by any list with editable capabilities.
 */
@UntilDestroy()
@Directive()
export class EditableListBase implements ControlValueAccessor {
    items: Array<any> = [];
    itemsFormArray: FormArrayRepeat;

    @Input() addLabel: string = 'Add';
    @Input() sortable: boolean = false;
    @Input() hasDivider: boolean = true;
    @Input() focusOnEnter: boolean = true;
    @Input() fullWidthList: boolean = false;
    @Input() fullHeightList: boolean = true;
    @Input() disableAdd: boolean = false;
    @Input() disableRawEdit: boolean = true;
    @Input() useVirtualScroll: boolean = false;
    @Input() virtualScrollItemSize: number | null = null;
    @Input() virtualScrollHeight: number | null = null;
    @Output() onAdd = new EventEmitter<number>();
    @Output() onDelete = new EventEmitter<number>();
    @Output() onValidityChange = new EventEmitter<boolean>(true);
    @Output() onFocus = new EventEmitter<any>();
    @Output() onBlur = new EventEmitter<any>();
    @Output() onReorder = new EventEmitter<any>();

    onChange: Function = () => { };

    onTouched: Function = () => { };

    writeValue(obj: any): void {
        this.items = _.cloneDeep(obj) || [];
        if (this.items && this.items.length) {
            this.itemsFormArray && this.itemsFormArray.setValue(this.items);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}

/**
 * A basic list that enables sort, add and remove capabilities on a list of FormArrayRepeat items.
 * It uses common lists styles and helpers through:
 *
 * - editable-list-drag
 * - editable-list-arrow
 * - editable-list-delete
 * - editable-list-input
 * - editable-list-template
 *
 * It is meant to be used with a template which is provided with:
 * - item           an instance of the given itemsFormArray.
 * - index          its index.
 * - goToNext       a method that allows to focus the next row (or add it if none).

 * @example
  <editable-list [itemsFormArray]="attendeesFormArray" addLabel="Add Attendee" sortable="true" (itemsChange)="handleChange($event)" (onAdd)="handleAdd($event)" (onDelete)="handleDelete($event)">
      <ng-template let-item="item" let-goToNext="goToNext">
          <editable-list-template (onInputEnter)="goToNext()">
              <editable-list-input ngDefaultControl [inputControl]="item?.get('firstName')" placeholder="Attendee first name"></editable-list-input>
              <editable-list-input ngDefaultControl [inputControl]="item?.get('lastName')" placeholder="Attendee last name"></editable-list-input>
              <input type="checkbox" [formControl]="item?.get('wantGoodies')"/> Want goodies
          </editable-list-template>
      </ng-template>
  </editable-list>
 *
 * If you have the matching specific use cases, you probably want to use pre-templated components like key-values-list, values-list, credentials-list or connection-properties-list.
 * If you have a new use case, you can take inspiration from these lists.
 */
@Component({
    selector: 'editable-list',
    templateUrl: './editable-list.component.html',
    styleUrls: ['./editable-list.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditableListComponent),
            multi: true
        }
    ]
})
export class EditableListComponent extends EditableListBase implements OnInit {
    @ContentChild(TemplateRef, { static: false }) template: TemplateRef<any>;
    @ViewChildren('itemLi') itemsLis: QueryList<ElementRef>;
    @ViewChild('virtualScrollViewport') virtualScrollViewport: CdkVirtualScrollViewport;
    @Input() confirmDeletion: boolean = false;
    @Input() itemsFormArray: FormArrayRepeat = new FormArrayRepeat(() => {
        return this.formBuilder.group({
            item: this.formBuilder.control('Template item value', [])
        });
    });
    @Output() itemsChange = new EventEmitter<Array<Object>>();
    formBuilder: FormBuilder;

    rawItems: string;
    parsedRaw$ = new ReplaySubject<any>();
    inRawEditMode = false;
    isParsing = false;

    constructor(private changeDetectorRef: ChangeDetectorRef, fb: FormBuilder, private promptService: PromptService) {
        super();
        this.formBuilder = fb;
    }

    ngOnInit(): void {
        // Subscribe to any changes on the form
        this.itemsFormArray.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((formValue) => {
                this.handleChange(formValue);
                if (this.virtualScrollViewport) {
                    // notify virtual scroll component that the items have updated by creating new array object
                    this.itemsFormArray.controls = [...this.itemsControls];
                }
            });

        // Subscribe to form validity changes
        this.itemsFormArray.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => { this.onValidityChange.emit(this.itemsFormArray.valid); });

        if (!this.disableRawEdit) {
            this.parsedRaw$.subscribe({
                next: (result) => {
                    this.itemsFormArray.reset({
                        emitEvent: false
                    });
                    this.itemsFormArray.patchValue(result);
                    this.inRawEditMode = false;
                }
            })
        }
    }

    get itemsControls() { return (this.itemsFormArray as FormArray).controls; }
    get itemsArray() { return this.itemsFormArray.value; }

    /** Add item and triggers onAdd event */
    add() {
        if (this.virtualScrollViewport) {
            this.virtualScrollViewport.scrollToIndex(this.itemsControls.length - 1);
        }

        this.itemsFormArray.add();
        this.handleAdd(this.itemsFormArray.length - 1);
        // Focus newly added item
        window.setTimeout(() => {
            this.focus(this.itemsLis.length - 1);
        }, 100);
    }

    realDelete(index: number) {
        this.itemsFormArray.removeAt(index);
        this.handleDelete(index);
    }

    /** Delete item at given index and triggers onDelete event */
    delete(event: Event, index: number) {
        event.stopPropagation();
        if(!this.confirmDeletion) {
            this.realDelete(index);
        } else {
            this.promptService.confirm("Confirm deletion", "Are you sure you want to remove this item?")
                .then(res => {
                    if (res) {
                        this.realDelete(index);
                    }
                }).catch(() => { }); // silently ignoring the error raised when we cancel the deletion
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.itemsArray, this.getDataIndex(event.previousIndex), this.getDataIndex(event.currentIndex));
        this.itemsFormArray.setValue(this.itemsArray);
        this.onReorder.emit(event);
    }

    /** Focus the first input found at the given item index. */
    focus(index: number) {
        const itemLi = this.itemsLis && this.itemsLis.toArray()[index];
        const itemLiFirstInput = itemLi && itemLi.nativeElement.querySelector('input[type="text"]');
        itemLiFirstInput && itemLiFirstInput.focus();
    }

    /** Focus next item or create one if none. Meant to be used from components within the template. */
    enter(index: number) {
        return (event: Event) => {
            if (!this.focusOnEnter) {
                return;
            }

            event.stopPropagation();

            const renderedIndex = this.getRenderedIndex(index);

            if (index < this.itemsArray.length - 1) {
                this.focus(renderedIndex + 1);
            } else {
                this.add();
            }
        }
    }

    /** Convert JSON array of list items to CSV */
    enterRawEditMode() {
        this.rawItems = unparse(this.itemsArray, {
            header: false,
            newline: '\n'
        });

        this.inRawEditMode = true;
    }

    /** Convert CSV to JSON array of list items */
    parseRawData() {
        const columns = this.getFormKeys().join();
        this.isParsing = true;
        parse(columns + '\n' + this.rawItems, {
            header: true,
            skipEmptyLines: 'greedy', // ignore empty lines
            complete: (result: any) => this.parsedRaw$.next(result.data)
        });
    }

    /*
        For virtual scroll, the item index in the data array is not always the same as the
        index in the QueryList (DOM).
        
        Returns index of element in QueryList (currently visible items in DOM) given data source index of item
    */
    private getRenderedIndex(dataIndex: number) {
        return this.virtualScrollViewport ? dataIndex - this.virtualScrollViewport.getRenderedRange().start : dataIndex;
    }

    /*
        Returns index of element in data source list given rendered (DOM) index of item
    */
    private getDataIndex(renderedIndex: number) {
        return this.virtualScrollViewport ? renderedIndex + this.virtualScrollViewport.getRenderedRange().start : renderedIndex;
    }

    private getFormKeys() {
        let example = this.itemsArray[0];
        if (!this.itemsArray.length) {
            // add a temp item to get the JSON structure
            this.itemsFormArray.add();
            example = this.itemsArray[0];
            this.itemsFormArray.removeAt(0);
        }

        return Object.keys(example);
    }

    // EVENTS HANDLERS

    /* Triggered when the list has changed in length either through removal or addition or when the template requires it.
     * Provides the list of current items. */
    handleChange(items: Array<Object>) {
        this.itemsChange.emit(items);
    }

    /** Triggered when an item is added. Provides the item index. */
    handleAdd(index: number) {
        this.onAdd.emit(index);
    }

    /** Triggered when an item is deleted. Provides the item index. */
    handleDelete(index: number) {
        this.onDelete.emit(index);
    }
}
