import type {SimpleKeyValue} from './../../../../../../../dku-core/src/main/java/com/dataiku/dip/coremodel/simple-key-value';
import type {TaggableObjectsService} from './../server/services/taggable-objects-service';

/**
 * Generated from com.dataiku.dip.labeling.LabelingTask
 */
export interface LabelingTask extends TaggableObjectsService._TaggableObject {
    classes: SimpleKeyValue[];
    consensusStrategy: LabelingTask.ConsensusStrategy;
    dataColumn?: string | null;
    id: string;
    idColumn: string;
    inputs: {[key: string]: LabelingTask.InputRole};
    instructions: string;
    minNbAnnotatorsPerRecord: number;
    name: string;
    outputs: {[key: string]: LabelingTask.OutputRole};
    owner: string;
    permissions: LabelingTask.LabelingPermissionItem[];
    projectKey: string;
    type: LabelingTask.LabelingTaskType;
}

export namespace LabelingTask {
    /**
     * Generated from com.dataiku.dip.labeling.LabelingTask$LabelingTaskOutput
     */
    export interface LabelingTaskOutput {
        ref: string;
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTask$LabelingTaskInput
     */
    export interface LabelingTaskInput {
        ref: string;
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTask$ConsensusStrategy
     */
    export enum ConsensusStrategy {
        AVERAGE = 'AVERAGE',
        MAJORITY = 'MAJORITY'
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTask$LabelingTaskType
     */
    export enum LabelingTaskType {
        IMAGE_CLASSIFICATION = 'IMAGE_CLASSIFICATION',
        OBJECT_DETECTION = 'OBJECT_DETECTION',
        NAMED_ENTITY_EXTRACTION = 'NAMED_ENTITY_EXTRACTION'
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTask$LabelingPermissionItem
     */
    export interface LabelingPermissionItem {
        annotate: boolean;
        group: string;
        review: boolean;
        user: string;
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTask$InputRole
     */
    export interface InputRole {
        items: LabelingTask.LabelingTaskInput[];
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTask$OutputRole
     */
    export interface OutputRole {
        items: LabelingTask.LabelingTaskOutput[];
    }

    /**
     * Generated from com.dataiku.dip.labeling.LabelingTask$LabelingTaskListItem
     */
    export interface LabelingTaskListItem extends TaggableObjectsService._TaggableListItem {
        owner: string;
        type: LabelingTask.LabelingTaskType;
    }
}