<div class="labeling__annotate-main" *ngIf="labelingService.labelingTaskInfo$ | async as task">
    <div class="labeling__record-container">
        <labeling-error></labeling-error>
        <labeling-unusable-warning *ngIf="unusableTaskWarning$ | async as warning" [warning]="warning" [privileges]="privilegesOnTask"></labeling-unusable-warning>
        <ng-container *ngIf="(unusableTaskWarning$ | async) === null">
            <ng-container *ngIf="{
                currentLabel: currentLabel$ | async,
                imageURL: imageURL$ | async
            } as ctx">
                <ng-container [ngSwitch]="task.type" *ngIf="ctx.imageURL">
                    <ng-container *ngSwitchCase="LabelingTaskType.OBJECT_DETECTION" >
                        <object-detection-image-canvas
                            class="w100 h100 pad16"
                            [imagePath]="ctx.imageURL"
                            [label]="ctx.currentLabel"
                            (labelChange)="currentLabel$.next($event)"
                            [selectionModeAvailable]="true" 
                            *ngIf="(ctx.currentLabel | instanceOf: ObjectDetectionUILabel)">
                        </object-detection-image-canvas>
                    </ng-container>
                    <div class="w100 h100" *ngSwitchCase="LabelingTaskType.IMAGE_CLASSIFICATION">
                        <image-container [className]="'labeling__image'" [src]="ctx.imageURL" [scale]="3" [placeholderSize]="'large'"></image-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div class="labeling__right-panel">
        <div class="labeling__right-panel-section labeling__right-panel-top">
            <div class="labeling__control-buttons">
                <button class="btn btn--secondary labeling__right-panel-button" [disabled]="(noRecords$ | async) ||(indexInHistory$ | async) === 0" (click)="first()"><span>first</span><code class="keybind"><i class="icon-arrow-up"></i></code></button>
                <button class="btn btn--secondary labeling__right-panel-button" [disabled]="(noRecords$ | async) || (indexInHistory$ | async) === 0" (click)="previous()"><span>back</span><code class="keybind"><i class="icon-arrow-left"></i></code></button>
                <button class="btn btn--secondary labeling__right-panel-button" [disabled]="(hasNoNextRecord$ | async) || (currentLabel$ | async)?.annotations?.length" (click)="skip()"><span>skip</span><code class="keybind">TAB</code></button>
                <button class="btn btn--secondary labeling__right-panel-button" *ngIf="(isDirty$ | async) " [disabled]="(canSave$ | async) === false" (click)="saveAndNext()"><span>Save & Next</span><code class="keybind"><i class="icon-arrow-right"></i></code></button>
                <button class="btn btn--secondary labeling__right-panel-button" *ngIf="(isDirty$ | async) === false" [disabled]="(hasNoNextRecord$ | async) || (isLast$ | async)" (click)="next()"><span>Next</span><code class="keybind"><i class="icon-arrow-right"></i></code></button>
                <button class="btn btn--secondary labeling__right-panel-button" [disabled]="(hasNoNextRecord$ | async) || (isLast$ | async)" (click)="last()"><span>last</span><code class="keybind"><i class="icon-arrow-down"></i></code></button>
            </div>
            <div class="labeling__counter-container" *ngIf="!(noRecords$ | async) && (labelingService.classes$ | async)?.length">
                <div class="labeling__stat horizontal-flex" *ngIf="!(hasNoNextRecord$ | async)">
                    <ng-container *ngIf="labelingService.instructions$ | async as instructions">
                        <div>
                            <a [dkuPopoverTrigger]="instructionsPopover">
                                How to label?
                            </a>
                        </div>

                        <dku-popover #instructionsPopover>
                            <div class="labeling__instruction-popover">
                                <div class="labeling__instruction-popover-title">
                                    Instructions for labeling
                                </div>
                                {{ instructions }}
                            </div>
                        </dku-popover>
                    </ng-container>
                    <span [ngStyle]="{'margin-left': (labelingService.instructions$ | async) ? '20%' : '40%'}">Sample {{(indexInHistory$ | async)! + 1}} / <span class="labeling__sample-counter">{{(recordHistory$ | async)?.length}}</span></span>
                    <i class="labeling__copy-icon cursor-pointer mleft8" [class]="labelingService.copyClass$ | async" (click)="copyPermalink()" [matTooltip]="'Copy image link to clipboard'" [matTooltipPosition]="'above'"></i>
                </div>
                <div *ngIf="(hasNoNextRecord$ | async)">{{indexInHistory$ | async}} images annotated. No more images.</div>
            </div>
        </div>
        <div class="image-object-wrapper">
            <div class="labeling__right-panel-section oa mbot0" [class.labeling__right-panel-section--half]="task.type !== LabelingTaskType.IMAGE_CLASSIFICATION">
                <category-selector class="category-selector" [label]="currentLabel$ | async" (labelChange)="currentLabel$.next($event)" (categoryChange)="selectedCategory$.next($event)"></category-selector>
            </div>
            <annotate-annotation-list
                *ngIf="task.type !== LabelingTaskType.IMAGE_CLASSIFICATION"
                [skipped]="(hasNoNextRecord$ | async) === false && (hasBeenSkipped$ | async) ?? false"
                [label]="currentLabel$ | async"
                (labelChange)="currentLabel$.next($event)">
            </annotate-annotation-list>
        </div>
    </div>
</div>
