<div class="card-layout__section" [qa]="'eda-ts-series-summary'">
    <div class="card-layout__title">Summary</div>
    <div class="card-layout__content">
        <div class="card-layout__section card-layout__section--horizontal">
            <div class="card-layout__section">
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Count</th>
                                <td class="stats-table__cell">{{ boxPlot.countComplete }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Series mean</th>
                                <td class="stats-table__cell">{{ boxPlot.mean | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Series standard deviation</th>
                                <td class="stats-table__cell">{{ boxPlot.stdDev | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Series box plot</th>
                                <td class="stats-table__cell boxplot-cell">
                                    <embedded-box-plot
                                        [variableName]="seriesVariableName"
                                        [boxPlot]="boxPlot"
                                    ></embedded-box-plot>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-layout__section">
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Time variable</th>
                                <td class="stats-table__cell">{{ timeVariableName }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Min timestamp</th>
                                <td class="stats-table__cell">{{ minTimestamp }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Max timestamp</th>
                                <td class="stats-table__cell">{{ maxTimestamp }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">
                                    Detected time {{ nTimeSteps === 1 ? 'step' : 'steps' }}
                                </th>
                                <td class="stats-table__cell" [matTooltip]="totalTimeStepsTooltip">
                                    {{ timeStepsLabel }}
                                    <span *ngIf="showTimeStepsEllipsis">…</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
