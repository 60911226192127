import { ChangeDetectionStrategy, Component, HostListener, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { EMPTY_WORKSPACE, WORKSPACE_STOCK_COLORS } from "@features/workspaces/workspace.constants";
import { User } from "@features/workspaces/workspaces-migration";
import { WorkspacesError } from "@features/workspaces/workspaces.service";
import _ from 'lodash';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Workspace } from "src/generated-sources";

export enum EditTabs {
    GENERAL = 'general',
    PERMISSIONS = 'users',
}

interface EditWorkspaceModalOptions {
    workspace: Workspace;
    submit: (workspace: Workspace) => Observable<Workspace>;
    tabs?: EditTabs[];
    error: WorkspacesError;
}

@Component({
    selector: 'edit-workspace-modal',
    templateUrl: './edit-workspace-modal.component.html',
    styleUrls: ['./edit-workspace-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditWorkspaceModalComponent {
    EditTabs = EditTabs;

    editedWorkspace: Workspace;
    isEdition = false;
    title: string;
    tabs: EditTabs[];
    currentTabIndex = 0;
    canNext = false;
    canSubmit = false;
    opened = false;

    users$ = this.DataikuAPI.security.listUsers() as Observable<User[]>;
    groups$ = this.DataikuAPI.security.listGroups(false).pipe(map(groups => ['$$ALL_USERS$$', ...groups]));
    error?: WorkspacesError;

    // Markdown formatting is done through an AngularJS dialog, so we need to handle the closing of
    // EditWorkspaceModalComponent manually by making sure an AngularJS dialog is not opened.
    // To remove once the markdown has been migrated to Angular.
    @HostListener('document:keydown.escape', ['$event']) onEscHandler(event: KeyboardEvent) {
        // Checking if an AngularJS is not opened
        if (!document.querySelector('div.modal.dku-modal')) {
            this.dialogRef.close();
        }
    }

    constructor(
        private dialogRef: MatDialogRef<EditWorkspaceModalComponent, Workspace | null>,
        private DataikuAPI: DataikuAPIService,
        @Inject(MAT_DIALOG_DATA) private data: EditWorkspaceModalOptions,
    ) {
        this.error = data.error;
        if (data.workspace) {
            this.title = `Edit workspace "${this.data.workspace.displayName}"`;
            this.editedWorkspace = _.cloneDeep(this.data.workspace);
            this.isEdition = true;
        } else {
            this.title = 'Create a new workspace';
            this.editedWorkspace = {
                ...EMPTY_WORKSPACE,
                color: WORKSPACE_STOCK_COLORS[Math.floor(Math.random() * WORKSPACE_STOCK_COLORS.length)],
            };
        }
        this.tabs = this.data.tabs || [EditTabs.GENERAL, EditTabs.PERMISSIONS];

        // workspace-general-edition does not support transform style on its parents.
        // It must be displayed after the open animation
        this.dialogRef.afterOpened().subscribe(() => this.opened = true);
        // Clean up error on close
        this.dialogRef.beforeClosed().subscribe(() => this.error?.resetError());
    }

    onWorkspaceChange(changes: Partial<Workspace>): void {
        this.editedWorkspace = { ...this.editedWorkspace, ...changes };
        const isDirty = !_.isEqual(this.editedWorkspace, this.data.workspace);
        this.canNext = !!this.editedWorkspace.workspaceKey && !!this.editedWorkspace.displayName;
        this.canSubmit = isDirty && this.canNext;
    }

    onTabChange(index: number): void {
        this.currentTabIndex = index;
    }

    previousTab(): void {
        this.currentTabIndex = this.currentTabIndex > 0 ? this.currentTabIndex - 1 : this.tabs.length - 1;
    }

    nextTab(): void {
        this.currentTabIndex = (this.currentTabIndex + 1) % this.tabs.length;
    }

    confirm(): void {
        this.data.submit(this.editedWorkspace)
            .subscribe(workspace => {
                if (workspace) {
                    this.dialogRef.close(workspace);
                }
            });
    }

    cancel(): void {
        this.dialogRef.close(null);
    }
}
