import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Details } from '@core/dataiku-api/definitions/private-feature-store-api';
import { PromptService } from '@shared/modals/prompt.service';
import { FriendlyTimeDeltaShortPipe } from '@shared/pipes/date-pipes/friendly-time-delta-short.pipe';
import { EscapeHtmlPipe } from '@shared/pipes/escaping/escape-html.pipe';
import { fairAny } from 'dku-frontend-core';
import { NavigatorService } from 'src/generated-sources';
import { Column, Hit } from '../query-result-models';

export interface ColumnType {
  name: string
  label: string
}

export interface HitWithRelated extends Hit {
  splitInputsByType?: fairAny
  splitOutputsByType?: fairAny
}

@Component({
  selector: 'feature-group-details',
  templateUrl: './feature-group-details.component.html',
  styleUrls: ['./feature-group-details.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureGroupDetailsComponent implements OnChanges {
  @Input() selectedItem: HitWithRelated | null;
  @Input() details: Details;
  @Input() users: Record<string, string>;
  @Input() canManageExposedElementsInProject: Record<string, boolean>;
  @Input() projectSharingRequestsEnabled: Record<string, boolean>;
  @Output() removedFromFeatureStore: EventEmitter<Hit>;

  showSchema: boolean;
  showUsage: boolean;
  useInProjectTitle: string;

  columnTypes: ColumnType[];
  allMeanings: Record<string, string>;
  filterName: string;

  escapeHtml: EscapeHtmlPipe;
  friendlyTimeDeltaShort: FriendlyTimeDeltaShortPipe;

  constructor(
    @Inject('$rootScope') public $rootScope: fairAny,
    @Inject('$scope') public $scope: fairAny,
    @Inject('Navigator') private navigator: fairAny,
    @Inject("StateUtils") private stateUtils: fairAny,
    @Inject("ColumnTypeConstants") private columnTypeConstants: fairAny,
    @Inject("ExposedObjectsService") private exposedObjectsService: fairAny,
    @Inject("CatalogRefreshUtils") private catalogRefreshUtils: fairAny,
    @Inject('TypeMappingService') private readonly typeMappingService: fairAny,
    private promptService: PromptService,
  ) {
    this.removedFromFeatureStore = new EventEmitter();
    this.escapeHtml = new EscapeHtmlPipe();
    this.friendlyTimeDeltaShort = new FriendlyTimeDeltaShortPipe();
    this.columnTypes = this.columnTypeConstants.types;
    this.columnTypes.unshift({
      name: "",
      label: "All types"
    });
    this.allMeanings = { "": "All meanings", ...this.$rootScope.appConfig.meanings.labelsMap };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItem && this.selectedItem) {
      this.useInProjectTitle = this.selectedItem._source.isQuicklyShareable || this.projectSharingRequestsEnabled[this.selectedItem._source.projectKey] ? "Use in project" : "This Feature Group is not quickly shareable and access requests are not enabled for the source project";
      this.filterName = "";
      this.showSchema = true;
      this.showUsage = this.selectedItem._source.usedIn.length < 10;
      this.catalogRefreshUtils.computeDatasetInputsOutputsByType(this.selectedItem);
    }
  }

  itemType(): string {
    return this.typeMappingService.mapDatasetTypeToName(this.selectedItem?._source.type_raw);
  }

  formatItemName(): string {
    if (this.selectedItem?.highlight) {
      if (this.selectedItem.highlight.name?.length > 0) {
        return this.selectedItem.highlight.name[0];
      }
      if (this.selectedItem.highlight['name.raw']?.length > 0) {
        return this.selectedItem.highlight['name.raw'][0];
      }
    }
    // Comes from _source, encode HTML entities in order to display attributes like <stuff
    return this.escapeHtml.transform(this.selectedItem?._source.name || "");
  }

  linkToProject(projectKey: string): string {
    return this.stateUtils.href.project(
      projectKey
    );
  }

  formatProjectName(): string {
    if (this.selectedItem && this.selectedItem.highlight?.projectName?.length > 0) {
      return this.formatHighlighted(this.selectedItem.highlight.projectName[0]);
    }
    return this.selectedItem?._source.projectName || "";
  }

  hasShortDescHighlighted(): boolean {
    return (this.selectedItem?.highlight?.shortDesc?.length || 0) > 0;
  }

  formatShortDesc(): string {
    if (this.hasShortDescHighlighted()) {
      return this.formatHighlighted(this.selectedItem?.highlight.shortDesc[0] || "");
    }
    return this.selectedItem?._source.shortDesc || "";
  }

  hasDescriptionHighlighted(): boolean {
    return (this.selectedItem?.highlight?.description?.length || 0) > 0;
  }

  formatDescription(): string {
    if (this.hasDescriptionHighlighted()) {
      return this.formatHighlighted(this.selectedItem?.highlight.description[0] || "");
    }
    return this.selectedItem?._source.description || "";
  }

  formatHighlighted(highlighted: string): string {
    return highlighted
      .replace(/<em>/g, "((STARTEM))").replace(/<\/em>/g, "((ENDEM))")
      .replace(/\(\(STARTEM\)\)/g, '<em class="highlight">').replace(/\(\(ENDEM\)\)/g, "</em>");
  }

  hasHighlightedColumns(): boolean {
    return (this.selectedItem?.highlight?.column?.length || 0) > 0
  }

  highlightedColumns(): Column[] {
    const columns: Column[] = [];
    let strippedCols: string[] = [];
    if (this.selectedItem?.highlight?.column) {
      strippedCols = this.selectedItem.highlight.column.map(str => str.replace(/<\/?em>/g, ''));
    }
    this.selectedItem?._source.columns.forEach(column => {
      if (this.showColumn(column)) {
        const index = strippedCols.indexOf(column.name);
        if (index === -1) {
          columns.push(column);
        } else {
          columns.push({
            comment: column.comment,
            name: this.formatHighlighted(this.selectedItem?.highlight.column[index] || ""),
            type: column.type
          });
        }
      }
    });
    return columns;
  }

  showColumn(column: Column): boolean {
    return column.name.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase());
  }

  getMeaningLabel(columnName: string): string {
    if (this.details.meanings[columnName]) {
      return this.allMeanings[this.details.meanings[columnName]];
    } else {
      return "Auto-detect";
    }
  }

  useInProject(): void {
    if (this.selectedItem && (this.canManageExposedElementsInProject[this.selectedItem._source.projectKey] || this.selectedItem._source.isQuicklyShareable)) {
      this.exposedObjectsService.exposeSingleObject(
        this.selectedItem._type.toUpperCase(),
        this.selectedItem._source.id,
        this.selectedItem._source.name,
        this.selectedItem._source.projectKey
      );
    }
    else if (this.selectedItem && this.projectSharingRequestsEnabled[this.selectedItem._source.projectKey]) {
      this.exposedObjectsService.requestSharing(
        this.selectedItem._type.toUpperCase(),
        this.selectedItem._source.id,
        this.selectedItem._source.name,
        this.selectedItem._source.projectKey
      )
    }
  }

  disableUseInProject(): boolean {
    return !this.selectedItem || (
      !this.selectedItem._source.isQuicklyShareable && 
      !this.canManageExposedElementsInProject[this.selectedItem._source.projectKey] &&
      !this.projectSharingRequestsEnabled[this.selectedItem._source.projectKey]
    );
  }

  removeFromFeatureStore(): void {
    if (this.$rootScope.appConfig.globalPermissions.mayManageFeatureStore) {
      this.promptService.confirm('Remove from Feature Store', 'Remove this Feature Group from the Feature Store?')
        .then(_ => this.removedFromFeatureStore.emit(this.selectedItem!))
        .catch(() => { }); // silently ignoring the error raised when we cancel the removal
    }
  }

  showNavigator(projectKey: string, id: string): void {
    this.navigator.show(projectKey, "DATASET", id);
  }

  flowLink(projectKey: string, name: string): string {
    return this.stateUtils.href.flowLink({
      nodeType: "DATASET",
      projectKey: projectKey,
      name: name
    });
  }

  datasetLink(): string {
    return this.stateUtils.href.dssObject(
      this.selectedItem?. _type.toUpperCase(),
      this.selectedItem?._source.id,
      this.selectedItem?._source.projectKey
    );
  }

  removeFromFeatureStoreLabel(): string {
    return `Remove from Feature Store${!this.$rootScope.appConfig.globalPermissions.mayManageFeatureStore ? ' (you do not have the permission to perform this action)' : ''}`
  }

  lastBuildInfo(): string {
    if (this.selectedItem?._source.type_raw === "UploadedFiles") {
      return "Uploaded Dataset";
    }
    if (this.details.datasetFullInfo.lastBuild && !this.details.datasetFullInfo.currentBuildState) {
      const buildTime = this.friendlyTimeDeltaShort.transform(this.details.datasetFullInfo.lastBuild.buildEndTime);
      const buildStatus = this.details.datasetFullInfo.lastBuild.buildSuccess ? '<span class="text-success">Success</span>' : '<span class="text-error">Failed</span>';
      return `${buildTime} (${buildStatus})`;
    }
    return "N/A";
  }
}
