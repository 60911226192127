import { Injectable } from '@angular/core';
import { DeephubInteractiveScoringCellData, InteractiveScoringService } from '@features/deephub/computer-vision/report/interactive-scoring/interactive-scoring-data.service';
import { DeephubImageClassificationPredictionData } from '../services/deephub-image-classification-report-data-fetcher.service';

export class DeephubImageClassificationInteractiveScoringCellData extends DeephubInteractiveScoringCellData implements DeephubImageClassificationPredictionData {
    prediction: string;
    probabilities: Map<string, number>;

    public setScore(modelResponse: any) {
        this.score = modelResponse;
        this.prediction = this.score.prediction;
        this.probabilities = new Map(Object.entries(this.score).filter(([key, _]) => key.startsWith("proba_"))) as Map<string, number>;
    }
}

@Injectable({
  providedIn: 'root'
})
export class ImageClassificationInteractiveScoringService extends InteractiveScoringService {
    createCellData(image: string, file: string) {
        return new DeephubImageClassificationInteractiveScoringCellData(image, file);
    }
}
