<div class="image-classification h100">
    <div class="image-classification__results oa">
        <div class="paned-page-section paned-page-section--no-border oa">
            <deephub-image-classification-report-confusion-matrix></deephub-image-classification-report-confusion-matrix>
        </div>
    </div>
    <div class="image-classification__feed">
        <deephub-image-classification-report-image-feed></deephub-image-classification-report-image-feed>
    </div>
</div>
