import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Details } from '@core/dataiku-api/definitions/private-feature-store-api';
import { NavItems } from '@core/nav/nav-items.enum';
import { NavUniverses } from '@core/nav/nav-universes.enum';
import { TopNavService } from '@core/nav/top-nav.service';
import { WaitingService } from '@core/overlays/waiting.service';
import { fairAny } from 'dku-frontend-core';
import _ from 'lodash';
import { forkJoin, switchMap } from 'rxjs';
import { NavigatorService } from 'src/generated-sources';
import { FacetMap, SearchOptions } from './feature-store-models';
import { FeatureStoreService } from './feature-store.service';
import { Aggregation, Hit } from './query-result-models';

@Component({
  selector: 'feature-store',
  templateUrl: './feature-store.component.html',
  styleUrls: ['./feature-store.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureStoreComponent implements OnInit {

  users: Record<string, string>;
  projectNames: Record<string, string>;
  tagsMap: any;
  canManageExposedElementsInProject: Record<string, boolean>;
  projectSharingRequestsEnabled: Record<string, boolean>;
  searchQuery: string;
  sortedBy: string;
  reverseOrder: boolean;
  activeFacets: FacetMap;
  hits: Hit[];
  aggregationResults: Record<string, Aggregation>;
  selectedItem: Hit | null;
  details: Details;

  constructor(
    @Inject("TaggingService") private taggingService: fairAny,
    public featureStoreService: FeatureStoreService,
    private changeDetectorRef: ChangeDetectorRef,
    private topNav: TopNavService,
    private waitingService: WaitingService,
  ) {
    this.users = {};
    this.projectNames = {};
    this.canManageExposedElementsInProject = {};
    this.projectSharingRequestsEnabled = {};
    this.searchQuery = "";
    this.sortedBy = "score";
    this.reverseOrder = true;
    this.activeFacets = {};
    this.aggregationResults = {};
  }

  ngOnInit(): void {
    this.topNav.setLocation(NavUniverses.DSS_HOME, NavItems.FEATURE_STORE);
    this.topNav.setNoItem();
    this.taggingService.fetchGlobalTags();

    forkJoin([
      this.featureStoreService.listUsers(),
      this.featureStoreService.listProjects(),
      this.featureStoreService.listTags()
    ])
      .subscribe(([usersResponse, projectsResponse, tagsResponse]) => {
        usersResponse.forEach((user: any) => {
          this.users[user.login] = user.displayName;
        })
        projectsResponse.forEach(project => {
          this.projectNames[project.projectKey] = project.name;
          this.canManageExposedElementsInProject[project.projectKey] = project.canManageExposedElements;
          this.projectSharingRequestsEnabled[project.projectKey] = project.sharingRequestsEnabled;
        })
        this.tagsMap = tagsResponse;
        this.search();
      });
  }

  search(): void {
    this.featureStoreService.search(this.searchQuery, this.activeFacets)
      .subscribe(results => {
        if (!results.hits.hits.find(h => h._id === this.selectedItem?._id)) this.selectedItem = null;
        this.hits = this.sortHits(results.hits.hits);
        this.aggregationResults = results.aggregations;
        this.changeDetectorRef.markForCheck();
      });
  }

  sortHits(hits: Hit[]): Hit[] {
    if (!hits) {
      return hits;
    } else {
      const getSortAttribute = (h: Hit) => {
        if (this.sortedBy === "createdOn") return h._source.createdOn;
        else if (this.sortedBy === "lastModifiedOn") return h._source.lastModifiedOn;
        else return h._score;
      }
      // using slice() to change the array reference
      const sorted = hits.slice().sort((a: Hit, b: Hit) => getSortAttribute(b) - getSortAttribute(a))
      return this.reverseOrder || this.sortedBy === "score" ? sorted : sorted.reverse();
    }
  }

  newQuery(searchOptions: SearchOptions) {
    this.sortedBy = searchOptions.sortBy;
    this.reverseOrder = searchOptions.reverse;
    if (searchOptions.query !== this.searchQuery) {
      this.searchQuery = searchOptions.query;
      this.search();
    } else {
      this.hits = this.sortHits(this.hits);
      this.changeDetectorRef.markForCheck();
    }
  }

  newFacetSelection(facetMap: FacetMap) {
    if (!_.isEqual(facetMap, this.activeFacets)) {
      this.activeFacets = facetMap;
      this.search();
    }
  }

  newItemSelected(item: Hit): void {
    this.featureStoreService.getDetails(
      item._source.projectKey,
      item._source.id,
      item._source.usedIn
    ).subscribe((details) => {
      this.details = details;
      this.selectedItem = item;
      this.changeDetectorRef.markForCheck();
    })
  }

  resetFiltersRequest(): void {
    this.searchQuery = "";
    this.activeFacets = {};
    this.search();
  }

  removedFromFeatureStore(item: Hit): void {
    this.featureStoreService.removeFromFeatureStore(item)
      .pipe(
        switchMap(_ => this.featureStoreService.flush()),
        this.waitingService.bindSpinner(),
      )
      .subscribe(_ => this.search());
  }
}
