import { Label, LabelingAnswer, LabelingRegion, LabelingTask } from "src/generated-sources";
import { UIAnnotation, UIBoundingBox, UIClassificationAnnotation } from "./annotation";
import { ClassificationAnnotationRegion, ObjectDetectionRegion } from "./labeling-region";

export function createFromRegion(r: LabelingRegion, taskType: LabelingTask.LabelingTaskType): AnnotationGroup {
    switch(taskType) {
        case LabelingTask.LabelingTaskType.OBJECT_DETECTION:
            return BoundingBoxGroup.createFromRegion(r);
        case LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION:
            return ClassificationAnnotationGroup.createFromRegion(r);
        default:
            throw('Not supported for ' + taskType);
    }
}

export abstract class AnnotationGroup {
    annotations: UIAnnotation[];
    modifiedByReviewer: boolean;
    selected: boolean;

    hasConflict(nbAnnotators: number): boolean {
        return this.conflictReasons(nbAnnotators).length !== 0;
    }

    conflictReasons(nbAnnotators: number): string[] {
        const reasons: string[] = [];

        if (this.annotations.length === 0) {
            return reasons;
        }
        if (this.hasConflictingCategory()) {
            reasons.push('No consensus on the category');
        }
        if (this.hasMissingAnnotator(nbAnnotators)) {
            reasons.push('Some annotators missed this object. Accept an answer to resolve');
        }

        return reasons;
    }

    hasMissingAnnotator(minNbAnnotators: number): boolean {
        return !this.modifiedByReviewer && new Set(this.annotations.map(object => object.annotator)).size < minNbAnnotators;
    }

    hasConflictingCategory(): boolean {
        return this.annotations.map(object => object.category).some((category, _, categories) => category != categories[0])
    }

    hasMissingCategory(): boolean {
        return this.annotations.map(object => object.category).some(category => category === undefined)
    }

    getConsensusCategory(): string | undefined {
        if (this.annotations.map(object => object.category).every((category, _, categories) => category === categories[0])) {
            if (this.annotations[0]) {
                return this.annotations[0].category!
            }
        }
        return undefined;
    }

    abstract computeAverageObject(): UIAnnotation;
}

export class ClassificationAnnotationGroup extends AnnotationGroup {
    annotations: UIClassificationAnnotation[];

    constructor(annotations: UIClassificationAnnotation[], modifiedByReviewer?: boolean) {
        super();
        this.annotations = annotations;
        this.selected = true;
        this.modifiedByReviewer = modifiedByReviewer || false;
    }

    computeAverageObject(): UIClassificationAnnotation {
        return this.annotations[0];
    }

    static createFromRegion(region: LabelingRegion) {
        let annotations = (region as ClassificationAnnotationRegion).elements.map(e => {
            return new UIClassificationAnnotation(e.annotation.category, undefined, e.annotator)
        });

        return new ClassificationAnnotationGroup(annotations);
    }
}

export class BoundingBoxGroup extends AnnotationGroup {
    // keep sync with com.dataiku.dip.labeling.objectdetection.SimpleObjectDetectionRegionDispatcher#BBOX_CONFLICT_THRESHOLD_IOU
    private readonly BBOX_CONFLICT_THRESHOLD_IOU = 0.7; // TODO: @labeling parametrize

    annotations: UIBoundingBox[];

    constructor(annotations: UIBoundingBox[], selected?: boolean, modifiedByReviewer?: boolean) {
        super();
        this.annotations = annotations;
        this.selected = selected || false;
        this.modifiedByReviewer = modifiedByReviewer || false;
    }

    static createFromRegion(region: LabelingRegion) {
        let bboxes = (region as ObjectDetectionRegion).elements.map(e => {
            const boundingBox = e.annotation;

            return new UIBoundingBox({
                width: boundingBox.bbox.width,
                top: boundingBox.bbox.y0,
                left: boundingBox.bbox.x0,
                height: boundingBox.bbox.height,
                category: boundingBox.category,
                annotator: e.annotator,
                pinned: true
            })
        });
        return new BoundingBoxGroup(bboxes);
    }

    computeAverageObject(): UIBoundingBox {
        return new UIBoundingBox({
            width: this.annotations.map(bbox => bbox.width).reduce((a, b) => a + b, 0) / this.annotations.length,
            height: this.annotations.map(bbox => bbox.height).reduce((a, b) => a + b, 0) / this.annotations.length,
            top: this.annotations.map(bbox => bbox.top).reduce((a, b) => a + b, 0) / this.annotations.length,
            left: this.annotations.map(bbox => bbox.left).reduce((a, b) => a + b, 0) / this.annotations.length,
            category: this.annotations[0].category!,
            annotator: "",
            pinned: false
        })
    }

    conflictReasons(nbAnnotators: number): string[] {
        const reasons = super.conflictReasons(nbAnnotators);

        if (this.hasConflictingIoU()) {
            reasons.push('Boxes don\'t overlap enough');
        }

        return reasons;
    }

    hasConflictingIoU(): boolean {
        return this.annotations.some((object, _, annotations) => !object.empty() && object.iou(annotations[0].bbox) < this.BBOX_CONFLICT_THRESHOLD_IOU)
    }
}
