import { Component, Input } from '@angular/core';
import { PainterService } from "@shared/services/painter.service";
import { SampleDataFetcherService } from '@shared/services/sample-data-fetcher.service';
import { ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeephubImageClassificationDesignPainterService } from '../services/deephub-image-classification-design-painter.service';
import { DeephubImageClassificationDesignDataFetcherService } from '../services/deephub-image-classification-design-data-fetcher.service';
import { PredictionMLTask } from 'src/generated-sources';
import { AbstractDeephubDesignDataFetcherService } from '@features/deephub/computer-vision/design/services/abstract-deephub-design-data-fetcher.service';

@Component({
    selector: 'deephub-image-classification-design-image-feed',
    templateUrl: './deephub-image-classification-design-image-feed.component.html',
    providers: [
        ColorMapContextService,
        DeephubImageClassificationDesignPainterService,
        DeephubImageClassificationDesignDataFetcherService,
        { provide: ImagesDataFetcherService, useExisting: DeephubImageClassificationDesignDataFetcherService },
        { provide: SampleDataFetcherService, useExisting: DeephubImageClassificationDesignDataFetcherService },
        { provide: PainterService, useExisting: DeephubImageClassificationDesignPainterService },
        { provide: AbstractDeephubDesignDataFetcherService, useExisting: DeephubImageClassificationDesignDataFetcherService },
    ]
})
export class DeephubImageClassificationDesignImageFeedComponent {
    @Input() mltask: PredictionMLTask.DeepHubPredictionMLTask;
    @Input() selectedCategories?: string[];
    // Explicitly pass pathColumn and managedFolderSmartId to trigger change from Angular
    @Input() pathColumn: string;
    @Input() managedFolderSmartId: string;
};
