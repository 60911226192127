<div>
    <div class="image-metadata__columns" *ngFor="let column of image.allColumns">
        <div class="image-metadata__columns-name mx-textellipsis" [title]="column.name">
            {{column.name}}
        </div>
        <div class="image-metadata__columns-content mx-textellipsis" [title]="column.content">
            {{column.content}}
        </div>
    </div>
    <div *ngIf="image.allColumns.length === 0">No metadata</div>
</div>
