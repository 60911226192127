<div class="labeling-data-tab">
    <labeling-error></labeling-error>
    <h1>Data</h1>
    <div class="section">
        <div *ngIf="managedFolder$ | async as managedFolder" class="labeling-data-tab__managed-folder-link">
            Images from <a (click)="goToManagedFolder(managedFolder)"><i class="icon-folder-open universe-color managed_folder"></i>{{ managedFolder.label }}</a>
        </div>
        <form [formGroup]="form" *ngIf="metadataColumns$ | async as columns" class="form-inline labeling-data-tab__form">
            <label class="control-label labeling-data-tab__path-column-label ">
                Path column
            </label>
            <dku-bs-select-form-control
                formControlName="idColumn"
                ngOptions="col for col in list"
                [list]="columns"
                [dataLiveSearch]="true"
                [layout]="'list'">
            </dku-bs-select-form-control>
            <span class="help-inline">Column containing the path to the images</span>
            <div *ngIf="form.controls.idColumn.invalid" class="alert alert-error mtop8">
                No path column is defined. Please select one or the labeling task will not be usable
            </div>
        </form>
    </div>
    <image-feed [imageHeight]="200" [itemsPerRow]="4" (imageClick)="openModal($event)" class="labeling-data-tab__image-feed"></image-feed>
</div>
