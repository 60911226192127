import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Column } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeepHubColumnFormat } from 'src/generated-sources';

@Component({
    selector: 'deephub-design-image-feed-modal',
    templateUrl: './deephub-design-image-feed-modal.component.html',
    styleUrls: ['./deephub-design-image-feed-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubDesignImageFeedModalComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            imageId: number,
            columns: Column[]
        },
        public colorMapService: ColorMapContextService
    ) {}
}
