import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { CardWizardVariable } from "@features/eda/card-models";
import { SampleContextService } from "@features/eda/sample-context.service";
import { CardWizardService } from "@features/eda/worksheet/card-wizard/card-wizard.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FormArrayRepeat } from "@utils/form-array-repeat";
import { observeFormControl } from "@utils/form-control-observer";
import { toggleFormControl } from "@utils/toggle-form-control";
import { combineLatestObject } from "dku-frontend-core";
import { combineLatest, EMPTY, Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged, map, mergeScan, startWith, switchMap } from "rxjs/operators";
import { ACFPlotCard, Card, isACFPlotCard, isUnitRootTestADFCard, isUnitRootTestCard, isUnitRootTestKPSSCard, isUnitRootTestZACard, ListMostFrequentValues, PACF, TimeSeriesCard, UnitRootTestADF, UnitRootTestADFCard, UnitRootTestKPSS, UnitRootTestKPSSCard, UnitRootTestZA, UnitRootTestZACard } from "src/generated-sources";

type PartialIdentifier = Partial<TimeSeriesCard.TimeSeriesIdentifier>;
type SeriesIdValuesSuggestions = { [key: string]: string[] };
type SeriesIdVariablesSuggestions = CardWizardVariable[][];
type UIData = Observable<{
    selectableColumns: SeriesIdVariablesSuggestions,
    suggestedValues: SeriesIdValuesSuggestions,
}>;

@UntilDestroy()
@Component({
    selector: 'time-series-card-config',
    templateUrl: './time-series-card-config.component.html',
    styleUrls: [
        './time-series-card-config.component.less',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSeriesCardConfigComponent implements OnChanges, OnDestroy, ErrorContext {
    @Input() params: TimeSeriesCard;
    params$ = new ReplaySubject<TimeSeriesCard>(1);
    @Output() paramsChange = new EventEmitter<TimeSeriesCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    configForm: FormGroup;
    seriesIdColumnsForm: FormArrayRepeat;
    private allVariables$: Observable<CardWizardVariable[]>;
    seriesVariables$: Observable<CardWizardVariable[]>;
    timeVariables$: Observable<CardWizardVariable[]>;

    error?: APIError | null;
    uiData$: UIData;

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService,
        private changeDetectorRef: ChangeDetectorRef,
        private sampleContextService: SampleContextService,
    ) {
        this.seriesIdColumnsForm = new FormArrayRepeat(
            () => {
                return this.fb.group({
                    column: this.fb.control(null, Validators.required),
                    value: this.fb.control(null, Validators.required),
                });
            },
            [ Validators.required, Validators.minLength(1) ]
        );

        this.configForm = this.fb.group({
            // Fields that are common to every time series card
            seriesColumn: this.fb.control(null, Validators.required),
            timeColumn: this.fb.control(null, Validators.required),

            // Long format fields
            useLongFormat: this.fb.control(null, Validators.required),
            seriesIdColumns: this.seriesIdColumnsForm,

            // Type of the card to configure (not editable in the UI)
            type: this.fb.control(null, Validators.required),

            // Number of lags (common setting to unit root tests and acf/pacf)
            autoComputeLags: this.fb.control(null, Validators.required),
            nLags: this.fb.control(
                { value: null, disabled: true },
                [ Validators.required, Validators.min(1) ],
            ),

            adfOptions: this.fb.group({
                regressionMode: this.fb.control(null, Validators.required),
            }),

            zaOptions: this.fb.group({
                regressionMode: this.fb.control(null, Validators.required),
            }),

            kpssOptions: this.fb.group({
                regressionMode: this.fb.control(null, Validators.required),
            }),

            acfPlotOptions: this.fb.group({
                showSummary: this.fb.control(null, Validators.required),
                isPartial: this.fb.control(null, Validators.required),
                adjusted: this.fb.control(null, Validators.required),
                pacfMethod: this.fb.control(null, Validators.required),
            }),
        });

        toggleFormControl(
            this.configForm.controls.adfOptions,
            this.configForm.controls.type,
            UnitRootTestADFCard.type
        );

        toggleFormControl(
            this.configForm.controls.zaOptions,
            this.configForm.controls.type,
            UnitRootTestZACard.type
        );

        toggleFormControl(
            this.configForm.controls.kpssOptions,
            this.configForm.controls.type,
            UnitRootTestKPSSCard.type
        );

        toggleFormControl(
            this.configForm.controls.acfPlotOptions,
            this.configForm.controls.type,
            ACFPlotCard.type
        );

        toggleFormControl(
            this.configForm.controls.seriesIdColumns,
            this.configForm.controls.useLongFormat,
            true
        );

        toggleFormControl(
            this.configForm.controls.autoComputeLags,
            this.configForm.controls.type,
            (type: Card["type"]) =>
                type === ACFPlotCard.type ||
                type === UnitRootTestADFCard.type ||
                type === UnitRootTestZACard.type ||
                type === UnitRootTestKPSSCard.type
        );

        toggleFormControl(
            this.configForm.controls.nLags,
            this.configForm.controls.autoComputeLags,
            false
        );

        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(formValue => {
                let newCard: TimeSeriesCard = {
                    ...this.params,
                    seriesColumn: formValue.seriesColumn,
                    timeColumn: formValue.timeColumn,
                    seriesIdentifiers: formValue.seriesIdColumns ?? [],
                };

                const nLags = formValue.nLags ?? null;

                if (isUnitRootTestADFCard(newCard)) {
                    newCard = {
                        ...newCard,
                        ...formValue.adfOptions,
                        nLags,
                    };
                } else if (isUnitRootTestZACard(newCard)) {
                    newCard = {
                        ...newCard,
                        ...formValue.zaOptions,
                        nLags,
                    };
                } else if (isUnitRootTestKPSSCard(newCard)) {
                    newCard = {
                        ...newCard,
                        ...formValue.kpssOptions,
                        nLags,
                    };
                } else if (isACFPlotCard(newCard)) {
                    newCard = {
                        ...newCard,
                        ...formValue.acfPlotOptions,
                        nLags,
                    };
                }

                this.paramsChange.emit(newCard);
        });

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.validityChange.emit(this.configForm.valid);
            });

        const cardType$ = this.params$.pipe(
            map(params => params.type),
            distinctUntilChanged(),
        );

        this.allVariables$ = cardType$.pipe(
            switchMap(type => this.cardWizardService.availableVariables(type))
        );

        this.seriesVariables$ = cardType$.pipe(
            switchMap(type => this.cardWizardService.availableVariables(type, { isSeriesVariable: true }))
        );

        this.timeVariables$ = cardType$.pipe(
            switchMap(type => this.cardWizardService.availableVariables(type, { isTimeVariable: true }))
        );

        const selectableColumns$ = combineLatest([observeFormControl(this.configForm), this.allVariables$]).pipe(
            map(([formValue, allVariables])=> {
                const { seriesColumn, timeColumn } = formValue;
                const seriesIdColumns: PartialIdentifier[] = formValue.seriesIdColumns ?? [];
                const seriesIdColumnsNames = seriesIdColumns.map(({ column }) => column?.name);

                return seriesIdColumns.map(current =>
                    allVariables.map(variable => {
                        // A column should not be selected more than once, so
                        // let's disable every selected columns.
                        const disabled = variable.name === seriesColumn?.name
                            || variable.name === timeColumn?.name
                            || (seriesIdColumnsNames.includes(variable.name) && variable.name !== current.column?.name);

                        return {
                            ...variable,
                            disabled,
                        };
                    })
                );
            })
        );

        const suggestedValues$ = observeFormControl<PartialIdentifier[]>(this.seriesIdColumnsForm).pipe(
            switchMap(ids => ids.map(id => id.column?.name)),
            mergeScan((cache, columnName) => {
                if(!columnName || cache[columnName]) {
                    return EMPTY;
                }

                return this.sampleContextService.runInteractiveQuery({
                    type: ListMostFrequentValues.type,
                    column: columnName,
                    maxValues: 100,
                }).pipe(
                    catchAPIError(this),
                    map(result => ({
                        ...cache,
                        [columnName]: result.values ?? [],
                    }))
                )
            }, {} as SeriesIdValuesSuggestions),
            startWith({})
        );

        this.uiData$ = combineLatestObject({
            selectableColumns: selectableColumns$,
            suggestedValues: suggestedValues$,
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params == null) {
            return;
        }

        this.params$.next(this.params);
        const card = this.params;
        const seriesIdentifiers = card.seriesIdentifiers ?? [];
        const useLongFormat = seriesIdentifiers.length > 0;

        this.configForm.patchValue({
            type: card.type,
            seriesColumn: card.seriesColumn,
            timeColumn: card.timeColumn,
            useLongFormat,
            seriesIdColumns: seriesIdentifiers,
        });

        if (isUnitRootTestADFCard(card)) {
            this.configForm.controls.adfOptions.patchValue({
                regressionMode: card.regressionMode,
            });
        } else if (isUnitRootTestZACard(card)) {
            this.configForm.controls.zaOptions.patchValue({
                regressionMode: card.regressionMode,
            });
        } else if (isUnitRootTestKPSSCard(card)) {
            this.configForm.controls.kpssOptions.patchValue({
                regressionMode: card.regressionMode,
            });
        } else if (isACFPlotCard(card)) {
            this.configForm.controls.acfPlotOptions.patchValue({
                showSummary: card.showSummary,
                isPartial: card.isPartial,
                adjusted: card.adjusted,
                pacfMethod: card.pacfMethod,
            });
        }

        if (isACFPlotCard(card) || isUnitRootTestCard(card)) {
            this.configForm.patchValue({
                autoComputeLags: card.nLags == null,
                nLags: card.nLags,
            });
        } else {
            this.configForm.patchValue({
                autoComputeLags: null,
                nLags: null,
            });
        }
    }

    ngOnDestroy(): void {
        // required by @UntilDestroy
    }

    adfRegressionOptions = [
        {
            name: 'Constant only',
            value: UnitRootTestADF.RegressionMode.CONSTANT_ONLY,
        },
        {
            name: 'Constant, linear trend',
            value: UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_TREND,
        },
        {
            name: 'Constant, linear and quadratic trend',
            value: UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_QUADRATIC_TREND,
        },
        {
            name: 'No constant, no trend',
            value: UnitRootTestADF.RegressionMode.NO_CONSTANT_NO_TREND,
        },
    ];

    zaRegressionOptions = [
        {
            name: 'Constant only',
            value: UnitRootTestZA.RegressionMode.CONSTANT_ONLY,
        },
        {
            name: 'Trend only',
            value: UnitRootTestZA.RegressionMode.TREND_ONLY,
        },
        {
            name: 'Constant with trend',
            value: UnitRootTestZA.RegressionMode.CONSTANT_WITH_TREND,
        },
    ];

    kpssRegressionOptions = [
        {
            name: 'Constant only',
            value: UnitRootTestKPSS.RegressionMode.CONSTANT,
        },
        {
            name: 'Constant with trend',
            value: UnitRootTestKPSS.RegressionMode.CONSTANT_WITH_TREND,
        },
    ];

    get isPartialACF(): boolean {
        const acfPlotOptions = this.configForm.controls.acfPlotOptions as FormGroup;
        return acfPlotOptions.controls.isPartial.value === true;
    }

    pacfMethodOptions = [
        {
            name: 'Yule-Walker',
            value: PACF.Method.YULE_WALKER,
        },
        {
            name: 'Regression on lags and on constant',
            value: PACF.Method.OLS,
        },
        {
            name: 'Regression on lags using bias adjustment',
            value: PACF.Method.OLS_UNBIASED,
        },
        {
            name: 'Levinson-Durbin recursion',
            value: PACF.Method.LEVINSON_DURBIN,
        },
    ];

    get useLongFormat(): boolean {
        return this.configForm.controls.useLongFormat.value === true;
    }

    get isAutoComputeLagsEnabled(): boolean {
        return this.configForm.controls.autoComputeLags.enabled;
    }

    get autoComputeLags(): boolean {
        return this.configForm.controls.autoComputeLags.value === true;
    }

    pushError(error: APIError | null) {
        this.error = error;
        this.changeDetectorRef.markForCheck();
    }
}
