import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, skipWhile, switchMap } from 'rxjs/operators';
import { LabelingTask } from 'src/generated-sources';


enum LabelingRight {
	ANNOTATE='ANNOTATE',
	REVIEW='REVIEW',
}
@UntilDestroy()
@Component({
	selector: 'labeling-permissions',
	templateUrl: './labeling-permissions.component.html',
	styleUrls: ['./labeling-permissions.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelingPermissionsComponent implements OnChanges, OnInit {
	@Input() labelingTask: LabelingTask;
	@Output() partialTaskChange = new EventEmitter<Partial<LabelingTask>>();

	private readonly DEFAULT_RIGHT = LabelingRight.ANNOTATE;

	private formLoaded = false;
	private permissions$ = new ReplaySubject<LabelingTask.LabelingPermissionItem[]>(1);
	private initForm = new ReplaySubject<LabelingTask.LabelingPermissionItem[]>(1);

	PERMISSION_CHOICES = [
		{
			key: LabelingRight.ANNOTATE,
			desc: 'Can annotate data'
		},
		{
			key: LabelingRight.REVIEW,
			desc: 'Can annotate data and review annotations'
		},
	]

	newPermissionForm = this.fb.group({
		newUser: this.fb.control(null),
		newGroup: this.fb.control(null),
	});

	permissions = this.fb.array([]);

    form = this.fb.group({
    	permissions: this.permissions
    });

	unassignedGroups$: Observable<string[]>;
	unassignedUsers$: Observable<string[]>;

	constructor(private fb: FormBuilder, private DataikuAPI: DataikuAPIService) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.labelingTask && changes.labelingTask.currentValue && !this.formLoaded) {
			this.permissions$.next(changes.labelingTask.currentValue.permissions);
			this.initForm.next(changes.labelingTask.currentValue.permissions);
		}
	}

	ngOnInit() {
		const allGroups$ = this.DataikuAPI.security.listGroups(false).pipe(shareReplay(1));
		const allUsers$ = this.DataikuAPI.security.listUsers().pipe(shareReplay(1));
		// TODO: add User to J2TS config?

		this.initForm.pipe(
			switchMap((permissions) => {
				return allUsers$.pipe(
					map((allUsers) => [permissions, allUsers]  as const)
				)
			}),
			untilDestroyed(this)
		).subscribe(([permissions, allUsers]) => {
			for (const perm of permissions) {
				// TODO: Don't send event (option available in v12)
				this.permissions.push(this.permissionToFormGroup(perm, allUsers));
			}
			this.formLoaded = true;
		});

		this.unassignedGroups$ = combineLatest([this.permissions$, allGroups$]).pipe(
			map(([permissions, allGroups]) => {
				const assignedGroups = permissions.map((perm) => perm.group);
				return allGroups.filter((grp) => assignedGroups.indexOf(grp) === -1);
			}),
		);

		this.unassignedUsers$ = combineLatest([this.permissions$, allUsers$]).pipe(
			map(([permissions, allUsers]) => {
				const assignedUsers = permissions.map((perm) => perm.user);
				return allUsers.filter((usr: any) => assignedUsers.indexOf(usr.login) === -1);
			}),
		);

		this.permissions.valueChanges.pipe(
			skipWhile(() => !this.formLoaded),
			untilDestroyed(this)
		).subscribe((permissions) => {
			const newPermissions = permissions.map((perm: any) => {
				return {
					group: perm.group || undefined,
					user: perm.user ? perm.user.login : undefined,
					annotate: true,
					review: perm.right === LabelingRight.REVIEW,
				}
			});
			this.permissions$.next(newPermissions);
		});

		this.permissions$.pipe(
			untilDestroyed(this)
		).subscribe((permissions) => {
			this.partialTaskChange.emit({ permissions });
		});
	}

	permissionToFormGroup(permission: LabelingTask.LabelingPermissionItem, allUsers: any[]): FormGroup {
		const formState = {
			group: this.fb.control(permission?.group),
			user: this.fb.control(permission.user ? allUsers.find((u) => u.login === permission.user) : null) ,
			right: this.fb.control(permission?.review ? LabelingRight.REVIEW : LabelingRight.ANNOTATE)
		}
		return this.fb.group(formState);
	}

	addUserPermissionToForm() {
		this.permissions.push(this.fb.group({
			group: null,
			user: this.newPermissionForm.get('newUser')?.value,
			right: this.DEFAULT_RIGHT,
		}));
	}

	addGroupPermissionToForm() {
		this.permissions.push(this.fb.group({
			user: null,
			group: this.newPermissionForm.get('newGroup')?.value,
			right: this.DEFAULT_RIGHT,
		}));
	}

	removePermission(index: number) {
		this.permissions.removeAt(index);
	}
}
