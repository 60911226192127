<form [formGroup]="configForm" class="dkuform-modal-horizontal">
    <div class="control-group">
        <label class="control-label">
            Test Variable
        </label>
        <single-variable-selector [variables]="(testVariables$ | async) ?? []"
            formControlName="xColumn" [qa]="'n-sample-test-variable'"></single-variable-selector>
    </div>
    <div class="control-group">
        <label class="control-label">    
            Grouping Variable
        </label>
        <single-variable-selector [variables]="(groupVariables$ | async) ?? []"
            formControlName="yColumn" [qa]="'n-sample-test-grouping'"></single-variable-selector>
    </div>
    <div class="card-editor__group" *ngIf="configForm.get('yColumn')!.value && configForm.get('yColumn')!.value.name"> 
        <div class="control-group">
            <label class="control-label--flex">
                <input type="radio" class="control--horizontal" formControlName="groupingMethod" value="auto">
                Build groups from most frequent values
            </label>
        </div>
        <div class="control-group control-group--child" *ngIf="configForm.controls.maxGroups.enabled">
            <label class="control-label">
                Maximum number of groups
            </label>
            <input type="number" class="card-option__input" formControlName="maxGroups">
        </div>
        <div class="control-group">
            <label class="control-label--flex">
                <input type="radio" class="control--horizontal" formControlName="groupingMethod"
                    value="manual" [qa]="'n-sample-test-manual-groups'">
                Define groups manually
            </label>
        </div>
        <ng-container *ngIf="configForm.controls.yValues.enabled">
            <p class="card-editor__description card-editor__description--single">Enter values of <span class="card-editor__strong">{{ configForm.get('yColumn')!.value.name }}</span> to form the populations.</p>
            <div class="control-group control-group--child">
                <values-list formControlName="yValues" [suggestions]="(suggestedYValues$ | async) ?? []" [qa]="'n-sample-test-group'"></values-list>
            </div>
        </ng-container>
    </div>
    <api-error-alert
        [apiError]="error"
        (resetError)="pushError(null)"
        [closable]="true"
    ></api-error-alert>
</form>