import { Injectable } from "@angular/core";

export enum ColorMeaning {
    CLASS,
    ANNOTATOR,
    IMAGE_STATE
}

export type RBGColor = [number, number, number];

@Injectable()
export class LabelingColorService {
    private colorMeaning: ColorMeaning = ColorMeaning.CLASS;
    private choices: { [key in ColorMeaning]?: string[]; } = {};

    readonly COLORS: RBGColor[] = [[230, 25, 75], [60, 180, 75], [240, 180, 63], [0, 130, 200], [245, 130, 48],
    [145, 30, 180], [87, 168, 238], [240, 50, 230], [194, 200, 81], [232, 94, 89], [0, 128, 128],
    [116, 86, 245], [128, 128, 128], [170, 110, 40], [136, 111, 101], [128, 0, 0], [105, 179, 172], [128, 128, 0],
    [238, 162, 133], [0, 0, 128]];

    readonly UNDEFINED_COLOR: RBGColor = [102, 102, 102];
    readonly VALIDATION_COLOR: RBGColor = [52, 125, 57];
    readonly WARNING_COLOR: RBGColor = [242, 140, 55];

    getColor(item: string | undefined): RBGColor {
        const idx = this.choices[this.colorMeaning]?.findIndex((c) => c == item);
        if (idx === undefined || idx === -1 || this.COLORS[idx] === undefined) {
            return this.colorMeaning == ColorMeaning.ANNOTATOR ? this.VALIDATION_COLOR : this.UNDEFINED_COLOR;
        }
        switch(this.colorMeaning) {
            case ColorMeaning.ANNOTATOR:
                return this.COLORS[this.COLORS.length - idx - 1];
            case ColorMeaning.CLASS:
                return this.COLORS[idx];
            case ColorMeaning.IMAGE_STATE:
                return [this.WARNING_COLOR, this.VALIDATION_COLOR, this.UNDEFINED_COLOR][idx]
        }
         
    }

    getColorForBBox(bbox: {annotator?: string, category?: string, state?: string}): RBGColor {
        if (this.colorMeaning === ColorMeaning.ANNOTATOR) {
            return this.getColor(bbox.annotator);
        } else if (this.colorMeaning === ColorMeaning.CLASS) {
            return this.getColor(bbox.category);
        } else if (this.colorMeaning === ColorMeaning.IMAGE_STATE) {
            return this.getColor(bbox.state);
        } else {
            throw new Error("Unsuported color meaning");
        }
    }

    categoryToColorString(category: string, opacity?: number) {
        return this.colorAsString(this.getColor(category), opacity);
    }

    colorAsString(rgb: number[], opacity?: number) {
        return `rgb(${rgb[0]},${rgb[1]},${rgb[2]},${opacity || 1})`;
    }

    setColorOptions(colorMeaning: ColorMeaning, options: string[]) {
        this.choices[colorMeaning] = options;
    }

    setCurrentColorMeaning(colorMeaning: ColorMeaning) {
        this.colorMeaning = colorMeaning;
    }
}
