import { Inject, Injectable } from "@angular/core";
import { ITaggingService, Workspace } from "src/generated-sources";

export enum WorkspaceNonTaggableType {
    LINK = 'LINK',
    APP = 'APP',
    UNKNOWN = 'UNKNOWN',
}

export type WorkspaceObjectType = ITaggingService.TaggableType | WorkspaceNonTaggableType;

@Injectable({
    providedIn: 'root',
})
export class WorkspaceDisplayService {

    constructor(
        @Inject('StateUtils') private readonly StateUtils: any,
    ) {}

    getObjectLink(workspaceKey: string, object: Workspace.WorkspaceObject): { href: string, target: string } | undefined {
        if (object.htmlLink) {
            return { href: object.htmlLink.url, target: '_blank' };
        } else if (object.appId) {
            return { href: this.StateUtils.href.workspaceApp(workspaceKey, object.appId), target: '' };
        } else if (object.reference) {
            return { href: this.StateUtils.href.workspaceObject(workspaceKey, object.reference.projectKey, object.reference.type, object.reference.id), target: '' };
        }
        return undefined;
    }

    getSourceObjectLink(object: Workspace.WorkspaceObject): string {
        if (object.appId) {
            return this.StateUtils.href.app(object.appId);
        } else if (object.reference) {
            switch (object.reference.type) {
                case ITaggingService.TaggableType.DASHBOARD:
                    return this.StateUtils.href.dashboard(object.reference.id, object.reference.projectKey, { name: object.displayName });
                case ITaggingService.TaggableType.DATASET:
                    return this.StateUtils.href.dataset(object.reference.id, object.reference.projectKey, { name: object.displayName });
                case ITaggingService.TaggableType.WEB_APP:
                    return this.StateUtils.href.webapp(object.reference.id, object.reference.projectKey, { name: object.displayName });
                case ITaggingService.TaggableType.ARTICLE:
                    return this.StateUtils.href.article(object.reference.id, object.reference.projectKey, { name: object.displayName });
                default:
                    return '';
            }
        }
        return '';
    }

    getObjectType(object: Workspace.WorkspaceObject): WorkspaceObjectType {
        if (object.reference) {
            return object.reference.type;
        } else if (object.htmlLink) {
            return WorkspaceNonTaggableType.LINK;
        } else if (object.appId) {
            return WorkspaceNonTaggableType.APP;
        }
        return WorkspaceNonTaggableType.UNKNOWN;
    }

    getAppImageURL(appId: string) {
        return `/dip/api/apps/get-template-image?appId=${encodeURIComponent(appId)}`;
    }

}
