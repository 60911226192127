import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { APIError } from '@core/dataiku-api/api-error';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LabelingTask } from 'src/generated-sources';
import { LabelingService } from './services/labeling.service';

export type LabelingTaskPrivileges = {
    canReadConf: boolean,
    canWriteConf: boolean,
    canAnnotate: boolean,
    canReview: boolean
}

@UntilDestroy()
@Component({
    selector: 'labeling',
    templateUrl: './labeling.component.html',
})
export class LabelingComponent implements OnChanges, OnInit {
    @Input() labelingTask: LabelingTask;

    @Input() privilegesOnTask?: LabelingTaskPrivileges;
    
    @Input() saveError?: APIError;
    @Output() saveErrorClose = new EventEmitter<void>();

    @Input() tab: "annotate" | "review" | "settings" | "overview";
    @Input() identifier: string;
    @Output() partialTaskChange = new EventEmitter<Partial<LabelingTask>>();
    @Output() partialTaskValidityChange = new EventEmitter<boolean>();

    constructor(
        private labelingService: LabelingService
    ) { }

    ngOnInit() {
        this.labelingService.getError().pipe(
            untilDestroyed(this)).subscribe((error) => {
            if (error === undefined) {
                this.saveError = undefined;
                this.saveErrorClose.emit();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.labelingTask) {
            this.labelingService.setLabelingTask(this.labelingTask);
        }

        if (changes.labelingTask?.firstChange) {
            this.labelingService.pushError();
        }

        if (changes.identifier?.firstChange || changes.identifier?.previousValue !== changes.identifier?.currentValue) {
            this.labelingService.setIdentifier(this.identifier);
        }

        if (changes.saveError?.firstChange || changes.saveError?.currentValue !== changes.saveError?.previousValue) {
            this.labelingService.pushError(this.saveError);
        }
    }
}
