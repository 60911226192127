<form [formGroup]="configForm" class="dkuform-modal-horizontal">
    <div class="control-group">
        <label class="control-label">
            Test Variable
        </label>
        <single-variable-selector [variables]="(testVariables$ | async) ?? []" formControlName="xColumn" [qa]="'two-sample-test-variable'"></single-variable-selector>
    </div>
    <div class="control-group">
        <label class="control-label">    
            Grouping Variable
        </label>
        <single-variable-selector [variables]="(groupVariables$ | async) ?? []" formControlName="yColumn" [qa]="'two-sample-test-grouping'"></single-variable-selector>
    </div>
    <ng-container *ngIf="configForm.get('yColumn')!.value && configForm.get('yColumn')!.value.name">
        <div class="control-group">
            <label class="control-label--flex">
                <input
                    type="radio"
                    class="control--horizontal"
                    formControlName="groupingMethod"
                    value="auto"
                >
                Build the two populations from the most frequent values
            </label>
        </div>
        <div class="control-group">
            <label class="control-label--flex">
                <input
                    type="radio"
                    class="control--horizontal"
                    formControlName="groupingMethod"
                    value="manual"
                >
                Define the two populations manually
            </label>
        </div>

        <ng-container *ngIf="configForm.controls.groupingMethod.value === 'manual'">
            <p class="card-editor__description">
                Enter values of <span class="card-editor__strong">{{ configForm.get('yColumn')!.value.name }}</span> to form the two populations below:
            </p>
            <div class="horizontal-flex">
                <div class="card-editor__column card-editor__column--group" [qa]="'two-sample-test-population-1'">
                    <div class="control-group">
                        <label class="control-label">
                            Population 1
                        </label>
                        <values-list formControlName="value1" [suggestions]="(suggestedYValues$ | async) ?? []"></values-list>
                    </div>
                </div>
                <div class="card-editor__column card-editor__column--group" [qa]="'two-sample-test-population-2'">
                    <div class="control-group">
                        <label class="control-label">
                            Population 2
                        </label>
                        <values-list formControlName="value2" [suggestions]="(suggestedYValues$ | async) ?? []"></values-list>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <api-error-alert
        [apiError]="error"
        (resetError)="pushError(null)"
        [closable]="true"
    ></api-error-alert>
</form>