import { ACFPlotCard, Chi2IndTestCard, DurbinWatsonCard, KsTest2SampCard, MoodTestNSampCard, OneWayANOVACard, PACF, ShapiroNormalityTestCard, TimeSeriesCard, UnivariateHeaderCard, Variable } from 'src/generated-sources';
import { randomId } from '@utils/random';
import { DEFAULT_SPLIT_BY_SPEC } from '@utils/dss-defaults';

export function createVarianceComparisonCard(column: Variable, groupBy: Variable): UnivariateHeaderCard {
    return {
        id: randomId(),
        type: 'univariate_header',
        xColumns: [column],
        showSummary: true,
        showBoxPlot: false,
        showHistogram: false,
        showQuantile: false,
        showFrequencyTable: false,
        showCumulativeDistributionFunction: false,
        cards: [
            {
                id: randomId(),
                type: 'column_card',
                column,
                cards: [
                    {
                        id: randomId(),
                        type: 'univariate_summary',
                        column,
                        confidenceLevel: 0.95,
                        showConfidenceInterval: false,
                        compute: {
                            // Display variance
                            variance: true,
                            // Override default values to display variance only (a bit verbose...)
                            totalCount: false,
                            mean: false,
                            sum: false,
                            median: false,
                            std: false,
                            skewness: false,
                            kurtosis: false,
                            sem: false,
                            completeCount: false,
                            nbEmpty: false,
                            zeroCount: false,
                            zeroRatio: false,
                            nonZeroRatio: false,
                            iqr: false,
                            distinctCount: false,
                            nbNonEmpty: false,
                            min: false,
                            max: false,
                            range: false,
                            mode: false
                        }
                    }
                ]
            }
        ],
        splitBy: {
            ...DEFAULT_SPLIT_BY_SPEC,
            groupingColumn: groupBy
        }
    };
}

export function createShapiroCard(column: Variable, groupBy?: Variable): ShapiroNormalityTestCard {
    return {
        id: randomId(),
        type: 'shapiro',
        column,
        splitBy: groupBy ? { ...DEFAULT_SPLIT_BY_SPEC, groupingColumn: groupBy } : null,
        confidenceLevel: 0.95
    };
}

export function createChi2IndTestCard(
    xColumn: Variable,
    yColumn: Variable,
    confidenceLevel: number,
): Chi2IndTestCard {
    return {
        id: randomId(),
        type: 'chi2_independence_test',
        xColumn,
        yColumn,
        confidenceLevel,
        maxValuesX: 5,
        maxValuesY: 5,
    };
}

export function createOneWayAnovaCard(
    xColumn: Variable,
    yColumn: Variable,
    confidenceLevel: number,
): OneWayANOVACard {
    return {
        id: randomId(),
        type: 'oneway_anova',
        xColumn,
        yColumn,
        confidenceLevel,
        maxGroups: 10,
    };
}

export function createMoodTestNSampCard(
    xColumn: Variable,
    yColumn: Variable,
    confidenceLevel: number,
): MoodTestNSampCard {
    return {
        id: randomId(),
        type: 'mood_nsamp',
        xColumn,
        yColumn,
        confidenceLevel,
        maxGroups: 10,
    };
}

export function createKSTest2SampCard(
    xColumn: Variable,
    yColumn: Variable,
    confidenceLevel: number,
): KsTest2SampCard {
    return {
        id: randomId(),
        type: 'ks_test_2samp',
        xColumn,
        yColumn,
        confidenceLevel,
        value1: null,
        value2: null,
    };
}

export function createACFPlotCard(
    seriesColumn: Variable,
    timeColumn: Variable,
    seriesIdentifiers: TimeSeriesCard.TimeSeriesIdentifier[]
): ACFPlotCard {
    return {
        id: randomId(),
        type: 'acf_plot',
        seriesColumn,
        timeColumn,
        seriesIdentifiers,
        adjusted: true,
        isPartial: false,
        showSummary: true,
        pacfMethod: PACF.Method.LEVINSON_DURBIN,
        confidenceLevel: 0.95,
    };
}

export function createDurbinWatsonCard(
    seriesColumn: Variable,
    timeColumn: Variable,
    seriesIdentifiers: TimeSeriesCard.TimeSeriesIdentifier[]
): DurbinWatsonCard {
    return {
        id: randomId(),
        type: 'durbin_watson',
        seriesColumn,
        timeColumn,
        seriesIdentifiers
    };
}
