import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Card, isBivariateHeaderCard, isCorrelationMatrixCard, isParallelCoordinatesPlotCard, isPCACard, isUnivariateHeaderCard } from 'src/generated-sources';

@Component({
    selector: 'card-editor',
    templateUrl: './card-editor.component.html',
    styleUrls: [
        '../../../shared-styles/card-wizard.less',
        './card-editor.component.less'
    ]
})
export class CardEditorComponent implements OnInit, OnDestroy {
    @Input() params: Card;
    @Output() paramsChange = new EventEmitter<Card>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);
    @Output() validityTooltipChange = new EventEmitter<string>(true);

    className = '';

    constructor() {

    }

    ngOnInit() {
        const typeClass = 'card-wizard__body--' + this.params.type;
        this.className = typeClass + ' card-wizard__body--' + this.getEditorWidth();
    }

    ngOnDestroy() {
    }

    getEditorWidth() {
        const requiresWideEditor =
            isUnivariateHeaderCard(this.params)
            || isBivariateHeaderCard(this.params)
            || isCorrelationMatrixCard(this.params)
            || isParallelCoordinatesPlotCard(this.params)
            || isPCACard(this.params);

            return requiresWideEditor ? "wide" : "narrow";
    }
}
