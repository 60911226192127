import { Inject, Injectable } from "@angular/core";
import { Workspace } from "src/generated-sources";
import { Role, ROLES, ROLE_LIST } from "../workspace.constants";
import { DKURootScope } from "../workspaces-migration";

@Injectable({
    providedIn: 'root',
})
export class WorkspaceSecurityService {

    constructor(@Inject('$rootScope') private $rootScope: DKURootScope) {}

    canCreateWorkspaces(): boolean {
        return this.$rootScope.appConfig && this.$rootScope.appConfig.loggedIn
            && (this.$rootScope.appConfig.admin || this.$rootScope.appConfig.globalPermissions.mayCreateWorkspaces);
    }

    getRole(name: string): Role | undefined {
        return ROLE_LIST.find(item => item.role === name);
    }

    getRoleFromPermission(permission: Workspace.Permissions): Role {
        if (permission.admin) {
            return this.getRole(ROLES.ADMIN) as Role;
        } else if (permission.write) {
            return this.getRole(ROLES.CONTRIBUTOR) as Role;
        }
        return this.getRole(ROLES.MEMBER) as Role;
    }

    getPermissionFromRole(role: string): Workspace.Permissions | null {
        switch (role) {
            case ROLES.ADMIN:
                return { admin: true, write: true, read: true };
            case ROLES.CONTRIBUTOR:
                return { admin: false, write: true, read: true };
            case ROLES.MEMBER:
                return { admin: false, write: false, read: true };
            default:
                return null;
        }
    }

}
