import type {DeepHubMetricParams} from './deep-hub-metric-params';
import type {PredictionMLTask} from './prediction-mltask';
import type {PredictionModelingParams} from './prediction-modeling-params';

export namespace DeepHubPreTrainModelingParams.ImageClassificationPreTrainModelingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageClassificationPreTrainModelingParams$PretrainedModel
     */
    export enum PretrainedModel {
        EFFICIENTNET_B0 = 'EFFICIENTNET_B0',
        EFFICIENTNET_B4 = 'EFFICIENTNET_B4',
        EFFICIENTNET_B7 = 'EFFICIENTNET_B7'
    }
}

/**
 * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams
 */
export interface _DeepHubPreTrainModelingParams {
    augmentationParams: DeepHubPreTrainModelingParams.ImageAugmentationParams;
    dummy: boolean;
    earlyStopping: DeepHubPreTrainModelingParams.EarlyStopping;
    enableParallelDataLoading: boolean;
    epochs: number;
    gpuParams: PredictionModelingParams.GpuCodeParams;
    learningRate: number;
    lrScheduler: DeepHubPreTrainModelingParams.LrScheduler;
    metrics: DeepHubMetricParams;
    numWorkers: number;
    optimizer: DeepHubPreTrainModelingParams.Optimizer;
    perDeviceBatchSize: number;
    processCountPerNode: number;
    trainableLayers: DeepHubPreTrainModelingParams.TrainableLayers;
    type: PredictionMLTask.PredictionType;
    weightDecay: number;
}

export type DeepHubPreTrainModelingParams = DeepHubPreTrainModelingParams.ImageClassificationPreTrainModelingParams | DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams;

export namespace DeepHubPreTrainModelingParams.TrainableLayers {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$TrainableLayers$TrainableLayersMode
     */
    export enum TrainableLayersMode {
        AUTO = 'AUTO',
        MANUAL = 'MANUAL'
    }
}

export namespace DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$PretrainedModel
     */
    export enum PretrainedModel {
        FASTERRCNN = 'FASTERRCNN'
    }
}

export namespace DeepHubPreTrainModelingParams {
    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$RandomCropParams
     */
    export interface RandomCropParams extends DeepHubPreTrainModelingParams.ImageAugmentationParam {
        minKeptRatio: number;
        preserveAspectRatio: boolean;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParams
     */
    export interface ImageAugmentationParams {
        affine: DeepHubPreTrainModelingParams.AffineParams;
        colorJitter: DeepHubPreTrainModelingParams.ColorJitterParams;
        crop: DeepHubPreTrainModelingParams.RandomCropParams;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ColorJitterParams
     */
    export interface ColorJitterParams extends DeepHubPreTrainModelingParams.ImageAugmentationParam {
        brightness: number;
        contrast: number;
        hue: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParam
     */
    export interface ImageAugmentationParam {
        enabled: boolean;
        probability: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageClassificationPreTrainModelingParams
     */
    export interface ImageClassificationPreTrainModelingParams extends _DeepHubPreTrainModelingParams {
        pretrainedModel: DeepHubPreTrainModelingParams.ImageClassificationPreTrainModelingParams.PretrainedModel;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$AffineParams
     */
    export interface AffineParams {
        horizontalFlip: DeepHubPreTrainModelingParams.ImageAugmentationParam;
        rotate: DeepHubPreTrainModelingParams.RotateParams;
        verticalFlip: DeepHubPreTrainModelingParams.ImageAugmentationParam;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$EarlyStopping
     */
    export interface EarlyStopping {
        enabled: boolean;
        minDelta: number;
        patience: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$Optimizer
     */
    export enum Optimizer {
        ADAM = 'ADAM',
        SGD = 'SGD',
        RMSPROP = 'RMSPROP',
        ADAMAX = 'ADAMAX',
        ADAGRAD = 'ADAGRAD',
        ADADELTA = 'ADADELTA'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$TrainableLayers
     */
    export interface TrainableLayers {
        mode: DeepHubPreTrainModelingParams.TrainableLayers.TrainableLayersMode;
        value: number;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams
     */
    export interface ObjectDetectionPreTrainModelingParams extends _DeepHubPreTrainModelingParams {
        pretrainedModel: DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.PretrainedModel;
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$LrScheduler
     */
    export enum LrScheduler {
        PLATEAU = 'PLATEAU',
        STEP = 'STEP',
        EXPONENTIAL = 'EXPONENTIAL'
    }

    /**
     * Generated from com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$RotateParams
     */
    export interface RotateParams extends DeepHubPreTrainModelingParams.ImageAugmentationParam {
        maxRotation: number;
    }
}