<ng-container *ngIf="!!object">
    <div [ngSwitch]="view"
         class="workspace-object-thumbnail">

        <img *ngSwitchCase="View.Image"
             class="workspace-object-thumbnail__image"
             [src]="safeImage" />

        <markdown *ngSwitchCase="View.Description"
             class="workspace-object-thumbnail__description markdown-in-tile"
             [from]="object.shortDesc">
        </markdown>

        <workspace-object-link-favicon *ngSwitchCase="View.Favicon"
                                       [object]="object">
        </workspace-object-link-favicon>

        <i *ngSwitchCase="View.DatasetIcon"
           class="workspace-object-thumbnail__icon universe-color dataset"
           [ngClass]="(object.subType || type) | datasetTypeToIcon">
        </i>

        <i *ngSwitchDefault
           class="workspace-object-thumbnail__icon"
           [ngClass]="[type | typeToIcon, type | typeToColor]">
        </i>
    </div>
</ng-container>