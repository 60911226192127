import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'colorContrast'
})
export class ColorContrast implements PipeTransform {

    static getColorContrast(backgroundColor: string | undefined): string {
        const DARK = '#000000';
        const LIGHT = '#FFFFFF';

        
            // 24 WAYS - http://24ways.org/2010/calculating-color-contrast/
            function getContrastYIQ(hexcolor: string){
                try {

                    var r = parseInt(hexcolor.substring(0,2),16);
                    var g = parseInt(hexcolor.substring(2,4),16);
                    var b = parseInt(hexcolor.substring(4,6),16);
  
                    var yiq = ((r*299)+(g*587)+(b*114))/1000;
                    return isNaN(yiq) ? DARK : (yiq >= 128) ? DARK : LIGHT;  

                } catch (err) {
                    return DARK;
                }
            };
        
            function stripNumberSign(color: string ){
                if(color[0] === "#") {
                    color = color.substring(1, color.length);
                }
                return color;
            };
            
            var textColor = DARK;
            if (backgroundColor) {
                backgroundColor = stripNumberSign(backgroundColor);
                textColor = getContrastYIQ(backgroundColor);
            }
            return textColor;
    }

    transform(backgroundColor: string | undefined): string {
        return ColorContrast.getColorContrast(backgroundColor);

    }
}