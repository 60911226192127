import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Inject, ViewContainerRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ExperimentTrackingService, RunViewModel } from "../experiment-tracking.service";
import { WaitingService } from "@core/overlays/waiting.service";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { map, Observable, ReplaySubject, shareReplay, Subject, switchMap } from "rxjs";
import { ETBreadcrumbItem } from "../experiment-tracking-header/experiment-tracking-header.component";
import { ModalsService, ModalShape } from '@shared/modals/modals.service';
import { ExperimentTrackingRunModelDeployModalComponent, ExperimentTrackingRunModelDeployModalComponentInput } from './experiment-tracking-run-model-deploy-modal/experiment-tracking-run-model-deploy-modal.component';
import { fairAny } from 'dku-frontend-core';

type runTab = 'details' | 'artifacts';

@UntilDestroy()
@Component({
    selector: 'experiment-tracking-run',
    templateUrl: './experiment-tracking-run.component.html',
    styleUrls: ['./experiment-tracking-run.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExperimentTrackingRunComponent implements OnInit, ErrorContext {

    projectKey: string;
    runId: string;
    experimentIds: string[];

    intermediateLinks: Observable<ETBreadcrumbItem>[] = [];

    activeTab: runTab = 'details';
    detailsHref: string;
    artifactsHref: string;
    run$: Observable<RunViewModel>;
    refresh$: Subject<void> = new ReplaySubject(1);
    error?: APIError;

    constructor(
        private DataikuAPI: DataikuAPIService,
        private experimentTrackingService: ExperimentTrackingService,
        private waitingService: WaitingService,
        private cd: ChangeDetectorRef,
        private modalsService: ModalsService,
        @Inject('$state') private $state: fairAny,
        private viewContainerRef: ViewContainerRef
    ) {
    }

    ngOnInit(): void {
        this.projectKey = this.experimentTrackingService.projectKey;
        this.runId = this.experimentTrackingService.runId || '';
        this.experimentIds = this.experimentTrackingService.experimentIds || [];
        this.loadTabs();
        
        if (this.runId) {
            this.loadRun(this.runId);
        }
        this.refresh();
    }

    loadRun(runId: string) {
        this.run$ = this.refresh$.pipe(
            untilDestroyed(this),
            switchMap(() => {
                return this.DataikuAPI.experiments.getRun(this.projectKey, runId).pipe(
                    this.waitingService.bindSpinner(),
                    map((run) => this.experimentTrackingService.mapRun(run)),
                    catchAPIError(this, false, this.cd));
            }),
            shareReplay()
        );
        this.createBreadcrumb();
    }

    loadTabs() {
        const viewAllRuns = this.experimentTrackingService.viewAllRuns;
        const viewAllExperiments = this.experimentTrackingService.viewAllExperiments;
        const currentUrl = this.experimentTrackingService.currentUrl.split('?')[0];

        this.activeTab = currentUrl.endsWith('details') ? 'details' : 'artifacts';
        this.detailsHref = this.experimentTrackingService.getRunIdHref(this.runId, this.experimentIds, viewAllExperiments, viewAllRuns);
        this.artifactsHref = this.experimentTrackingService.getRunIdArtifactHref(this.runId, this.experimentIds, viewAllExperiments, viewAllRuns);
    }

    createBreadcrumb() {
        const runsListBreadcrumbItem$: Observable<ETBreadcrumbItem> = this.experimentTrackingService.getRunsListBreadcrumbFromRun(this.run$);
        const runNameBreadcrumbItem$: Observable<ETBreadcrumbItem> = this.experimentTrackingService.getRunNameBreadcrumb(this.run$);
        this.intermediateLinks = [runsListBreadcrumbItem$, runNameBreadcrumbItem$];
    }

    openDeployModal(run: RunViewModel) {
        this.modalsService.open(
            ExperimentTrackingRunModelDeployModalComponent,
            {
                run: run, projectKey: this.projectKey
            } as ExperimentTrackingRunModelDeployModalComponentInput,
            ModalShape.WIDE,
            this.viewContainerRef,
            { restoreFocus: false }
        ).then(
            () => { this.$state.go("projects.project.flow", { projectKey: this.experimentTrackingService.projectKey }) },
            () => { }
        );
    }

    pushError(error: APIError): void {
        this.error = error;
    }

    refresh(): void {
        this.refresh$.next();
    }
}
