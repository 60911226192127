import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';

import { DataikuAPIModule } from '@core/dataiku-api/dataiku-api.module';
import { RequestsModule } from '@core/requests/requests.module';
import { NavModule } from '@core/nav/nav.module';
import { WaitingOverlayComponent } from './overlays/waiting-overlay/waiting-overlay.component';

/*
* This module and all sub-modules gather all Services that should be singletons and that will be loaded at startup
* by AppModule. This module should not contain anything else
*
* For Components, Pipes and Services that don't need to be singletons and don't need to be loaded at all time
* please use "Shared" (or Feature if there is no need to import from another Feature module)
*
*/
@NgModule({
    imports: [
        CommonModule,
        DataikuAPIModule,
        NavModule,
        RequestsModule,
    ],
    declarations: [
        WaitingOverlayComponent
    ],
    providers: []
})
export class CoreModule {
    // make sure CoreModule is imported only by the AppModule
    constructor(
        @Optional() @SkipSelf() parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
