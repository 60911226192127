import { Inject, Injectable } from "@angular/core";
import { APIError, catchAPIError, ErrorContext } from "@core/dataiku-api/api-error";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { Workspace } from "src/generated-sources";
import { DKURootScope } from "./workspaces-migration";

export interface WorkspacesError {
    error$: Observable<APIError | undefined>;
    resetError: () => void;
}

@UntilDestroy()
@Injectable()
export class WorkspacesService implements ErrorContext {

    private error$ = new BehaviorSubject<APIError | undefined>(undefined);

    constructor(
        private DataikuAPI: DataikuAPIService,
        @Inject('$rootScope') private $rootScope: DKURootScope,
    ) {
    }

    pushError(error?: APIError): void {
        this.error$.next(error);
    }

    getError(): WorkspacesError {
        return {
            error$: this.error$.pipe(distinctUntilChanged()),
            resetError: () => this.pushError(),
        };
    }

    createWorkspace(workspace: Workspace): Observable<Workspace> {
        return this.DataikuAPI.workspaces.create(workspace).pipe(
            tap(newWorkspace => this.ng1Notify('addAndGoToWorkspace', newWorkspace)),
            catchAPIError(this),
            untilDestroyed(this),
        );
    }

    updateWorkspace(workspace: Workspace): Observable<Workspace> {
        return this.DataikuAPI.workspaces.update(workspace).pipe(
            tap(updatedWorkspace => {
                this.ng1Notify('updateWorkspaces');
                this.ng1Notify('workspaceUpdated', updatedWorkspace);
            }),
            catchAPIError(this),
            untilDestroyed(this),
        );
    }

    deleteWorkspace(workspaceKey: string): Observable<void> {
        return this.DataikuAPI.workspaces.delete(workspaceKey).pipe(
            tap(() => this.ng1Notify('workspaceDeleted', workspaceKey)),
            catchAPIError(this),
            untilDestroyed(this),
        );
    }

    removeWorkspaceObject(workspace: Workspace, object: Workspace.WorkspaceObject, goToWorkspace?: boolean): Observable<Workspace> {
        return this.DataikuAPI.workspaces.removeObjects(workspace.workspaceKey, [ object ]).pipe(
            tap(workspace => {
                this.ng1Notify('workspaceUpdated', workspace);
                if (goToWorkspace) {
                    this.ng1Notify('goToWorkspace', workspace);
                }
            }),
            catchAPIError(this),
            untilDestroyed(this),
        );
    }

    /**
     * Send an event to angularjs
     * Mainly used to keep model sync with WorkspacesIndexPageController
     * TODO: This method and all its references should be removed after
     * completing the workspaces migration to Angular
     */
    ng1Notify(eventName:string, options?: unknown): void {
        this.$rootScope.$emit(eventName, options);
    }

}
