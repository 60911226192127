import { Injectable } from '@angular/core';
import { Chunk } from '@shared/components/infinite-scroll/infinite-scroll.component';
import { ManagedFolder } from '@shared/utils/managed-folder';
import { Observable, ReplaySubject } from 'rxjs';
import { AnyLoc } from 'src/generated-sources';

export interface CellData {
    itemPath: string,
    imageId: number
}

@Injectable()
export abstract class ImagesDataFetcherService {
    readonly CHUNK_SIZE = 64;

    projectKey: string;
    managedFolderLoc: AnyLoc;

    private subject = new ReplaySubject<void>(1);
    dataChanged$: Observable<void> = this.subject.asObservable();

    abstract getChunk(offset: number): Observable<Chunk>;

    abstract getImagePath(itemPath: string): string;

    onDataChanged(): void {
        this.subject.next();
    }
}
