<div class="modal">
    <modal-header [hidden]="currentPanel !== ModalPanels.CREATE_SMV"
                  [title]="modalTitle"
                  [hasBorder]="false">
    </modal-header>
    <div class="modal-body"
         [hidden]="currentPanel !== ModalPanels.CREATE_SMV">
        <api-error-alert *ngIf="error"
                         [apiError]="error"
                         [closable]="true"
                         (resetError)="resetError()"></api-error-alert>
        <form class="dkuform-modal-horizontal"
              [formGroup]="form">
            <div class="control-group">
                <label class="control-label">Model to deploy*</label>
                <div class="controls">
                    <dku-bs-select-form-control formControlName="modelName"
                                                ngOptions="model.artifactPath as model.artifactPath for model in list"
                                                [list]="run.data.models"
                                                [layout]="'list'"
                                                [dataLiveSearch]="true">
                    </dku-bs-select-form-control>
                </div>
            </div>
            <div class="control-group">
                <label class="control-label">Saved Model*</label>
                <div class="controls">
                    <dku-bs-select-form-control formControlName="savedModel"
                                                ngOptions="sm.id as sm.label for sm in list"
                                                [list]="(savedModels$ | async) ?? []"
                                                [layout]="'list'"
                                                [dataLiveSearch]="true"
                                                matTooltip="Displaying only models with prediction type '{{runPredictionTypeName}}'"
                                                [matTooltipDisabled]="!runPredictionTypeName">
                    </dku-bs-select-form-control><br>
                    <button class="btn btn--text btn--primary"
                            (click)="currentPanel = ModalPanels.CREATE_SM">
                        Create new model
                    </button>
                </div>
            </div>
            <div *ngIf="predictionType"
                 class="control-group">
                <label class="control-label">Prediction type*</label>
                <div class="controls">
                    <label>{{predictionType?.label}}</label>
                </div>
            </div>
            <div class="control-group">
                <label class="control-label">
                    Version ID*
                </label>
                <div class="controls">
                    <input formControlName="versionId" type="text">
                    <span class="help-inline">Unique identifier of a Saved Model Version. If id already exists, existing
                        version is overwritten. Whitespaces are not authorized.</span>
                </div>
            </div>
            <div class="alert alert-warning mtop8"
                 *ngIf="savedModelVersions.includes(form.controls['versionId'].value)">
                <i class="icon-dku-warning"></i> Model version {{ form.controls['versionId'].value }} already exists and
                will be overwritten.
            </div>
            <div class="control-group">
                <label class="control-label">Activate this version*</label>
                <div class="controls">
                    <input formControlName="activate" type="checkbox">
                </div>
            </div>
            <div class="control-group">
                <label class="control-label">
                    Code Env*
                </label>
                <div class="controls">
                    <dku-bs-select-form-control formControlName="codeEnvName"
                                                ngOptions="codeEnv.envName as codeEnv.envName for codeEnv in list"
                                                [list]="codeEnvs"
                                                [optionsDescriptions]="codeEnvsDescriptions"
                                                [layout]="'list'"
                                                [dataLiveSearch]="true">
                    </dku-bs-select-form-control>
                    <span class="help-inline">Must contain a supported version of the mlflow package and the ML libs
                        used to train the model.</span>
                </div>
            </div>
            <div *ngIf="codeEnvCompatibilityStatus === COMPATIBILITY_STATUS_MAYBE_COMPATIBLE"
                 class="alert alert-warning mtop8">
                <i class="icon-dku-warning"></i> The model may not work with the selected code environment: {{
                codeEnvMessage }}.
            </div>
            <div class="alert alert-error mtop8"
                 *ngIf="codeEnvCompatibilityStatus === COMPATIBILITY_STATUS_INCOMPATIBLE">
                <i class="icon-dku-error"></i> Selected code environment does not meet requirements: {{ codeEnvMessage
                }}.
            </div>
            <div class="control-group">
                <label class="control-label">Evaluation Dataset*</label>
                <div class="controls">
                    <dss-accessible-objects-selector [objects]="datasets"
                                                     [type]="'DATASET'"
                                                     [multi]="false"
                                                     formControlName="dataset">
                    </dss-accessible-objects-selector>
                </div>
            </div>
            <div class="control-group">
                <label class="control-label">Target column*</label>
                <div class="controls">
                    <dku-bs-select-form-control ngOptions="name as name for name in list"
                                                formControlName="targetColumn"
                                                [list]="datasetColumns"
                                                [layout]="'list'"
                                                [dataLiveSearch]="true">
                    </dku-bs-select-form-control>
                </div>
            </div>
            <div *ngIf=classification
                 class="control-group">
                <label class="control-label">
                    Classes*
                </label>
                <div class="controls">
                    <div class="classes-input-control">
                        <values-list formControlName="classes"
                                     [required]="true"
                                     [addLabel]="'Add class'"
                                     [valuePlaceholder]="'Class label'"
                                     (onValidityChange)="onClassValuesListValidityChanged($event)">
                        </values-list>
                    </div>
                    <span class="help-inline">Should be declared in the same order as when training the model. Classes
                        saved using mlflow_extension.set_run_classes(run_id, classes_list) are automatically proposed
                        here.</span>
                </div>
            </div>
            <div *ngIf="form.controls.classes?.errors?.binaryIncorrectNumClasses"
                 class="alert alert-error mtop8">
                <i class="icon-dku-error"></i> Binary classification must have only two classes defined.
            </div>
            <div *ngIf="form.controls.classes?.errors?.multiclassIncorrectNumClasses"
                 class="alert alert-error mtop8">
                <i class="icon-dku-error"></i> Multiclass classification must have at least two classes defined.
            </div>
        </form>
    </div>
    <div class="modal-footer modal-footer-std-buttons"
         [hidden]="currentPanel !== ModalPanels.CREATE_SMV">
        <div class="pull-right">
            <button type="button"
                    class="btn btn--text btn--secondary"
                    (click)="dismiss()">Cancel</button>
            <button type="button"
                    class="btn btn--text btn--primary"
                    (click)="deploy()"
                    [disabled]="form.invalid || (error && form.pristine) || deployingModel">
                Deploy
            </button>
        </div>
    </div>
    <modal-header [hidden]="currentPanel !== ModalPanels.CREATE_SM"
                  title="Create new Saved Model"
                  [hasBorder]="false">
    </modal-header>
    <div class="modal-body"
         [hidden]="currentPanel !== ModalPanels.CREATE_SM">
        <api-error-alert *ngIf="error"
                         [apiError]="error"
                         [closable]="true"
                         (resetError)="resetError()">
        </api-error-alert>
        <form class="dkuform-modal-horizontal"
              [formGroup]="formSM">
            <div class="control-group">
                <label class="control-label">Saved Model Name*</label>
                <div class="controls">
                    <input formControlName="smName" type="text">
                </div>
            </div>
            <div *ngIf="!run.predictionType"
                 class="control-group">
                <label class="control-label">Prediction Type*</label>
                <div class="controls">
                    <dku-bs-select-form-control formControlName="predictionType"
                                                ngOptions="prediction_type.key as prediction_type.label for prediction_type in list"
                                                [list]="supportedPredictionTypes"
                                                [layout]="'list'">
                    </dku-bs-select-form-control>
                    <label>{{runPredictionTypeName}}</label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer modal-footer-std-buttons"
         [hidden]="currentPanel !== ModalPanels.CREATE_SM">
        <div class="pull-right">
            <button type="button"
                    class="btn btn--text btn--secondary"
                    (click)="currentPanel = ModalPanels.CREATE_SMV">Cancel</button>
            <button type="button" class="btn btn--text btn--primary"
                    (click)="createMLflowSavedModel()"
                    [disabled]="formSM.invalid || error">
                Create
            </button>
        </div>
    </div>
</div>