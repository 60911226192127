export interface ImagePositionInformation {
    scale: number;
    top: number;
    left: number;
}

export interface BasePainterOptions {
}

export class PainterImage {
    artifacts: PainterImageArtifact[] = [];

    addArtifact(artifact: PainterImageArtifact) {
        this.artifacts.push(artifact);
    }

    removeArtifacts() {
        this.artifacts = [];
    }
}

export class PainterImageArtifact {
    style: any;
    text?: string;
    icon?: string;

    constructor(style: any) {
        this.style = style;
    }
}