import { ChangeDetectionStrategy, Component, HostListener, Inject, Input, OnInit, TemplateRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CarouselService } from "@shared/services/carousel.service";
import { ColorMapContextService } from "@shared/services/color-map-context.service";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { DeepHubPredictionModelDetails } from "src/generated-sources";
import { DeephubInteractiveScoringCellData, InteractiveScoringService } from "./interactive-scoring-data.service";

@UntilDestroy()
@Component({
    selector: 'deephub-report-interactive-scoring',
    templateUrl: './deephub-report-interactive-scoring.component.html',
    styleUrls: [
        './deephub-report-interactive-scoring.component.less',
        '../../../shared-styles/deephub-image.less',
        '../../../shared-styles/deephub-interactive-scoring.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeephubReportInteractiveScoringComponent implements OnInit {
    @Input() modelData: DeepHubPredictionModelDetails;
    @Input() headerTemplate: TemplateRef<any>;
    @Input() imageDetailsTemplate: TemplateRef<any>;
    
    draggedOver$ = new BehaviorSubject<boolean>(false);
    selectedCellData$ = new ReplaySubject<DeephubInteractiveScoringCellData>();
    hasLoadedImages$: Observable<boolean>;
    loading$ : Observable<boolean>;
    loadedCellDataItems$: BehaviorSubject<DeephubInteractiveScoringCellData[]>;

    constructor(
        private interactiveScoringService: InteractiveScoringService,
        private colorMapContextService: ColorMapContextService,
        public carouselService: CarouselService<DeephubInteractiveScoringCellData>,
        @Inject('COLOR_PALETTES') private COLOR_PALETTES: { [palette: string]: string[]; }
    ) {}

    ngOnInit(): void {
        this.colorMapContextService.setMapping(this.modelData.preprocessing.target_remapping.map(label => label.sourceValue),
                                               this.COLOR_PALETTES.highContrast);
        this.loading$ = this.interactiveScoringService.getLoading();

        this.interactiveScoringService.getLoadedCellDataItems().pipe(
            untilDestroyed(this)
        ).subscribe(cellData => this.carouselService.items$.next(cellData));

        this.carouselService.selectedIndex$.pipe(
            withLatestFrom(this.carouselService.items$),
            untilDestroyed(this)
        ).subscribe(([seletedIndex, loadedCellDataItems]) => {
            if (loadedCellDataItems.length > 0) {
                
                loadedCellDataItems.forEach((cellData, index) => {
                    cellData.selected = (index == seletedIndex);
                });

                this.selectedCellData$.next(loadedCellDataItems[seletedIndex]);
            }
        });

        this.carouselService.items$.pipe(
            untilDestroyed(this)
        ).subscribe((loadedCellDataItems) => {
            if (loadedCellDataItems.length > 0) {
                this.carouselService.selectedIndex$.next(0);
            }
        });

        this.hasLoadedImages$ = this.carouselService.items$.pipe(
            map(loadedCellDataItems => loadedCellDataItems.length > 0)
        );
    }

    @HostListener("dragover", ["$event"])
    onDragOver(event: DragEvent) {
        event.preventDefault();
        this.draggedOver$.next(true);
    }

    @HostListener("dragleave", ["$event"])
    onDragLeave() {
        this.draggedOver$.next(false);
    }

    @HostListener("drop", ["$event"])
    onDrop(event: DragEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.uploadImages(event.dataTransfer?.files!);
        this.draggedOver$.next(false);
    }

    uploadImages(files?: FileList) {
        if (files?.length && files?.length > 0) {
            this.interactiveScoringService.uploadFiles(files);
        }
    }

    selectImage(image: {itemPath?: string, imageId?: number}) {
        this.carouselService.selectedIndex$.next(image.imageId!);
    }
} 
