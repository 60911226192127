import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, HostListener, Output, EventEmitter, TemplateRef, Input } from '@angular/core';
import { HeatmapComponent } from '../heatmap/heatmap.component';
import _ from 'lodash';
import { scaleLog } from 'd3-scale';
@Component({
  selector: 'confusion-matrix',
  templateUrl: './confusion-matrix.component.html',
  styleUrls: ['./confusion-matrix.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfusionMatrixComponent extends HeatmapComponent implements OnInit {
    @Input() xHeaderLabel: string;
    @Input() yHeaderLabel: string;
    @Input() filteredLabelMap: {
        [key: string]: boolean
    } = {};
    @Input() popoverTemplate: TemplateRef<any>;
    @Input() popoverWidth = 225;
    @Input() popoverHeight = 115;
    @Output() cellClicked = new EventEmitter<{xIndex: number, yIndex: number} | null>();
    @ViewChild('container', { static: true }) container: ElementRef;
    @ViewChild('matrix', { static: true }) matrix: ElementRef;

    popoverOffsetTop: number;
    popoverOffsetLeft: number;
    isPopoverVisible: boolean = false;
    hoveredCell: {
        xLabel: string,
        yLabel: string,
        value: any
    };
    selectedCell: {
        xIndex: number,
        yIndex: number
    } | null;
    private popoverPositionX = 'right';
    private popoverPositionY = 'bottom';
    private popoverMargin = 8;

    @HostListener('document:click', ['$event']) outsideClicked() {
        // Click outside of the menu was detected
        this.hidePopover();
    }

    constructor() { 
        super();
    }

    ngOnInit(): void {
        
    }

    computeColor(param: number | null): string {
        return scaleLog<string>()
            .domain([1, this.dataMax])
            .range(['#FFFFFF', '#323dff']) // color scale is the same as div.heat-gradient
            (param || 1); // log scale cannot include 0
    }

    clickCell(xIndex: number, yIndex: number) {
        if (this.selectedCell && this.selectedCell.xIndex === xIndex && this.selectedCell.yIndex === yIndex) {
            this.selectedCell = null;
        } else {
            this.selectedCell = {
                xIndex,
                yIndex
            };
        }
        this.cellClicked.emit(this.selectedCell);
    }

    hoverCell(event: MouseEvent, xIndex: number, yIndex: number) {
        const cell = event.target as HTMLElement;
        this.popoverOffsetTop = cell.offsetTop + event.offsetY + (this.popoverPositionY === 'top' ? -this.popoverHeight - this.popoverMargin : 0 + this.popoverMargin);
        this.popoverOffsetLeft = cell.offsetLeft + event.offsetX + (this.popoverPositionX === 'left' ? -this.popoverWidth - this.popoverMargin: 0 + this.popoverMargin);
        this.hoveredCell = {
            xLabel: this.maskedXLabels[xIndex],
            yLabel: this.maskedYLabels[yIndex],
            value: this.maskedData[xIndex][yIndex] || 0,
        };
        
        this.showPopover();
    }

    positionPopover(event: MouseEvent) {
        const cell = event.target as HTMLElement;
        const matrix = this.matrix.nativeElement as HTMLElement;
        const containerWidth = this.container.nativeElement.offsetWidth;
        const containerHeight = this.container.nativeElement.offsetHeight;
        this.popoverPositionX = cell.offsetLeft + matrix.offsetLeft + this.popoverWidth + 2 * this.popoverMargin > containerWidth ? 'left' : 'right'; // double margin to ensure direction switch early
        this.popoverPositionY = cell.offsetTop + matrix.offsetTop + this.popoverHeight + 2 * this.popoverMargin > containerHeight ? 'top' : 'bottom';
    }

    xIsSelected(xIndex: number) {
        return this.selectedCell && xIndex === this.selectedCell.xIndex;
    }

    yIsSelected(yIndex: number) {
        return this.selectedCell && yIndex === this.selectedCell.yIndex;
    }

    showPopover() {
        this.isPopoverVisible = true;
    }

    hidePopover() {
        this.isPopoverVisible = false;
    }
    
    isLabelFiltered(label: string) {
        return _.isEmpty(this.filteredLabelMap) || this.filteredLabelMap[label];
    }
}
