import { Injectable } from "@angular/core";
import { DataikuAPIService } from "@core/dataiku-api/dataiku-api.service";
import { CurrentRouteService } from "@core/nav/current-route.service";
import { WaitingService } from "@core/overlays/waiting.service";
import { DeephubCellData } from "@features/deephub/services/abstract-deephub-data-fetcher.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, from, Observable, of, Subject, zip } from "rxjs";
import { map, switchMap, withLatestFrom } from "rxjs/operators";

export abstract class DeephubInteractiveScoringCellData implements DeephubCellData {
    private readonly SRC_REGEXP = /^data\:image\/(?<format>\w+);base64,(?<base64Repr>[A-Za-z0-9+/=]+)$/; 
    
    itemPath: string;  // holding the base 64 url representation (e.g. "data:image/png;base64,XXXXX") of the image to fit the DataFetcher API
    fileName: string;
    format: string;
    base64Repr: string;
    score: any;
    selected: boolean;
    imageId: number;

    constructor(imageSrcAsBase64: string, fileName: string) {
        this.itemPath = imageSrcAsBase64;
        this.fileName = fileName;
        
        const match = this.SRC_REGEXP.exec(imageSrcAsBase64);

        if (!match) {
            throw new Error("Passed string is not a base64 url " + imageSrcAsBase64);
        }

        this.format = match.groups?.format!;
        this.base64Repr = match.groups?.base64Repr!;
    }

    public abstract setScore(modelResponse: any): void;

    public setIndex(index: number) {
        this.imageId = index;
    }
}

@UntilDestroy()
@Injectable()
export abstract class InteractiveScoringService {
    loadedCellDataItems$ = new BehaviorSubject<DeephubInteractiveScoringCellData[]>([]);
    loading$ = new BehaviorSubject<boolean>(false);
    imagesUploaded$ = new Subject<DeephubInteractiveScoringCellData[]>();

    constructor(
        private DataikuAPI: DataikuAPIService, 
        private currentRouteService: CurrentRouteService, 
        private waitingService: WaitingService
    ) {
        this.imagesUploaded$.pipe(
            switchMap((uploadedCellDataItems) => {
                return zip(
                    of(uploadedCellDataItems),
                    this.DataikuAPI.analysis.computeInteractiveModelScore(this.currentRouteService.fullModelId, {
                        records: uploadedCellDataItems.map(uploadedCellData => ({
                            input: uploadedCellData.base64Repr
                        }))
                    }).pipe(this.waitingService.bindOverlayAndWaitForResult())
                );
            }),map(([uploadedCellData, modelResponse]) => {
                uploadedCellData.forEach((uploadedCellData, index) => uploadedCellData.setScore(modelResponse.scores[index]));
                return uploadedCellData;
            }),
            withLatestFrom(this.loadedCellDataItems$),
            untilDestroyed(this)
        ).subscribe(([uploadedCellDataItems, loadedCellDataItems]) => {
            const newLoadedCellDataItems = [...uploadedCellDataItems, ...loadedCellDataItems];
            newLoadedCellDataItems.forEach((cellData, index) => cellData.setIndex(index))
            this.loadedCellDataItems$.next(newLoadedCellDataItems);
            this.loading$.next(false);
        });
    }

    abstract createCellData(image: string, file: string): DeephubInteractiveScoringCellData;

    getLoading(): Observable<boolean> {
        return this.loading$;
    }

    getLoadedCellDataItems(): BehaviorSubject<DeephubInteractiveScoringCellData[]> {
        return this.loadedCellDataItems$;
    }

    private uploadImageAsBase64(file: File): Observable<DeephubInteractiveScoringCellData> {
        return from(new Promise<DeephubInteractiveScoringCellData>((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = (event: ProgressEvent<FileReader>) => {
                if (reader.result) {
                    resolve(this.createCellData(reader.result as string, file.name));
                }
            }
            reader.readAsDataURL(file)
        }));
    }

    uploadFiles(files: FileList) {
        this.loading$.next(true);
        
        const uploadedFiles$ = [] as Observable<DeephubInteractiveScoringCellData>[];
        for (var i = 0; i < files.length; i++) { 
            uploadedFiles$.push(this.uploadImageAsBase64(files[i]));
        }

        zip(...uploadedFiles$).subscribe((uploadedFiles) => {
            this.imagesUploaded$.next(uploadedFiles);
        })
    }

}