import { Injectable } from '@angular/core';
import { Result } from '@features/feature-store/query-result-models';
import { DkuHttpService } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { NavigatorService } from 'src/generated-sources';

export interface Details {
    meanings: Record<string, string>
    usages: Usages,
    datasetFullInfo: NavigatorService.DatasetFullInfo
}

export interface Usages {
    projectsWithAccess: Project[]
    usagesInNonAccessibleProjects: number
}

export interface Project {
    projectName: string
    projectKey: string
}

@Injectable({ providedIn: 'root' })
export class PrivateFeatureStoresAPI {
    constructor(
        private dkuHttp: DkuHttpService,
    ) { }

    search(queryString: string, facets: {[key: string]: string[]}): Observable<Result> {
        return this.dkuHttp.request('POST', '/feature-store/feature-groups/search', 
            { 
                query: queryString, 
                facets: JSON.stringify(facets)
            });
    }

    getDetails(projectKey: string, datasetName: string, usedInProjects: string[]): Observable<Details> {
        return this.dkuHttp.request('GET', '/feature-store/feature-groups/details', 
            { 
                projectKey: projectKey, 
                datasetName: datasetName,
                usedInProjects: usedInProjects
            });
    }

    setFeatureGroup(projectKey: string, datasetName: string, isFeatureGroup: boolean): Observable<any> {
        return this.dkuHttp.request("POST", "/feature-store/feature-groups/set", 
            {
                projectKey: projectKey, 
                name: datasetName, 
                isFeatureGroup: isFeatureGroup
            });
    }
}
