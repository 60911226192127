import { Injectable } from "@angular/core";
import { Workspace } from "src/generated-sources";

export interface WT1WorkspaceEvent {
    color: string;
    groups: number;
    users: number;
    description: boolean;
}

export interface WT1WorkspaceChangeEvent extends Partial<WT1WorkspaceEvent> {
    displayName?: boolean;
    groupsDiff?: number;
    usersDiff?: number;
    from?: string;
}

@Injectable({
    providedIn: 'root',
})
export class WorkspaceWT1Service {

    buildWT1Event(workspace: Workspace): WT1WorkspaceEvent {
        return {
            color: workspace.color,
            groups: workspace.permissions.filter(permission => permission.group).length,
            users: workspace.permissions.filter(permission => permission.user).length,
            description: Boolean(workspace.description),
        };
    }

    buildWT1ChangeEvent(initialWorkspace: Workspace, workspace: Workspace, from: string): WT1WorkspaceChangeEvent {
        const initialGroups = initialWorkspace.permissions.filter(permission => permission.group).length;
        const initialUsers = initialWorkspace.permissions.filter(permission => permission.user).length;
        const groups = workspace.permissions.filter(permission => permission.group).length;
        const users = workspace.permissions.filter(permission => permission.user).length;
        
        const event: WT1WorkspaceChangeEvent = {};

        if (initialWorkspace.displayName !== workspace.displayName) {
            event.displayName = true;
        }
        if (initialWorkspace.color !== workspace.color) {
            event.color = workspace.color;
        }
        if (initialWorkspace.description !== workspace.description) {
            event.description = true;
        }
        if (initialGroups !== groups) {
            event.groups = groups;
            event.groupsDiff = groups - initialGroups;
        }
        if (initialUsers !== users) {
            event.users = users;
            event.usersDiff = users - initialUsers;
        }
        if (from) {
            event.from = from;
        }

        return event;
    }

}
