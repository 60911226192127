import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap } from 'rxjs/operators';
import { ColorMeaning, LabelingColorService } from '../services/labeling-color.service';
import { LabelingService } from '../services/labeling.service';
import { LabelingReviewImagePathService } from './services/labeling-review-image-path.service';
import { LabelingReviewAnnotationGroupService } from './services/labeling-review-annotation-group.service';
import { LabelingReviewUpdateService } from './services/labeling-review-update-service';
import { LabelingTaskPrivileges } from '../labeling.component';
import { isTaskSetProperly } from '../utils';

@UntilDestroy()
@Component({
    selector: 'labeling-task-review',
    templateUrl: './labeling-task-review.component.html',
    styleUrls: ['./labeling-task-review.component.less'],
    providers: [
        LabelingReviewImagePathService,
        LabelingReviewAnnotationGroupService,
        LabelingReviewUpdateService
    ]
})
export class LabelingTaskReviewComponent implements OnInit {
    @Input() privilegesOnTask: LabelingTaskPrivileges;
    constructor(
        private labelingService: LabelingService,
        private labelingColorService: LabelingColorService
    ) {}

    ngOnInit() {
        this.labelingService.labelingTaskInfo$.pipe(
            filter(isTaskSetProperly),
            switchMap((loc) => {
                return this.labelingService.listAnnotators(loc.projectKey, loc.labelingTaskId)
            }),
            untilDestroyed(this),
        ).subscribe((annotators) => {
            this.labelingColorService.setColorOptions(ColorMeaning.ANNOTATOR, annotators);
        });

    }
}
