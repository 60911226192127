import { CollectionViewer, DataSource, ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';

export interface Chunk {
    totalItems: number, // total number of items in the dataset
    chunkItems: any[] // items in the chunk
}

/*
    Inspired by https://github.com/AngularFirebase/145-infinite-virtual-scroll-cdk-angular
*/
@UntilDestroy()
@Component({
    selector: 'infinite-scroll',
    templateUrl: './infinite-scroll.component.html',
    styleUrls: ['./infinite-scroll.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfiniteScrollComponent implements OnChanges {
    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
    @ContentChild(TemplateRef) public template: TemplateRef<{ index: number; item: any; }>;
    @Input() itemsPerRow: number = 1;
    @Input() chunkSize: number;
    @Input() itemHeight: number;
    @Input() getChunkFn: (offset: number) => Observable<Chunk>;
    @Input() selectedIndex?: number | null;

    @Output() loadingStatus = new EventEmitter<boolean>();
    ds: InfiniteScrollDataSource;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedIndex && 
            changes.selectedIndex.firstChange === false &&
             changes.selectedIndex.currentValue !== changes.selectedIndex.previousValue) {
            this.scrollTo(changes.selectedIndex.currentValue);
        }
    }

    reset() {
        if (this.ds) {
            this.viewport.scrollToIndex(0);
            this.ds.reset();
        } else {
            this.elementRef.nativeElement.style.setProperty('--items-per-row', this.itemsPerRow);
            this.ds = new InfiniteScrollDataSource(this.itemsPerRow, this.chunkSize, this.getChunkFn);
            this.ds.isLoading$.pipe(
                untilDestroyed(this)  
            ).subscribe(isLoading => {
                this.loadingStatus.emit(isLoading);
            })
        }
        this.cd.markForCheck();
    }

    trackByFn(i: number) {
        return i;
    }

    scrollTo(index: number) {
        if (!this.ds) {
            return
        }
        const rowId = Math.trunc(index / this.itemsPerRow);
        const colId = Math.trunc(index % this.itemsPerRow);

        const element = document.getElementById(`infiniteScrollElement-${colId}-${rowId}`);

        if (element !== null) {
            const viewportBox = this.viewport.elementRef.nativeElement.getBoundingClientRect();
            const boundedRect = element.getBoundingClientRect();
            const isInViewport = boundedRect.top >= viewportBox.top && boundedRect.bottom <= viewportBox.bottom;
            if (isInViewport === false) {
                element.scrollIntoView(boundedRect.bottom <= viewportBox.bottom);
            }
        } else {
            // In case the element are not in DOM yet (can happen if a shortcut, that increase index, is pressed like a baboon)
            this.viewport.scrollToIndex(index, "smooth");
        }
    }
}

// More info: https://material.angular.io/cdk/scrolling/overview#specifying-data
export class InfiniteScrollDataSource extends DataSource<any | undefined> {
    private cachedData = Array.from<any>({length: 1}); // need to have at least length of 1 to trigger change
    private dataStream = new BehaviorSubject<(string | undefined)[]>(this.cachedData);
    private subscription = new Subscription();
    private offsetSubscription = new Subscription();
    private getChunkFn: (offset: number) => Observable<Chunk>;
    private chunkSize = 64;
    private itemsPerRow: number = 1; // number of items
    private pageFetchThreshold: number = 1; // number of rows in advance before we fetch the next set
    private fetchedPages: Set<number>;
    private offset$ = new ReplaySubject<number>(1);

    public isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(itemsPerRow: number, chunkSize: number, getChunkFn: (offset: number) => Observable<any>) {
        super();
        this.itemsPerRow = itemsPerRow;
        this.chunkSize = chunkSize;
        this.getChunkFn = getChunkFn;
        this.reset();

        this.offsetSubscription = this.offset$.pipe(
            concatMap(offset => this.getChunkFn(offset)
                .pipe(
                    take(1),
                    map(chunk => this.groupByRow(this.fetchedPages.size === 1 ? Array.from(Array(chunk.totalItems)) : this.cachedData, chunk.chunkItems, offset) // chunk data into rows)),
                )
            )
        )).subscribe(data => {
            this.cachedData = data;
            this.dataStream.next(this.cachedData);
            this.isLoading$.next(false);
        }, () => {
            this.isLoading$.next(false);
        });
    }

    connect(collectionViewer: CollectionViewer): Observable<(string | undefined)[]> {
        this.subscription.add(collectionViewer.viewChange.subscribe(range => {
            this.viewChanged(range);
        }));
        return this.dataStream;
    }
  
    disconnect(): void {
        this.subscription.unsubscribe();
        this.offsetSubscription.unsubscribe();
    }

    reset() {
        this.cachedData = Array.from<any>({length: 1}); 
        this.fetchedPages = new Set<number>();
        this.isLoading$.next(true);
        this.dataStream.next(this.cachedData);
        this.viewChanged({
            start: 0,
            end: 0
        });
    }

    private viewChanged(range: ListRange) {
        const startPage = this.getPageForRowNumber(range.start);
        const endPage = this.getPageForRowNumber(range.end + this.pageFetchThreshold);
        for (let i = startPage; i <= endPage; i++) {
            this.fetchPage(i);
        }
    }

    private groupByRow(rowData: any[], newData: any[], offset: number) {
        const items = [].concat(...rowData); // flatten rows
        const data = [...items.slice(0, offset), ...newData, ...items.slice(offset + newData.length, items.length)];
        const dataInRows = [];
        for (let i = 0, j = data.length; i < j; i += this.itemsPerRow) {
            const rowEnd = i + this.itemsPerRow;
            dataInRows.push(
                data.slice(i, rowEnd <= data.length ? rowEnd : data.length)
            );
        }

        return dataInRows;
    }
  
    private getPageForRowNumber(rowNumber: number): number {
        rowNumber = rowNumber < 0 ? 0 : rowNumber;
        return Math.floor(rowNumber * this.itemsPerRow / this.chunkSize);
    }
  
    private fetchPage(page: number) {
        if (this.fetchedPages.has(page)) {
            return;
        }
        this.fetchedPages.add(page);
        this.offset$.next(page * this.chunkSize);
    }
}
