<div>
    <experiment-tracking-header [intermediateLinks]="intermediateLinks">
        <ng-container *ngIf="run$ | async as run">
            <span matTooltip="No model logged in the current run." [matTooltipDisabled]="(run.data?.models?.length || 0) != 0">
                <button class="btn btn--secondary right-header-deploy"
                        (click)="openDeployModal(run)"
                        [disabled]="((run.data?.models?.length || 0) == 0)"
                        [ngClass]="{disabled: !(run.data?.models?.length || 0)}">
                   <i class="icon-play"></i>
                   {{ (run.data?.models?.length || 0) < 1 ? 'Deploy the model' : 'Deploy a model (' + run.data?.models?.length + ')'}}
                </button>
            </span>
        </ng-container>
    </experiment-tracking-header>
    <div class="dss-page row-fluid offset0">
        <div class="span2 nav-list-sidebar sidebar-experiment-tracking oa">
            <ul>
                <li class="l1" [class.active]="activeTab === 'details'">
                    <a [href]="detailsHref" [wt1Click]="'experiment-tracking-run-go-to-details'">Run Details</a>
                </li>
                <li class="l1" [class.active]="activeTab === 'artifacts'">
                    <a [href]="artifactsHref" [wt1Click]="'experiment-tracking-run-go-to-artifacts'">Run Artifacts</a>
                </li>
            </ul>
        </div>
        <div class="span10 h100 offset0 boxed-next-to-sidebar oa">
            <div *ngIf="error">
                <api-error-alert [apiError]="error" [closable]="false"></api-error-alert>
            </div>
            <ng-container *ngIf="run$ | async as run">
                <div experiment-tracking-run-details *ngIf="activeTab === 'details'" [run]="run" (refreshRun)="refresh()" class="oa experiment-tracking-run-tab-content">
                </div>
                <div experiment-tracking-run-artifacts *ngIf="activeTab === 'artifacts'" [run]="run" class="oa experiment-tracking-run-tab-content">
                </div>
            </ng-container>
        </div>
    </div>
</div>