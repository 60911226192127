import { Component } from '@angular/core';
import { LabelingCanvasPainterService } from '@features/labeling/services/labeling-image-painter.service';
import { LabelingReviewDataFetcherService } from '@features/labeling/services/labeling-review-data-fetcher.service';
import { ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { PainterService } from '@shared/services/painter.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LabelingReviewImagePathService } from '../services/labeling-review-image-path.service';

@Component({
    selector: 'review-image-feed',
    templateUrl: './review-image-feed.component.html',
    styleUrls: ['./review-image-feed.component.less'],
    providers: [
        LabelingReviewDataFetcherService,
        { provide: ImagesDataFetcherService, useExisting: LabelingReviewDataFetcherService },
        LabelingCanvasPainterService,
        { provide: PainterService, useExisting: LabelingCanvasPainterService }
    ]
})
export class ReviewImageFeedComponent {

    currentImagePath$: Observable<string>;
    currentIndex$: Observable<number>;
    numImagesToReview$: Observable<number>;

    constructor(private labelingReviewImagePathService: LabelingReviewImagePathService) {
        this.currentImagePath$ = labelingReviewImagePathService.currentImagePath$;
        this.currentIndex$ = labelingReviewImagePathService.currentIndex$;

        this.numImagesToReview$ = labelingReviewImagePathService.itemPaths$.pipe(
            map((paths) => paths.length)
        );
    }

    selectImage(event: {itemPath?: string, imageId?: number}) {
        this.labelingReviewImagePathService.selectImage(event.itemPath!, event.imageId!);
    }
}
