import { Observable, ReplaySubject } from 'rxjs';
import { DeephubImagesDataService, PredictionMLTask, SerializedMemTableV2, SerializedTableChunk } from "src/generated-sources";
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractDeephubDataFetcherService, DeephubCellData, DeephubDataColumns } from '@features/deephub/services/abstract-deephub-data-fetcher.service';
import { Injectable } from '@angular/core';
import { resolveSmartName } from '@utils/loc';

export interface DeephubDesignCellData extends DeephubCellData {
    listCategories(): string[]
}

interface DeephubDesignDataColumns extends DeephubDataColumns {}

interface MLTaskInfo {
    projectKey: string,
    analysisId: string,
    mlTask: PredictionMLTask.DeepHubPredictionMLTask
}

@UntilDestroy()
@Injectable()
export abstract class AbstractDeephubDesignDataFetcherService extends AbstractDeephubDataFetcherService {
    filters: DeephubImagesDataService.DeepHubDesignFilterRequest;
    analysisId: string;
    projectKey: string;
    mlTask: PredictionMLTask.DeepHubPredictionMLTask;

    private mlTaskInfo$ = new ReplaySubject<MLTaskInfo>(1);

    constructor(private DataikuAPI: DataikuAPIService) {
        super();
        this.mlTaskInfo$.pipe(untilDestroyed(this)).subscribe(mlTaskInfo => {
            this.mlTask = mlTaskInfo.mlTask;
            this.projectKey = mlTaskInfo.projectKey;
            this.analysisId = mlTaskInfo.analysisId;
            this.managedFolderLoc = resolveSmartName(this.projectKey, this.mlTask.managedFolderSmartId);
            this.onDataChanged();
        });
    }

    setMLTaskInfo(projectKey: string, analysisId: string, mlTask: PredictionMLTask.DeepHubPredictionMLTask) {
        this.mlTaskInfo$.next({ projectKey, analysisId, mlTask });
    }

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.DataikuAPI.analysis.refreshPMLImagesDataSample(this.projectKey, this.analysisId, this.mlTask.id, this.CHUNK_SIZE ,this.filters)
            .pipe(
                map(result => {
                    this.onRefreshSample(result);
                    return result;
                })
            );
    }

    getSampleChunk(firstRow: number, nbRows: number): Observable<SerializedTableChunk> {
        return this.DataikuAPI.analysis.getPMLImagesDataChunk(this.projectKey, this.analysisId, this.mlTask.id, firstRow, nbRows, this.filters);
    }

    createDataColumns(orderedColumns: string[]): DeephubDesignDataColumns {
        const columns = {
            target: {
                name: this.mlTask.targetVariable
            },
            itemPath: {
                name: this.mlTask.pathColumn
            }
        };

        this.addColumnIndices(columns, orderedColumns);

        return columns;
    }

    setFilteredCategories(filteredCategories: string[]) {
        this.filters = {
            targetCategories: filteredCategories
        };
        this.onDataChanged();
    }
}
