import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Chunk } from "@shared/components/infinite-scroll/infinite-scroll.component";
import { ImagesDataFetcherService } from "@shared/services/images-data-fetcher.service";
import { deepDistinctUntilChanged } from "dku-frontend-core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LabelingReviewImagePathService } from "../labeling-task-review/services/labeling-review-image-path.service";
import { getImageURL } from "../utils";
import { LabelingService } from "./labeling.service";

@UntilDestroy()
@Injectable()
export class LabelingReviewDataFetcherService extends ImagesDataFetcherService {
    labelingTaskId: string;
    
    constructor(private labelingService: LabelingService, private labelingReviewImagePathService: LabelingReviewImagePathService) {
        super();

        this.labelingService.labelingTaskInfo$.pipe(untilDestroyed(this)).subscribe((info) => {
            this.onDataChanged();
            this.labelingTaskId = info.labelingTaskId;
            this.projectKey = info.projectKey;
            this.managedFolderLoc = info.getManagedFolderLoc();
        })

        this.labelingReviewImagePathService.itemPaths$.pipe(untilDestroyed(this)).subscribe(() => {
            this.onDataChanged();
        });
    }

    getImagePath(itemPath: string): string {
        return getImageURL(this.projectKey, this.labelingTaskId, this.managedFolderLoc, itemPath);
    }

    getChunk(offset: number): Observable<Chunk> {
        return this.labelingReviewImagePathService.itemPaths$.pipe(
            deepDistinctUntilChanged(),
            map((paths) => {
                return {
                    chunkItems: paths.slice(offset, offset + this.CHUNK_SIZE).map((path, index) => {
                        return {
                            imageId: index + offset,
                            itemPath: path,
                        }
                    }),
                    totalItems: paths.length,
                }
            })
        );
    }

}