import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { DeephubObjectDetectionReportDataFetcherService } from '../services/deephub-object-detection-report-data-fetcher.service';

@Component({
  selector: 'deephub-object-detection-report-image-legend',
  templateUrl: './deephub-object-detection-report-image-legend.component.html',
  styleUrls: [
    '../../../computer-vision/report/image-legend/deephub-computer-vision-report-image-legend.component.less',
    '../../../shared-styles/deephub-image.less'
    ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubObjectDetectionReportImageLegendComponent {
    constructor(
        public dataFetcher: DeephubObjectDetectionReportDataFetcherService,
        public colorMapService: ColorMapContextService
    ) {}
}
