<mat-form-field>
    <mat-select [id]="id"
                [disableOptionCentering]="true"
                placeholder="Nothing selected"
                [formControl]="objectsControl"
                [multiple]="multi">
        <mat-select-trigger>
            <ng-container *ngIf="!multi; else multiTrigger">
                <div class="select-trigger-content" *ngIf="objectsControl.value">
                    <ng-container *ngTemplateOutlet="objectTypeIcon; context: { type: objectsControl.value.type, subtype: objectsControl.value.subtype }">
                    </ng-container>

                    {{ objectsControl.value.label }}
                </div>
            </ng-container>
            <ng-template #multiTrigger>
                <div class="select-trigger-content" *ngIf="objectsControl.value.length">
                    <ng-container *ngIf="objectsControl.value.length === 1">
                        <ng-container *ngTemplateOutlet="objectTypeIcon; context: { type: objectsControl.value[0].type, subtype: objectsControl.value[0].subtype }">
                        </ng-container>

                        {{ objectsControl.value[0].label }}
                    </ng-container>
                    <ng-container *ngIf="objectsControl.value.length > 1">
                        <ng-container *ngTemplateOutlet="objectTypeIcon; context: { type: objectsControl.value[0].type }">
                        </ng-container>

                        {{ objectsControl.value.length }} {{ objectsControl.value[0].type | niceConst |
                        plurify:objectsControl.value.length | lowercase }} selected
                    </ng-container>
                </div>
            </ng-template>
        </mat-select-trigger>

        <mat-option>
            <ngx-mat-select-search [formControl]="searchControl"
                                   [hideClearSearchButton]="true"
                                   [noEntriesFoundLabel]="'No results match ' + searchControl.value"
                                   placeholderLabel="Filter..."></ngx-mat-select-search>
        </mat-option>

        <mat-option class="object-option object-option--empty-state" *ngIf="!(objects$ | async)?.length" disabled>
            No {{ type | niceConst | lowercase }} in this project
        </mat-option>

        <ng-container *ngIf="filteredAndGroupedObjects$ | async as groupedObjects">
            <mat-optgroup *ngFor="let group of groupedObjects | keyvalue; trackBy: trackOptGroup"
                          [label]="group.key">
                <mat-option *ngFor="let object of group.value; trackBy: trackOption"
                            [value]="object"
                            [disabled]="object.usable === false"
                            class="object-option">
                    <div class="object-option__content">
                        <ng-container
                                      *ngTemplateOutlet="objectTypeIcon; context: { type: object.type, subtype: object.subtype  }">
                        </ng-container>

                        <div class="object-option__text">
                            <div class="object-option__text-top">{{ object.label }}</div>
                            <div class="object-option__text-bottom"
                                 *ngIf="object.subtype || (object.usable === false)">
                                <ng-container *ngIf="object.subtype">{{ object.subtype }}</ng-container>
                                <ng-container *ngIf="object.subtype && (object.usable === false)">&nbsp;</ng-container>
                                <ng-container *ngIf="object.usable === false">{{ object.usableReason }}</ng-container>
                            </div>
                        </div>
                    </div>
                </mat-option>
            </mat-optgroup>
        </ng-container>
    </mat-select>
</mat-form-field>

<ng-template #objectTypeIcon
             let-type="type"
             let-subtype="subtype">
    <ng-container *ngIf="!subtype">
        <i [attr.class]="'object-type-icon icon ' +  (type | typeToIcon) + ' ' + (type | typeToColor)"></i>
    </ng-container>
    <ng-container *ngIf="!!subtype">
        <i
           [attr.class]="'object-type-icon icon ' +  (subtype | typeToIcon) + ' ' + (subtype | subtypeToColor: type)"></i>
    </ng-container>
</ng-template>