import { Injectable, Inject, NgZone } from '@angular/core';
import { DkuActivatedRouteService } from '@migration/dku-activated-route';
import { ParamMap } from '@angular/router';
import type { IScope } from 'angular';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

/*
    This is a simple service to get the state params IF we know for sure that they will be ready
    and we don't care about future changes (no use of observables)
*/
@Injectable({
    providedIn: 'root'
})
export class CurrentRouteService {
    params: ParamMap;

    stateParams$ = new Observable<any>(observer => {
        observer.next(this.$state.params);
        return this.$rootScope.$on('$stateChangeSuccess', () =>
            this.ngZone.run(() => observer.next(this.$state.params))
        );
    }).pipe(shareReplay(1));

    projectKey$ = this.stateParams$.pipe(
        map(params => params.projectKey as string | undefined)
    );

    constructor(
        private ngZone: NgZone,
        private dkuActivatedRouteService: DkuActivatedRouteService,
        @Inject('$state') private $state: any,
        @Inject('$rootScope') private $rootScope: IScope
    ) {
        this.dkuActivatedRouteService.paramMap.subscribe(paramMap => this.refresh(paramMap));
    }

    refresh(paramMap: ParamMap): any {
        this.params = paramMap;
    }

    get projectKey(): string {
        return this.$state.params.projectKey;
    }

    get datasetName(): string {
        if (this.$state.params.datasetName) {
            return this.$state.params.datasetName;
        } else {
            return this.$state.params.datasetFullName;
        }
    }

    get worksheetId(): string {
        return this.$state.params.worksheetId;
    }

    get analysisId(): string {
        return this.$state.params.analysisId;
    }

    get runId(): string | undefined {
        return this.$state.params.runId;
    }

    get experimentIds(): string[] {
        return (this.$state.params.experimentIds) ? this.$state.params.experimentIds.split(',') : undefined;
    }

    get viewAllExperiments(): string {
        return this.$state.params.viewAllExperiments;
    }

    get viewAllRuns(): string {
        const viewAllRuns = this.$state.params.viewAllRuns;
        if (viewAllRuns === undefined) {
            return this.viewAllExperiments;
        }
        return viewAllRuns;
    }

    get subfolder(): string {
        return this.$state.params.subfolder;
    }

    get labelingTaskId(): string {
        return this.$state.params.labelingTaskId;
    }

    get mlTaskId(): string {
        return this.$state.params.mlTaskId;
    }

    get fullModelId(): string {
        return this.$state.params.fullModelId;
    }
}
