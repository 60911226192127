import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Inject, QueryList } from '@angular/core';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SidebarNavItemComponent } from './sidebar-nav-item/sidebar-nav-item.component';

@UntilDestroy()
@Component({
  selector: 'sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarNavComponent implements AfterContentInit {
    @ContentChildren(SidebarNavItemComponent)
    tabs: QueryList<SidebarNavItemComponent>;

    activeTab: SidebarNavItemComponent;
    path: string;
    
    constructor(
        @Inject('$state') private $state: any,
        private currentRoute: CurrentRouteService,
        private cd: ChangeDetectorRef
    ) { }

    ngAfterContentInit(): void {
        // automatically select first tab
        this.open(this.getTab(this.$state.params.selectedTab), !this.$state.params.selectedTab);
    }

    open(tab: SidebarNavItemComponent, replaceLocation = false) {
        this.$state.go('.', {
            selectedTab: tab.id
        }, {
            location: replaceLocation ? 'replace' : true,
            relative: this.$state.$current, 
            notify: false
        }).then(() => {
            this.activeTab = tab;
            this.cd.detectChanges();
        });
    }

    private getTab(id: string) {
        return this.tabs.find(tab => id === tab.id) || this.tabs.first;
    }
}
