import { Injectable } from '@angular/core';
import { ImagePositionInformation, PainterImage } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PainterService } from '@shared/services/painter.service';
import { DeephubImageClassificationDesignCellData } from './deephub-image-classification-design-data-fetcher.service';
import { fabric } from "fabric";
import { ImageClassificationLabelHelper } from '../../utils/label-helper';

@Injectable()
export class DeephubImageClassificationDesignPainterService extends PainterService {
    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }

    paintForFeed(cellData: DeephubImageClassificationDesignCellData, image: PainterImage, imagePosition: ImagePositionInformation): void {
        const color = this.colorMapService.mapping.get(cellData.target);
        const box = ImageClassificationLabelHelper.createLabel(imagePosition, color);
        box.text = cellData.target;
        image.addArtifact(box);
    }
}
