import { ManagedFolder } from "@shared/utils/managed-folder";
import { AnyLoc } from "src/generated-sources";
import { LabelingTaskInfo } from "./services/labeling.service";

export function isTaskSetProperly(task: LabelingTaskInfo): boolean {
    return task.idColumn !== undefined;
}

export function getImageURL(contextProjectKey: string, labelingTaskId: string, managedFolderLoc: AnyLoc, imagePath: string): string{
    return `/dip/api/labelingtasks/preview-image?labelingTaskId=${labelingTaskId}&contextProjectKey=${encodeURIComponent(contextProjectKey)}&projectKey=${encodeURIComponent(managedFolderLoc.projectKey)}&odbId=${encodeURIComponent(managedFolderLoc.id)}&itemPath=${encodeURIComponent(imagePath)}&contentType=${encodeURIComponent(ManagedFolder.getSupportedContentType(imagePath))}`;
}
