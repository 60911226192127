import { Injectable } from "@angular/core";
import { UIAnnotation } from "@features/labeling/models/annotation";
import { UILabel } from "@features/labeling/models/label";
import { AnnotationFactory } from "@features/labeling/services/annotation.factory";
import { LabelingService } from "@features/labeling/services/labeling.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { cloneDeep } from "lodash";
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged, map, switchMap, withLatestFrom } from "rxjs/operators";
import { ColorMeaning, LabelingColorService } from "../../services/labeling-color.service";
import { LabelingReviewImagePathService } from "./labeling-review-image-path.service";

@UntilDestroy()
@Injectable()
export class LabelingReviewUpdateService {
    currentReview$ = new ReplaySubject<UILabel>(1);
    fetchedReview$ = new ReplaySubject<UILabel>(1);
    updatingReview$ = new BehaviorSubject<boolean>(false);
    resetReviewTrigger$ = new Subject<void>();
    isDirty$: Observable<boolean>;
    
    constructor(
        private labelingReviewImagePathService: LabelingReviewImagePathService, 
        private labelingService: LabelingService,
        private labelingColorService: LabelingColorService,
        private annotationFactory: AnnotationFactory
    ) {
        this.labelingReviewImagePathService.currentImagePath$.pipe(
            untilDestroyed(this),
            switchMap((path) => {
                return this.labelingService.getVerifiedAnswer(path);
            }),
        ).subscribe((answer) => {
            const review = this.annotationFactory.fromAnswer(answer);
            this.currentReview$.next(review);
            this.fetchedReview$.next(cloneDeep(review));
            this.updatingReview$.next(answer !== null);
            if (answer !== null) {
                this.labelingColorService.setCurrentColorMeaning(ColorMeaning.CLASS);
            }
        });

        this.isDirty$ = combineLatest([
            this.currentReview$,
            this.fetchedReview$
        ]).pipe(
            distinctUntilChanged(),
            map(([annotation, fetchedAnnotation]) => {
                return !annotation.equals(fetchedAnnotation);
            }),
        );
        this.resetReviewTrigger$.pipe(
            untilDestroyed(this),
            withLatestFrom(this.fetchedReview$)
        ).subscribe(([_trigger, fetchedReview]) => {
            this.currentReview$.next(cloneDeep(fetchedReview));
        })
    }

    updateReview(annotations: UIAnnotation[]) {
        this.currentReview$.next(this.annotationFactory.fromAnnotations([...annotations]));
    }

    resetReview() {
        this.resetReviewTrigger$.next();
    }
}