<div class="image-feed__container" [class.image-feed__container--single-row]="itemsPerRow === 1" #container>
    <div class="tac" *ngIf="loadingStatus$ | async">
        <i class="dku-loader icon-spin dku-loader--inlined image-feed__loader"></i>
    </div>
    <infinite-scroll class="w100" [getChunkFn]="getChunk" [selectedIndex]="selectedIndex" [chunkSize]="getChunkSize()" [itemsPerRow]="itemsPerRow" [itemHeight]="imageHeight" (loadingStatus)="loadingStatus$.next($event)">
        <ng-template let-item="item" let-index="index">
            <image-feed-image class="image-feed__image" (click)="imageClick?.emit({ imageId: index, itemPath: item?.itemPath })" [ngClass]="{ 'cursor-pointer': !!imageClick && (selectedPath === undefined || selectedPath !== item.itemPath)}" [cellData]="item" [imageHeight]="imageHeight" [imageId]="index" [imageSelected]="selectedIndex !== undefined && selectedIndex === index" [showBackground]="true"></image-feed-image>
        </ng-template>
    </infinite-scroll>
</div>
