import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy, SimpleChanges, OnChanges, ViewContainerRef } from '@angular/core';
import { AbstractHeaderCard, Variable, isBivariateHeaderCard, Card, isColumnCard, ColumnCard } from 'src/generated-sources';
import { CardActionType, CardAction, UpdateCardAction, DebugCardAction, AddCardAction, PublishCardAction } from '@features/eda/worksheet/cards/events';
import { switchMap, map } from 'rxjs/operators';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { CollapsibleColumnCard, CollapsingService, UpdatableCollapsingService } from '@features/eda/collapsing.service';
import { ModalsService, ModalShape } from '@shared/modals/modals.service';
import { EditCardModalComponent } from '../../../card-wizard/edit-card-modal/edit-card-modal.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WT1Service } from '@core/dataiku-wt1/wt1.service';

@Component({
    selector: 'header-card-body',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './header-card-body.component.html',
    styleUrls: ['./header-card-body.component.less'],
    animations: []
})
export class HeaderCardBodyComponent implements OnChanges {
    @Input() params: AbstractHeaderCard;
    @Input() results: AbstractHeaderCard.AbstractHeaderCardResult;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();
    projectKey: string;
    datasetName: string;

    Variable = Variable;
    isBivariateHeaderCard = isBivariateHeaderCard;
    isColumnCard = isColumnCard;
    isColumnCardResult = ColumnCard.isColumnCardResult;

    showCollapsingControls: boolean;

    private params$ = new ReplaySubject<AbstractHeaderCard>(1);
    private results$ = new ReplaySubject<AbstractHeaderCard.AbstractHeaderCardResult>(1);
    columnCollapseStates$: Observable<boolean[]>;

    constructor(
        private collapsingService: CollapsingService,
        private modalsService: ModalsService,
        private viewContainerRef: ViewContainerRef,
        private wt1Service: WT1Service
    ) {
        this.columnCollapseStates$ = this.params$.pipe(
            map(params => params.xColumns
                .map(column => new CollapsibleColumnCard(params.id, column))
                .map(collapsible => this.collapsingService.watchIsCollapsed(collapsible))),
            switchMap(observables => combineLatest(observables))
        );
        this.showCollapsingControls = this.collapsingService instanceof UpdatableCollapsingService;
    }

    toggleColumn(column: Variable, newIsCollapsed: boolean) {
        this.collapsingService.setIsCollapsed(
            new CollapsibleColumnCard(this.params.id, column),
            newIsCollapsed
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.params$.next(this.params);
        }
        if (changes.results) {
            this.results$.next(this.results);
        }
    }

    handleColumnCardUpdate(index: number, action: UpdateCardAction) {
        if (action.newParams.type === 'column_card') {
            const newParams = {
                ...this.params,
                cards: [...this.params.cards],
                xColumns: [...this.params.xColumns]
            };
            newParams.cards[index] = action.newParams;
            newParams.xColumns[index] = action.newParams.column;
            this.action.emit({ type: CardActionType.UPDATE, newParams });
        }
    }

    handleColumnCardDelete(columnIndex: number) {
        this.action.emit({
            type: CardActionType.UPDATE,
            newParams: {
                ...this.params,
                // cards are fixed up by the backend
                xColumns: this.params.xColumns.filter((_, xColumnIndex) => xColumnIndex !== columnIndex)
            }
        });
    }

    handleColumnCardExport(columnIndex: number, action: AddCardAction | DebugCardAction | PublishCardAction) {
        let exportedCard;
        if (action.card.type === 'column_card') {
            // Export the column card itself by converting it into a regular header card
            exportedCard = {
                ...this.params,
                xColumns: [action.card.column],
                cards: [{ ...action.card }]
            };
        } else {
            // Export a card *within* a column card
            exportedCard = {
                ...action.card,
                splitBy: this.params.splitBy,
                filter: this.params.filter
            };
        }

        this.action.emit({ type: action.type, card: exportedCard });
    }

    configureAnalysis() {
        this.modalsService.open(EditCardModalComponent,
            {
                params: this.params
            },
            ModalShape.NONE,
            this.viewContainerRef
        ).then(({ card }) => {
            this.action.emit({ type: CardActionType.UPDATE, newParams: card });
        }, () => { });
    }

    handleColumnCardAction(columnIndex: number, action: CardAction) {
        switch (action.type) {
            case CardActionType.UPDATE:
                this.handleColumnCardUpdate(columnIndex, action);
                break;
            case CardActionType.DELETE:
                this.handleColumnCardDelete(columnIndex);
                break;
            case CardActionType.ADD:
            case CardActionType.DEBUG:
            case CardActionType.PUBLISH:
                this.handleColumnCardExport(columnIndex, action);
                break;
            case CardActionType.HIGHLIGHT:
                this.action.emit(action);
                break;
        }
    }

    trackByColumnName(index: number, card: Card) {
        return isColumnCard(card) ? card.column.name : index;
    }

    dropColumn(event: CdkDragDrop<string[]>) {
        this.action.emit({
            type: CardActionType.REORDER_HEADER_CARD,
            cardId: this.params.id,
            previousIndex: event.previousIndex,
            currentIndex: event.currentIndex
        });
        this.wt1Service.event('statistics-drag-drop-header-card-column', {});
    }
}
