import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LabelingService } from '@features/labeling/services/labeling.service';
import { LabelingShortcutService } from '@features/labeling/services/labeling-shortcut.service';
import { LabelingColorService } from '@features/labeling/services/labeling-color.service';
import { LabelingReviewImagePathService } from '../labeling-task-review/services/labeling-review-image-path.service';
import { LabelingReviewUpdateService } from '../labeling-task-review/services/labeling-review-update-service';
import { AnnotationFactory } from '../services/annotation.factory';
import { LabelingAnswerService } from '../services/labeling-answer.service';

@Component({
    selector: 'labeling-container',
    templateUrl: './labeling-container.component.html',
    styleUrls: ['./labeling-container.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LabelingService,
        LabelingColorService,
        LabelingShortcutService,
        LabelingReviewImagePathService,
        LabelingReviewUpdateService,
        AnnotationFactory,
        LabelingAnswerService,
    ]
})
export class LabelingContainerComponent {

    constructor() { }

}
