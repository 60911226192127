import type {ConditionalOutput} from './../coremodel/conditional-output';
import type {MLTask} from './../analysis/model/mltask';
import type {PartitioningScheme} from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
import type {PredictionMLTask} from './../analysis/model/prediction/prediction-mltask';
import type {SerializedDataset} from './../coremodel/serialized-dataset';
import type {TaggableObjectsService} from './../server/services/taggable-objects-service';

/**
 * Generated from com.dataiku.dip.dao.SavedModel
 */
export interface SavedModel extends TaggableObjectsService._TaggableObject {
    activeVersion: string;
    conditionalOutputs: ConditionalOutput[];
    contentType: string;
    flowOptions: SerializedDataset.FlowOptions;
    id: string;
    lastExportedFrom: string;
    lastTrainIndex: number;
    lastTrainJobId: string;
    metrics: any /* TODO: Missing translation of com.dataiku.dip.metrics.ProbesSet */;
    metricsChecks: any /* TODO: Missing translation of com.dataiku.dip.metrics.ChecksSet */;
    miniTask: MLTask;
    name: string;
    partitioning: PartitioningScheme;
    projectKey: string;
    publishPolicy: SavedModel.ModelPublishPolicy;
    rebuildBehavior: SerializedDataset.RebuildBehavior;
    savedModelType: SavedModel.SavedModelType;
}

export namespace SavedModel {
    /**
     * Generated from com.dataiku.dip.dao.SavedModel$ModelPublishPolicy
     */
    export enum ModelPublishPolicy {
        MANUAL = 'MANUAL',
        UNCONDITIONAL = 'UNCONDITIONAL'
    }

    /**
     * Generated from com.dataiku.dip.dao.SavedModel$SavedModelType
     */
    export enum SavedModelType {
        DSS_MANAGED = 'DSS_MANAGED',
        MLFLOW_PYFUNC = 'MLFLOW_PYFUNC'
    }

    /**
     * Generated from com.dataiku.dip.dao.SavedModel$SavedModelListItem
     */
    export interface SavedModelListItem extends TaggableObjectsService._TaggableListItem {
        backendType: MLTask.BackendType;
        predictionType?: PredictionMLTask.PredictionType | null;
        type: MLTask.MLTaskType;
        versionsCount: number;
    }
}