import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { ColumnCard, GroupedCompiledCardWrapper, AbstractHeaderCard, Card, Filter, SplitBySpec, Variable, isBivariateHeaderCard, isAbstractHeaderCard } from 'src/generated-sources';
import { CardActionType, CardAction, UpdateCardAction, AddCardAction, DebugCardAction, PublishCardAction } from '@features/eda/worksheet/cards/events';
import { ReplaySubject, Observable, combineLatest, EMPTY } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { fadeInOutHeight } from '@shared/animations/fade-in-out';
import { CollapsingService, CollapsibleGroup, CollapsibleColumnCard, UpdatableCollapsingService } from '@features/eda/collapsing.service';
import { noFilterIfAll } from '@features/eda/card-utils';
import { EditCardModalComponent } from '@features/eda/worksheet/card-wizard/edit-card-modal/edit-card-modal.component';
import { ModalsService, ModalShape } from '@shared/modals/modals.service';
import { filterName } from '@features/eda/pipes/filter-name.pipe';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WT1Service } from '@core/dataiku-wt1/wt1.service';


@Component({
    selector: 'grouped-header-card-body',
    templateUrl: './grouped-header-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/menu-button.less',
        './grouped-header-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInOutHeight]
})
export class GroupedHeaderCardBodyComponent implements OnChanges {
    @Input() results: GroupedCompiledCardWrapper.GroupedCardResult;
    @Input() params: AbstractHeaderCard;
    @Input() readOnly: boolean;
    @Input() extendedActions: boolean;
    @Output() action = new EventEmitter<CardAction>();
    CardActionType = CardActionType;

    showCollapsingControls: boolean;
    isBivariateHeaderCard = isBivariateHeaderCard;
    isAbstractHeaderCardResult = AbstractHeaderCard.isAbstractHeaderCardResult;

    private params$ = new ReplaySubject<AbstractHeaderCard>(1);
    private results$ = new ReplaySubject<GroupedCompiledCardWrapper.GroupedCardResult>(1);
    groupCollapseStates$: Observable<boolean[]>;
    columnCollapseStates$: Observable<boolean[]>;

    constructor(
        public collapsingService: CollapsingService,
        public modalsService: ModalsService,
        private viewContainerRef: ViewContainerRef,
        private wt1Service: WT1Service
    ) {
        this.groupCollapseStates$ = combineLatest([this.params$, this.results$]).pipe(
            switchMap(([params, results]) => {
                if (results.type === 'groups') {
                    return combineLatest(
                        results.groups
                            .map(group => new CollapsibleGroup(params.id, group))
                            .map(collapsible => this.collapsingService.watchIsCollapsed(collapsible))
                    );
                }
                return EMPTY;
            })
        );
        this.columnCollapseStates$ = this.params$.pipe(
            switchMap(params => {
                return combineLatest(
                    params.xColumns
                        .map(column => new CollapsibleColumnCard(params.id, column))
                        .map(collapsible => this.collapsingService.watchIsCollapsed(collapsible))
                );
            })
        );
        this.showCollapsingControls = this.collapsingService instanceof UpdatableCollapsingService;
    }

    toggleColumn(column: Variable, newIsCollapsed: boolean) {
        this.collapsingService.setIsCollapsed(
            new CollapsibleColumnCard(this.params.id, column),
            newIsCollapsed
        );
    }

    toggleGroup(group: Filter, newIsCollapsed: boolean) {
        this.collapsingService.setIsCollapsed(
            new CollapsibleGroup(this.params.id, group),
            newIsCollapsed
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.params) {
            this.params$.next(this.params);
        }
        if (changes.results) {
            this.results$.next(this.results);
        }
    }

    handleColumnCardUpdate(action: UpdateCardAction, columnIndex: number) {
        if (action.newParams.type === 'column_card') {
            const newParams = {
                ...this.params,
                cards: [...this.params.cards!],
                xColumns: [...this.params.xColumns!]
            };
            newParams.cards[columnIndex] = action.newParams;
            newParams.xColumns[columnIndex] = action.newParams.column;
            this.action.emit({ type: CardActionType.UPDATE, newParams });
        }
    }

    handleColumnCardDelete(columnIndex: number) {
        this.action.emit({
            type: CardActionType.UPDATE,
            newParams: {
                ...this.params,
                // cards are fixed up by the backend
                xColumns: this.params.xColumns.filter((_, xColumnIndex) => xColumnIndex !== columnIndex)
            }
        });
    }

    handleColumnCardExport(action: AddCardAction | DebugCardAction | PublishCardAction,
        columnIndex: number, groupIndex?: number) {
        let exportedCard;
        if (action.card.type === 'column_card') {
            exportedCard = this.convertToHeaderCard(action.card);
        } else {
            // Export a card *within* a column card
            exportedCard = action.card;
        }

        let filter: Filter | null = null;
        let splitBy: SplitBySpec | null | undefined = null;

        if (groupIndex != null) {
            // Export is coming from a group: transform it into a filter
            const groupFilter = this.results.groups[groupIndex];
            filter = {
                ...groupFilter,
                name: this.params.splitBy!.groupingColumn.name + ': ' + filterName(groupFilter)
            };
        } else {
            // Export is not coming from a group: propagate the 'splitBy' to that the export stays grouped
            splitBy = this.params.splitBy;
        }

        exportedCard = { ...exportedCard, splitBy, filter: noFilterIfAll(filter) };
        this.action.emit({ type: action.type, card: exportedCard });
    }

    handleColumnCardAction(action: CardAction, columnIndex: number, groupIndex?: number) {
        switch (action.type) {
            case CardActionType.UPDATE:
                this.handleColumnCardUpdate(action, columnIndex);
                break;
            case CardActionType.DELETE:
                this.handleColumnCardDelete(columnIndex);
                break;
            case CardActionType.ADD:
            case CardActionType.DEBUG:
            case CardActionType.PUBLISH:
                this.handleColumnCardExport(action, columnIndex, groupIndex);
                break;
            case CardActionType.HIGHLIGHT:
                if (action.filter && groupIndex != null) {
                    const groupFilter = {
                        ...this.results.groups[groupIndex],
                        name: this.params.splitBy!.groupingColumn.name
                            + ': ' + filterName(this.results.groups[groupIndex])
                    };
                    action = {
                        ...action,
                        filter: noFilterIfAll({
                            type: 'and',
                            filters: [action.filter, groupFilter]
                        })
                    };
                }
                this.action.emit(action);
                break;
        }
    }

    // We don't want to export standalone column cards: it must converted into a header card first
    convertToHeaderCard(columnCard: ColumnCard): AbstractHeaderCard {
        return {
            ...this.params,
            xColumns: [columnCard.column],
            cards: [{ ...columnCard }],
            filter: null,
            splitBy: null
        };
    }

    // Export a part of this header as another header card
    // If a group index is given: create a new filtered header card
    // If no group index is given: create a new splitted header card
    exportGroup(columnIndex: number, groupIndex: number, type: CardActionType.ADD | CardActionType.DEBUG | CardActionType.PUBLISH) {
        this.action.emit({
            type, card: {
                ...this.convertToHeaderCard(this.params.cards[columnIndex] as ColumnCard),
                filter: noFilterIfAll(this.results.groups[groupIndex])
            }
        });
    }

    trackByIndex(index: number) {
        return index;
    }

    trackByCardId(index: number, card: Card) {
        return card.id;
    }

    configureAnalysis() {
        this.modalsService.open(EditCardModalComponent,
            {
                params: this.params
            },
            ModalShape.NONE,
            this.viewContainerRef
        ).then(({ card }) => {
            this.action.emit({ type: CardActionType.UPDATE, newParams: card });
        }, () => { });
    }

    dropRow(event: CdkDragDrop<string[]>) {
        this.action.emit({
            type: CardActionType.REORDER_HEADER_CARD,
            cardId: this.params.id,
            previousIndex: event.previousIndex,
            currentIndex: event.currentIndex
        });
        this.wt1Service.event('statistics-drag-drop-header-card-column', {});
    }
}
