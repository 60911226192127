<mat-form-field>
  <mat-select
      [id]="id"
      [disableOptionCentering]="true"
      placeholder="Nothing selected"
      [formControl]="projectControl"
      panelClass="project-selector-panel"
  >
      <mat-option>
          <ngx-mat-select-search
              [formControl]="searchControl"
              [hideClearSearchButton]="true"
              [noEntriesFoundLabel]="'No results match ' + searchControl.value"
              placeholderLabel="Filter..."
          ></ngx-mat-select-search>
      </mat-option>

      <mat-option
          *ngFor="let project of (filteredProjects$ | async)"
          [value]="project.projectKey"
      >
        {{ project.name }} ({{ project.projectKey }})
      </mat-option>
  </mat-select>
</mat-form-field>
