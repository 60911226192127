import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CellData } from '@shared/services/images-data-fetcher.service';
import { SampleDataFetcherService } from '@shared/services/sample-data-fetcher.service';
import { ManagedFolder } from '@shared/utils/managed-folder';
import { SerializedMemTableV2 } from 'src/generated-sources';

export interface DeephubCellData extends CellData {
}

export interface DeephubColumn {
    name: string,
    index?: number
}

export interface DeephubDataColumns {
    target: DeephubColumn,
    itemPath: DeephubColumn
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDeephubDataFetcherService extends SampleDataFetcherService {
    dataColumns: DeephubDataColumns;

    abstract createDataColumns(orderedColumns: string[]): DeephubDataColumns;

    onRefreshSample(sample: SerializedMemTableV2): void {
        this.dataColumns = this.createDataColumns(sample.allColumnNames);
        this.tableData = sample;
        this.totalItems = sample.totalKeptRows;
    }

    // modifies dataColumns object
    addColumnIndices(dataColumns: DeephubDataColumns, orderedColumns: string[]) {
        Object.values(dataColumns).forEach(column => {
            column.index = orderedColumns.indexOf(column.name);
        });
    }

    getImagePath(itemPath: string): string {
        return new ManagedFolder(this.managedFolderLoc, this.projectKey).getImagePath(itemPath);
    }

    abstract rowToCellData(rowContent: string[], i: number, j: number): DeephubCellData;
}
