
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ImagesDataFetcherService } from '@shared/services/images-data-fetcher.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Chunk, InfiniteScrollComponent } from '../infinite-scroll/infinite-scroll.component';

@UntilDestroy()
@Component({
    selector: 'image-feed',
    templateUrl: './image-feed.component.html',
    styleUrls: ['./image-feed.component.less']
})
export class ImageFeedComponent implements AfterViewInit {
    @Input() imageHeight: number;
    @Input() itemsPerRow: number;
    @Input() selectedPath?: string | null;
    @Input() selectedIndex?: number | null;

    @Output() imageClick = new EventEmitter<{ imageId?: number, itemPath?: string }>();
    @ViewChild(InfiniteScrollComponent) viewport: InfiniteScrollComponent;

    loadingStatus$ = new BehaviorSubject<boolean>(false);

    constructor( 
        private dataFetcher: ImagesDataFetcherService
    ) {}

    getChunk = (offset: number): Observable<Chunk> => {
        return this.dataFetcher.getChunk(offset);
    };

    getChunkSize(): number {
        return this.dataFetcher.CHUNK_SIZE;
    }

    ngAfterViewInit(): void {
        this.dataFetcher.dataChanged$.pipe(
            untilDestroyed(this)
        ).subscribe(_ => {
            this.viewport.reset();
        });
    }
}
