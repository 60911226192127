import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { WORKSPACE_STOCK_COLORS } from "@features/workspaces/workspace.constants";
import { Workspace } from "@model-main/workspaces/workspace";

@Component({
    selector: 'workspace-general-edition',
    templateUrl: './workspace-general-edition.component.html',
    styleUrls: ['./workspace-general-edition.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceGeneralEditionComponent {
    readonly stockColors = WORKSPACE_STOCK_COLORS;

    @Input() workspace: Workspace;
    @Input() editionMode?: boolean;
    @Output() workspaceChange = new EventEmitter<Workspace>();

    onDisplayNameChange(): void {
        if (!this.editionMode) {
            this.updateWorkspaceKey()
        }
        this.onWorkspaceChange();
    }

    onWorkspaceChange(): void {
        this.workspaceChange.emit({...this.workspace});
    }

    updateWorkspaceKey(): void {
        this.workspace.workspaceKey = this.workspace.displayName ? this.workspace.displayName.toUpperCase().replace(/\W+/g, "_") : '';
    }

    pickStockColor(color: string): void {
        this.workspace.color = color;
        this.onWorkspaceChange();
    }

}
