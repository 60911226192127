import { Component, Input, OnInit } from '@angular/core';
import { UIAnnotation, UIBoundingBox, UIClassificationAnnotation } from '@features/labeling/models/annotation';
import { LabelingColorService } from '@features/labeling/services/labeling-color.service';
import { LabelingService } from '@features/labeling/services/labeling.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iif } from 'rxjs';
import { LabelingTask } from 'src/generated-sources';
import { LabelingReviewUpdateService } from '../../services/labeling-review-update-service';
import { LabelingReviewAnnotationGroupService } from './../../services/labeling-review-annotation-group.service';

enum ReviewMode {
    INITIAL,
    UPDATE
}

@UntilDestroy()
@Component({
    selector: 'annotation-list',
    templateUrl: './annotation-list.component.html',
    styleUrls: [
        '../../../shared-styles/annotation.less',
        './annotation-list.component.less'
    ]
})
export class AnnotationListComponent implements OnInit {
    @Input() updating: boolean;
    @Input() conflicting?: boolean; // can be undefined in ReviewMode.UPDATE

    annotations: UIAnnotation[];
    classes: string[];
    reviewMode: ReviewMode;
    readonly LabelingTaskType = LabelingTask.LabelingTaskType;
    readonly ReviewMode = ReviewMode;
    UIBoundingBox = UIBoundingBox;
    UIClassificationAnnotation = UIClassificationAnnotation;

    constructor(
        private labelingReviewUpdateService: LabelingReviewUpdateService,
        private labelingReviewAnnotationGroupService: LabelingReviewAnnotationGroupService,
        public labelingColorService: LabelingColorService,
        public labelingService: LabelingService
    ) { }
    
    ngOnInit(): void {
        iif(() => this.updating, this.labelingReviewUpdateService.currentReview$, this.labelingReviewAnnotationGroupService.selectedAnnotationGroup$).pipe(
            untilDestroyed(this)
        ).subscribe(review => {
            this.annotations = review.annotations;
            this.reviewMode = this.updating ? ReviewMode.UPDATE : ReviewMode.INITIAL;
        });

        this.labelingService.classes$.subscribe(classes => this.classes = classes);
    }

    update(modifiedByReviewer?: boolean) {
        if (this.reviewMode === ReviewMode.INITIAL) {
            this.labelingReviewAnnotationGroupService.updateAnnotationGroup(this.annotations, modifiedByReviewer);
        } else {
            this.labelingReviewUpdateService.updateReview(this.annotations);
        }
    }

    color(bbox: UIBoundingBox) {
        return this.labelingColorService.getColorForBBox(bbox);
    }

    selectAnnotation(index: number) {
        const selecting = !this.annotations[index].selected;
        if (selecting) {
            this.annotations.forEach(annotation => {
                annotation.selected = false;
            });
        }
        this.annotations[index].selected = selecting;

        this.update();
    }

    deleteAnnotation(index: number, type: LabelingTask.LabelingTaskType) {
        if (type === LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION && this.annotations.length === 1) {
            this.annotations[0].category = undefined;
            this.annotations[0].annotator = undefined;
        } else {
            this.annotations = this.annotations.filter((_, idx) => idx != index);
        }

        this.update(true);
    }

    resolveAnnotation(index: number) {
        this.annotations = this.annotations.filter((_, idx) => idx === index);

        this.update(true);
    }

    emitNewAnnotations() {
        this.update(true);
    }

    canDelete(annotation: UIAnnotation, type: LabelingTask.LabelingTaskType) {
        return (this.reviewMode === ReviewMode.INITIAL && annotation.annotator) ||
            (this.reviewMode === ReviewMode.UPDATE && type !== LabelingTask.LabelingTaskType.IMAGE_CLASSIFICATION);
    }

    canResolve(numberOfAnnotations: number): boolean {
        return this.reviewMode === ReviewMode.INITIAL && (numberOfAnnotations > 1 || this.conflicting === true);
    }
    
    showEmptyState(annotationCount: number, type: LabelingTask.LabelingTaskType) {
        return this.reviewMode === ReviewMode.UPDATE && !annotationCount && type === LabelingTask.LabelingTaskType.OBJECT_DETECTION;
    }
}

