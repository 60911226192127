import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { SerializedProject, Workspace, ProjectsService, ITaggingService } from '../../../../../generated-sources';
import { DataikuAPIService } from '../../../../core/dataiku-api/dataiku-api.service';
import { AccessibleObjectOption } from '../../../../shared/components/accessible-objects-selector/accessible-objects-selector.component';
import { observeFormControl } from '../../../../utils/form-control-observer';
import { AddObjectsForm, AddObjectsFormValue } from './add-objects.form';
import { TYPES } from './object-types';

const SCHEME_REGEX = /^(\w+):\/\//;

interface WorkspaceObject extends Partial<Omit<AccessibleObjectOption, 'type'>> {
    id: AccessibleObjectOption['id'];
    label: AccessibleObjectOption['label'];
}

interface DssObjectPayload {
    id: string;
    projectKey: string;
    type: string;
}

interface LinkPayload {
    type: string;
    url: string;
    name: string;
    description: string | null;
}

type OnChange = (DssObjectPayload | LinkPayload)[] | null;

interface ProjectAuthorizations extends Partial<SerializedProject.ProjectDashboardAuthorizations> {
    projectKey?: string;
}

@UntilDestroy()
@Component({
    selector: 'dss-workspace-add-objects',
    templateUrl: './workspace-add-objects.component.html',
    styleUrls: ['./workspace-add-objects.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceAddObjectsComponent implements OnInit, OnDestroy {
    @Input() workspace: Workspace;
    @Output() onChange = new EventEmitter<OnChange>();
    @Output() onError = new EventEmitter();

    readonly TYPES = TYPES;
    readonly addObjectsForm = new AddObjectsForm();
    readonly sharableProjects$ = this.getSharableProjects();
    readonly objects$ = this.getObjects();
    readonly projectAuthorizations$ = this.getProjectAuthorizations();
    readonly numObjectsToShare$ = this.getNumObjectsToShare();

    constructor(private readonly dataikuApi: DataikuAPIService) { }

    ngOnInit(): void {
        observeFormControl<AddObjectsFormValue>(this.addObjectsForm).pipe(untilDestroyed(this), tap(() => this.notifyChange())).subscribe();
    }

    ngOnDestroy(): void {
        this.addObjectsForm.onDestroy();
    }

    /** Appends a default scheme if missing. */
    sanitizeURL(event: UIEvent): void {
        if (!event.target) return;

        const url = (event.target as HTMLInputElement).value;
        if (url && !SCHEME_REGEX.test(url)) {
            this.addObjectsForm.url.setValue(`http://${url}`);
        }
    }

    private notifyChange(): void {
        let toEmit: OnChange = null;
        if (this.addObjectsForm.valid) {
            const selectedObjects: WorkspaceObject[] = this.addObjectsForm.objects.value;
            if (!!selectedObjects.length) {
                toEmit = selectedObjects.map(selectedObject => ({
                    id: selectedObject.id,
                    type: this.addObjectsForm.type.value as string,
                    projectKey: this.addObjectsForm.projectKey.value as string,
                }));
            } else {
                toEmit = [this.addObjectsForm.value];
            }
        }
        this.onChange.emit(toEmit);
    }

    private getAccessibleObjects(type: string, projectKey: string | null): Observable<WorkspaceObject[]> {
        if (projectKey === null) {
            return of([]);
        }

        return this.dataikuApi.taggableObjects.listAccessibleObjects(projectKey, type, 'READ')
            .pipe(
                map(objects => {
                    if (type === 'DATASET') {
                        return objects.filter(dataset => dataset.localProject)
                    }
                    return objects;
                }),
                map(objects => objects.map(object =>
                    this.setUsable(object as WorkspaceObject, !this.workspace.workspaceObjects.some(wsObject =>
                        wsObject.reference &&
                        wsObject.reference.type === object.type &&
                        wsObject.reference.id === object.id &&
                        wsObject.reference.projectKey === object.projectKey
                    ))
                ))
            );
    }

    private getAppTemplates(): Observable<WorkspaceObject[]> {
        return this.dataikuApi.apps.listTemplates().pipe(
            map((appTemplates) =>
                appTemplates.items.map((appTemplate) => {
                    const appTemplateObject = { ...appTemplate, localProject: true, id: appTemplate.appId, type: 'APP' };

                    return this.setUsable(appTemplateObject, !this.workspace.workspaceObjects.some(wsObject =>
                        wsObject?.appId === appTemplate.appId
                    ));
                })
            )
        );
    }

    private setUsable(object: WorkspaceObject, usable: boolean): WorkspaceObject {
        const newObject = { ...object };
        newObject.usable = usable;
        if (!usable) {
            newObject.usableReason = '(Already in Workspace)';
        }
        return newObject;
    }

    private computeNumObjectsToShare(type: string, objects: WorkspaceObject[], projectAuthorizations: ProjectAuthorizations): number {
        if (['DATASET', 'ARTICLE', 'WEB_APP'].includes(type) && !!objects.length &&
            !projectAuthorizations.allAuthorized && !!projectAuthorizations.authorizations?.length
        ) {
            return objects.reduce((result, object) =>
                result + (projectAuthorizations.authorizations?.find(({ objectRef }) => objectRef.objectId === object.id && objectRef.objectType === type) ? 0 : 1)
                , 0);
        } else {
            return 0;
        }
    }

    private getObjects(): Observable<WorkspaceObject[]> {
        return combineLatest([
            observeFormControl<string>(this.addObjectsForm.projectKey),
            observeFormControl<string>(this.addObjectsForm.type)
        ]).pipe(
            switchMap(([projectKey, type]) => {
                if (type === 'APP') {
                    return this.getAppTemplates();
                }
                return this.getAccessibleObjects(type, projectKey);
            }),
            this.notifyOnError(),
            shareReplay(1)
        );
    }

    private getProjectAuthorizations(): Observable<SerializedProject.ProjectDashboardAuthorizations> {
        return observeFormControl<string>(this.addObjectsForm.projectKey)
            .pipe(
                filter(projectKey => !!projectKey),
                switchMap(projectKey => this.dataikuApi.projects.getDashboardAuthorizations(projectKey)),
                this.notifyOnError(),
                shareReplay(1),
            );
    }

    private getNumObjectsToShare(): Observable<number> {
        return combineLatest([this.projectAuthorizations$, observeFormControl<WorkspaceObject[]>(this.addObjectsForm.objects)])
            .pipe(
                map(([projectAuthorizations, objects]) => this.computeNumObjectsToShare(this.addObjectsForm.type.value, objects, projectAuthorizations)),
                this.notifyOnError(),
            );
    }

    private getSharableProjects(): Observable<ProjectsService.UIProject[]> {
        return this.dataikuApi.projects.list()
            .pipe(
                map(projects => projects.filter(project => this.isSharableProject(project))),
                this.notifyOnError(),
                shareReplay(1),
            );
    }

    private isSharableProject(project: ProjectsService.UIProject): boolean {
        return project.canShareToWorkspaces;
    }

    private notifyOnError<T>(): OperatorFunction<T, T> {
        return catchError<T, Observable<T>>(err => {
            this.onError.next(err);
            return throwError(err);
        });
    }
}
