<div class="card-layout">
    <div class="card-layout__section card-layout__section--vertical">
        <time-series-summary
            [seriesVariableName]="params.seriesColumn.name"
            [timeVariableName]="params.timeColumn.name"
            [boxPlot]="results.boxPlot"
            [timeInfo]="results.timeInfo"
        ></time-series-summary>

        <div class="card-layout__section card-layout__section--horizontal">
            <div class="card-layout__section">
                <div class="card-layout__title">Hypothesis</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Tested hypothesis</th>
                                <td class="stats-table__cell">{{ params.seriesColumn.name }} has no first order autocorrelation</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Threshold for positive serial correlation</th>
                                <td class="stats-table__cell">&le; 1.5</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Threshold for negative serial correlation</th>
                                <td class="stats-table__cell">&ge; 2.5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-layout__section">
                <div class="card-layout__title">Results</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Statistic</th>
                                <td class="stats-table__cell">{{ results.statistic | smarterNumber }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="test-conclusion">
    <p *ngIf="hasLikelyPositiveCorrelation"
       class="test-conclusion__wording test-conclusion__wording--rejected"
    >
        The statistic (&le; 1.5) shows evidence of positive serial correlation
        in <u>{{ params.seriesColumn.name }}</u>.
    </p>
    <p *ngIf="hasLikelyNegativeCorrelation"
       class="test-conclusion__wording test-conclusion__wording--rejected"
    >
        The statistic (&ge; 2.5) shows evidence of negative serial correlation
        in <u>{{ params.seriesColumn.name }}</u>.
    </p>
    <p *ngIf="isInconclusive"
       class="test-conclusion__wording"
    >
        The statistic does not show enough evidence for serial correlation
        in <u>{{ params.seriesColumn.name }}</u>.
    </p>
</div>
