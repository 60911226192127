import { Injectable } from '@angular/core';
import { DkuHttpService, FutureWatcherService, TypedFutureResponse } from 'dku-frontend-core';
import { Observable } from 'rxjs';
import { MLflowExperimentRunOrigin, MLflowModelDeployResult, SavedModel } from 'src/generated-sources';
import { PrivateSavedModelsClusteringAPI } from './private-saved-models-clustering-api';
import { PrivateSavedModelsPredictionAPI } from './private-saved-models-prediction-api';

@Injectable({ providedIn: 'root' })
export class PrivateSavedModelsAPI {
    constructor(
        private futureWatcher: FutureWatcherService,
        private dkuHttp: DkuHttpService,
        public clustering: PrivateSavedModelsClusteringAPI,
        public prediction: PrivateSavedModelsPredictionAPI
    ) { }

    deployMLflowModel(projectKey: string, predictionType: string, smId: string,
        pythonCodeEnvName: string, folderRef: string, path: string, versionId: string, activate: boolean, dataset: string,
                targetColumn: string, classes: string[], origin: MLflowExperimentRunOrigin): Observable<TypedFutureResponse<MLflowModelDeployResult>> {
        const classLabels = classes?.map((classLabel) => ({label: classLabel}));
        const modelVersionInfo = {
            gatherFeaturesFromDataset: dataset,
            targetColumnName: targetColumn,
            ...(classLabels && {classLabels}),
            pythonCodeEnvName,
            predictionType
        };
        return this.futureWatcher.requestFuture('POST', '/savedmodels/deploy-mlflow', {
            projectKey, smId, folderRef, path, versionId, modelVersionInfo, origin, activate
         });
    }

    createExternalSavedModel(projectKey: string, predictionType: string, name: string): Observable<SavedModel> {
        // not specifying savedModelType as it can only be MLFLOW_PYFUNC for the time being
        return this.dkuHttp.request('POST', '/savedmodels/', {
            projectKey, name, predictionType
        });
    }
}
