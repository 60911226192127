import type {DatasetOrdering} from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/input/dataset-ordering';
import type {ObjectCustomMetadata} from './object-custom-metadata';
import type {PartitioningScheme} from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
import type {Schema} from './../../../../../../../dss-core/src/main/java/com/dataiku/dip/coremodel/schema';
import type {TaggableObjectsService} from './../server/services/taggable-objects-service';

/**
 * Generated from com.dataiku.dip.coremodel.SerializedDataset
 */
export interface SerializedDataset extends TaggableObjectsService._TaggableObject {
    autodetectedFormatId: string;
    checks: SerializedDataset.SerializedCheck[];
    customMeta: ObjectCustomMetadata;
    deserializationContext: any /* TODO: Missing translation of com.google.gson.JsonDeserializationContext */;
    dkuProperties: any /* TODO: Missing translation of com.dataiku.dip.connections.AbstractSQLConnection$CustomDatabaseProperty */[];
    featureGroup: boolean;
    flowOptions: SerializedDataset.FlowOptions;
    formatParams: any /* TODO: Missing translation of com.dataiku.dip.coremodel.FormatParams */;
    formatType: string;
    managed: boolean;
    metrics: any /* TODO: Missing translation of com.dataiku.dip.metrics.ProbesSet */;
    metricsChecks: any /* TODO: Missing translation of com.dataiku.dip.metrics.ChecksSet */;
    metricsChecksRaw: any /* TODO: Missing translation of com.google.gson.JsonElement */;
    metricsRaw: any /* TODO: Missing translation of com.google.gson.JsonElement */;
    name: string;
    overrideTable: any /* TODO: Missing translation of com.dataiku.dip.variables.OverrideTable */;
    params: any /* TODO: Missing translation of com.dataiku.dip.datasets.DatasetHandler$DatasetParams */;
    partitioning: PartitioningScheme;
    projectKey: string;
    readWriteOptions: SerializedDataset.ReadWriteOptions;
    remoteFiles: SerializedDataset.RemoteFiles;
    schema: Schema;
    smartName: string;
    type: string;
}

export namespace SerializedDataset {
    /**
     * Generated from com.dataiku.dip.coremodel.SerializedDataset$RemoteFilesSource
     */
    export interface RemoteFilesSource {
        connection: string;
        path: string;
        protocol: string;
        url: string;
        useConnection: boolean;
        useGlobalProxy: boolean;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedDataset$SerializedCheck
     */
    export interface SerializedCheck {
        params: {[key: string]: string};
        type: string;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedDataset$CrossProjectBuildBehaviour
     */
    export enum CrossProjectBuildBehaviour {
        DEFAULT = 'DEFAULT',
        STOP = 'STOP',
        TRAVERSE = 'TRAVERSE'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedDataset$RebuildBehavior
     */
    export enum RebuildBehavior {
        NORMAL = 'NORMAL',
        EXPLICIT = 'EXPLICIT',
        WRITE_PROTECT = 'WRITE_PROTECT'
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedDataset$ReadWriteOptions
     */
    export interface ReadWriteOptions {
        defaultReadOrdering: DatasetOrdering;
        forceSingleOutputFile: boolean;
        preserveOrder: boolean;
        singleOutputFileBaseName: string;
        writeBuckets: number;
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedDataset$RemoteFiles
     */
    export interface RemoteFiles {
        alwaysCheckSync: boolean;
        sources: SerializedDataset.RemoteFilesSource[];
    }

    /**
     * Generated from com.dataiku.dip.coremodel.SerializedDataset$FlowOptions
     */
    export interface FlowOptions {
        crossProjectBuildBehavior: SerializedDataset.CrossProjectBuildBehaviour;
        dateBlacklist: string;
        flowEpoch: string;
        rebuildBehavior: SerializedDataset.RebuildBehavior;
        virtualizable: boolean;
    }
}