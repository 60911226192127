import { Component, EventEmitter, Input, Output, SimpleChanges, Inject, OnChanges } from '@angular/core';
import { realAny } from 'dku-frontend-core';
import type { IScope } from 'angular';

@Component({
    selector: 'dku-bs-select',
    template: `
    <angularjs-dku-bs-select [params]="params" [list]="list" [model]="model" [ngOptions]="ngOptions" [required]="required"
                            [modelChange]="modelChange" [optionsDescriptions]="optionsDescriptions" [optionsAnnotations]="optionsAnnotations"
                            [dataActionsBox]="dataActionsBox" [dataLiveSearch]="dataLiveSearch"
                            [layout]="layout" [dkuMultiple]="dkuMultiple">
    </angularjs-dku-bs-select>`
})
export class DkuBsSelectComponent implements OnChanges {
    // This is a thin wrapper to allow angular-style "two-way binding"
    // We transform an output (datasetChange) into an output and make it available to an AngularJS watch
    // not sure if there is a better way with AngularUpgrade.

    // ngOptions should always refer to the array as "list"
    // and the array should be given as the "list" param
    // Note than only ngOptions is supported, it's not possible to specify <option> tags
    @Input() params?: object;
    @Input() list: realAny[];
    @Input() optionsDescriptions?: realAny[];
    @Input() optionsAnnotations?: realAny[];
    @Input() ngOptions: string;
    @Input() required?: boolean;
    @Input() model: realAny;
    @Input() layout?: string;
    @Input() dataActionsBox?: boolean;
    @Input() dataLiveSearch?: boolean;
    @Input() dkuMultiple?: boolean;

    // Allow to have 2 way data-binding in templates using the `[(model)]` syntax
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    ngOnChanges() {
        // Run AngularJS change detetion
        this.$rootScope.$applyAsync();
    }

    constructor(@Inject('$rootScope') private $rootScope: IScope) {
    }
}
